export const getTEPositionAnsWidth = (
  StartMinutes: number,
  FinishMinutes: number
) => {
  const denominator = 14.4; //14.4;
  return {
    left: Math.abs(StartMinutes / denominator) + "%",
    width: Math.abs((FinishMinutes - StartMinutes) / denominator) + "%",
  };
};
