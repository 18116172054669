import { Component } from "react";
import FXCard from "../Common/FXCard/FXCard";
import { Button } from "@progress/kendo-react-buttons";
import { IFXCardProps } from "./interfaces";
import styles from "./card.module.scss";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import UserInfo from "../../stores/User";
import Loader from "../Common/Loader";
import { simpleObject } from "../../helpers/interfaces";
import moment from "moment";
import { RunScriptAsync } from "../../helpers/runscripts";
import { showSomeError } from "../../helpers/helpers";

interface props extends IFXCardProps {
  buildPlanId: number;
  currentProgress: number;

  afterSave(): void;
}

interface state {
  initedSettings: boolean;
  invalidMessage: string;
  processing: boolean;
}

class UpdateBudgetProgressCard extends Component<props, state> {
  isFinancialAllow: boolean = false;
  progress: number | undefined = this.props.currentProgress;
  date: Date = new Date();

  constructor(props: props) {
    super(props);
    this.state = {
      initedSettings: false,
      invalidMessage: "",
      processing: false,
    };
  }

  componentDidMount() {
    this.InitSettings();
  }

  render() {
    return (
      <FXCard
        title={`Update Progress`}
        onClose={this.props.finally}
        initialWidth={400}
        initialHeight={200}
        originalPaddings={true}
      >
        <div className={styles.FormWrapper}>
          {!this.state.initedSettings && <Loader />}
          {this.state.initedSettings && (
            <>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ width: 85, flex: "0 0 auto" }}>
                    Progress:{" "}
                  </span>
                  <NumericTextBox
                    onChange={this.OnChangeProgress}
                    defaultValue={this.progress}
                    required={true}
                    width={80}
                    min={0}
                    max={100}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <span
                    style={{
                      width: 85,
                      flex: "0 0 auto",
                    }}
                  >
                    Effective Date:{" "}
                  </span>
                  <DatePicker
                    onChange={(event: DatePickerChangeEvent) => {
                      if (event.value) {
                        this.date = event.value;
                        this.CheckValidity();
                      }
                    }}
                    disabled={!this.isFinancialAllow}
                    defaultValue={new Date()}
                    max={new Date()}
                  />
                </div>
              </div>
              <div className={`${styles.FormFooter} k-action-buttons`}>
                <div style={{ flex: 1 }}>
                  <div style={{ color: "red" }}>
                    {this.state.invalidMessage}
                  </div>
                </div>
                <Button
                  onClick={this.props.finally}
                  disabled={this.state.processing}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    this.state.processing || !!this.state.invalidMessage
                  }
                  onClick={this.UpdateProgress}
                  themeColor="primary"
                >
                  Ok
                </Button>
              </div>
            </>
          )}
        </div>
      </FXCard>
    );
  }

  OnChangeProgress = (event: NumericTextBoxChangeEvent) => {
    this.progress = event.value !== null ? event.value : undefined;
    this.CheckValidity();
  };

  GetInvalidMessage = () => {
    let isValidDate =
      !!this.date && moment(this.date).isSameOrBefore(new Date());
    let isValidProgress =
      this.progress !== undefined && this.progress >= 0 && this.progress <= 100;
    let invalidMessage = "";
    if (!isValidDate) invalidMessage = "Date can not be after current day.";
    if (!isValidProgress)
      invalidMessage = `${invalidMessage}${
        invalidMessage ? " " : ""
      }Progress should be in interval from 0 to 100`;
    return invalidMessage;
  };

  CheckValidity = () => {
    this.setState({ invalidMessage: this.GetInvalidMessage() });
  };

  UpdateProgress = async () => {
    try {
      this.setState({ processing: true });
      let params: simpleObject = {
        BPID: this.props.buildPlanId,
        Progress: this.progress,
      };
      if (this.date) params.EffectiveDate = moment(this.date).format();
      await RunScriptAsync("FSMBuildPlans_UpdateProgress", params);
      this.props.afterSave();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
      this.props.finally?.();
    }
  };

  InitSettings = async () => {
    let IsFinancialDataAllow = await UserInfo.getIsFinancialDataAllow();
    this.isFinancialAllow = !!IsFinancialDataAllow;
    this.setState({
      initedSettings: true,
      invalidMessage: this.GetInvalidMessage(),
    });
  };
}

export default UpdateBudgetProgressCard;
