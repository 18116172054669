import React, { useCallback, useMemo, useRef } from "react";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting } from "../Dashboard/interfaces";
import CardManagement from "../Cards/CardManagement";
import styles from "./tc.module.scss";
import api from "../../core/api/api";
import {
  SQL_TK_GetRequestData_Response_RequestInfo,
  SQL_TK_GetRequestData_Response_WOAllocation,
} from "../../core/api/generated/conterra";
import { TRenderToolbar } from "../TabsNew/interfaces";

interface IProps {
  tcId?: number;
  isActive: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
}

const TCRequests = (props: IProps) => {
  const { tcId, isActive } = props;
  const tcIdRef = useRef<number>();
  const allocationDataRef = useRef<
    SQL_TK_GetRequestData_Response_WOAllocation[]
  >([]);
  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "createdLocal",
      title: "Created (Local)",
      type: "date",
      gridWidth: 130,
      format: {
        type: "datetime",
      },
    },
    {
      field: "requestType",
      title: "Type",
      type: "string",
      minWidth: 150,
      format: {
        type: "string",
        fieldId: "requestType",
      },
    },
    {
      field: "solicitation",
      title: "Solicitation",
      type: "string",
      minWidth: 150,
    },
    {
      field: "description",
      title: "Description",
      type: "string",
      minWidth: 150,
    },
    {
      field: "reasonName",
      title: "Reason",
      type: "string",
      gridWidth: 100,
      format: {
        type: "string",
        fieldId: "reasonId",
      },
    },
    {
      field: "payrollItemName",
      title: "Payroll Item",
      type: "string",
      gridWidth: 180,
      format: {
        type: "string",
        fieldId: "payrollItemId",
      },
    },
    {
      field: "qty",
      title: "QTY",
      type: "number",
      gridWidth: 100,
      format: {
        type: "integer",
      },
    },
    {
      field: "workOrderName",
      title: "Work Order",
      type: "string",
      minWidth: 180,
      format: {
        type: "string",
        fieldId: "workOrderId",
      },
    },
    {
      field: "reviewStarted",
      title: "Review Started",
      type: "date",
      gridWidth: 130,
      format: {
        type: "datetime",
      },
    },
    {
      field: "reviewStage",
      title: "Stage",
      type: "string",
      gridWidth: 100,
      format: {
        type: "string",
        fieldId: "stage",
      },
    },
    {
      field: "rejectionComment",
      title: "Rejection Comment",
      type: "string",
      minWidth: 100,
    },
  ]);
  const gridRefreshRef = useRef<() => void>(() => {});
  const currentTcId = useMemo(() => {
    if (isActive && tcId && tcId !== tcIdRef.current) {
      tcIdRef.current = tcId;
    }
    return tcIdRef.current;
  }, [tcId, isActive]);

  const loadData = useCallback(async () => {
    const [{ requestInfo, woAllocation }] = await api.sql.tkGetRequestData({
      tcId: currentTcId,
    });
    allocationDataRef.current = woAllocation;
    return requestInfo;
  }, [currentTcId]);

  const onSelectRow = useCallback(
    (dataItem: SQL_TK_GetRequestData_Response_RequestInfo | null): any => {
      if (!dataItem) return;
      const allocation =
        dataItem.requestType === "Time Adjustment"
          ? allocationDataRef.current.filter(
              (item) => item.requestId === dataItem.requestId
            )
          : [];
      CardManagement.OpenTKRequestsActionsCard(
        dataItem,
        allocation,
        gridRefreshRef.current
      );
    },
    []
  );

  const initRefresh = useCallback((refresh: any) => {
    gridRefreshRef.current = refresh;
  }, []);

  const getRowKey = useCallback(
    ({ requestId }: SQL_TK_GetRequestData_Response_RequestInfo) =>
      requestId + "",
    []
  );

  if (!currentTcId) return null;
  return (
    <>
      <DashboardGrid
        isActive={isActive}
        key={currentTcId}
        columnsSettings={columnsRef.current}
        getData={loadData}
        getRowKey={getRowKey}
        initRefresh={initRefresh}
        excelFileName="Requests.xlsx"
        columnMenu={{
          filters: true,
          columns: false,
        }}
        toolbar={{
          renderToolbar: props.renderToolbar,
        }}
        selectSettings={{
          pageId: "TKDashboard",
          onSelectRow: onSelectRow,
        }}
        getRowClass={({
          reviewStage,
        }: SQL_TK_GetRequestData_Response_RequestInfo) =>
          reviewStage === "Pending" ||
          reviewStage === "Solicited" ||
          reviewStage === "Approved"
            ? styles.ActiveRequestRow
            : ""
        }
      />
    </>
  );
};

export default TCRequests;
