import FXCard from "../../Common/FXCard/FXCard";
import { IFXCardProps } from "../interfaces";
import React, { useCallback, useEffect, useRef, useState } from "react";
import formStyles from "../card.module.scss";
import { materialItemsCardId } from "./interfaces";
import { IColumnSetting } from "../../Dashboard/interfaces";
import {
  MaterialItemCardDto,
  MaterialItemHistoryDto,
  PropsMaterialItemHistoryDto,
} from "../../../core/api/generated/warehouse";
import api from "../../../core/api/api";
import { MaterialItemCardGeneral } from "./General";
import DashboardGrid from "../../Dashboard/Grid";
import { IRenderToolbarProps } from "../../TabsNew/interfaces";
import MaterialItemCardToolbar from "./MaterialItemCardToolbar";

interface IProps extends IFXCardProps {
  materialItemId: number;
}

const MaterialItemCard = (props: IProps) => {
  const { materialItemId } = props;
  const [generalData, setGeneralData] = useState<MaterialItemCardDto>();
  const [activeTab, setActiveTab] = useState<materialItemsCardId>("general");
  const initedHistoryRef = useRef(false);
  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: PropsMaterialItemHistoryDto.timestamp,
      title: "Date",
      type: "date",
      format: {
        type: "datetime",
      },
    },
    {
      field: PropsMaterialItemHistoryDto.eventType,
      title: "Event",
      type: "string",
      gridWidth: 120,
      format: {
        type: "string",
        fieldId: PropsMaterialItemHistoryDto.eventType,
      },
    },
    {
      field: PropsMaterialItemHistoryDto.userId,
      title: "User Id",
      type: "string",
      gridWidth: 120,
      format: {
        type: "string",
      },
    },
    {
      field: "changesStr", //PropsMaterialItemHistoryDto.changes,
      title: "Changes",
      type: "string",
      gridWidth: 300,
      format: {
        type: "string",
      },
    },
  ]);

  useEffect(() => {
    const LoadGeneral = async () => {
      const result = await api.wh.mi.getById(materialItemId);
      setGeneralData(result);
    };
    LoadGeneral();
  }, []);

  const LoadHistory = useCallback(async () => {
    const result = await api.wh.mi.getHistory(materialItemId);
    return result.map((r) => ({
      ...r,
      // @ts-ignore
      changesStr: JSON.stringify(r.changes),
    }));
  }, [materialItemId]);

  const renderToolbar = useCallback(
    (props?: IRenderToolbarProps) => {
      return (
        <MaterialItemCardToolbar
          activeTabId={activeTab}
          toolbarProps={props}
          onChangeTab={(tabId) => {
            if (tabId === "history") {
              initedHistoryRef.current = true;
            }
            setActiveTab(tabId);
          }}
        />
      );
    },
    [activeTab, setActiveTab]
  );

  const renderGeneral = useCallback(() => {
    if (activeTab !== "general" || !generalData) return null;
    return (
      <MaterialItemCardGeneral
        data={generalData}
        renderToolbar={renderToolbar}
      />
    );
  }, [renderToolbar, generalData, activeTab]);

  const renderHistory = useCallback(() => {
    if (activeTab !== "history" && !initedHistoryRef.current) return null;

    return (
      <div
        style={{
          display: activeTab === "history" ? "flex" : "none",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <DashboardGrid
          isActive={true}
          columnsSettings={columnsRef.current}
          getRowKey={({ timestamp }: MaterialItemHistoryDto) => timestamp}
          getData={LoadHistory}
          toolbar={{
            renderToolbar,
          }}
          columnMenu={{
            filters: true,
            columns: false,
          }}
        />
      </div>
    );
  }, [renderToolbar, LoadHistory, activeTab]);

  return (
    <FXCard
      title={"Material Item " + generalData?.name}
      onClose={props.finally}
      initialWidth={940}
      initialHeight={550}
      originalPaddings={false}
    >
      <div className={formStyles.FormWrapper}>
        {renderGeneral()}
        {renderHistory()}
      </div>
    </FXCard>
  );
};

export default MaterialItemCard;
