import { ETCPerformAction, IProcessedTC } from "../interfaces";
import { IOpenSolicitTKRequest } from "../../../Components/Cards/interfaces";
import CardManagement from "../../../Components/Cards/CardManagement";
import { ModalRef } from "../../../Components/Common/Modal/Modal";
import { TextArea } from "@progress/kendo-react-inputs";
import { OpenRecord, RunScriptLocal } from "../../../helpers/runscripts";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./tkTC.module.scss";
import React, { useCallback, useMemo } from "react";
import ReprocessingTCButton from "./ReprocessingTCButton";
import { isTKCanCloneTCs, isTKManager } from "../../../core/tools/roles";
import {
  getAvailableTCActionsToPerform,
  performTCAction,
} from "../PerformTCActions";
import { PerformActionToSettings } from "../helpers";

const TCActions = (props: {
  tcId: number;
  data: IProcessedTC;
  refreshTC: () => void;
  isIntegrationWithDesktop: boolean;
}) => {
  const { tcId, refreshTC, isIntegrationWithDesktop, data } = props;
  const availableActions = useMemo(() => getAvailableTCActionsToPerform(), []);

  const {
    canComplete,
    canReturn,
    canReprocess,
    employeeID,
    date,
    canChangeAdjustments,
  } = data;

  const solicitTKRequest = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const params: IOpenSolicitTKRequest = {
        employeeId: employeeID,
        date: date,
        afterSave: props.refreshTC,
      };
      CardManagement.OpenSolicitTKRequestCard(params);
    },
    [employeeID, date, props.refreshTC]
  );

  const askCommentForAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const actionName = e.currentTarget.dataset.action as ETCPerformAction;
    if (!actionName) return;
    PerformActionToSettings[actionName];
    let commentRef: any;
    ModalRef.showDialog({
      title: PerformActionToSettings[actionName].title,
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            let comment = commentRef.element.current.value;
            ModalRef.hideDialog();
            performTCAction({
              actionName,
              tcs: [tcId],
              comment,
              OnSuccess: async () => {
                // TODO
                /*if (props.props?.refreshParent) props.props.refreshParent();
                  await loadTCInfo();
                  refreshActiveTab();*/
              },
            });
          },
        },
      ],
      children: (
        <TextArea
          ref={(ref) => {
            commentRef = ref;
            if (ref && ref.focus) {
              ref?.focus();
            }
          }}
          rows={5}
          style={{ width: "100%" }}
        />
      ),
    });
  };

  return (
    <>
      <Button
        className={styles.ListTCAction}
        iconClass={canChangeAdjustments ? "mdi mdi-pencil" : "mdi mdi-earth"}
        fillMode={"flat"}
        title={"Open Adjustments Map"}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          CardManagement.OpenTCMapCard(tcId, refreshTC);
        }}
      />
      <Button
        iconClass={"mdi mdi-email"}
        title={"Solicit TK Request"}
        onClick={solicitTKRequest}
        fillMode={"flat"}
      ></Button>
      {canReprocess && (
        <ReprocessingTCButton
          tcId={tcId}
          refreshActiveTab={refreshTC}
          className={styles.ListTCAction}
        />
      )}
      {isIntegrationWithDesktop && isTKManager() && isTKCanCloneTCs() && (
        <Button
          className={styles.ListTCAction}
          iconClass={"mdi mdi-content-copy"} // file-replace-outline
          fillMode={"flat"}
          title={"Clone"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            RunScriptLocal("TKTimeCards_Clone", { TCID: tcId });
          }}
        />
      )}
      {isIntegrationWithDesktop && (
        <Button
          className={styles.ListTCAction}
          iconClass={"mdi mdi-file-outline"} // text-box-outline
          fillMode={"flat"}
          title={"Open Record"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            OpenRecord("TKTimeCards", tcId);
          }}
        />
      )}
      {canReturn && (
        <Button
          fillMode={"flat"}
          iconClass={PerformActionToSettings[ETCPerformAction.Reject].iconClass}
          title={"Return to Pending"}
          data-action={ETCPerformAction.Reject}
          onClick={askCommentForAction}
        />
      )}
      {canComplete && (
        <Button
          iconClass={
            PerformActionToSettings[ETCPerformAction.Complete].iconClass
          }
          fillMode={"flat"}
          title={"Complete Time Card"}
          data-action={ETCPerformAction.Complete}
          onClick={askCommentForAction}
        />
      )}
      {availableActions.map((action) => {
        if (action === ETCPerformAction.Complete /* && !CanComplete*/)
          return null;
        if (action === ETCPerformAction.Reject /* && !CanReturn*/) return null;
        const { iconClass, title } = PerformActionToSettings[action];
        return (
          <Button
            key={action}
            title={
              /*action === ETCPerformAction.Reject ? "Return to Pending" : */ title
            }
            iconClass={iconClass}
            data-action={action}
            fillMode={"flat"}
            onClick={askCommentForAction}
          />
        );
      })}
    </>
  );
};

export default TCActions;
