import EventEmitter from "eventemitter2";
import { injectable } from "inversify";

import { IAuthInfo, ISettingsStorage } from "../core/interfaces";

@injectable()
export default class SettingsStorage implements ISettingsStorage {
  protected _authInfo: IAuthInfo | undefined;
  private eventEmitter = new EventEmitter();
  private localStorage = window.localStorage;

  constructor() {
    // will not raise event if changed from current tab
    window.addEventListener("storage", (event) => {
      if (event.key) {
        this.eventEmitter.emit(event.key);
      }
    });
  }

  public setAuthInfo(authInfo: IAuthInfo | undefined) {
    this._authInfo = authInfo;
  }

  get(key: string) {
    return this.localStorage.getItem(key) || undefined;
  }

  set(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  getForCurrentUser(key: string) {
    return (
      this.localStorage.getItem(this._getKeyForCurrentUser(key)) || undefined
    );
  }

  setForCurrentUser(key: string, value: string) {
    this.localStorage.setItem(this._getKeyForCurrentUser(key), value);
  }

  removeForCurrentUser(key: string) {
    this.localStorage.removeItem(this._getKeyForCurrentUser(key));
  }

  public subscribeNotMineChanges = (key: string, listener: () => void) => {
    this.eventEmitter.on(key, listener);

    return () => this.eventEmitter.off(key, listener);
  };

  private _getKeyForCurrentUser(key: string) {
    return `${this._authInfo?.InstanceId}_${this._authInfo?.UserId}_${key}`;
  }
}
