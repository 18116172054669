import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import React, { useCallback } from "react";
import { materialItemsCardId } from "./interfaces";
import {
  TabBtnComponent,
  TabsBtnsGroupComponent,
  TabsToolbarComponent,
} from "../../Common/Tabs/TabsComponents";
import { IRenderToolbarProps } from "../../TabsNew/interfaces";

const tabs: { id: materialItemsCardId; name: string }[] = [
  {
    id: "general",
    name: "General",
  },
  {
    id: "history",
    name: "History",
  },
];

const MaterialItemCardToolbar = (props: {
  activeTabId: materialItemsCardId;
  onChangeTab(id: materialItemsCardId): void;
  toolbarProps?: IRenderToolbarProps;
}) => {
  const { activeTabId, onChangeTab, toolbarProps } = props;
  const {
    actionBtns,
    refresh,
    setDefaultFilters,
    isFiltersDefault,
    exportToExcel,
  } = toolbarProps || {};

  const renderTabs = useCallback(
    (activeTabId?: materialItemsCardId) => {
      return (
        <TabsBtnsGroupComponent>
          {tabs.map((t) => (
            <TabBtnComponent
              key={t.id}
              isActive={t.id === activeTabId}
              buttonProps={{
                onClick: () => onChangeTab(t.id),
              }}
            >
              {t.name}
            </TabBtnComponent>
          ))}
        </TabsBtnsGroupComponent>
      );
    },
    [onChangeTab]
  );

  const renderRefreshBtn = useCallback(() => {
    if (!refresh) return null;
    return <Button icon={"refresh"} fillMode={"flat"} onClick={refresh} />;
  }, [refresh]);

  const renderClearFiltersBtn = useCallback(() => {
    if (!setDefaultFilters) return null;
    return (
      <Button
        icon="filter-clear"
        title="Set Default Filters"
        fillMode={"flat"}
        onClick={setDefaultFilters}
        themeColor={!isFiltersDefault ? "primary" : undefined}
      />
    );
  }, [setDefaultFilters, isFiltersDefault]);

  const renderExportToExcelBtn = useCallback(() => {
    if (!exportToExcel) return null;
    return (
      <Button
        icon="file-excel"
        title="Export to Excel"
        fillMode={"flat"}
        onClick={exportToExcel}
      />
    );
  }, [exportToExcel]);

  return (
    <TabsToolbarComponent>
      <>
        {renderTabs(activeTabId)}
        <ToolbarSpacer />
        {actionBtns}
        {renderClearFiltersBtn()}
        {renderExportToExcelBtn()}
        {renderRefreshBtn()}
      </>
    </TabsToolbarComponent>
  );
};

export default MaterialItemCardToolbar;
