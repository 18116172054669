import moment from "moment";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import BaseComponent from "../BaseComponent";
import { GridRowHeight } from "../Dashboard/helpers";
import Loader from "../Common/Loader";
import FXCard from "../Common/FXCard/FXCard";
import { IFXCardProps } from "./interfaces";
import { showSomeError } from "../../helpers/helpers";
import { GetReferenceRecordName } from "../../helpers/queries";

interface IHistoryItem {
  Date: null | string;
  Details: string;
  User: string;
}

interface IProps extends IFXCardProps {
  id: number;
}

interface IState {
  history: Array<IHistoryItem>;
  loading: boolean;
  title: string;
}

class RecordHistory extends BaseComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      title: "",
      history: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.GetTitle();
    this.LoadHistory();
  }

  render() {
    return (
      <FXCard title={this.state.title} onClose={this.props.finally}>
        {this.state.loading && <Loader />}
        <Grid
          style={{ height: "100%" }}
          data={this.state.history}
          resizable={true}
          rowHeight={GridRowHeight}
        >
          <Column field="Date" title="Date" width="150px" />
          <Column field="User" title="User" width="200px" />
          <Column field="Details" title="Details" />
        </Grid>
      </FXCard>
    );
  }

  GetTitle = async () => {
    const title = await GetReferenceRecordName(+this.props.id);
    this.setState({ title });
  };

  LoadHistory = async () => {
    let id = this.props.id;
    if (!id) return;
    try {
      this.setState({ loading: true });
      const result = await this.GetSQLData({
        spName: "GetRecordHistory",
        params: { recordId: id },
      });
      if (!result?.[0]?.length) {
        this.setState({ history: [] });
        return;
      }
      const history = result[0];
      for (let item of history) {
        if (item.Date) item.Date = moment(item.Date).format("L LT");
      }
      this.setState({ history });
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };
}

export default RecordHistory;
