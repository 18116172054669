import { SQL_DB_TK_Review_Response_DispatchInfo } from "../../../core/api/generated/conterra";
import {
  Button,
  Chip,
  ChipHandle,
  ChipMouseEvent,
} from "@progress/kendo-react-buttons";
import React, { useCallback, useEffect } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { Popover } from "@progress/kendo-react-tooltip";
import CardManagement from "../../../Components/Cards/CardManagement";
import { OpenRecord } from "../../../helpers/runscripts";
import styles from "./tkTC.module.scss";

export const DispatchesChips = (props: {
  dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
  onSelectDispatch: (dispatch: INewComboboxItem) => void;
}) => {
  const { dispatches } = props;
  const isShownPopover = useBooleanState(false);
  const anchor = React.useRef<ChipHandle>(null);

  useEffect(() => {
    if (isShownPopover.value) {
      document.addEventListener("click", isShownPopover.setFalse);
    } else {
      document.removeEventListener("click", isShownPopover.setFalse);
    }
    return () => {
      document.removeEventListener("click", isShownPopover.setFalse);
    };
  }, [isShownPopover.value]);

  const onFilterBtnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const { id, name } = event.currentTarget.dataset;
      props.onSelectDispatch({ id: +id!, name: name! });
      isShownPopover.setFalse();
    },
    []
  );

  const onLinkBtnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const { id, woid } = event.currentTarget.dataset;
      if (id) {
        isShownPopover.setFalse();
        CardManagement.OpenDispatchCard({
          newDispatch: false,
          dsId: +id,
        });
      } else if (woid) {
        isShownPopover.setFalse();
        OpenRecord("FSMWorkOrders", +woid);
      }
    },
    []
  );

  const onClickChip = useCallback(
    (e: ChipMouseEvent) => {
      e.syntheticEvent.stopPropagation();
      isShownPopover.toggle();
    },
    [isShownPopover.toggle]
  );

  const renderPopover = () => {
    return (
      <Popover
        show={isShownPopover.value}
        anchor={anchor.current && anchor.current.element}
        callout={false}
        collision={{
          vertical: "flip",
          horizontal: "fit",
        }}
        position={"bottom"}
      >
        <div style={{ margin: -4 }}>
          {dispatches.map(({ id, name, woId }) => {
            if (!name) return null; // todo delete after server fix TS
            return (
              <div key={id || woId} className={styles.DispatchRow}>
                <div className={styles.DispatchName}>{name}</div>
                <Button
                  icon="filter"
                  fillMode="flat"
                  title={"Set Filter by Dispatch"}
                  data-id={id}
                  data-name={name}
                  data-woid={woId}
                  onClick={onFilterBtnClick}
                />
                <Button
                  icon="hyperlink-open"
                  fillMode="flat"
                  title={"Open Card"}
                  data-id={id}
                  data-name={name}
                  data-woid={woId}
                  onClick={onLinkBtnClick}
                />
              </div>
            );
          })}
        </div>
      </Popover>
    );
  };

  return (
    <>
      <Chip
        ref={anchor}
        size={"medium"}
        fillMode={"solid"}
        rounded={"medium"}
        selected={true}
        text={`${dispatches.length} Dispatch${
          dispatches.length > 1 ? "es" : ""
        }`}
        onClick={onClickChip}
      />
      {renderPopover()}
    </>
  );
};
