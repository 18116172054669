import { SQL_WH_BOM_AvailableMaterials_Response } from "../../../core/api/generated/conterra";
import { BomMaterialDetailsDto } from "../../../core/api/generated/warehouse";

export type bomCardTabId = "general" | "materials" | "documents";

export interface IAvailableMaterial
  extends SQL_WH_BOM_AvailableMaterials_Response {
  isSNRequiredStr: "Yes" | "No";
}

export interface IBOMMaterial extends BomMaterialDetailsDto {
  availableWHQTY: number;
  availableTotalQTY: number;
  pickQty: number;
}

/*export type bomActions =
  | "Return BOM"
  | "Complete BOM"
  | "Return(Complete BOM)"
  | "Open Financial Distribution"
  | "Create Purchase Request"
  | "Pick All QTY"
  | "Return to Pending"
  | "Cancel"
  | "Add From Template"
  | "Add Material Items"
  | "Add Materials"
  | "Send to Warehouse";*/

export enum EBomActions {
  "return" = "return",
  "complete" = "complete",
  "returnComplete" = "returnComplete",
  "ofd" = "ofd", // "Open Financial Distribution"
  "mpr" = "mpr", //"Create Purchase Request"
  "pickAllQTY" = "pickAllQTY",
  "returnToPending" = "retirnToPending",
  "cancel" = "cancel",
  "addFromTemplate" = "addFromTemplate",
  "addMaterialItems" = "addMaterialItems",
  "addMaterials" = "addMaterials",
  "send" = "send",
  "kit" = "kit",
}
