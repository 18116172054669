import { Component } from "react";
import BPTabsPage from "../../Pages/BPTabs";
import FXCard from "../Common/FXCard/FXCard";
import { OpenRecord } from "../../helpers/runscripts";
import { GetReferenceRecordName } from "../../helpers/queries";
import styles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import OpenCardIconLink from "./OpenCardIconLink";
import { IInitialTabSettings, tabId } from "../Tabs/interfaces";

interface state {
  Name: string;
}

interface props extends IFXCardProps {
  cardId: string;
  Id: number;
  initialTab: tabId | IInitialTabSettings;
}

class BPCard extends Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      Name: "",
    };
  }

  componentDidMount() {
    this.GetBPName();
  }

  render() {
    return (
      <FXCard
        title={
          <>
            <OpenCardIconLink onClick={this.OpenBP} title="Open BP" />
            <span className={styles.HeaderTitle}>
              {this.state.Name || "Loading..."}
            </span>
          </>
        }
        onClose={this.props.finally}
      >
        <BPTabsPage
          rowData={{ BPID: this.props.Id }}
          parentId={this.props.initialTab ? "LiveMap" : "BPCard"}
          isActive={true}
          initialTab={this.props.initialTab}
        />
      </FXCard>
    );
  }

  OpenBP = () => {
    OpenRecord("FSMBuildPlans", this.props.Id);
  };

  GetBPName = async () => {
    const Name = await GetReferenceRecordName(+this.props.Id);
    this.setState({ Name });
  };
}

export default BPCard;
