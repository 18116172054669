import { useBooleanState } from "../../../core/tools/Hooks";
import { RunScriptAsync } from "../../../helpers/runscripts";
import { ModalRef } from "../../../Components/Common/Modal/Modal";
import { showSomeError } from "../../../helpers/helpers";
import ProcessingButton from "../../../Components/Common/Form/ProcessingButton";

const ReprocessingTCButton = (props: {
  tcId: number;
  refreshActiveTab: () => void;
  className?: string;
}) => {
  const processing = useBooleanState(false);

  const reprocessTc = async () => {
    ModalRef.startProcessing();
    processing.setTrue();
    RunScriptAsync("ADM_ReprocessTimeCard", { TCID: props.tcId })
      .catch(showSomeError)
      .finally(() => {
        processing.setFalse();
        ModalRef.stopProcessing();
        props.refreshActiveTab();
      });
  };

  return (
    <ProcessingButton
      ButtonProps={{
        onClick: reprocessTc,
        title: "Reprocess Time Card",
        fillMode: "flat",
        iconClass: "mdi mdi-file-refresh-outline",
        className: props.className,
      }}
      processing={processing.value}
      loaderThemeColor={"dark"}
    />
  );
};

export default ReprocessingTCButton;
