import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { useRef, useState } from "react";

const ContextMenuListContainer = (props: {
  htmlId: string;
  children: any;
  itemClassName: string;
  onSelect(workShiftId: number): void;
}) => {
  const [position, setPosition] = useState<
    | {
        left: number;
        top: number;
      }
    | undefined
  >(undefined);
  const workShiftIdRef = useRef<number | undefined>(undefined);
  const closeContextMenu = () => {
    setPosition(undefined);
    workShiftIdRef.current = undefined;
    document.removeEventListener("click", closeContextMenu);
  };

  const openContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    const container = e.target.classList.contains(props.itemClassName)
      ? e.target
      : // @ts-ignore
        e.nativeEvent.target.closest("." + props.itemClassName);
    if (!container) return;
    const { workshiftid } = container.dataset;
    if (!workshiftid) return;

    e.nativeEvent.preventDefault();
    workShiftIdRef.current = +workshiftid;
    const { clientX: left, clientY: top } = e;
    setPosition({ left, top });
    document.addEventListener("click", closeContextMenu);
  };

  const onSelect = async () => {
    if (!workShiftIdRef.current) return;
    props.onSelect(workShiftIdRef.current);
  };

  return (
    <>
      <div id={props.htmlId} onContextMenu={openContextMenu}>
        {props.children}
      </div>
      <Popup offset={position || undefined} show={!!position}>
        <Menu vertical={true} onSelect={onSelect} className={"k-context-menu"}>
          <MenuItem text="Recalculate Workshift"></MenuItem>
        </Menu>
      </Popup>
    </>
  );
};

export default ContextMenuListContainer;
