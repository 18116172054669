import { useLayoutEffect, useState } from "react";
import { DocumentExtentions } from "./interfaces";
import { DOCS_EXT, EMBED_BROWSER_EXT, IMAGES_EXT, VIDEOS_EXT } from "./helpers";
import styles from "./documentViewer.module.scss";
import Loader from "../Loader";
import Viewer from "react-viewer";
import { Button } from "@progress/kendo-react-buttons";

interface IProps {
  containerId: string;
  document:
    | {
        id: number;
        extension: DocumentExtentions;
        previewUrl?: string | null;
        alt?: string;
      }
    | undefined;
  documentLoading: boolean;
}

const DocumentViewer = (props: IProps) => {
  const { document, documentLoading } = props;
  const containerId: string = `${props.containerId}-viewer`;
  const ext = document?.extension.toLowerCase();
  const [hasContainer, setHasContainer] = useState(false);

  useLayoutEffect(() => {
    if (getContainer()) setHasContainer(true);
  });

  const renderContent = () => {
    const cont = getContainer();
    if (!hasContainer || !cont) return <div>Content no</div>;
    return renderDocumentPreview(cont);
  };

  const getContainer = () => window.document.getElementById(containerId);

  const renderDocumentPreview = (container: HTMLElement) => {
    if (!ext || !document || !document.previewUrl)
      return <div className={styles.DetailsNoData}>No Document</div>;
    if (IMAGES_EXT.indexOf(ext) > -1) {
      return (
        <>
          <Viewer
            container={container}
            visible={true}
            images={[{ src: document?.previewUrl, alt: document?.alt || "" }]}
            noClose={true}
            noNavbar={true}
            changeable={false}
            showTotal={false}
            scalable={false}
            zoomSpeed={0.65}
            loop={false}
            minScale={1}
            disableKeyboardSupport={true}
          />
          <Button
            key={"imgbtn" + document.id}
            title="Print"
            className={styles.PrintBtn}
            icon="print"
            onClick={PrintImg}
          />
        </>
      );
    } else if (
      DOCS_EXT.indexOf(ext) > -1 ||
      EMBED_BROWSER_EXT.indexOf(ext) > -1
    ) {
      return (
        <iframe
          title="Document"
          src={document.previewUrl}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      );
    } else if (VIDEOS_EXT.indexOf(ext.toLowerCase()) > -1) {
      return (
        <video
          data-file-id={document.id}
          id="video-player"
          src={document.previewUrl}
          controls
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else {
      return (
        <div>File preview is not supported. Please use download button.</div>
      );
    }
  };

  const PrintImg = (e: any) => {
    if (!document || !document.previewUrl) return;
    let win = window.open();
    if (win) {
      win.document.write(
        '<img style="max-width: 100%; min-width: 300px;" onload="imgOnload()" src="' +
          document.previewUrl +
          '"><script>window.imgOnload = function(){ window.print(); }</script>'
      );
      win.document.close();
      win.focus();
      win.onafterprint = win.close;
    }
  };

  return (
    <div className={styles.DocumentContainer} id={containerId}>
      {documentLoading ? <Loader /> : renderContent()}
    </div>
  );
};

export default DocumentViewer
