import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import moment from "moment/moment";
import React, { useCallback, useContext, useMemo } from "react";
import styles from "./tkTC.module.scss";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import { IProcessedTC } from "../interfaces";
import { formatTimeDiff } from "../../../core/tools/formats";
import { useBooleanState } from "../../../core/tools/Hooks";
import {
  OpenRecord,
  RunScriptAsync,
  RunScriptLocal,
} from "../../../helpers/runscripts";
import { showSomeError } from "../../../helpers/helpers";
import LoaderComponent from "../../../Components/Common/Loader";
import { loadTimecards, ROW_HEIGHT } from "../helpers";
import { HorizontalTimeline } from "../../../Components/TC/HorizontalTimeline";
import { DispatchesChips } from "./DispatchesChips";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { tabId } from "../../../Components/Tabs/interfaces";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { isTKCanCloneTCs, isTKManager } from "../../../core/tools/roles";
import { STATE_ID_TO_NAME } from "./helpers";
import { QuickChatHistory } from "./QuickChatHistory";
import TCActions from "./Actions";
import CardManagement from "../../../Components/Cards/CardManagement";

export const TKListTimeCard = (props: {
  data: IProcessedTC;
  onToggleCheck: (event: CheckboxChangeEvent) => void;
  checkBoxRefresher: number;
  checkedTcs: number[];
  pushUpdateTC: (tc: IProcessedTC) => void;
  setDispatchFilter(dispatch: INewComboboxItem): void;
  onClick(tc: IProcessedTC | null, initialTabId?: tabId | undefined): void;
  isCompareMode: boolean;
  onToggleCompareCheck(tc: IProcessedTC, value: boolean): void;
  isSelectedForComparing: boolean;
  isSelected: boolean;
}) => {
  const {
    reviewState,
    id,
    date,
    reviewerName,
    canReprocess,
    employeeName,
    isPossibleActions,
    hasPW,
    activeRequests,
    importantAuditResults,
    // IsSelected,
    isCrewLead,
    isRelated,
    warnings,
    allocationStatus,
    auditedHours,
    clockedHours,
    approvedHours,
    lunchHours,
    firstClockIn,
    lastClockOut,
    timeLine,
    adjustedTimeLine,
    dispatches,
    reasonName,
    canChangeAdjustments,
    notConfirmedAttestationCount,
    commentCount,
  } = props.data;
  const {
    checkBoxRefresher,
    checkedTcs,
    isCompareMode,
    isSelectedForComparing,
    isSelected,
  } = props;
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  const isLoading = useBooleanState(false);
  const isCheckedDefault = useMemo(() => {
    return checkedTcs.findIndex((tcId) => tcId === id) > -1;
  }, [checkBoxRefresher, checkedTcs]);

  const reprocess = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      try {
        isLoading.setTrue();
        await RunScriptAsync("ADM_ReprocessTimeCard", { TCID: id }, false);
        await refreshTC();
      } catch (e) {
        showSomeError(e);
      } finally {
        isLoading.setFalse();
      }
    },
    []
  );

  const refreshTC = useCallback(async () => {
    try {
      isLoading.setTrue();
      const data = await loadTimecards(
        {
          tcId: id,
        },
        isSelected ? id : null
      );
      const updatedTC = data[0];
      if (updatedTC) {
        props.pushUpdateTC(updatedTC);
      }
    } catch (e) {
      showSomeError(e);
    } finally {
      isLoading.setFalse();
    }
  }, [isLoading, props.data, props.pushUpdateTC]);

  const toggleCompare = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      props.onToggleCompareCheck(props.data, !isSelectedForComparing);
    },
    [props.onToggleCompareCheck, props.data, isSelectedForComparing]
  );

  const renderActions = () => {
    return (
      <>
        <div className={styles.Actions}>
          <Button
            className={styles.ListTCAction}
            iconClass={
              canChangeAdjustments ? "mdi mdi-pencil" : "mdi mdi-earth"
            }
            fillMode={"flat"}
            title={"Open Adjustments Map"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              CardManagement.OpenTCMapCard(id, refreshTC);
            }}
          />
          <Button
            className={styles.ListTCAction}
            iconClass={"mdi mdi-refresh"} // ?? delete?
            fillMode={"flat"}
            title={"Refresh"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              refreshTC();
            }}
          />
          {canReprocess && (
            <Button
              className={styles.ListTCAction}
              iconClass={"mdi mdi-file-refresh-outline"}
              fillMode={"flat"}
              title="Reprocess TC"
              onClick={reprocess}
            />
          )}
          {isTKManager() && isTKCanCloneTCs() && isIntegrationWithDesktop && (
            <Button
              className={styles.ListTCAction}
              iconClass={"mdi mdi-content-copy"} // file-replace-outline
              fillMode={"flat"}
              title={"Clone"}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                RunScriptLocal("TKTimeCards_Clone", { TCID: id });
              }}
            />
          )}
          {isIntegrationWithDesktop && (
            <Button
              className={styles.ListTCAction}
              iconClass={"mdi mdi-file-outline"} // text-box-outline
              fillMode={"flat"}
              title={"Open Record"}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                OpenRecord("TKTimeCards", id);
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderCheckBox = () => {
    if (isCompareMode) {
      return (
        <div className={styles.Check}>
          <span
            style={{
              color: isSelectedForComparing
                ? "var(--color-primary)"
                : undefined,
            }}
            className={`${styles.CompareCheckIcon} ${
              isSelectedForComparing
                ? "mdi mdi-checkbox-marked-circle-outline"
                : "mdi mdi-checkbox-blank-circle-outline"
            }`}
            onClick={toggleCompare}
          ></span>
        </div>
      );
    }
    return (
      <div className={styles.Check}>
        {isPossibleActions && (
          <Checkbox
            key={id + checkBoxRefresher}
            id={id + "_tc"}
            label={" "}
            name={id + ""}
            size={"large"}
            defaultChecked={isCheckedDefault}
            onChange={props.onToggleCheck}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    );
  };

  const renderEmployee = () => {
    return (
      <div className={styles.EmployeeContainer}>
        {/*{IsCrewLead && (
          <span
            title={"Crew Lead"}
            className={`mdi mdi-star ${styles.CrewLead}`}
          ></span>
        )}*/}
        <span
          className={styles.EmployeeName}
          style={isCrewLead ? { fontWeight: 600 } : {}}
          title={`${isCrewLead ? "Crew Lead: " : ""}${employeeName}`}
        >
          {employeeName}
        </span>
        {!!commentCount && <QuickChatHistory tcId={id} count={commentCount} />}
      </div>
    );
  };

  const renderDate = () => (
    <div className={styles.Date}>{moment(date).format("L")}</div>
  );

  const renderClockInOut = () => {
    return (
      <div className={styles.ClockInOut}>
        {!!firstClockIn && (
          <>
            <span
              className={"mdi mdi-circle-medium"}
              style={{ color: "green" }}
            ></span>
            <span title={"First Clock In"}>
              {moment(firstClockIn).format("LT")}
            </span>
          </>
        )}
        {!!lastClockOut && (
          <>
            <span
              className={"mdi mdi-circle-medium"}
              style={{ color: "red" }}
            ></span>
            <span title={"Last Clock Out"}>
              {moment(lastClockOut).format("LT")}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderTimeFormula = () => {
    if (!clockedHours && !lunchHours && !auditedHours && !approvedHours)
      return null;
    return (
      <div className={styles.TimeFormula}>
        <span title={"Clocked"}>
          {formatTimeDiff((clockedHours || 0) * 3600)}
        </span>
        <span> - </span>
        <span title={"Lunch"} style={{ color: "orange" }}>
          {formatTimeDiff((lunchHours || 0) * 3600)}
        </span>
        <span> - </span>
        <span style={{ color: "red" }} title={"Audited"}>
          {formatTimeDiff((auditedHours || 0) * 3600)}
        </span>
        <span> = </span>
        <span style={{ color: "green" }} title={"Approved"}>
          {formatTimeDiff((approvedHours || 0) * 3600)}
        </span>
      </div>
    );
  };

  const renderAllocationStatus = () => {
    if (allocationStatus === "Done") {
      return (
        <Chip
          size={"medium"}
          themeColor={"success"}
          fillMode={"solid"}
          rounded={"medium"}
          text={"Allocation Done"}
          disabled={true}
          selected={true}
        />
      );
    }
    if (allocationStatus === "Pending") {
      return (
        <Chip
          size={"medium"}
          themeColor={"warning"}
          fillMode={"solid"}
          rounded={"medium"}
          text={"Pending Allocation"}
          disabled={true}
          selected={true}
        />
      );
    }
  };

  const renderChips = () => {
    return (
      <>
        {!!dispatches.length && (
          <DispatchesChips
            dispatches={dispatches}
            onSelectDispatch={props.setDispatchFilter}
          />
        )}
        {!!reasonName && (
          <Chip
            className={styles.ReasonChip}
            themeColor={"info"}
            size={"medium"}
            fillMode={"solid"}
            rounded={"medium"}
            text={`Reason: ${reasonName}`}
            disabled={true}
            selected={true}
          />
        )}
        {renderAllocationStatus()}
        {hasPW && (
          <Chip
            size={"medium"}
            fillMode={"solid"}
            rounded={"medium"}
            text={"PW"}
            disabled={true}
            themeColor={"info"}
            className={styles.PWChip}
            selected={true}
          />
        )}
        {!!importantAuditResults && (
          <Chip
            size={"medium"}
            themeColor={"error"}
            fillMode={"solid"}
            rounded={"medium"}
            selected={true}
            text={`${importantAuditResults} Important Audit${
              importantAuditResults > 1 ? "s" : ""
            }`}
            onClick={(e) => {
              e.syntheticEvent.stopPropagation();
              props.onClick(props.data, "TCAudits");
            }}
          />
        )}

        {!!activeRequests && (
          <Chip
            size={"medium"}
            themeColor={"error"}
            fillMode={"solid"}
            rounded={"medium"}
            selected={true}
            text={`${activeRequests} Active Request${
              activeRequests > 1 ? "s" : ""
            }`}
            onClick={(e) => {
              e.syntheticEvent.stopPropagation();
              props.onClick(props.data, "TCRequests");
            }}
          />
        )}

        {!!notConfirmedAttestationCount && (
          <Chip
            size={"medium"}
            themeColor={"error"}
            fillMode={"solid"}
            rounded={"medium"}
            selected={true}
            text={`${notConfirmedAttestationCount} Not Confirmed Attestation${
              notConfirmedAttestationCount > 1 ? "s" : ""
            }`}
            onClick={(e) => {
              e.syntheticEvent.stopPropagation();
              props.onClick(props.data, "TCAttestations");
            }}
          />
        )}

        {!!warnings && (
          <Chip
            className={styles.WarningChip}
            size={"medium"}
            themeColor={"warning"}
            fillMode={"solid"}
            rounded={"medium"}
            text={warnings}
            disabled={true}
            selected={true}
          />
        )}
      </>
    );
  };

  if (isLoading.value) {
    return (
      <div
        style={{
          height: ROW_HEIGHT + "px",
          position: "relative",
        }}
      >
        <LoaderComponent />
      </div>
    );
  }

  return (
    <div
      className={`${styles.Container} ${
        isCompareMode && !isSelected ? styles.CompareMode : ""
      } ${isSelected ? styles.SelectedTC : ""}`}
      style={{ height: ROW_HEIGHT + "px" }}
      onClick={() => props.onClick(props.data)}
    >
      <div className={styles.Content}>
        <div className={styles.LeftCol}>
          <Tooltip anchorElement="target" showCallout={false}>
            <div className={styles.Row}>
              {!isSelected && renderCheckBox()}
              {renderDate()}
              {renderEmployee()}
            </div>
          </Tooltip>
          <div className={`${styles.Row} ${styles.ChipsRow}`}>
            {renderChips()}
          </div>
          {isTKManager() && (
            <div className={`${styles.Row} ${styles.Reviewer}`}>
              Reviewer: {reviewerName}
            </div>
          )}
        </div>

        <div className={styles.RightCol}>
          <Tooltip
            anchorElement="target"
            showCallout={false}
            parentTitle={true}
          >
            <div className={styles.Row}>
              {renderClockInOut()}
              {renderTimeFormula()}
              <div style={{ flex: 1 }}></div>
              <div className={styles.Actions}>
                <TCActions
                  tcId={id}
                  isIntegrationWithDesktop={isIntegrationWithDesktop}
                  refreshTC={refreshTC}
                  data={props.data}
                />
              </div>
              {isTKManager() && (
                <div className={styles.Status}>
                  <span>{STATE_ID_TO_NAME[reviewState]}</span>
                </div>
              )}
            </div>
          </Tooltip>
          <div style={{ maxWidth: 750 }}>
            <div className={`${styles.Row}`}>
              <HorizontalTimeline
                timeLine={timeLine}
                adjustedTimeLine={adjustedTimeLine}
                dispatches={dispatches}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
