import { tabId } from "../Tabs/interfaces";
import { Badge } from "@progress/kendo-react-indicators";
import React from "react";
import styles from "./tabsnew.module.scss";
import { TabBtnComponent } from "../Common/Tabs/TabsComponents";

interface IProps {
  tabId: tabId;
  text: string;
  counter?: number | string;
  isActive: boolean;
  onChange: (tabId: tabId) => void;
}

const TabBtn = (props: IProps) => {
  const { tabId, isActive, text, counter } = props;
  return (
    <>
      <TabBtnComponent
        isActive={isActive}
        buttonProps={{
          onClick: () => props.onChange(tabId),
          key: "materials",
        }}
      >
        <>
          {text}
          {!!counter && (
            <Badge
              themeColor="error"
              rounded={"full"}
              className={styles.TabBtnBadge}
              position={"inside"}
            >
              {counter}
            </Badge>
          )}
        </>
      </TabBtnComponent>
    </>
  );
};

export default TabBtn;
