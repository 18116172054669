import React, { useContext } from "react";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import { RunScriptLocal } from "../../../helpers/runscripts";
import { SQL_DB_Inbox_GetJobInfo_Response_Attachments } from "../../../core/api/generated/conterra";
import styles from "./inboxCard.module.scss";

export default function InboxAttachmentLink(props: {
  data: SQL_DB_Inbox_GetJobInfo_Response_Attachments;
}) {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  const { id, name, type } = props.data;
  return (
    <div
      className={`${styles.Attachment} ${
        isIntegrationWithDesktop ? styles.AttachmentAsLink : ""
      }`}
      onClick={
        isIntegrationWithDesktop
          ? () => {
              RunScriptLocal("ConterraObject_Open", {
                ObjectID: id,
                Type: type,
              });
            }
          : undefined
      }
    >
      <span
        className={`mdi mdi-card-text-outline ${styles.AttachmentIcon}`}
      ></span>
      <span className={styles.AttachmentText}>{name || "Null"}</span>
    </div>
  );
}
