import { ToolbarSpacer } from "@progress/kendo-react-buttons";
import React, { useCallback } from "react";
import { bomCardTabId } from "./interfaces";
import {
  TabBtnComponent,
  TabsBtnsGroupComponent,
  TabsToolbarComponent,
} from "../../Common/Tabs/TabsComponents";

const tabs: { id: bomCardTabId; name: string }[] = [
  {
    id: "general",
    name: "General",
  },
  {
    id: "materials",
    name: "Materials",
  },
  {
    id: "documents",
    name: "Documents",
  },
];

const BomCardToolbar = (props: {
  activeTabId: bomCardTabId;
  onChangeTab(id: bomCardTabId): void;
  actions?: JSX.Element | JSX.Element[];
}) => {
  const {
    activeTabId,
    onChangeTab,
    actions,
    // exportToExcel,
  } = props;

  const renderTabs = useCallback(
    (activeTabId?: bomCardTabId) => {
      return (
        <TabsBtnsGroupComponent>
          {tabs.map((t) => (
            <TabBtnComponent
              key={t.id}
              isActive={t.id === activeTabId}
              buttonProps={{
                onClick: () => onChangeTab(t.id),
              }}
            >
              {t.name}
            </TabBtnComponent>
          ))}
        </TabsBtnsGroupComponent>
      );
    },
    [
      /*OnClickTab*/
    ]
  );

  /*const renderExportToExcelBtn = useCallback(() => {
    if (!exportToExcel) return null;
    return (
      <Button
        icon="file-excel"
        title="Export to Excel"
        fillMode={"flat"}
        onClick={exportToExcel}
      />
    );
  }, [exportToExcel]);*/

  return (
    <TabsToolbarComponent>
      <>
        {renderTabs(activeTabId)}
        <ToolbarSpacer />
        <>
          {actions}
          {/*{renderExportToExcelBtn()}*/}
        </>
      </>
    </TabsToolbarComponent>
  );
};

export default BomCardToolbar;
