import { SQL_DB_TK_Review_Response_DispatchInfo } from "../../../core/api/generated/conterra";
import styles from "./tkTimeLine.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React from "react";
import { getTEPositionAnsWidth } from "./helpers";
import { DispatchTooltipContent } from "./DispatchTooltipContent";

export const DispatchFlagItem = (props: {
  isStart: boolean;
  minutes: number;
  dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
}) => {
  const { isStart, dispatches, minutes } = props;
  const { left } = getTEPositionAnsWidth(minutes, minutes);
  return (
    <div
      className={`${styles.DispatchFlagTE}`}
      style={{
        position: "absolute",
        left,
        top: "100%",
        height: isStart ? 16 : 10,
        width: 1,
        background: "black",
      }}
    >
      <Tooltip
        style={{ maxWidth: 450 }}
        content={() => <DispatchTooltipContent dispatches={dispatches} />}
        anchorElement="target"
        showCallout={false}
        parentTitle={true}
      >
        <div
          className={`${styles.DispatchFlag} ${
            !isStart ? styles.FinishDispatchFlag : ""
          }`}
          title={dispatches.length + ""}
        ></div>
      </Tooltip>
    </div>
  );
};
