const isProduction = process.env.REACT_APP_ENV === 'production'

const config = {
  SEQ_URL: "https://seq.fieldclix.com",
  MAP_QUEST_KEY: "SoMm2tfHQ6fueiRsj6gfFMhGiIA2BM4r",
  API_URL: isProduction ? "https://api.fieldclix.com/" : "https://api.dev.fieldclix.com/",
  SEQ_KEY: isProduction ? "2AMmahQudyoRdlnRoWpu" : "tMuQHgghjzryuUSRefs0",
};

export default config;
