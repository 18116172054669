import Bluebird from "bluebird";
import diContainer from "../../inversify.config";
import DI_TYPES from "../diTypes";
import { ILogger } from "../interfaces";

const logger = diContainer.get<ILogger>(DI_TYPES.ILogger);

Bluebird.config({
  longStackTraces: true,
  cancellation: true,
});
global.Promise = Bluebird.Promise as any;
// @ts-ignore
global.onunhandledrejection = function onunhandledrejection(error: unknown) {
  // Warning: when running in "remote debug" mode (JS environment is Chrome browser),
  // this handler is called a second time by Bluebird with a custom "dom-event".
  // We need to filter this case out:
  if (error instanceof Error) {
    logger.fatalException(error, "Unhandled Promise Rejection");
  }
};
