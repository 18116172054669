import React, { useCallback, useMemo, useRef } from "react";

import api from "../../core/api/api";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { SQL_DB_TK_GetTimeCardAudits_Response } from "../../core/api/generated/conterra";
import styles from "./tc.module.scss";
import { TRenderToolbar } from "../TabsNew/interfaces";
import { IColumnSetting } from "../Dashboard/interfaces";

interface IProps {
  tcId?: number;
  isActive: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
}

const TCAuditResults = (props: IProps) => {
  const { tcId, isActive } = props;
  const tcIdRef = useRef<number>();
  const gridRefreshRef = useRef<() => void>(() => {});
  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "rowNum",
      title: "#",
      type: "string",
      aggregate: "count",
      gridWidth: 40,
      filterable: false,
      columnMenu: false,
    },
    {
      field: "auditRuleName",
      title: "Audit Rule",
      type: "string",
      gridWidth: 200,
      format: {
        type: "string",
        fieldId: "auditRuleId",
      },
    },
    {
      field: "resultText",
      title: "Text",
      type: "string",
      gridWidth: 250,
      format: {
        type: "string",
      },
    },
    {
      field: "adjustment",
      title: "Adjustment",
      type: "number",
      gridWidth: 100,
      format: {
        type: "number",
      },
    },
  ]);
  const currentTcId = useMemo(() => {
    if (isActive && tcId && tcId !== tcIdRef.current) {
      tcIdRef.current = tcId;
    }
    return tcIdRef.current;
  }, [tcId, isActive]);

  const loadData = useCallback(async () => {
    return await api.sql.dbTkGetTimeCardAudits({ tcId: currentTcId });
  }, [currentTcId]);

  const initRefresh = useCallback((refresh: any) => {
    gridRefreshRef.current = refresh;
  }, []);

  const getRowKey = useCallback(
    ({ rowNum }: SQL_DB_TK_GetTimeCardAudits_Response) => rowNum + "",
    []
  );

  const getRowClass = useCallback(
    ({ isImportant }: SQL_DB_TK_GetTimeCardAudits_Response) =>
      isImportant ? styles.ImportantAuditResultRow : "",
    []
  );

  if (!currentTcId) return null;

  return (
    <DashboardGrid
      key={currentTcId}
      isActive={isActive}
      initRefresh={initRefresh}
      columnsSettings={columnsRef.current}
      getData={loadData}
      getRowKey={getRowKey}
      getRowClass={getRowClass}
      excelFileName="TCAuditResults.xlsx"
      columnMenu={{
        filters: true,
        columns: false,
      }}
      toolbar={{
        renderToolbar: props.renderToolbar,
      }}
    />
  );
};

export default TCAuditResults;
