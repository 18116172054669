import React, { useCallback } from "react";
import moment from "moment/moment";
import formStyles from "../card.module.scss";
import { MaterialItemCardDto } from "../../../core/api/generated/warehouse";
import { IRenderToolbarProps } from "../../TabsNew/interfaces";

export const MaterialItemCardGeneral = (props: {
  data: MaterialItemCardDto;
  renderToolbar(props?: IRenderToolbarProps): JSX.Element;
}) => {
  const { data } = props;

  const renderReadOnlyValue = useCallback(
    (label: string, value: string, isLargeLabel?: boolean) => (
      <div className={formStyles.Row}>
        <div
          className={formStyles.FormFieldLabel}
          style={isLargeLabel ? { width: 120 } : undefined}
        >
          {label}:
        </div>
        <div>
          <div className={formStyles.ReadOnlyField}>{value}</div>
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      {props.renderToolbar()}
      <div style={{ padding: 12 }}>
        <div
          className={`${formStyles.Row}`}
          style={{ alignItems: "flex-start", gap: 12 }}
        >
          <div className={formStyles.RowCell}>
            <div
              className={formStyles.Row}
              style={{
                alignItems: "flex-start",
                padding: "5px 0",
              }}
            >
              <div className={formStyles.FormFieldLabel}>Material:</div>
              <div>
                <div
                  className={formStyles.ReadOnlyField}
                  style={{ padding: 0, minHeight: "auto" }}
                >
                  {data.material.name}
                </div>
              </div>
            </div>
            {renderReadOnlyValue("SN", data.sn, false)}
            {renderReadOnlyValue("Stage", data.stage.title, false)}
            {renderReadOnlyValue("Package", data.shipmentPackage.name, false)}
            {renderReadOnlyValue(
              "Warehouse",
              data.warehouse?.name || "",
              false
            )}
            {renderReadOnlyValue("Area", data.warehouseArea?.name || "", false)}
            {renderReadOnlyValue(
              "Created",
              moment(data.createdAt).format("L"),
              false
            )}
          </div>
          <div className={formStyles.RowCell}>
            {renderReadOnlyValue("Vendor", data.vendor.name, true)}
            {renderReadOnlyValue(
              "Vendor Part NO",
              data.vendorPartNo || "",
              true
            )}
            {renderReadOnlyValue(
              "MFG Product #",
              data.mfgProductNo || "",
              true
            )}
            {renderReadOnlyValue(
              "Build Plan",
              data.buildPlan?.name || "",
              true
            )}
            {renderReadOnlyValue("Site", data.site?.name || "", true)}
            {renderReadOnlyValue("BOM Number", data.bom?.number || "", true)}
            {renderReadOnlyValue(
              "BOM Date",
              data.bom?.date ? moment(data.bom.date).format("L") : "",
              true
            )}
            {renderReadOnlyValue(
              "BOM Due Date",
              data.bom?.dueDate ? moment(data.bom.dueDate).format("L") : "",
              true
            )}
          </div>
        </div>
      </div>
    </>
  );
};
