import React from "react";
import { simpleObject } from "../../helpers/interfaces";
import {
  IColumnSetting,
  IDashboardChildProps,
} from "../../Components/Dashboard/interfaces";
import DashboardGrid from "../../Components/Dashboard/Grid";
import BaseComponent from "../../Components/BaseComponent";

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    aggregate: "count",
    columnMenu: false,
  },
  {
    field: "TjNumber",
    title: "# ITJ",
    type: "string",
    format: {
      type: "link",
      refName: "ICTransactionsJournal",
      fieldId: "TjId",
    },
  },
  {
    field: "VendorName",
    title: "Vendor",
    type: "string",
    format: {
      type: "string",
      fieldId: "VendorId",
    },
  },
  {
    field: "LocationFromName",
    title: "From",
    type: "string",
    format: {
      type: "string",
      fieldId: "LocationFromId",
    },
  },
  {
    field: "LocationToName",
    title: "To",
    type: "string",
    format: {
      type: "string",
      fieldId: "LocationToId",
    },
  },
  {
    field: "DateOfPurchase",
    title: "Date Of Purchase (Start)",
    type: "date",
    gridWidth: 130,
    format: {
      type: "date",
    },
  },
  {
    field: "DateReceived",
    title: "Date Received (Confirm)",
    type: "date",
    gridWidth: 130,
    format: {
      type: "date",
    },
  },
  {
    field: "ShippingDuration",
    title: "Shipping Duration",
    type: "number",
    gridWidth: 80,
    format: {
      type: "integer",
    },
  },
  {
    field: "QuantityRequested",
    title: "QTY Requested",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "QuantityReceived",
    title: "QTY Received",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "ItemCost",
    title: "Item Cost",
    type: "number",
    format: {
      type: "currency",
    },
  },
  {
    field: "AdjustedCOGS",
    title: "Adjusted COGS After Received",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "MprName",
    title: "MPR",
    type: "string",
    gridWidth: 90,
    format: {
      type: "link",
      fieldId: "MprId",
      refName: "ICPurchaseRequests",
    },
  },
];

interface state {}

class TransactionJournal extends BaseComponent<IDashboardChildProps, state> {
  gridRef: any = React.createRef();
  locationId = this.props.rowData?.LocationId;
  materialId = this.props.rowData?.MAId;
  key = "";

  constructor(props: IDashboardChildProps) {
    super(props);
    this.key = this.GetKey();
  }

  render() {
    let key = this.GetKey();
    if (this.props.isActive && this.key !== key) {
      this.key = key;
      this.locationId = this.props.rowData?.LocationId;
      this.materialId = this.props.rowData?.MAId;
    }

    if (!key || (!this.props.isActive && this.key !== key)) return null;
    return (
      <DashboardGrid
        key={key}
        ref={this.gridRef}
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={COLUMNS}
        getData={this.LoadGridData}
        toolbar={{ unSelect: this.props.unSelect }}
        excelFileName="TransactionJournal.xlsx"
        columnMenu={{
          filters: false,
          columns: false,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.TjId}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_GetTransactionJournalData",
      params: {
        materialId: this.materialId,
        locationId: this.locationId,
        ...filters,
      },
    });
    return result[0];
  };

  GetKey = () => {
    return this.props.rowData
      ? `${this.props.rowData.LocationId}_${this.props.rowData.MAId}`
      : "";
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default TransactionJournal;
