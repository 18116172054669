import FXCard from "../Common/FXCard/FXCard";
import { IFXCardProps } from "./interfaces";
import React, { useEffect, useRef, useState } from "react";
import { WindowActionsEvent } from "@progress/kendo-react-dialogs";
import TCMap from "../TC/Map";
import SideBarToggleBtn from "../Common/SideBar/SideBarToggleBtn";
import { GetReferenceRecordName } from "../../helpers/queries";

enum windowStage {
  DEFAULT = "DEFAULT",
  FULLSCREEN = "FULLSCREEN",
  MINIMIZED = "MINIMIZED",
}

interface IProps extends IFXCardProps {
  tcId: number;
  tcRefresh?: () => void;
}

const TCMapCard = (props: IProps) => {
  const { tcId } = props;
  const [title, setTitle] = useState("");
  const [stage, setStage] = useState<string>(windowStage.DEFAULT);
  const [isMapHidden, setMapHidden] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [boxHeight, setBoxHeight] = useState(0);

  useEffect(() => {
    setBoxHeight(containerRef.current?.clientHeight || 100);
  }, [containerRef.current]);

  useEffect(() => {
    const loadCardTitle = async () => {
      const Name = await GetReferenceRecordName(tcId);
      setTitle(Name);
    };
    loadCardTitle();
  }, []);

  const onCardStageChange = (stage: WindowActionsEvent) => {
    setStage(stage.state || windowStage.FULLSCREEN);
  };

  const onClose = () => {
    if (props.finally) props.finally();
  };

  const toggleMap = () => {
    const value = !isMapHidden;
    setMapHidden(value);
    setStage(value ? windowStage.DEFAULT : windowStage.FULLSCREEN);
  };

  const renderToggleMapBtn = () => {
    return (
      <div style={{ width: 30, position: "relative" }}>
        <SideBarToggleBtn
          toggle={toggleMap}
          btnText={"Map"}
          boxHeight={boxHeight}
          opened={!isMapHidden}
          rtl={true}
        />
      </div>
    );
  };

  return (
    <FXCard
      title={title || "Loading..."}
      onClose={onClose}
      stage={stage}
      onStageChange={onCardStageChange}
      initialWidth={890}
    >
      <div
        ref={containerRef}
        style={{ width: "100%", height: "100%", display: "flex" }}
      >
        {renderToggleMapBtn()}
        <div style={{ flex: 1 }}>
          <TCMap
            tcId={tcId}
            tcRefresh={props.tcRefresh}
            isMapHidden={isMapHidden}
            modalTCMapWindowStage={stage}
            parentId={"TCMapCard"}
            isActive={true}
          />
        </div>
      </div>
    </FXCard>
  );
};

export default TCMapCard;
