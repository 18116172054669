import React, { useMemo } from "react";
import OpenCardLink from "../../OpenCardLink";
import styles from "./timecard.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import ButtonLink from "../../Common/Buttons/ButtonLink";
import CardManagement from "../../Cards/CardManagement";
import { MultipleWo } from "./MultipleWo";
import {
  SQL_DB_TK_GetTCSummary_Response_DefaultWOs,
  SQL_DB_TK_GetTCSummary_Response_TimeCardInfo,
} from "../../../core/api/generated/conterra";
import moment from "moment";

interface IProps {
  tcId: number;
  info: SQL_DB_TK_GetTCSummary_Response_TimeCardInfo;
  defaultWOs: SQL_DB_TK_GetTCSummary_Response_DefaultWOs[];
  refresh(): void;
}

const TCInfo = (props: IProps) => {
  const { tcId, defaultWOs } = props;
  const {
    employeeName,
    employeeId,
    isWageAllocationLocked,
    reasonName,
    reasonId,
    employeeCategoryName,
    employeeCategoryId,
    periodName,
    periodId,
    canChangeReason,
    sickHoursAvailableToRequest,
    sickHoursRequestedNotCompleted,
    vacationHoursAvailableToRequest,
    vacationHoursRequestedNotCompleted,
  } = props.info;

  const formatInfoData = useMemo(() => {
    const { firstClockIn, lastClockOut, date } = props.info;
    const addInfo: {
      date: string;
      overClockIn?: boolean;
      firstClockIn?: string;
      overClockOut?: boolean;
      lastClockOut?: string;
    } = {
      date: moment(date).format("L"),
    };

    if (firstClockIn) {
      const firstClockInMoment = moment(firstClockIn);
      addInfo.overClockIn = firstClockInMoment.format("L") !== addInfo.date;
      addInfo.firstClockIn = firstClockInMoment.format("LT");
    }
    if (lastClockOut) {
      const lastClockOutMoment = moment(lastClockOut);
      addInfo.overClockOut = lastClockOutMoment.format("L") !== addInfo.date;
      addInfo.lastClockOut = lastClockOutMoment.format("LT");
    }
    return addInfo;
  }, [props.info]);

  const renderInfoTooltip = () => {
    return (
      <>
        <div className={styles.TooltipRow}>
          <span className={styles.TooltipRowName}></span>
          <span className={styles.TooltipColName}>Available to Request</span>
          <span className={styles.TooltipColName}>
            Requested, Not Completed
          </span>
        </div>
        {renderTooltipRow(
          "Sick",
          sickHoursAvailableToRequest || 0,
          sickHoursRequestedNotCompleted || 0
        )}
        {renderTooltipRow(
          "Vacation",
          vacationHoursAvailableToRequest || 0,
          vacationHoursRequestedNotCompleted || 0
        )}
      </>
    );
  };

  const renderTooltipRow = (
    rowName: string,
    availableToRequest: number,
    requestedNotCompleted: number
  ) => {
    return (
      <div className={styles.TooltipRow}>
        <span className={styles.TooltipRowName}>{rowName}</span>
        <span className={styles.TooltipValue}>
          {availableToRequest.toFixed(2)}
        </span>
        <span className={styles.TooltipValue}>
          {requestedNotCompleted.toFixed(2)}
        </span>
      </div>
    );
  };

  const renderInfoClockInOut = (
    color: string,
    time: string,
    overName: "PD" | "ND",
    isOver?: boolean
  ) => {
    return (
      <div className={styles.TCInfoCell}>
        {isOver && (
          <small
            className={styles.TCInfoName}
            title={overName === "PD" ? "Previous Day" : "Next Day"}
          >
            {overName}
          </small>
        )}
        <span
          className="k-icon k-font-icon k-i-round-corners"
          style={{ color }}
        ></span>
        <span className={styles.TCInfoTime}>{time}</span>
      </div>
    );
  };

  const renderInfoPeriod = () => {
    return renderInfoRow("Period", periodName, periodId, "TKPayrollPeriods");
  };

  const renderInfoEmployeeCategory = () => {
    return renderInfoRow(
      "Employee category",
      employeeCategoryName,
      employeeCategoryId,
      "EmployeeCategories"
    );
  };

  const renderInfoDefaultWO = () => {
    if (!defaultWOs.length) return null;
    if (defaultWOs.length === 1) {
      const { defaultWOId, defaultWOName } = defaultWOs[0];
      return renderInfoRow(
        "Default WO",
        defaultWOName,
        defaultWOId,
        "FSMWorkOrders"
      );
    }

    return (
      <div className={styles.TCInfoCell}>
        <span className={styles.TCInfoName}>Default WO:</span>
        <MultipleWo workOrders={defaultWOs} />
      </div>
    );
  };

  const renderInfoRow = (
    Name: any,
    linkName: string | null,
    linkId: number | null,
    refName: string
  ) => {
    return (
      <div className={styles.TCInfoCell}>
        <span className={styles.TCInfoName}>{Name}:</span>
        {!!linkName && !!linkId && (
          <OpenCardLink text={linkName} refName={refName} dataAttr={linkId} />
        )}
        {refName === "Employees" && (
          <div
            title="Available Hours"
            style={{
              position: "relative",
              width: 35,
              height: 16,
            }}
          >
            <Tooltip
              anchorElement="target"
              position="bottom"
              parentTitle={true}
              style={{ width: "230px" }}
              tooltipStyle={{ marginTop: "-10px" }}
              showCallout={true}
              content={renderInfoTooltip}
            >
              <Button
                iconClass="mdi mdi-information-outline"
                fillMode="flat"
                className={styles.InfoBtn}
                style={{ position: "absolute", right: 0, top: -6 }}
              ></Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const openReasonCard = () => {
    CardManagement.OpenTCReasonCard({
      tcId,
      reason:
        !!reasonName && !!reasonId ? { Id: reasonId, Name: reasonName } : null,
      onFinish: props.refresh,
    });
  };
  const { date, firstClockIn, lastClockOut, overClockOut, overClockIn } =
    formatInfoData;
  const showClockInOutInfo = !!firstClockIn || !!lastClockOut;
  return (
    <div className={styles.TCInfoBox}>
      <div className={styles.TCRow}>
        <div
          className={styles.TCRow}
          style={
            showClockInOutInfo
              ? {
                  position: "relative",
                  paddingRight: 76,
                }
              : undefined
          }
        >
          <div className={styles.TCInfoCOL}>
            {renderInfoRow(date, employeeName, employeeId, "Employees")}
            {renderInfoPeriod()}
          </div>

          <div className={styles.TCInfoCOL}>
            {renderInfoEmployeeCategory()}
            {renderInfoRow(
              canChangeReason ? (
                <ButtonLink text={"Reason"} onClick={openReasonCard} />
              ) : (
                "Reason"
              ),
              reasonName,
              reasonId,
              "TKTimeCardReasons"
            )}
          </div>

          <div className={styles.TCInfoCOL}>
            {renderInfoDefaultWO()}
            {isWageAllocationLocked && (
              <div className={styles.TCInfoCell}>
                <div style={{ color: "red" }}>Locked Allocation</div>
              </div>
            )}
          </div>
          {showClockInOutInfo && (
            <div className={styles.TCClockInOut}>
              {!!firstClockIn &&
                renderInfoClockInOut("green", firstClockIn, "PD", overClockIn)}
              {!!lastClockOut &&
                renderInfoClockInOut("red", lastClockOut, "ND", overClockOut)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TCInfo;
