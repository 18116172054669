import formStyles from "../card.module.scss";
import React, { useContext, useEffect, useState } from "react";
import api from "../../../core/api/api";
import { useLazyAsync } from "../../../core/tools/Hooks";
import FXCard from "../../Common/FXCard/FXCard";
import LoaderComponent from "../../Common/Loader";
import { IInboxCardProps } from "../interfaces";
import OpenCardIconLink from "../OpenCardIconLink";
import { OpenJob, RunScriptLocal } from "../../../helpers/runscripts";
import { Button, ButtonGroup, Toolbar } from "@progress/kendo-react-buttons";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import InboxAttachmentLink from "./AttachmentLink";
import styles from "./inboxCard.module.scss";

type tab = "text" | "attachments";
const tabs: { id: tab; text: string }[] = [
  {
    id: "text",
    text: "Thread",
  },
  {
    id: "attachments",
    text: "Attachments",
  },
];

const InboxCard = (props: IInboxCardProps) => {
  const [getText, { data, loading }] = useLazyAsync(api.sql.dbInboxGetJobInfo);
  const [getTextHtml, { data: textHtml, loading: loadingTextHTML }] =
    useLazyAsync(api.script.inboxGetJobTreeHtml);
  const { jobId, isRead } = props.dataItem;
  const [activeTabId, setActiveTabId] = useState<"text" | "attachments">(
    "text"
  );
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );

  useEffect(() => {
    getText({ jobId });
    getTextHtml({ JobId: jobId });
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!isRead) {
      api.script
        .inboxMarkJobsAsRead({ IsRead: true, JobIds: [{ Id: jobId }] })
        .then(props.refreshGrid);
    }
  }, [data]);

  const close = () => {
    props.finally!();
  };

  const onIframeLoad = (e: React.SyntheticEvent<HTMLIFrameElement>) => {
    // @ts-ignore
    const iframeDocument = e.target?.contentDocument;
    const links = iframeDocument.getElementsByTagName("a");
    if (links && links.length) {
      for (let el of links) {
        if (isIntegrationWithDesktop) {
          el.addEventListener("click", onIframeLinkClick);
        } else {
          el.classList.add("disabled");
        }
      }
    }
  };

  const onIframeLinkClick = (e: any) => {
    const { id, type } = e.currentTarget.dataset;
    RunScriptLocal("ConterraObject_Open", {
      ObjectID: id,
      Type: type,
    });
  };

  const swithchTab = (tab: tab) => {
    if (activeTabId !== tab) setActiveTabId(tab);
  };

  const renderTitle = () => {
    return (
      <>
        <OpenCardIconLink
          onClick={() => {
            OpenJob(jobId);
          }}
          title="Open Job Card"
        />
        <span>Inbox</span>
      </>
    );
  };

  const { attachments } = data?.[0] || {};

  return (
    <FXCard
      title={<span className={formStyles.HeaderTitle}>{renderTitle()}</span>}
      initialWidth={800}
      initialHeight={500}
      originalPaddings={true}
      onClose={close}
    >
      <div className={formStyles.FormWrapper}>
        <Toolbar>
          <ButtonGroup>
            {tabs.map(({ id, text }) => {
              return (
                <Button
                  key={id}
                  data-id={id}
                  themeColor={activeTabId === id ? "primary" : undefined}
                  onClick={() => swithchTab(id as tab)}
                >
                  {text}
                </Button>
              );
            })}
          </ButtonGroup>
        </Toolbar>

        {loading || loadingTextHTML ? (
          <LoaderComponent />
        ) : (
          <>
            {activeTabId === "text" && !!textHtml && (
              <div style={{ flex: 1 }}>
                <iframe
                  className={styles.TextIframe}
                  srcDoc={textHtml}
                  onLoad={onIframeLoad}
                ></iframe>
              </div>
            )}
            {activeTabId === "attachments" && !!attachments?.length && (
              <div className={styles.AttachmentsContainer}>
                {attachments.map((item) => (
                  <InboxAttachmentLink key={item.id} data={item} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </FXCard>
  );
};
export default InboxCard;
