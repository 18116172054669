import { IPromiseActions } from "./interfaces";

export function GetEmptyPromise() {
  let resolve, reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  if (!resolve || !reject) throw Error("Promise not created");
  return { promise, resolve, reject } as IPromiseActions;
}
