import React, { useRef } from "react";
import styles from "./livemap.module.scss";

interface IProps {
  action: any;
  listId: number;
  clockInId: number;
}

const EmptyRowWithPeriodToggle = (props: IProps) => {
  const { listId, clockInId } = props;

  const elementRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={elementRef}
      className={styles.TERow}
      data-clockinid={clockInId}
      data-listid={listId}
    >
      <div className={styles.TERowLeft}>{props.action}</div>
      <div className={styles.NotClockInStartPeriodRow}>
        <i
          className="mdi mdi-triangle-down"
          style={{
            fontSize: 12,
            position: "relative",
            left: -1,
          }}
        ></i>
      </div>
    </div>
  );
};

export default EmptyRowWithPeriodToggle;
