import React, { Component } from "react";
import { process } from "@progress/kendo-data-query";
import { IBuildPlansGridData, IRowItem } from "./interfaces";
import { gridColumns } from "./helpers";
import {
  CellOptions,
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { TYPE_TO_EXCEL_FORMAT } from "../../helpers/helpers";

interface props {
  data: Array<IRowItem>;
  groupedData: IBuildPlansGridData;
}

const blueBG = "#bcdffb";

class BudgetDetailsExcelGrid extends Component<props> {
  gridRef: any = React.createRef();

  render() {
    let groupBy = [{ field: "BPId" }, { field: "Section" }];
    let filter = { field: "Type", value: "buildPlan", operator: "neq" };
    let filterSection = { field: "Type", value: "section", operator: "neq" };
    let groupableData = process(this.props.data, {
      group: groupBy,
      filter: { filters: [filter, filterSection], logic: "and" },
    });
    return (
      <>
        <ExcelExport
          data={groupableData.data}
          ref={this.gridRef}
          fileName="BudgetDashboardDetails.xlsx"
          group={groupBy}
        >
          <ExcelExportColumn
            field="BPId"
            hidden={true}
            groupHeaderCellOptions={{
              wrap: true,
              background: blueBG,
              borderTop: { size: 1 },
            }}
            groupHeader={this.renderGroupHeader}
          />
          <ExcelExportColumn
            field="Section"
            hidden={true}
            groupHeaderCellOptions={{ wrap: true }}
            groupHeader={(props: any) => props.value}
          />
          {gridColumns.map((column) => {
            let field = column.field;
            if (field !== "Title") field += "Formatted";
            return (
              <ExcelExportColumn
                key={column.title}
                field={field}
                title={column.title}
                width={column.gridWidth}
                headerCellOptions={{ wrap: false, textAlign: "center" }}
                cellOptions={this.GetExcelCellOptions(column)}
              />
            );
          })}
        </ExcelExport>
      </>
    );
  }

  GetExcelCellOptions = (column: {
    title: string;
    field: string;
    printWidth: number;
    gridWidth: number;
  }): CellOptions => {
    let textAlign: "left" | "center" | "right" = "right";
    if (column.field === "Title") textAlign = "left";
    else if (
      column.field === "UsedofTotalPlanned" ||
      column.field === "UsedofPlanned"
    )
      textAlign = "center";
    return {
      format:
        column.field === "Title" ? undefined : TYPE_TO_EXCEL_FORMAT.number,
      textAlign,
      background:
        column.field === "Revised" || column.field === "Actual"
          ? blueBG
          : undefined,
    };
  };

  renderGroupHeader = (props: any) => {
    let group = this.props.groupedData[props.value].data;
    return `${group.Name} | BP Owner: ${group.OwnerName}${
      group.EstimatedCompletion !== null
        ? " | Estimated Completion: " + group.EstimatedCompletion + "%"
        : ""
    }${group.CXStatusName ? " | CX Status: " + group.CXStatusName : ""}`;
  };
}

export default BudgetDetailsExcelGrid;
