import { Button } from "@progress/kendo-react-buttons";
import { RunScriptAsync } from "../../helpers/runscripts";
import { IComboboxItem } from "../../helpers/interfaces";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import BaseComponent from "../BaseComponent";
import FXCard from "../Common/FXCard/FXCard";
import Loader from "../Common/Loader";
import FilterCombobox from "../Common/Form/FilterCombobox";
import ComboboxFilterVirtual from "../Dashboard/ComboboxFilterVirtual";
import styles from "./card.module.scss";
import CardManagement from "./CardManagement";
import { IFXCardProps } from "./interfaces";
import { showSomeError } from "../../helpers/helpers";

interface props extends IFXCardProps {
  buildPlanId?: number;

  afterSave?(): void;
}

interface ITypeItem extends IComboboxItem {
  AllowDispatch: boolean;
}

interface state {
  processing: boolean;
  loading: boolean;
  woTypes: Array<ITypeItem>;
  woSites: Array<IComboboxItem>;
  buildPlans: Array<IComboboxItem>;
  loadingBuildPlans: boolean;
  bpId: number | null;
  selectedWOType: ITypeItem | null;
}

class AddWOCard extends BaseComponent<props, state> {
  BPID: number | null = this.props.buildPlanId || null;
  WOTID: number | null = null;
  WONumber: string = "";
  WOSID: number | null = null;
  Description: string = "";
  selectedWOSite: IComboboxItem | null = null;

  constructor(props: props) {
    super(props);

    this.state = {
      loading: false,
      processing: false,
      woTypes: [],
      woSites: [],
      buildPlans: [],
      loadingBuildPlans: false,
      bpId: this.props.buildPlanId || null,
      selectedWOType: null,
    };
  }

  componentDidMount() {
    if (!this.props.buildPlanId) this.LoadBuildPlans();
    else this.LoadActionData();
  }

  render() {
    const {
      bpId,
      processing,
      buildPlans,
      woTypes,
      woSites,
      loading,
      selectedWOType,
      loadingBuildPlans,
    } = this.state;
    const { buildPlanId } = this.props;
    return (
      <FXCard
        title={"New Work Order"}
        onClose={this.props.finally}
        initialWidth={450}
        initialHeight={buildPlanId ? 340 : 370}
        originalPaddings={true}
      >
        <div className={styles.FormWrapper}>
          {processing && <Loader />}
          {!buildPlanId && (
            <FilterCombobox
              placeholder="Build Plan *"
              data={buildPlans}
              loading={loadingBuildPlans}
              onChange={this.OnChangeBuildPlan}
              className={styles.FormField}
            />
          )}
          <FilterCombobox
            placeholder="Type *"
            data={woTypes}
            onChange={this.OnChangeType}
            className={styles.FormField}
            loading={loading}
            disabled={!bpId}
            value={selectedWOType}
          />
          <Input
            placeholder="Number"
            className={styles.FormField}
            onChange={this.OnChangeWONumber}
            disabled={!bpId}
          />
          <ComboboxFilterVirtual
            key={"sites" + bpId + loading}
            filter={{
              id: "Site",
              placeholder: "Site",
              type: "combobox",
            }}
            data={woSites}
            onChange={this.OnChangeSite}
            className={styles.FormField}
            loading={loading}
            disabled={!bpId}
            defaultValue={this.selectedWOSite}
          />
          <TextArea
            className={styles.TextArea}
            rows={8}
            placeholder="Description"
            onChange={this.OnChangeDescription}
            disabled={!bpId}
          ></TextArea>
          <div className={`${styles.FormFooter} k-action-buttons`}>
            <Button onClick={this.props.finally}>Cancel</Button>
            <Button
              onClick={this.AddWOOnly}
              themeColor="primary"
              disabled={!bpId || !selectedWOType}
            >
              Create
            </Button>

            <Button
              onClick={this.AddWOAndSchedule}
              themeColor="primary"
              disabled={!bpId || !selectedWOType?.AllowDispatch}
            >
              Create and Schedule
            </Button>
          </div>
        </div>
      </FXCard>
    );
  }

  OnChangeBuildPlan = (value: IComboboxItem | null) => {
    let valueId = value ? +value.Id : null;
    this.BPID = valueId;
    this.WOSID = null;
    this.WOTID = null;
    this.selectedWOSite = null;
    this.setState({
      bpId: valueId,
      selectedWOType: null,
    });
    if (valueId) this.LoadActionData();
  };

  OnChangeSite = (value: IComboboxItem | null) => {
    this.WOSID = value ? +value.Id : null;
    this.selectedWOSite = value;
  };

  OnChangeType = (value: ITypeItem | null) => {
    this.WOTID = value ? +value.Id : null;
    this.setState({ selectedWOType: value });
  };

  OnChangeDescription = (e: any) => {
    this.Description = e.value;
  };

  OnChangeWONumber = (e: any) => {
    this.WONumber = e.value;
  };

  LoadBuildPlans = async () => {
    try {
      this.setState({ loadingBuildPlans: true });
      let result: any = await this.GetSQLData({
        spName: "Calendar_AvailableBuildPlans",
      });
      this.setState({ buildPlans: result[0] || [] });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loadingBuildPlans: false });
    }
  };

  LoadActionData = async () => {
    try {
      this.setState({ loading: true });
      let result: any = await this.GetSQLData({
        spName: "Calendar_AddWOActionData",
        params: { bpId: this.BPID },
      });
      this.setState({ woTypes: result[0] || [], woSites: result[1] || [] });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  AddWOOnly = () => {
    this.AddWO(false);
  };

  AddWOAndSchedule = () => {
    this.AddWO(true);
  };

  AddWO = async (schedule: boolean) => {
    if (!this.WOTID) return;
    try {
      this.setState({ processing: true });
      let params = {
        BPID: this.BPID,
        WOTID: this.WOTID || null,
        WONumber: this.WONumber,
        WODescription: this.Description,
        WOSID: this.WOSID,
      };
      let result = await RunScriptAsync("Calendar_AddWO", params);
      if (schedule && result) {
        CardManagement.OpenDispatchCard({
          newDispatch: true,
          buildPlanId: this.BPID || undefined,
          woId: +result,
          afterSave: this.props.afterSave,
        });
      }
      if (this.props.finally) this.props.finally();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };
}

export default AddWOCard;
