import React from "react";
import { SQL_DB_TK_Review_Response_DispatchInfo } from "../../../core/api/generated/conterra";
import styles from "./tkTimeLine.module.scss";

export const DispatchTooltipContent = (props: {
  dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
}) => {
  const renderTooltipRow = (d: SQL_DB_TK_Review_Response_DispatchInfo) => {
    return (
      <div className={styles.DispatchTooltipRow} key={d.id}>
        <span className={styles.DispatchTooltipValue}>{d.clockInAfter}</span>
        <span className={styles.DispatchTooltipValue}>{d.clockOutBefore}</span>
        <span className={styles.DispatchTooltipRowName}>{d.name}</span>
      </div>
    );
  };

  return (
    <>
      <div className={styles.DispatchTooltipRow}>
        <span className={styles.DispatchTooltipColName}>Clock In</span>
        <span className={styles.DispatchTooltipColName}>Clock Out</span>
        <span className={styles.DispatchTooltipRowName}>{/*(Expected)*/}</span>
      </div>
      {props.dispatches.map((d) => renderTooltipRow(d))}
    </>
  );
};
