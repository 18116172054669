import DashboardGrid from "../../Components/Dashboard/Grid";
import { IColumnSetting } from "../../Components/Dashboard/interfaces";
import api from "../../core/api/api";
import { SQL_DB_Inbox_Response } from "../../core/api/generated/conterra";
import styles from "./inboxdashboard.module.scss";
import moment from "moment";
import DesktopButton from "../../Components/Common/Buttons/DesktopButton";
import { Button } from "@progress/kendo-react-buttons";
import CardManagement from "../../Components/Cards/CardManagement";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { ModalRef } from "../../Components/Common/Modal/Modal";
import { RunScriptLocal } from "../../helpers/runscripts";
import CounterStore from "../../stores/CounterStore";
import BaseComponent from "../../Components/BaseComponent";
import { simpleObject } from "../../helpers/interfaces";

interface IRowItem extends SQL_DB_Inbox_Response {
  isReadStr: "Yes" | "No";
}

interface IProps {
  isActive: boolean;
}

interface state {
  markAsReadEnabled: boolean;
  markAsUnreadEnabled: boolean;
  deleteEnabled: boolean;
  remountCheckboxesKey: number;
}

class InboxDashboard extends BaseComponent<IProps, state> {
  checkedItems: {
    jobId: number;
    isRead: boolean;
    canBeDeleted: boolean;
    linkId: number;
  }[] = [];
  allData: IRowItem[] = [];
  COLUMNS: Array<IColumnSetting>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      markAsReadEnabled: false,
      markAsUnreadEnabled: false,
      deleteEnabled: false,
      remountCheckboxesKey: +new Date(),
    };
    this.COLUMNS = [
      {
        field: "checked",
        title: " ",
        type: "string",
        filterable: false,
        columnMenu: false,
        sortable: false,
        format: {
          type: "string",
          customFormat: true,
          getCustomFormat: this.renderColumnCheck,
        },
        titleHTML: this.renderCheckAll(),
        gridWidth: 40,
      },
      {
        field: "kind",
        title: "Kind",
        type: "string",
        filterable: false,
        format: {
          type: "icon",
          fieldId: "kind",
          customFormat: true,
          getCustomFormat: this.renderKindColumn,
        },
        gridWidth: 70,
      },
      {
        field: "subject",
        title: "Subject",
        type: "string",
        gridWidth: 400,
      },

      {
        field: "route",
        title: "Route",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "route",
        },
      },
      {
        field: "initiator",
        title: "Initiator",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "initiator",
        },
      },
      {
        field: "performer",
        title: "Performer",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "performer",
        },
      },
      {
        field: "deadline",
        title: "Deadline",
        type: "date",
        format: {
          type: "datetime",
        },
      },
      {
        field: "created",
        title: "Created",
        type: "date",
        format: {
          type: "datetime",
        },
      },
      {
        field: "completed",
        title: "Completed",
        type: "date",
        format: {
          type: "datetime",
        },
      },
      {
        field: "state",
        title: "State",
        type: "string",
        format: {
          type: "string",
          fieldId: "state",
        },
        gridWidth: 100,
      },
      {
        field: "isReadStr",
        title: "Is Read",
        type: "string",
        format: {
          type: "string",
          fieldId: "isRead",
        },
        gridWidth: 100,
      },
    ];
  }

  renderButtons = () => {
    return (
      <>
        <Button
          iconClass={"mdi mdi-email-open-outline"}
          disabled={!this.state.markAsReadEnabled}
          onClick={() => this.MarkAs(true)}
          title={"Mark as Read"}
        ></Button>
        <Button
          iconClass={"mdi mdi-email-outline"}
          disabled={!this.state.markAsUnreadEnabled}
          onClick={() => this.MarkAs(false)}
          title={"Mark as Unread"}
        ></Button>
        <Button
          iconClass={"mdi mdi-trash-can-outline"}
          disabled={!this.state.deleteEnabled}
          onClick={() => this.DeleteChecked()}
          title={"Delete"}
        ></Button>
      </>
    );
  };

  renderCheckAll = () => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all", width: 32 }}>
        <Checkbox
          key={`all-${this.state.remountCheckboxesKey}`}
          id={"Allcheck"}
          name={"all"}
          label={" "}
          onChange={this.OnChangeCheckAll}
        />
      </div>
    );
  };

  renderColumnCheck = ({ jobId, isRead, canBeDeleted, linkId }: IRowItem) => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all" }}>
        <Checkbox
          key={`${jobId}-${this.state.remountCheckboxesKey}`}
          id={jobId + "check"}
          name={jobId + ""}
          label={" "}
          data-isread={isRead}
          data-canbedeleted={canBeDeleted}
          data-linkid={linkId}
          checked={this.checkedItems.findIndex((i) => i.jobId === jobId) > -1}
          onChange={this.OnChangeCheck}
        />
      </div>
    );
  };

  renderKindColumn = ({ kind }: IRowItem) => {
    return (
      <div style={{ textAlign: "center" }}>
        <span
          className={
            kind === "Job"
              ? "mdi mdi-calendar-clock-outline"
              : "mdi mdi-note-text-outline"
          }
        ></span>
      </div>
    );
  };

  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={this.COLUMNS}
        getData={this.LoadGridData}
        excelFileName="Inbox.xlsx"
        getRowKey={({ jobId }: IRowItem) => jobId.toString()}
        getRowClass={({ isRead, deadline, state }: IRowItem) =>
          `${isRead ? "" : styles.notRead} ${
            deadline &&
            moment(deadline).isBefore(moment(), "day") &&
            state === "In progress"
              ? styles.deadLineRow
              : ""
          }`
        }
        columnMenu={{
          filters: true,
          columns: false,
        }}
        initRefresh={this.InitRefresh}
        selectSettings={{
          pageId: "InboxDashboard",
          onSelectRow: (dataItem: IRowItem | null) => {
            if (!dataItem) return;
            CardManagement.OpenInboxCard({
              dataItem,
              refreshGrid: this.Refresh,
            });
          },
        }}
        toolbar={{
          isSingleRowToolbar: true,
          switcher: this.renderButtons(),
          actionBtns: [
            <DesktopButton
              key={"newTask"}
              onClick={() => RunScriptLocal("Inbox_CreateNewTask")}
            >
              New Task
            </DesktopButton>,
          ],
        }}
      ></DashboardGrid>
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const response = await api.sql.dbInbox(filters);
    CounterStore.Load();
    const data: IRowItem[] = [];
    for (let row of response) {
      data.push({ ...row, isReadStr: row.isRead ? "Yes" : "No" });
    }
    this.allData = data;
    return data;
  };

  OnChangeCheckAll = (e: CheckboxChangeEvent) => {
    const { value: checked } = e.target;
    if (!checked) {
      this.checkedItems = [];
      this.setState({
        markAsReadEnabled: false,
        markAsUnreadEnabled: false,
        remountCheckboxesKey: +new Date(),
      });
    } else {
      this.checkedItems = this.allData.map(
        ({ jobId, isRead, canBeDeleted, linkId }) => ({
          isRead,
          jobId,
          canBeDeleted,
          linkId,
        })
      );

      this.UpdateActionButtons();
    }
  };

  OnChangeCheck = (e: CheckboxChangeEvent) => {
    const { value: checked, name: jobIdStr, element } = e.target;
    const isRead = element?.dataset.isread === "true";
    const canBeDeleted = element?.dataset.canbedeleted === "true";
    const linkIdStr = element?.dataset.linkid;

    if (checked) {
      this.checkedItems.push({
        jobId: +jobIdStr!,
        isRead,
        canBeDeleted,
        linkId: +linkIdStr!,
      });
    } else {
      this.checkedItems = this.checkedItems.filter(
        (i) => i.jobId !== +jobIdStr!
      );
    }

    this.UpdateActionButtons();
  };

  UpdateActionButtons = () => {
    this.setState({
      markAsReadEnabled:
        !!this.checkedItems.length &&
        this.checkedItems.findIndex(({ isRead }) => !isRead) > -1,
      markAsUnreadEnabled:
        !!this.checkedItems.length &&
        this.checkedItems.findIndex(({ isRead }) => isRead) > -1,
      deleteEnabled:
        !!this.checkedItems.length &&
        this.checkedItems.findIndex(({ canBeDeleted }) => canBeDeleted) > -1,
      remountCheckboxesKey: +new Date(),
    });
  };

  DeleteChecked = () => {
    const LinkIds = this.checkedItems
      .filter((i) => i.canBeDeleted)
      .map((i) => ({
        Id: i.linkId,
      }));
    ModalRef.startProcessing();
    api.script
      .inboxDeleteLinks({ LinkIds })
      .then(() => {
        this.checkedItems = [];
        this.UpdateActionButtons();
        this.Refresh();
      })
      .finally(() => {
        ModalRef.stopProcessing();
        CounterStore.Load();
      });
  };

  MarkAs = (IsRead: boolean) => {
    const JobIds = this.checkedItems
      .filter((i) => i.isRead !== IsRead)
      .map((i) => ({
        Id: i.jobId,
      }));
    ModalRef.startProcessing();
    api.script
      .inboxMarkJobsAsRead({ IsRead, JobIds })
      .then(() => {
        this.checkedItems = [];
        this.UpdateActionButtons();
        this.Refresh();
      })
      .finally(() => {
        ModalRef.stopProcessing();
        CounterStore.Load();
      });
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default InboxDashboard;
