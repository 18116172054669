import {
  BillOfMaterialDetailsDto,
  BillOfMaterialsStageEnum,
  BillOfMaterialsTypeEnum,
  BomMaterialDetailsDto,
  BomMaterialItemDetailsDto,
  PropsBomMaterialDetailsDto,
  PropsBomMaterialItemDetailsDto,
} from "../../../core/api/generated/warehouse";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { SortDescriptor } from "@progress/kendo-data-query/dist/npm/sort-descriptor";
import { orderBy } from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridColumnProps,
  GridDetailRowProps,
  GridFooterCellProps,
  GridRowProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CommentCell,
  IconBtnCell,
  QTYCell,
  renderBooleanCell,
  renderCurrencyCell,
  renderLinkCell,
  renderMaterialNameCell,
  renderSNLinkCell,
} from "./Components";
import styles from "./bomcard.module.scss";
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import { saveAs } from "@progress/kendo-file-saver";
import { ExcelExport, KendoOoxml } from "@progress/kendo-react-excel-export";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import { EBomActions } from "./interfaces";
import { formatFinancial } from "../../../helpers/helpers";

interface IMaterialGridItem extends BomMaterialDetailsDto {
  id: number;
  expanded: boolean;
}

const BOM_ActionsNames = {
  [EBomActions.return]: "Return BOM",
  [EBomActions.complete]: "Complete BOM",
  [EBomActions.returnComplete]: "Complete BOM",
  [EBomActions.ofd]: "Open Financial Distribution",
  [EBomActions.mpr]: "Create Purchase Request",
  [EBomActions.pickAllQTY]: "Pick All QTY",
  [EBomActions.returnToPending]: "Return to Pending",
  [EBomActions.cancel]: "Cancel",
  [EBomActions.addFromTemplate]: "Add From Template",
  [EBomActions.addMaterialItems]: "Add Material Items",
  [EBomActions.addMaterials]: "Add Materials",
  [EBomActions.send]: "Send to Warehouse",
  [EBomActions.kit]: "Kit BOM",
};

const BOM_ActionsIcons = {
  [EBomActions.complete]:
    "mdi mdi-truck-delivery-outline " + styles.GreenActionIcon, // green
  [EBomActions.returnComplete]:
    "mdi mdi-truck-delivery-outline " + styles.GreenActionIcon, // green
  [EBomActions.returnToPending]: "mdi mdi-home-clock-outline",
  [EBomActions.cancel]: "mdi mdi-cancel " + styles.RedActionIcon, // red
  [EBomActions.send]: "mdi mdi-home-import-outline",
  [EBomActions.kit]: "mdi mdi-package-variant-closed",

  [EBomActions.return]: "",
  [EBomActions.pickAllQTY]: "",
  [EBomActions.addFromTemplate]: "",
  [EBomActions.addMaterialItems]: "",
  [EBomActions.addMaterials]: "",
  [EBomActions.ofd]: "",
  [EBomActions.mpr]: "",
};

export const BOMCardMaterials = (props: {
  bomTotal: number;
  renderToolbar(actions?: JSX.Element | JSX.Element[]): JSX.Element;
  general: BillOfMaterialDetailsDto;
  bomMaterials: BomMaterialDetailsDto[];
  onSelectAction(action: EBomActions): void;
  removeMaterial(material: BomMaterialDetailsDto): void;
  removeMaterialItem(materialItem: BomMaterialItemDetailsDto): void;
  updateMaterialComment(materialId: number, comment: string): void;
  updateMaterialQTY(materialId: number, quantity: number): void;
  changeMaterialPickQTY(material: BomMaterialDetailsDto, qty: number): void;
  assignMaterialItems(material: BomMaterialDetailsDto): void;
  removeMaterialItems(material: BomMaterialDetailsDto): void;
}) => {
  const {
    bomMaterials,
    general: {
      stage: { id: stageId },
      type: { id: typeId },
    },
    bomTotal,
  } = props;
  const isPendingStage = stageId === BillOfMaterialsStageEnum.Pending;
  const isSentStage = stageId === BillOfMaterialsStageEnum.Sent;
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  const exportRef = useRef<ExcelExport | null>(null);
  const [expandedState, setExpandedState] = useState<{
    [key: number]: boolean;
  }>({});
  const [sort, setSort] = React.useState<SortDescriptor[]>([]);
  const gridData: IMaterialGridItem[] = useMemo(() => {
    return bomMaterials.map((r) => ({
      ...r,
      id: r.material.id,
      expanded:
        expandedState[r.material.id] === undefined
          ? true
          : expandedState[r.material.id],
    }));
  }, [bomMaterials, expandedState]);

  const sortedGridData = useMemo(() => {
    return orderBy(gridData, sort);
  }, [sort, gridData]);

  const availableActions = useMemo(() => {
    const actions: EBomActions[] = [];
    if (stageId === BillOfMaterialsStageEnum.Pending) {
      actions.push(EBomActions.addMaterials);
      actions.push(EBomActions.addMaterialItems);
      actions.push(EBomActions.addFromTemplate);
    }

    if (
      stageId === BillOfMaterialsStageEnum.Pending ||
      stageId === BillOfMaterialsStageEnum.Sent
    ) {
      actions.push(EBomActions.cancel);
    }

    if (
      stageId === BillOfMaterialsStageEnum.Pending &&
      typeId === BillOfMaterialsTypeEnum.Return
    ) {
      actions.push(EBomActions.returnComplete);
    } else {
      if (stageId === BillOfMaterialsStageEnum.Pending) {
        actions.push(EBomActions.send);
      }
      if (
        (stageId === BillOfMaterialsStageEnum.Sent &&
          typeId !== BillOfMaterialsTypeEnum.Remaining) ||
        stageId === BillOfMaterialsStageEnum.Canceled
      ) {
        actions.push(EBomActions.returnToPending);
      }
      if (stageId === BillOfMaterialsStageEnum.Sent) {
        actions.push(EBomActions.kit);
        if (bomMaterials.findIndex((bm) => !bm.isSnRequired) > -1) {
          actions.push(EBomActions.pickAllQTY);
        }
      }
      if (
        isIntegrationWithDesktop &&
        (stageId === BillOfMaterialsStageEnum.Pending ||
          stageId === BillOfMaterialsStageEnum.Sent)
      ) {
        actions.push(EBomActions.mpr);
      }
      if (
        isIntegrationWithDesktop &&
        (stageId === BillOfMaterialsStageEnum.Kitted ||
          stageId === BillOfMaterialsStageEnum.Completed)
      ) {
        actions.push(EBomActions.ofd);
      }
      if (stageId === BillOfMaterialsStageEnum.Kitted) {
        actions.push(EBomActions.complete);
      }
      if (stageId === BillOfMaterialsStageEnum.Completed) {
        actions.push(EBomActions.return);
      }
    }
    return actions;
  }, [stageId, typeId, isIntegrationWithDesktop]);

  const OnChangeQTY = useCallback(
    (material: BomMaterialDetailsDto, qty: number) => {
      props.updateMaterialQTY(material.material.id, qty);
    },
    [props.updateMaterialQTY]
  );

  const OnChangeComment = useCallback(
    (material: BomMaterialDetailsDto, comment: string) => {
      props.updateMaterialComment(material.material.id, comment);
    },
    [props.updateMaterialComment]
  );

  const renderPickQTYCell = useCallback(
    (cellProps: GridCellProps) => {
      const dataItem = cellProps.dataItem as IMaterialGridItem;
      if (dataItem.isSnRequired)
        return <td>{dataItem.materialItems.length}</td>;
      return (
        <QTYCell
          gridCellProps={cellProps}
          action={props.changeMaterialPickQTY}
        />
      );
    },
    [props.changeMaterialPickQTY]
  );

  const renderTotalFooterCell = useCallback(
    (props: GridFooterCellProps) => {
      const { ariaColumnIndex, ...rest } = props;
      return (
        <td {...rest}>
          <div className={styles.CurrencyBox}>
            <span>$</span>
            {formatFinancial(bomTotal)}
          </div>
        </td>
      );
    },
    [bomTotal]
  );

  const renderRemoveMaterialCell = useCallback(
    (cellProps: GridCellProps) => (
      <IconBtnCell
        action={props.removeMaterial}
        gridCellProps={cellProps}
        iconClass={"mdi mdi-trash-can-outline"}
      />
    ),
    [props.removeMaterial]
  );
  const renderQTYCell = useCallback(
    (gridCellProps: GridCellProps) => {
      return <QTYCell gridCellProps={gridCellProps} action={OnChangeQTY} />;
    },
    [OnChangeQTY]
  );

  const renderCommentCell = useCallback(
    (gridCellProps: GridCellProps) => {
      return (
        <CommentCell
          editable={isPendingStage || isSentStage}
          gridCellProps={gridCellProps}
          action={OnChangeComment}
        />
      );
    },
    [OnChangeComment, isPendingStage, isSentStage]
  );

  const MaterialItemsColumns = useMemo(() => {
    const columns: GridColumnProps[] = [
      {
        field: PropsBomMaterialItemDetailsDto.sn,
        title: "SN",
        width: 100,
        cell: renderSNLinkCell,
      },
      {
        field: PropsBomMaterialItemDetailsDto.warehouseArea,
        title: "Area",
        cell: renderLinkCell,
        width: 130,
      },
      {
        field: PropsBomMaterialItemDetailsDto.vendor,
        title: "Vendor",
        cell: renderLinkCell,
        width: 180,
      },
      {
        field: PropsBomMaterialItemDetailsDto.mfgProductNo,
        title: "MFG Product #",
        width: 120,
      },
    ];
    if (isPendingStage || isSentStage) {
      columns.push({
        field: PropsBomMaterialDetailsDto.material,
        title: " ",
        cell: (cellProps: GridCellProps) => (
          <IconBtnCell
            action={props.removeMaterialItem}
            gridCellProps={cellProps}
            iconClass={"mdi mdi-delete-outline"}
          />
        ),
        width: 40,
      });
    }
    return columns;
  }, [isPendingStage, isSentStage, renderLinkCell, props.removeMaterialItem]);

  const MaterialsColumns = useMemo(() => {
    const columns: GridColumnProps[] = [
      {
        field: PropsBomMaterialDetailsDto.material,
        title: "Name",
        width: 280,
        cell: renderMaterialNameCell,
      },
      {
        field: PropsBomMaterialDetailsDto.uomName,
        title: "UOM",
        width: 140,
      },
      {
        field: PropsBomMaterialDetailsDto.isSnRequired,
        title: "SN Required",
        width: 80,
        cell: renderBooleanCell,
      },
      {
        field: PropsBomMaterialDetailsDto.cogs,
        title: "COGS",
        width: 60,
        cell: renderCurrencyCell,
      },
    ];
    if (isSentStage || isPendingStage) {
      columns.push({
        title: "Available QTY",
        children: [
          {
            field: "availableWHQTY",
            title: "WH",
            width: 70,
          },
          {
            field: "availableTotalQTY",
            title: "Total",
            width: 70,
          },
        ],
      });
    }
    columns.push({
      field: PropsBomMaterialDetailsDto.quantity,
      title: "QTY",
      width: 70,
      cell: isPendingStage ? renderQTYCell : undefined,
    });
    if (isSentStage) {
      columns.push({
        field: "pickQty",
        title: "Pick QTY",
        cell: renderPickQTYCell,
        width: 70,
      });
    }
    columns.push({
      field: PropsBomMaterialDetailsDto.total,
      title: "Total",
      cell: renderCurrencyCell,
      width: 120,
      footerCell: renderTotalFooterCell,
    });
    columns.push({
      field: PropsBomMaterialDetailsDto.comment,
      title: "Comment",
      cell: renderCommentCell,
      width: 100,
    });
    if (isPendingStage) {
      columns.push({
        field: PropsBomMaterialDetailsDto.material,
        title: " ",
        cell: renderRemoveMaterialCell,
        width: 40,
      });
    }
    if (isSentStage) {
      columns.push({
        field: PropsBomMaterialDetailsDto.material,
        title: " ",
        cell: (cellProps: GridCellProps) => {
          const dataItem = cellProps.dataItem as IMaterialGridItem;
          if (!dataItem.isSnRequired) return <td></td>;
          return (
            <IconBtnCell
              action={props.assignMaterialItems}
              gridCellProps={cellProps}
              iconClass={"mdi mdi-attachment-check"}
              title={"Assign Material Items"}
              themeColor={"success"}
            />
          );
        },
        width: 40,
      });
      columns.push({
        field: PropsBomMaterialDetailsDto.material,
        title: " ",
        cell: (cellProps: GridCellProps) => {
          const dataItem = cellProps.dataItem as IMaterialGridItem;
          if (!dataItem.isSnRequired) return <td></td>;
          return (
            <IconBtnCell
              action={props.removeMaterialItems}
              gridCellProps={cellProps}
              iconClass={"mdi mdi-attachment-remove"}
              title={"Remove Material Items"}
              themeColor={"error"}
              disabled={!dataItem.materialItems.length}
            />
          );
        },
        width: 40,
      });
    }
    return columns;
  }, [
    isPendingStage,
    isSentStage,
    renderQTYCell,
    renderPickQTYCell,
    renderCurrencyCell,
    renderTotalFooterCell,
    renderCommentCell,
    renderRemoveMaterialCell,
    props.assignMaterialItems,
    props.removeMaterialItems,
  ]);

  const exportToExcel = useCallback(() => {
    if (!exportRef.current || !sortedGridData) return;
    const workbook = exportRef.current.workbookOptions();
    const sheet = workbook.sheets?.[0];
    if (!sheet) return;
    const rows = sheet.rows;
    if (!rows) return;
    // @ts-ignore
    sheet.columns[3].autoWidth = true;
    const headerRow = rows[0];
    while (
      headerRow.cells!.findIndex((c) => !c.value!.toString().trim()) > -1
    ) {
      const actionCellIndex = headerRow.cells!.findIndex(
        (c) => !c.value!.toString().trim()
      );
      headerRow.cells!.splice(actionCellIndex, 1);
    }
    const firstRowDataIndex = rows.findIndex((r) => r.type === "data");
    for (let rowIndex = firstRowDataIndex; rowIndex < rows.length; rowIndex++) {
      const rowCells = rows[rowIndex].cells;
      // @ts-ignore
      rowCells[0].value = rowCells[0].value.name;
      // @ts-ignore
      rowCells[3].value = rowCells[3].value ? "Yes" : "No";

      if (rowCells!.length > headerRow.cells!.length) {
        rowCells!.splice(headerRow.cells!.length);
      }
    }

    // @ts-ignore
    const headerOptions = {
      background: "#dedede",
      colSpan: 1,
      color: "#333333",
      firstCell: false,
      rowSpan: 1,
    };
    for (let idx = sortedGridData.length - 1; idx >= 0; idx--) {
      const materialItems = sortedGridData[idx].materialItems;
      if (!materialItems?.length) continue;
      for (
        let productIdx = materialItems.length - 1;
        productIdx >= 0;
        productIdx--
      ) {
        const product = materialItems[productIdx];
        rows.splice(idx + 2, 0, {
          cells: [
            { value: product.sn },
            { value: product.warehouseArea?.name },
            { value: product.vendor.name },
            { value: product.mfgProductNo },
            {},
            {},
            {},
          ],
        });
      }
      rows.splice(idx + 2, 0, {
        cells: [
          Object.assign({}, headerOptions, { value: "SN", firstCell: true }),
          Object.assign({}, headerOptions, { value: "Area" }),
          Object.assign({}, headerOptions, { value: "Vendor" }),
          Object.assign({}, headerOptions, { value: "MFG Product #" }),
          {},
          {},
          {},
        ],
      });
    }
    new KendoOoxml.Workbook(workbook).toDataURL().then((dataUrl) => {
      saveAs(dataUrl, "BomMaterials.xlsx");
    });
  }, [sortedGridData]);

  const OnSelectAction = useCallback(
    (event: DropDownButtonItemClickEvent) => {
      props.onSelectAction(event.item.id);
    },
    [props.onSelectAction]
  );

  const renderMaterialsActions = useCallback(() => {
    const availableIconActions = availableActions.filter(
      (a) => !!BOM_ActionsIcons[a]
    );
    const availableRestActions = availableActions.filter(
      (a) => !BOM_ActionsIcons[a]
    );

    return (
      <>
        {availableIconActions.map((iconAction) => (
          <Button
            key={iconAction}
            fillMode={"flat"}
            className={styles.IconActionBtn}
            iconClass={BOM_ActionsIcons[iconAction]}
            onClick={() => {
              props.onSelectAction(iconAction);
            }}
          >
            {BOM_ActionsNames[iconAction]}
          </Button>
        ))}
        {!!availableRestActions.length && (
          <DropDownButton
            buttonClass={styles.DownloadButton}
            iconClass="mdi mdi-dots-horizontal"
            items={availableRestActions.map((rA) => ({
              id: rA,
              name: BOM_ActionsNames[rA],
            }))}
            fillMode={"flat"}
            onItemClick={OnSelectAction}
            textField={"name"}
            popupSettings={{
              popupAlign: {
                horizontal: "center",
                vertical: "top",
              },
            }}
          />
        )}
        <Button
          fillMode={"flat"}
          iconClass={"mdi mdi-file-excel-outline"}
          themeColor={"success"}
          title="Export to Excel"
          onClick={exportToExcel}
        />
      </>
    );
  }, [exportToExcel, OnSelectAction, availableActions]);

  const renderDetailsGrid = useCallback(
    (detailsGridProps: GridDetailRowProps) => {
      const dataItem = detailsGridProps.dataItem as BomMaterialDetailsDto;
      if (!dataItem.isSnRequired || !dataItem.materialItems?.length)
        return null;
      return (
        <div style={{ margin: "0 -4px" }}>
          <div
            style={{
              width: 572,
            }}
          >
            <Grid
              data={dataItem.materialItems}
              rowHeight={25}
              scrollable={"none"}
            >
              {MaterialItemsColumns.map((column) => (
                <Column key={column.field} {...column} />
              ))}
            </Grid>
          </div>
        </div>
      );
    },
    [MaterialItemsColumns]
  );

  const renderMaterialsGridRow = useCallback(
    (
      row: React.ReactElement<
        HTMLTableRowElement,
        string | React.JSXElementConstructor<any>
      >,
      gridRowProps: GridRowProps
    ) => {
      const dataItem = gridRowProps.dataItem as IMaterialGridItem;
      const { onClick, onDoubleClick, rowHeight, children } = gridRowProps;
      const additionalClassName =
        !dataItem.isSnRequired || !dataItem.materialItems?.length
          ? styles.NotShowBtn
          : "";
      return (
        <tr
          key={dataItem.id}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          className={`${row.props.className} ${additionalClassName}`}
          style={{ height: rowHeight }}
        >
          {children}
        </tr>
      );
    },
    []
  );

  const onSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort);
  }, []);

  return (
    <>
      {props.renderToolbar(renderMaterialsActions())}
      <div style={{ flex: 1, overflow: "hidden" }}>
        <ExcelExport data={sortedGridData} ref={exportRef}>
          <Grid
            style={{ height: "100%" }}
            data={sortedGridData}
            detail={renderDetailsGrid}
            expandField="expanded"
            rowHeight={40}
            scrollable={"scrollable"}
            sortable={true}
            sort={sort}
            onSortChange={onSortChange}
            onExpandChange={(event) => {
              const dataItem = event.dataItem as IMaterialGridItem;
              expandedState[dataItem.id] = event.value;
              setExpandedState({ ...expandedState });
            }}
            dataItemKey={"id"}
            rowRender={renderMaterialsGridRow}
          >
            {MaterialsColumns.map((column) => {
              if (column.children) {
                return (
                  <Column key={column.title} title={column.title}>
                    {column.children.map((subColumn, index) => (
                      <Column key={column.title || "" + index} {...subColumn} />
                    ))}
                  </Column>
                );
              } else {
                return <Column key={column.field} {...column} />;
              }
            })}
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};
