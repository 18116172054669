/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** GpsSyncBatchRequest */
export interface GpsSyncBatchRequest {
  /** List`1 */
  location: any[];
}

export const PropsGpsSyncBatchRequest = {
  location: "location",
};

import { AxiosRequestConfig, ResponseType } from "axios";
import { BBPromise as BBPromiseInterface } from "../../interfaces";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => BBPromiseInterface<T>;
}

/**
 * @title FieldClix Time Keeping
 * @version v1
 */

export class Api_gps {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  gps = {
    /**
 * No description
 *
 * @tags Gps
 * @name ProcessGpsDataForWorkshift
 * @request POST:/Gps/process-gps-data
 * @secure

 */
    processGpsDataForWorkshift: (
      query: {
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/process-gps-data`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
 * No description
 *
 * @tags Gps
 * @name GetSasToken
 * @request GET:/Gps/Sas
 * @secure

 */
    getSasToken: (params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Gps/Sas`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Gps
 * @name SyncBatch
 * @request POST:/Gps/sync-batch
 * @secure

 */
    syncBatch: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: GpsSyncBatchRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/sync-batch`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
