import * as React from "react";
import {
  GridColumnMenuFilter,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { IStatementColumn } from "./interfaces";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  IColumnValue,
  IGridFilter,
  IGridFilterItem,
} from "../../Components/Dashboard/interfaces";
import { IsComplexGridFilter } from "../../Components/Dashboard/helpers";
import gridStyles from "../../Components/Dashboard/dashboard.module.scss";

interface props {
  defaultProps: any;
  defaultColumnsId: Array<string>; // string - IStatementColumn.key
  columns?: Array<IStatementColumn>;
  fieldId?: string;

  onColumnsSubmit(columns: Array<IStatementColumn>): void;

  onFilterChange?(
    gridFilter: IGridFilter,
    fieldId: string,
    fieldName: string
  ): void;

  filterByValues?(
    filters: Array<IGridFilterItem>,
    values: Array<IColumnValue>,
    fieldId: string,
    fieldName: string
  ): void;

  getColumnValues?(field: string, fieldId: string): Array<IColumnValue>;
}

interface state {
  columns: Array<IStatementColumn>;
  columnsExpanded: boolean;
  filtersExpanded: boolean;
  values: Array<IColumnValue>;
  valuesExpanded: boolean;
}

export default class CustomColumnMenu extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    let stateColumns: Array<IStatementColumn> = [];
    if (this.props.columns) {
      for (let i = 0; i < this.props.columns.length; i++) {
        stateColumns.push({ ...this.props.columns[i] });
      }
    }

    this.state = {
      columns: stateColumns,
      columnsExpanded: true,
      filtersExpanded: !this.props.columns ? true : false,
      valuesExpanded: false,
      values: this.GetInitialValuesSettings(),
    };
  }

  OnToggleColumn = (e: any) => {
    let key = e.target.dataset.id;
    let column = this.state.columns.find((column) => key === column.id);
    if (column) column.visible = !column.visible;
    this.forceUpdate();
  };

  OnReset = (event: any) => {
    if (this.props.columns) {
      event.preventDefault();
      let stateColumns: Array<IStatementColumn> = [];
      for (let i = 0; i < this.props.columns.length; i++) {
        stateColumns.push({ ...this.props.columns[i] });
      }
      this.setState({ columns: stateColumns });
    }
  };

  OnSubmit = (event?: any) => {
    if (event) event.preventDefault();
    this.props.onColumnsSubmit(this.state.columns);
    this.props.defaultProps.onCloseMenu();
  };

  OnMenuItemClick = () => {
    this.setState((state) => ({ columnsExpanded: !state.columnsExpanded }));
  };

  SetDefaultColumns = () => {
    let columns = this.state.columns;
    let defaultColumnsId = this.props.defaultColumnsId;
    for (let i = 0; i < columns.length; i++) {
      columns[i].visible = defaultColumnsId.indexOf(columns[i].id) > -1;
    }
    this.forceUpdate();
  };

  FilterExpandChange = () => {
    this.setState((state) => ({ filtersExpanded: !state.filtersExpanded }));
  };

  ValuesExpandChange = () => {
    this.setState((state) => ({ valuesExpanded: !state.valuesExpanded }));
  };

  OnFilterSubmit = (filter: IGridFilter) => {
    if (this.props.onFilterChange)
      this.props.onFilterChange(
        filter,
        this.props.fieldId!,
        this.props.defaultProps.column.field
      );
  };

  FilterByValues = (event?: any) => {
    if (event) event.preventDefault();
    if (!this.props.filterByValues) return;
    let filters: Array<IGridFilterItem> = [];
    for (let item of this.state.values) {
      if (item.Selected)
        filters.push({
          value: item.Id,
          field: item.FieldId,
          operator: "eq",
        });
    }
    this.props.filterByValues(
      filters,
      this.state.values,
      this.props.fieldId!,
      this.props.defaultProps.column.field
    );
    this.props.defaultProps.onCloseMenu();
  };

  OnToggleValue = (e: any) => {
    let index = e.nativeEvent.target.dataset.index;
    let values = this.state.values;
    values[index].Selected = e.value;
    this.forceUpdate();
  };

  SetDefaultValues = (event: any) => {
    event.preventDefault();
    let values = this.state.values;
    for (let key in values) {
      values[key].Selected = false;
    }
    this.setState({ values });
    this.FilterByValues();
  };

  GetInitialValuesSettings = () => {
    let stateValues = [];
    if (this.props.getColumnValues) {
      let values = this.props.getColumnValues(
        this.props.defaultProps.column.field,
        this.props.fieldId!
      );
      for (let value of values) {
        stateValues.push({ ...value });
      }
    }
    return stateValues;
  };

  render() {
    return (
      <div style={{ minWidth: 230 }}>
        {this.props.columns && this.renderColumnMenuGroup()}
        {this.props.fieldId && this.renderFilterByValues()}
        {this.renderFilterMenu()}
      </div>
    );
  }

  renderColumnMenuGroup = () => {
    return (
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem
          title={"Columns"}
          iconClass={"k-i-columns"}
          onClick={this.OnMenuItemClick}
        />
        <GridColumnMenuItemContent show={this.state.columnsExpanded}>
          <div className="k-column-list-wrapper">
            <form onSubmit={this.OnSubmit} onReset={this.OnReset}>
              <div className="k-column-list">
                {this.state.columns.map((column) => {
                  return (
                    <div key={column.id} className="k-column-list-item">
                      <Checkbox
                        id={`column-visibility-show-${column.id}`}
                        data-id={column.id}
                        readOnly={true}
                        checked={column.visible}
                        onClick={this.OnToggleColumn}
                        label={column.title}
                        style={{ gap: 8 }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="k-columnmenu-actions">
                <Button type="reset">Cancel</Button>
                <Button themeColor="primary">Save</Button>
                <Button
                  title="Set Default Columns Settings"
                  onClick={this.SetDefaultColumns}
                >
                  Default
                </Button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    );
  };

  renderFilterMenu = () => {
    let props = this.props.defaultProps;
    let gridFilters: IGridFilter = props.filter;
    let isFiltered =
      gridFilters &&
      gridFilters.filters &&
      gridFilters.filters.find(
        (filter) =>
          IsComplexGridFilter(filter) &&
          !IsComplexGridFilter(filter.filters[0]) &&
          filter.filters[0].field === props.column.field
      );
    let filteredClassName = isFiltered ? "filtered" : "";
    let expandedClassName = this.state.filtersExpanded ? "filtersExpanded" : "";
    return (
      <div
        className={`${gridStyles.ColumnmenuFilter} columnMenuExpandPanel ${filteredClassName} ${expandedClassName}`}
      >
        <GridColumnMenuFilter
          {...this.props.defaultProps}
          onExpandChange={this.FilterExpandChange}
          expanded={this.state.filtersExpanded}
          onFilterChange={this.OnFilterSubmit}
        />
      </div>
    );
  };

  renderFilterByValues = () => {
    let hasSelected = this.state.values.findIndex((item) => item.Selected) > -1;
    let selectedClassName = hasSelected ? "filtered" : "";
    let expandedClassName = this.state.valuesExpanded ? "valuesExpanded" : "";
    return (
      <div
        className={`columnMenuExpandPanel ${selectedClassName} ${expandedClassName}`}
      >
        <GridColumnMenuItemGroup>
          <GridColumnMenuItem
            title={"Filter by Value"}
            iconClass={"k-i-filter"}
            onClick={this.ValuesExpandChange}
          ></GridColumnMenuItem>
          <GridColumnMenuItemContent show={this.state.valuesExpanded}>
            <div className="k-column-list-wrapper">
              <form onSubmit={this.FilterByValues}>
                <div className="k-column-list" style={{ maxHeight: "350px" }}>
                  {this.state.values.map((item, i) => {
                    let key = item.Id;
                    return (
                      <div key={key} className="k-column-list-item">
                        <Checkbox
                          id={`column-visibility-show-${key}`}
                          data-index={i}
                          checked={item.Selected}
                          onChange={this.OnToggleValue}
                          label={item.Name === null ? "null" : item.Name}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="k-columnmenu-actions">
                  <Button onClick={this.SetDefaultValues}>Clear</Button>
                  <Button themeColor="primary">Apply</Button>
                </div>
              </form>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      </div>
    );
  };
}
