import FXCard from "../Common/FXCard/FXCard";
import { IOpenLocationCardProps } from "./interfaces";

import formStyles from "./card.module.scss";
import BaseComponent from "../BaseComponent";

import { OpenRecord } from "../../helpers/runscripts";
import OpenCardIconLink from "./OpenCardIconLink";
import React from "react";
import { GetReferenceRecordName } from "../../helpers/queries";
import LocationCardView from "../Map/LocationCardView";
import { showSomeError } from "../../helpers/helpers";

interface state {
  lastResizeCard: number;
  remountKey: number;
  locationName: string;
}

class LocationCard extends BaseComponent<IOpenLocationCardProps, state> {
  constructor(props: IOpenLocationCardProps) {
    super(props);
    this.state = {
      lastResizeCard: +new Date(),
      remountKey: +new Date(),
      locationName: "",
    };
  }

  componentDidMount(): void {
    if (this.props.locationId) this.GetLocationName();
  }

  render() {
    return (
      <FXCard
        title={
          <span className={formStyles.HeaderTitle}>{this.renderTitle()}</span>
        }
        onClose={this.Close}
        initialWidth={730}
        initialHeight={750}
        originalPaddings={true}
        onResize={this.OnCardResize}
        onStageChange={this.OnCardResize}
      >
        <LocationCardView
          isModalCard={true}
          closeModalCard={this.Close}
          lastResizeModalCard={this.state.lastResizeCard}
          {...this.props}
        />
      </FXCard>
    );
  }

  renderTitle = () => {
    if (!this.props.locationId) {
      return "New Location";
    }

    return (
      <>
        <OpenCardIconLink
          onClick={this.OpenLocationCard}
          title="Open Location Card"
        />
        <span>{this.state.locationName || "Loading..."}</span>
      </>
    );
  };

  GetLocationName = async () => {
    try {
      const { locationId } = this.props;
      if (!locationId) return;
      const locationName = await GetReferenceRecordName(+locationId);
      this.setState({ locationName });
    } catch (e) {
      showSomeError(e);
    }
  };

  OpenLocationCard = () => {
    if (this.props.locationId) OpenRecord("Locations", this.props.locationId);
  };

  OnCardResize = () => {
    this.setState({ lastResizeCard: +new Date() });
  };

  Close = () => {
    this.props.finally!();
  };
}

export default LocationCard
