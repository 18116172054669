import React, { useEffect, useState } from "react";
import FXCard from "../Common/FXCard/FXCard";
import formStyles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import {
  IProcessedTC,
  IProcessedTCAjustedTE,
  IProcessedTCOriginalTE,
} from "../../Pages/TKReview/interfaces";
import TCDetailsGrid from "../TC/DetailsGrid";
import { Card } from "@progress/kendo-react-layout";
import { useBooleanState } from "../../core/tools/Hooks";
import { showSomeError } from "../../helpers/helpers";
import api from "../../core/api/api";
import LoaderComponent from "../Common/Loader";
import moment from "moment/moment";
import { HorizontalTimeline } from "../TC/HorizontalTimeline";
import {
  filterByCTId,
  getProcessedDispatchInfo,
  getProcessedTE,
} from "../../Pages/TKReview/helpers";
import { Button, ButtonGroup, Toolbar } from "@progress/kendo-react-buttons";
import CardManagement from "./CardManagement";
import {
  SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo,
  SQL_DB_TK_TimeCardsForCompare_Response_TimeCards,
} from "../../core/api/generated/conterra";

interface IProps extends IFXCardProps {
  timeCards: IProcessedTC[];
}
interface ITCCompare {
  Id: number;
  Name: string;
  Details: SQL_DB_TK_TimeCardsForCompare_Response_TimeCards[];
  TimeLine: IProcessedTCOriginalTE[]; //SQL_TimeCardsForCompare_Response_TETimeLine[];
  AdjustedTimeLine: IProcessedTCAjustedTE[]; //SQL_TimeCardsForCompare_Response_AdjustedTimeLine[];
  DispatchInfo: SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo[];
}

const TimeCardsComparisonCard = (props: IProps) => {
  const { timeCards } = props;
  const loading = useBooleanState(false);
  const [compareData, setCompareData] = useState<ITCCompare[]>();
  const timeLineMode = useBooleanState(true);

  useEffect(() => {
    const loadData = async () => {
      const tcIds = timeCards.map(({ tcId }) => tcId).join(",");
      try {
        loading.setTrue();
        const result = await api.sql.dbTkTimeCardsForCompare({ tcIds });
        const timeCardsObj: {
          [key: number]: ITCCompare;
        } = {};
        const { timeCards, teTimeLine, adjustedTimeLine, dispatchInfo } =
          result[0];
        timeCards.forEach(({ tcId, employeeName, date }) => {
          timeCardsObj[tcId] = {
            Id: tcId,
            Name: `${moment(date).format("L")} ${employeeName}`,
            Details: timeCards.filter((tc) => filterByCTId(tc, tcId)),
            TimeLine: teTimeLine
              .filter((te) => filterByCTId(te, tcId))
              .map((te) => ({ ...te, ...getProcessedTE(te) })),
            AdjustedTimeLine: adjustedTimeLine
              .filter((te) => filterByCTId(te, tcId))
              .map((te) => ({ ...te, ...getProcessedTE(te) })),
            DispatchInfo: dispatchInfo
              .filter((d) => filterByCTId(d, tcId))
              .map((d) => ({
                ...d,
                ...getProcessedDispatchInfo(d),
              })),
          };
        });
        const compareData: ITCCompare[] = [];
        for (let id in timeCardsObj) {
          const data = timeCardsObj[id];
          compareData.push(data);
        }
        setCompareData(compareData);
      } catch (e) {
        showSomeError(e);
      } finally {
        loading.setFalse();
      }
    };
    loadData();
  }, []);

  const close = () => {
    props.finally!();
  };

  return (
    <FXCard
      title={`Comparison of ${timeCards.length} Time Cards`}
      initialWidth={950}
      initialHeight={900}
      originalPaddings={true}
      onClose={close}
    >
      <div className={formStyles.FormWrapper} style={{ overflow: "auto" }}>
        {loading.value && <LoaderComponent />}
        {!!compareData && (
          <>
            <Toolbar style={{ flex: "0 0 auto" }}>
              <ButtonGroup>
                <Button
                  togglable={true}
                  selected={timeLineMode.value}
                  onClick={timeLineMode.setTrue}
                >
                  Timeline
                </Button>
                <Button
                  togglable={true}
                  selected={!timeLineMode.value}
                  onClick={timeLineMode.setFalse}
                >
                  Grid
                </Button>
              </ButtonGroup>
            </Toolbar>
            {compareData.map(
              ({
                Id,
                Details,
                TimeLine,
                AdjustedTimeLine,
                Name,
                DispatchInfo,
              }) => {
                return (
                  <Card
                    key={Id}
                    style={{
                      marginTop: 8,
                      display: "block",
                      overflow: "initial",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 4,
                        padding: 4,
                      }}
                    >
                      <div
                        style={{
                          fontStyle: "italic",
                          fontWeight: 600,
                        }}
                      >
                        {Name}
                      </div>
                      <Button
                        fillMode={"flat"}
                        onClick={() => {
                          CardManagement.OpenTCCard(
                            Id,
                            undefined,
                            "TCAllocation"
                          );
                        }}
                      >
                        Open Card
                      </Button>
                    </div>
                    {timeLineMode.value ? (
                      <div style={{ padding: "0 8px" }}>
                        <HorizontalTimeline
                          timeLine={TimeLine}
                          adjustedTimeLine={AdjustedTimeLine}
                          dispatches={DispatchInfo}
                          style={{
                            marginTop: 25,
                            marginBottom: 25,
                          }}
                        />
                      </div>
                    ) : (
                      <TCDetailsGrid data={Details} />
                    )}
                  </Card>
                );
              }
            )}
          </>
        )}
      </div>
    </FXCard>
  );
};

export default TimeCardsComparisonCard;
