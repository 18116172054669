import { Button } from "@progress/kendo-react-buttons";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  TextArea,
} from "@progress/kendo-react-inputs";
import BaseComponent from "../../BaseComponent";
import FXCard from "../../Common/FXCard/FXCard";
import Loader from "../../Common/Loader";
import { IGBPCardProps } from "../interfaces";
import formStyles from "../card.module.scss";
import styles from "./gbpcard.module.scss";
import { IComboboxItem, simpleObject } from "../../../helpers/interfaces";
import { OpenRecord, RunScriptAsync } from "../../../helpers/runscripts";
import ComboboxFilterVirtual from "../../Dashboard/ComboboxFilterVirtual";
import CardManagement from "../CardManagement";
import {
  ICustomerJob,
  IInfo,
  IRole,
  IScenarioItem,
  ISettings,
  IUpsertInfo,
} from "./interfaces";
import { showSomeError, sortByNameCombobox } from "../../../helpers/helpers";
import OpenCardIconLink from "../OpenCardIconLink";
import ButtonLink from "../../Common/Buttons/ButtonLink";
import Link from "../../Common/Buttons/ButtonLink";
import { ReferenceRecordsDataSource } from "../../../helpers/queries";
import { ModalRef } from "../../Common/Modal/Modal";
import FilterDropdownTree from "../../Common/Form/FilterDropdownTree";

interface IState {
  processing: boolean;
  loadingInitialData: boolean;
  invalidForm: boolean;
  invalidRoles: boolean;
  remountKey: number;
  remountRoleKey: number;
  sites: Array<IComboboxItem>;
  scenarios: Array<IScenarioItem>;
  customers: Array<IComboboxItem>;
  filteredCustomerJobs: Array<ICustomerJob>;
  bpOwners: Array<ICustomerJob>;
  categories: Array<IComboboxItem>;
  classes: Array<IComboboxItem>;
  filteredRoles: Array<IComboboxItem>;
  employees: Array<IComboboxItem>;
  settings: ISettings | null;
}

class GPBCard extends BaseComponent<IGBPCardProps, IState> {
  submitRef: any;
  Name: string = "";
  Description: string = "";
  customerJobs: Array<ICustomerJob> = [];
  rolesList: Array<IComboboxItem> = [];

  upsertInfo: IUpsertInfo = {
    RequestId: 0,
    Name: "",
    IsCustomName: false,
    SiteId: null,
    ScenarioId: null,
    BPOId: null,
    CustomerId: null,
    CustomerJobId: null,
    CategoryId: null,
    ClassId: null,
    Description: "",
    NewCustomerJobName: "",
    IsPW: false,
  };

  Info: IInfo = {
    Name: "",
    IsCustomName: false,
    Site: null,
    Scenario: null,
    Customer: null,
    CustomerJob: null,
    BPO: null,
    Category: null,
    Class: null,
    Description: "",
    IsPW: false,
  };

  roles: Array<IRole> = [];

  fullBlockedProcessing: boolean = true;

  constructor(props: IGBPCardProps) {
    super(props);
    this.state = {
      processing: false,
      loadingInitialData: false,
      invalidForm: false,
      invalidRoles: false,
      remountKey: +new Date(),
      remountRoleKey: +new Date(),
      sites: [],
      scenarios: [],
      customers: [],
      filteredCustomerJobs: [],
      bpOwners: [],
      categories: [],
      classes: [],
      filteredRoles: [],
      employees: [],
      settings: null,
    };
  }

  componentDidMount() {
    this.LoadInitialData();
  }

  render() {
    return (
      <FXCard
        title={
          <>
            <OpenCardIconLink onClick={this.OpenRequest} title="Open Request" />
            <span className={formStyles.HeaderTitle}>Build Plan Requests</span>
          </>
        }
        onClose={this.Close}
        initialWidth={830}
        initialHeight={540}
        originalPaddings={true}
      >
        <div className={formStyles.FormWrapper}>
          {(this.state.processing || this.state.loadingInitialData) && (
            <Loader
              style={{
                background: !this.fullBlockedProcessing
                  ? "transparent"
                  : "#fff",
              }}
            />
          )}
          <div className={formStyles.Row}>
            <Input
              key={this.state.remountKey + "bpname"}
              className={`${formStyles.FormField} ${styles.BPNameInput}`}
              placeholder="Build Plan Name *"
              defaultValue={this.Info.Name}
              onChange={this.OnChangeBPName}
              onBlur={this.OnBlurBPName}
              onKeyDown={this.OnBlurBPName}
              disabled={!this.Info.IsCustomName}
              required={true}
            />
            {!!this.state.settings?.CustomBPNameAllow && (
              <div className={styles.CustomNameCheckBox}>
                <Checkbox
                  id="customName"
                  key={this.state.remountKey + "customName"}
                  defaultChecked={this.Info.IsCustomName}
                  label="Custom BP Name"
                  onChange={this.OnChangeIsCustomName}
                  dir={"RTL"}
                />
              </div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div className={styles.Left}>
              <div style={{ height: 19 }}>
                <Link
                  text="Copy from previous Request"
                  onClick={this.CopyPreviousRequest}
                />
              </div>
              <div className={formStyles.Row}>
                <ComboboxFilterVirtual
                  key={this.state.remountKey + "site"}
                  placeholder="Site *"
                  defaultValue={this.Info.Site}
                  data={this.state.sites}
                  loading={this.state.loadingInitialData}
                  onChange={this.OnChangeCombobox}
                  className={`${formStyles.FormField} ${formStyles.AddCombobox}`}
                  filter={"Site"}
                  required={true}
                  valueRender={this.renderSiteValue}
                />
                <Button
                  className={formStyles.AddBtn}
                  icon="plus"
                  fillMode="flat"
                  onClick={this.OpenAddSiteCard}
                />
              </div>
              <div style={{ paddingRight: 31.4 }}>
                <ComboboxFilterVirtual
                  key={this.state.remountKey + "scenario"}
                  placeholder="Project: Scenario *"
                  defaultValue={this.Info.Scenario}
                  data={this.state.scenarios}
                  onChange={this.OnChangeCombobox}
                  className={formStyles.FormField}
                  loading={this.state.loadingInitialData}
                  filter={"Scenario"}
                  required={true}
                />
              </div>
              <div className={formStyles.Row}>
                <ComboboxFilterVirtual
                  key={this.state.remountKey + "customer"}
                  placeholder="Customer *"
                  defaultValue={this.Info.Customer}
                  data={this.state.customers}
                  onChange={this.OnChangeCombobox}
                  className={`${formStyles.FormField} ${formStyles.AddCombobox}`}
                  loading={this.state.loadingInitialData}
                  filter={"Customer"}
                  required={true}
                />
                <Button
                  className={formStyles.AddBtn}
                  icon="plus"
                  fillMode="flat"
                  onClick={this.OpenAddCustomerCard}
                  disabled={!this.state.settings?.CustomerCreationAllow}
                />
              </div>
              <div className={formStyles.Row}>
                <FilterDropdownTree
                  key={this.state.remountKey + "customerjob"}
                  placeholder={
                    this.upsertInfo.NewCustomerJobName
                      ? `New Job: ${this.upsertInfo.NewCustomerJobName}`
                      : "Customer Job"
                  }
                  defaultValue={this.Info.CustomerJob}
                  data={this.state.filteredCustomerJobs}
                  onChange={this.OnChangeCombobox}
                  className={`${formStyles.FormField} ${formStyles.AddCombobox}`}
                  loading={this.state.loadingInitialData}
                  dataAttr={"CustomerJob"}
                  disabled={
                    !this.state.settings?.CustomerJobEditingAllow ||
                    this.Info.Customer === null ||
                    this.Info.Scenario === null
                  }
                />
                <Button
                  className={formStyles.AddBtn}
                  icon="plus"
                  fillMode="flat"
                  onClick={this.OpenAddCustomerJob}
                  disabled={
                    !this.state.settings?.CustomerJobEditingAllow ||
                    this.Info.Customer === null ||
                    this.Info.Scenario === null
                  }
                />
              </div>

              {!!this.state.settings?.CustomBPNameAllow &&
                this.Info.CustomerJob && (
                  <div className={styles.LinkBox}>
                    <ButtonLink
                      onClick={this.SetCustomerJobAsName}
                      text="Use Customer Job as Custom BP Name"
                    />
                  </div>
                )}
              <ComboboxFilterVirtual
                key={this.state.remountKey + "bpowner"}
                placeholder="BP Owner *"
                defaultValue={this.Info.BPO}
                data={this.state.bpOwners}
                onChange={this.OnChangeCombobox}
                className={formStyles.FormField}
                loading={this.state.loadingInitialData}
                filter={"BPO"}
                required={true}
              />
              <ComboboxFilterVirtual
                key={this.state.remountKey + "category"}
                placeholder="Category"
                defaultValue={this.Info.Category}
                data={this.state.categories}
                onChange={this.OnChangeCombobox}
                className={formStyles.FormField}
                loading={this.state.loadingInitialData}
                filter={"Category"}
              />
              <ComboboxFilterVirtual
                key={this.state.remountKey + "class"}
                placeholder="Class"
                defaultValue={this.Info.Class}
                data={this.state.classes}
                onChange={this.OnChangeCombobox}
                className={formStyles.FormField}
                loading={this.state.loadingInitialData}
                filter={"Class"}
              />
              <TextArea
                key={this.state.remountKey + "descr"}
                className={formStyles.TextArea}
                rows={6}
                placeholder="Description"
                defaultValue={this.Info.Description}
                onChange={this.OnChangeDescription}
                onBlur={this.OnBlurDescription}
              ></TextArea>
              <div className={`${formStyles.Row} ${formStyles.CheckboxRow}`}>
                <Checkbox
                  id="isPW"
                  key={this.state.remountKey + "isPW"}
                  defaultChecked={this.Info.IsPW}
                  label="PW by Default"
                  onChange={this.OnChangePW}
                />
              </div>
            </div>
            <div className={styles.Right}>{this.renderRoles()}</div>
          </div>
          {this.renderFooter()}
        </div>
      </FXCard>
    );
  }

  renderRoles = () => {
    return (
      <div>
        <div className={`${formStyles.Row} ${formStyles.HeadRow}`}>
          <div className={styles.RoleColumn}>Role *</div>
          <div className={styles.RoleColumn}>Employee *</div>
          <div className={styles.RoleDeleteColumn}></div>
        </div>
        {this.roles.map((item, i) => {
          return (
            <div
              className={formStyles.Row}
              key={this.state.remountRoleKey + "" + (item.Role?.Id || i)}
            >
              <div className={styles.RoleColumn}>
                <ComboboxFilterVirtual
                  placeholder="Role"
                  data={this.state.filteredRoles}
                  defaultValue={item.Role}
                  onChange={(value) => this.OnChangeRole(i, value)}
                  className={formStyles.FormField}
                  filter={i}
                  required={true}
                />
              </div>
              <div className={styles.RoleColumn}>
                <ComboboxFilterVirtual
                  // value={item.employee}
                  placeholder="Employee"
                  data={this.state.employees}
                  defaultValue={item.Employee}
                  onChange={(value) => this.OnChangeEmployee(i, value)}
                  className={formStyles.FormField}
                  filter={i}
                  required={true}
                />
              </div>
              <div className={styles.RoleDeleteColumn}>
                <Button
                  icon="minus"
                  fillMode="flat"
                  title="Delete"
                  style={{ marginBottom: 5 }}
                  onClick={() => this.RemoveRole(i)}
                />
              </div>
            </div>
          );
        })}
        <div className={formStyles.Row} key={"newRow"}>
          <Button
            icon="plus"
            onClick={this.AddRole}
            disabled={this.state.invalidRoles}
            className={styles.AddRoleBtn}
          />
        </div>
      </div>
    );
  };

  renderSiteValue = (rendering: React.ReactElement<HTMLSpanElement>) => {
    return (
      <>
        {rendering}
        {!!this.Info.Site && (
          <Button
            className={styles.OpenSiteCardBtn}
            icon="hyperlink-open"
            fillMode="flat"
            onClick={this.OpenSiteCard}
            size={"small"}
            title={"Open Site Card"}
          />
        )}
      </>
    );
  };

  renderFooter = () => {
    return (
      <div className={`${formStyles.FormFooter} k-action-buttons`}>
        <span className={formStyles.InvalidMessage}>
          {this.state.invalidForm && <span>Fill all mandatory fields</span>}
        </span>
        <Button onClick={this.Close}>Cancel</Button>
        <Button
          ref={(ref) => {
            if (this.submitRef !== ref) this.submitRef = ref;
          }}
          onClick={this.GBP}
          themeColor="primary"
          disabled={this.state.processing || this.state.invalidForm}
        >
          Generate Build Plan
        </Button>
      </div>
    );
  };

  CopyPreviousRequest = async () => {
    try {
      let result = await RunScriptAsync(
        "BuildPlanRequests_CopyFromPreviousRequest",
        { RequestId: this.upsertInfo.RequestId }
      );
      if (!result) this.Upsert("request", null);
      else
        ModalRef.showDialog({
          type: "error",
          title: "Copy from previous Request Error",
          text: result,
        });
    } catch (e) {
      showSomeError(e);
    }
  };

  OpenRequest = () => {
    OpenRecord("FSMBuildPlanRequests", this.upsertInfo.RequestId);
    this.Close();
  };

  SetCustomerJobAsName = () => {
    this.Name = this.Info.CustomerJob!.Name;
    this.OnChange([[this.Name, "Name"]]);
  };

  OnSiteCreated = async (siteId: number) => {
    try {
      this.setState({ loadingInitialData: true });
      const sites = (await ReferenceRecordsDataSource(
        "FSMSites"
      )) as IComboboxItem[];
      let site = sites.find((site: IComboboxItem) => site.Id === siteId);
      this.Info.Site = site || null;
      this.setState({
        remountKey: +new Date(),
        sites: sites.sort(sortByNameCombobox),
      });
      this.OnChange([[site, "Site"]]);
    } finally {
      this.setState({ loadingInitialData: false });
    }
  };

  OnCustomerCreated = async (customerId?: number) => {
    try {
      this.setState({ loadingInitialData: true });
      let result: any = await this.GetSQLData({
        spName: "GBP_GetInitialData",
        params: { onlyCustomers: true },
      });
      let customers = result[0];
      let customer = customers.find(
        (customer: IComboboxItem) => customer.Id === customerId
      );
      this.Info.Customer = customer || null;
      this.setState({
        remountKey: +new Date(),
        customers: customers.sort(sortByNameCombobox),
      });
      this.OnChange([[customer, "Customer"]]);
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loadingInitialData: false });
    }
  };

  OnCustomerJobCreated = async (customerJobId?: number) => {
    try {
      this.setState({ loadingInitialData: true });
      let result: any = await this.GetSQLData({
        spName: "GBP_GetInitialData",
        params: { onlyCustomerJobs: true },
      });
      this.customerJobs = result[0].sort(sortByNameCombobox);
      let customerJob = this.customerJobs.find(
        (customer: IComboboxItem) => customer.Id === customerJobId
      );
      this.Info.CustomerJob = customerJob || null;
      this.setState({
        remountKey: +new Date(),
        filteredCustomerJobs: this.GetFilteredCustomerJobs(),
      });
      this.OnChange([[customerJob, "CustomerJob"]]);
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loadingInitialData: false });
    }
  };

  OnChangeIsCustomName = (e: any) => {
    this.OnChange([[e.value, "IsCustomName"]]);
  };

  OnChangeBPName = (e: any) => {
    this.Name = e.value;
  };

  OnBlurBPName = (e: any) => {
    if (
      (e.type === "blur" && this.Name !== this.Info.Name) ||
      (e.type === "keydown" && e.key === "Enter")
    ) {
      const doGBPAfterUpsert = e.relatedTarget === this.submitRef?.element;
      let changes: Array<[any, keyof IInfo]> = [[this.Name, "Name"]];
      let customerJobByName = this.state.filteredCustomerJobs.find(
        (item) => item.Name.toLowerCase() === this.Name.toLowerCase().trim()
      );
      if (customerJobByName) {
        changes.push([customerJobByName, "CustomerJob"]);
      }
      this.OnChange(changes, doGBPAfterUpsert);
    }
  };

  OnChangeDescription = (e: any) => {
    this.Description = e.value;
  };

  OnBlurDescription = () => {
    this.OnChange([[this.Description, "Description"]]);
  };

  OnChangeCombobox = (value: any, dataAttr: keyof IInfo) => {
    let changes: Array<[any, keyof IInfo]> = [[value, dataAttr]];
    if (dataAttr === "Scenario") {
      changes.push([null, "CustomerJob"]);
    }
    this.OnChange(changes);
  };

  OnChangePW = (event: CheckboxChangeEvent) => {
    this.OnChange([[event.value, "IsPW"]]);
  };

  OnChange = (
    updateData: Array<[any, keyof IInfo]>,
    doGBPAfterUpsert?: boolean
  ) => {
    let changedData: simpleObject = {};
    updateData.forEach((data) => {
      let [value, field] = data;
      // @ts-ignore
      this.Info[field] = value;
      if (
        field === "IsCustomName" ||
        field === "Description" ||
        field === "Name" ||
        field === "IsPW"
      ) {
        changedData[field] = value;
      } else {
        changedData[field + "Id"] = value?.Id || null;
      }
    });
    this.Upsert("request", changedData, doGBPAfterUpsert);
  };

  IsInvalidRoles = () => {
    return this.roles.findIndex((item) => !item.Role || !item.Employee) > -1;
  };

  IsInvalidForm = () => {
    const invalidRoles = this.IsInvalidRoles();
    return (
      !this.Info.Name ||
      !this.Info.Site ||
      !this.Info.Scenario ||
      !this.Info.Customer ||
      !this.Info.BPO ||
      invalidRoles
    );
  };

  ValidateForm = () => {
    let invalidRoles = this.IsInvalidRoles();
    let invalidForm = this.IsInvalidForm();
    this.setState({ invalidRoles, invalidForm });
  };

  AddRole = () => {
    this.roles.push({ Role: null, Employee: null });
    this.OnChangeRoleRow();
  };

  RemoveRole = (index: number) => {
    this.roles.splice(index, 1);
    this.OnChangeRoleRow();
  };

  OnChangeRole = (index: number, value: IComboboxItem | null) => {
    let role = this.roles[index];
    role.Role = value;
    this.OnChangeRoleRow();
  };

  OnChangeEmployee = (index: number, value: IComboboxItem) => {
    let role = this.roles[index];
    role.Employee = value;
    this.OnChangeRoleRow();
  };

  OnChangeRoleRow = () => {
    if (this.IsInvalidRoles()) {
      let roles =
        this.roles.length === 0 ? this.rolesList : this.GetFilteredRoles();
      this.setState({
        filteredRoles: roles.sort(sortByNameCombobox),
        remountRoleKey: +new Date(),
        invalidForm: true,
        invalidRoles: true,
      });
      return;
    }
    let upsertData: Array<{
      RoleId: number | null;
      EmployeeId: number | null;
    }> = [];
    this.roles.forEach((row) => {
      upsertData.push({
        RoleId: row.Role ? +row.Role.Id : null,
        EmployeeId: row.Employee ? +row.Employee.Id : null,
      });
    });

    this.Upsert("roles", upsertData);
  };

  GetFilteredCustomerJobs = () => {
    return this.customerJobs.filter(
      (item) => item.CustomerId === this.Info.Customer!.Id
    );
  };

  GetFilteredRoles = () => {
    return this.rolesList.filter(
      (role) => this.roles.findIndex((item) => item.Role?.Id === role.Id) === -1
    );
  };

  LoadInitialData = async () => {
    try {
      this.setState({ loadingInitialData: true });
      let result: any = await this.GetSQLData({ spName: "GBP_GetInitialData" });
      this.customerJobs = result[4];
      this.rolesList = result[7];
      const sites: IComboboxItem[] = result[0].sort(sortByNameCombobox);
      this.setState({
        sites,
        scenarios: result[1].sort(sortByNameCombobox),
        bpOwners: result[2].sort(sortByNameCombobox),
        customers: result[3].sort(sortByNameCombobox),
        categories: result[5].sort(sortByNameCombobox),
        classes: result[6].sort(sortByNameCombobox),
        employees: result[8].sort(sortByNameCombobox),
        settings: result[9][0],
      });
      await this.Upsert("request", null);
      if (this.props.siteId) {
        const site = sites.find(({ Id }) => Id === this.props.siteId) || null;
        if (site) this.OnChange([[site, "Site"]]);
      }
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loadingInitialData: false });
    }
  };

  Upsert = async (
    type: "request" | "roles",
    data: simpleObject | null | Array<any>,
    doGBPAfterUpsert?: boolean
  ) => {
    try {
      this.fullBlockedProcessing = data === null;
      this.setState({ processing: true });
      let params: simpleObject = {
        [type === "request" ? "RequestJSON" : "RolesJSON"]: data
          ? JSON.stringify(data)
          : null,
      };
      if (data) params.RequestId = this.upsertInfo.RequestId;

      let result: any = await RunScriptAsync("GBP_Upsert", params);
      let {
        Request,
        Roles,
      }: {
        Request: IUpsertInfo;
        Roles: Array<{ RoleId: number; EmployeeId: number | null }>;
      } = result;

      if (type === "request") {
        if (this.Info.Name !== Request.Name) {
          this.Info.Name = Request.Name;
        }
        if (this.Info.IsCustomName !== Request.IsCustomName) {
          this.Info.IsCustomName = Request.IsCustomName;
        }
        if (this.Info.IsPW !== Request.IsPW) {
          this.Info.IsPW = Request.IsPW;
        }
        if ((this.Info.Site && this.Info.Site.Id) !== Request.SiteId) {
          let list = this.state.sites;
          this.Info.Site =
            (Request.SiteId &&
              list.find((item) => item.Id === Request.SiteId)) ||
            null;
        }
        if (
          (this.Info.Scenario && this.Info.Scenario.Id) !== Request.ScenarioId
        ) {
          let list = this.state.scenarios;
          this.Info.Scenario =
            (Request.ScenarioId &&
              list.find((item) => item.Id === Request.ScenarioId)) ||
            null;
        }
        if ((this.Info.BPO && this.Info.BPO.Id) !== Request.BPOId) {
          let list = this.state.bpOwners;
          this.Info.BPO =
            (Request.BPOId && list.find((item) => item.Id === Request.BPOId)) ||
            null;
        }
        if (
          (this.Info.Customer && this.Info.Customer.Id) !== Request.CustomerId
        ) {
          let list = this.state.customers;
          this.Info.Customer =
            (Request.CustomerId &&
              list.find((item) => item.Id === Request.CustomerId)) ||
            null;
        }
        if (
          (this.Info.CustomerJob && this.Info.CustomerJob.Id) !==
          Request.CustomerJobId
        ) {
          let list = this.customerJobs;
          this.Info.CustomerJob =
            (Request.CustomerJobId &&
              list.find((item) => item.Id === Request.CustomerJobId)) ||
            null;
        }
        if (
          (this.Info.Category && this.Info.Category.Id) !== Request.CategoryId
        ) {
          let list = this.state.categories;
          this.Info.Category =
            (Request.CategoryId &&
              list.find((item) => item.Id === Request.CategoryId)) ||
            null;
        }
        if ((this.Info.Class && this.Info.Class.Id) !== Request.ClassId) {
          let list = this.state.classes;
          this.Info.Class =
            (Request.ClassId &&
              list.find((item) => item.Id === Request.ClassId)) ||
            null;
        }

        this.Description = Request.Description;
        if (this.Info.Description !== Request.Description) {
          this.Info.Description = Request.Description;
        }

        this.upsertInfo = Request;
      }

      if (Roles.length || type === "roles") {
        let roles: Array<IRole> = [];
        Roles.forEach((item) => {
          roles.push({
            Role:
              (item.RoleId &&
                this.rolesList.find((role) => role.Id === item.RoleId)) ||
              null,
            Employee:
              (item.EmployeeId &&
                this.state.employees.find(
                  (employee) => employee.Id === item.EmployeeId
                )) ||
              null,
          });
        });
        this.roles = roles;
      }
      const filteredRoles: Array<IComboboxItem> =
        data === null || this.roles.length === 0
          ? this.rolesList
          : this.GetFilteredRoles();
      this.setState({
        remountKey: +new Date(),
        remountRoleKey: +new Date(),
        filteredCustomerJobs: this.Info.Customer?.Id
          ? this.GetFilteredCustomerJobs()
          : [],
        filteredRoles: filteredRoles.sort(sortByNameCombobox),
        invalidForm: this.IsInvalidForm(),
        invalidRoles: this.IsInvalidRoles(),
      });
      if (doGBPAfterUpsert) this.GBP();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.fullBlockedProcessing = false;
      this.setState({ processing: false });
    }
  };

  GBP = async () => {
    try {
      this.fullBlockedProcessing = true;
      this.setState({ processing: true });
      let bpId = await RunScriptAsync("GBP_Process", {
        RequestID: this.upsertInfo.RequestId,
      });
      if (bpId) {
        CardManagement.OpenBPCard(+bpId);
        this.Close();
      } else {
        this.Upsert("request", null);
      }
    } catch (e) {
      showSomeError(e);
    } finally {
      this.fullBlockedProcessing = false;
      this.setState({ processing: false });
    }
  };

  OpenAddSiteCard = () => {
    CardManagement.OpenSiteCard({ onFinish: this.OnSiteCreated });
  };

  OpenSiteCard = () => {
    let siteId = this.Info.Site?.Id;
    if (siteId)
      CardManagement.OpenSiteCard({
        siteId: +siteId,
        onFinish: this.OnSiteCreated,
      });
  };

  OpenAddCustomerCard = () => {
    CardManagement.OpenAddCustomerCard({ onFinish: this.OnCustomerCreated });
  };

  OpenAddCustomerJob = () => {
    if (!this.Info.Customer || !this.Info.Scenario) return;
    CardManagement.OpenAddCustomerJobCard({
      customer: this.Info.Customer,
      scenario: this.Info.Scenario,
      onFinish: this.OnCustomerJobCreated,
    });
  };

  Close = () => {
    this.props.finally!();
  };
}

export default GPBCard;
