import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { IAdjustedTimeLineItem } from "../interfaces";
import styles from "./adjustmentsEdit.module.scss";
import CardManagement from "../../../Cards/CardManagement";
import FilterCombobox from "../../../Common/Form/FilterCombobox";
import { INewComboboxItem } from "../../../../helpers/interfaces";
import { getDefaultStateCode } from "./helpers";
import { MultipleWo } from "./MultipleWo";
import {
  PropsSQLTKGetAvailableWOsResponse,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo,
  SQL_TK_GetAvailableWOs_Response,
} from "../../../../core/api/generated/conterra";

interface IProps {
  tcId: number;
  tcInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo;
  data: IAdjustedTimeLineItem;
  workOrders: SQL_TK_GetAvailableWOs_Response[];
  updateAllocation: (
    oldData: IAdjustedTimeLineItem,
    allocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[],
    stateCode: string | null
  ) => void;
  onCloseAllocationCard: () => void;
}

const AllocationInfo = (props: IProps) => {
  const [isEditMode, setEditMode] = useState(false);
  const { data, workOrders, tcId } = props;

  const turnOnEditMode = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setEditMode(true);
    document.addEventListener("click", turnOffEditMode);
  };

  const turnOffEditMode = () => {
    setEditMode(false);
    document.removeEventListener("click", turnOffEditMode);
  };

  const onSaveMultiAllocation = (
    result: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[],
    workOrders: SQL_TK_GetAvailableWOs_Response[]
  ) => {
    const stateCode = data.stateCode || getDefaultStateCode(result, workOrders);
    props.updateAllocation(data, result, stateCode);
  };

  const onChangeSingleWOAllocation = (value: INewComboboxItem | null) => {
    const allocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[] =
      [];
    const wo = (value as SQL_TK_GetAvailableWOs_Response) || undefined;
    if (wo) {
      allocation.push({
        percentage: 100,
        rowNumber: 0, //?
        workOrderCode: wo.code,
        workOrderId: wo.id,
        workOrderName: wo.name,
        workOrderNumber: wo.number,
      });
    }
    const stateCode =
      data.stateCode || getDefaultStateCode(allocation, workOrders);
    props.updateAllocation(data, allocation, stateCode);
  };

  const editAllocation = () => {
    const { date, employeeId } = props.tcInfo;
    CardManagement.WOAllocationCard({
      tcId: tcId,
      employeeId,
      date,
      onResult: onSaveMultiAllocation,
      allocation: data.manualAllocation || [],
      onClose: props.onCloseAllocationCard,
    });
  };

  const length = (data.manualAllocation || []).length;
  if (data.isStaticAllocation) {
    if (length > 1) {
      return (
        <div className={styles.TEEditWOAllocationBox}>
          <div>Multiple Work Orders ({length})</div>
          <MultipleWo
            btnText={`Multiple Work Orders (${length})`}
            workOrders={data.manualAllocation || []}
          />
        </div>
      );
    }
    return (
      <div className={styles.TEEditWOAllocationBox}>
        <span> {data.manualAllocation?.[0]?.workOrderName}</span>
      </div>
    );
  }

  if (length > 1) {
    return (
      <div className={styles.TEEditWOAllocationBox} onClick={editAllocation}>
        <span> Multiple Work Orders ({length})</span>
      </div>
    );
  }

  const allocation = data.manualAllocation?.[0];
  const wo =
    (allocation && workOrders.find((wo) => wo.id === allocation.workOrderId)) ||
    null;
  return (
    <div className={styles.TEEditWOAllocationBox} onClick={turnOnEditMode}>
      {isEditMode ? (
        <FilterCombobox
          placeholder={"Is Not Allocated"}
          className={styles.AllocationDropDown}
          data={workOrders}
          value={wo}
          onChange={onChangeSingleWOAllocation}
          groupMode="modern"
          groupField={PropsSQLTKGetAvailableWOsResponse.groupName}
          size={"small"}
          popupSettings={{
            width: 350,
          }}
          dataItemKey={"id"}
          textField={"name"}
        />
      ) : (
        <div
          style={{
            flex: 1,
            height: 25,
            display: "flex",
            alignItems: "center",
          }}
        >
          {allocation?.workOrderName || "Is Not Allocated"}
        </div>
      )}

      <Button
        onClick={editAllocation}
        iconClass={"mdi mdi-call-split"}
        title={"Multiple Allocation"}
        fillMode={"flat"}
      />
    </div>
  );
};

export default AllocationInfo;
