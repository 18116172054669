import axios from "axios";

export enum ErrorType {
  Error = "error",
  Warning = "warning",
  Info = "info",
}

enum ErrorLevel {
  Info = "Information",
  Warning = "Warning",
  Exception = "Exception",
}

const KNOWN_EXCEPTION_CODES = [
  "FxValidationException",
  "FxScriptException",
  "FxAccessDeniedException",
  "FxNotFoundException",
  "FxException",
];

export const transformApiError = (error: any) => {
  if (axios.isCancel(error)) {
    return new ApiCancellationError(error);
  }

  if (error.isAxiosError) {
    if (error.response) {
      const responseData = error.response?.data;
      if (
        !!responseData?.Code &&
        KNOWN_EXCEPTION_CODES.includes(responseData?.Code)
      ) {
        if (responseData?.Code !== "FxScriptException")
          return new ApiWarningError(error);
        
        switch (responseData.Level) {
          case ErrorLevel.Info:
            return new ApiInformationError(error);
          case ErrorLevel.Warning:
            return new ApiWarningError(error);
          case ErrorLevel.Exception:
            return new ApiExceptionError(error);
        }
      } else if (error.response.status >= 500) {
        return new ApiServerError(error);
      }
    } else {
      return new ApiNetworkError(error);
    }
  }
  return new ApiSyntaxError(error);
};

export class ApiError extends Error {
  type: ErrorType;
  supressErrorHandler: boolean;

  constructor(error: any) {
    super(error);
    this.type = ErrorType.Error;
    this.supressErrorHandler =
      !!error?.config?.supressErrorHandler || error.response?.status === 401;
  }
}

export class ApiInformationError extends ApiError {
  constructor(error: any) {
    super(error);
    this.name = "Information";
    this.message = error?.response?.data.Message;
    this.type = ErrorType.Info;
  }
}

export class ApiWarningError extends ApiError {
  constructor(error: any) {
    super(error);
    this.name = "Warning";
    this.message = error?.response?.data.Message;
    this.type = ErrorType.Warning;
  }
}

export class ApiExceptionError extends ApiError {
  constructor(error: any) {
    super(error);
    this.name = "Error";
    this.message = "Operation Error. Contact Administrator.";
  }
}

export class ApiServerError extends ApiError {
  constructor(error: any) {
    super(error);
    this.message = "Unknown Server Error.";
    this.name = "Error";
  }
}

export class ApiNetworkError extends ApiError {
  constructor(error: any) {
    super(error);
    this.message = "No Connectivity. Try again.";
    this.name = "Network Error";
  }
}

export class ApiCancellationError extends ApiError {
  constructor(error: any) {
    super(error);
    this.message = "Cancelled";
    this.name = "Cancelled";
  }
}

export class ApiSyntaxError extends ApiError {
  constructor(error: any) {
    super(error);
    this.message = "Syntax Error";
    this.name = "Syntax Error";
  }
}
