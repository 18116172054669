import React, { useEffect, useRef } from "react";
import { VariableSizeTree as Tree } from "react-vtree";

import { simpleObject } from "../../../helpers/interfaces";
import ExpandedHeader from "../../Common/ExpandedPanel/ExpandedHeader";
import { RunScriptLocal } from "../../../helpers/runscripts";
import styles from "./quicklaunchcard.module.scss";
import { IShortCutItem } from "./index";

interface props {
  groupedList: {
    type: string;
    items: IShortCutItem[];
  }[];
}

const ShortCutsGroupedList = (props: props) => {
  const { groupedList } = props;
  const treeRef = useRef<any>();
  const selectedIdRef = useRef<number | undefined>();

  useEffect(() => {
    selectedIdRef.current = undefined;
    recomputeTree();
  }, [groupedList]);

  const select = ({ shortCutId, shortCutType, index }: IShortCutItem) => {
    selectedIdRef.current = +shortCutId;
    recomputeTree();
  };

  const onSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    const { shortcutid } = e.currentTarget.dataset;
    if (!shortcutid || selectedIdRef.current === +shortcutid) return;
    selectedIdRef.current = +shortcutid;
    recomputeTree();
  };

  const onRowDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const ShortCutId = e.currentTarget.dataset.shortcutid;
    if (ShortCutId) shortCutOpen(+ShortCutId);
  };

  const shortCutOpen = (ShortCutId: number) => {
    RunScriptLocal("ShortCuts_Open", { ShortCutId });
  };

  const recomputeTree = () => {
    treeRef.current.list.current.props.itemData.recomputeTree();
  };

  const treeWalker = function* (refresh: boolean) {
    const stack = [];
    const groupedReverse = [...groupedList].reverse();
    for (let ch of groupedReverse) {
      stack.push({
        nestingLevel: 0,
        node: ch,
      });
    }

    while (stack.length !== 0) {
      // @ts-ignore
      const item = stack.pop();
      // @ts-ignore
      const {
        node: { items: children = [], type, shortCutId, shortCutName },
        nestingLevel,
      } = item;
      const id = type || shortCutId + "";
      const name = type || shortCutName;
      // @ts-ignore
      const isOpened = yield refresh
        ? {
            defaultHeight: type ? 35 : 30,
            id,
            isLeaf: children.length === 0,
            isOpenByDefault: true,
            name,
            nestingLevel,
            rowData: item.node,
          }
        : id;

      if (children.length !== 0 && isOpened) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            node: children[i],
          });
        }
      }
    }
  };

  const renderRow = (props: any) => {
    const rowData = props.data.rowData;
    const styles: React.CSSProperties = rowData.type
      ? { borderTop: "1px" + " solid #dee2e6", boxSizing: "border-box" }
      : {};
    return (
      <div style={{ ...props.style, ...styles }}>
        {rowData.type
          ? renderExpandHeader(rowData, props.isOpen, props.toggle)
          : renderShortCut(rowData)}
      </div>
    );
  };

  const renderExpandHeader = (
    row: simpleObject,
    isOpen: boolean,
    toggle: any
  ) => {
    const text = row.type;
    return (
      <ExpandedHeader
        key={text}
        expanded={isOpen}
        className="ShortCutType"
        level={2}
        onToggle={toggle}
        dataId={text}
        dataText={text}
        content={text}
      />
    );
  };

  const renderShortCut = (row: IShortCutItem) => {
    const { shortCutId, shortCutName, index } = row;
    const selected = shortCutId === selectedIdRef.current;
    return (
      <div
        key={shortCutId}
        className={`${styles.ShortCutItem} ${
          index % 2 ? styles.ShortCutItemOdd : ""
        } ${selected ? styles.ShortCutItemSelected : ""}`}
        style={{ height: 30, padding: 8, boxSizing: "border-box" }}
        data-shortcutid={shortCutId}
        onClick={onSelect}
        onDoubleClick={onRowDoubleClick}
      >
        {shortCutName}
      </div>
    );
  };

  return (
    <Tree
      ref={treeRef}
      className="ShortCutsList"
      treeWalker={treeWalker}
      height={2000}
      width={"100%"}
    >
      {renderRow}
    </Tree>
  );
};

export default ShortCutsGroupedList;
