import { Range } from "@progress/kendo-react-inputs";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./dashboard.module.scss";
import { IFilterSetting } from "./interfaces";
import { useState } from "react";

interface props {
  defaultValue: Range;
  filter: IFilterSetting;

  onChange(value: Range, filter: IFilterSetting): void;
}

interface state {
  rangeValue: Range;
}

const RangeTimeFilter = (props: props) => {
  const [rangeValue, setRangeValue] = useState<Range>(
    props.defaultValue || {
      start: 0,
      end: 24,
    }
  );
  // class RangeTimeFilter extends BaseComponent<props, state> {
  /*constructor(props: props) {
        super(props)
        this.state = {
            rangeValue: this.props.defaultValue || {start: 0, end: 24},
        }
    }

    render() {*/

  const GetTime = (hour: number) => {
    if (hour === 0) {
      return "12 AM";
    } else if (hour < 12) {
      return hour + " AM";
    } else if (hour === 12) {
      return "12 PM";
    } else if (hour === 24) {
      return "12 AM";
    } else {
      return hour - 12 + " PM";
    }
  };

  const OnChange = (e: any) => {
    let rangeValue: Range = { start: e[0], end: e[1] };
    setRangeValue(rangeValue);
    // this.setState({rangeValue})
  };

  const OnChangeEnd = (e: any) => {
    let rangeValue: Range = { start: e[0], end: e[1] };
    props.onChange(rangeValue, props.filter);
  };
  return (
    <div className={styles.TimeRangeSlider}>
      <Slider
        range={true}
        step={1}
        min={0}
        max={24}
        defaultValue={[
          props.defaultValue?.start || 0,
          props.defaultValue?.end || 23,
        ]}
        allowCross={false}
        onAfterChange={OnChangeEnd}
        onChange={OnChange}
        handleStyle={{ borderColor: "#2196f3", boxShadow: "none" }}
      />
      <div className={styles.TimeRangeSliderValue}>
        {GetTime(rangeValue.start)} - {GetTime(rangeValue.end)}
      </div>
    </div>
  );
  // }
};

export default RangeTimeFilter;
