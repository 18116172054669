import React, { useCallback, useMemo, useRef } from "react";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting } from "../Dashboard/interfaces";
import styles from "./tc.module.scss";
import { TRenderToolbar } from "../TabsNew/interfaces";
import api from "../../core/api/api";
import { SQL_TK_GetTCAttestations_Response } from "../../core/api/generated/conterra";

interface IProps {
  tcId?: number;
  isActive: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
}

const TCAttestations = (props: IProps) => {
  const { tcId, isActive } = props;
  const tcIdRef = useRef<number>();
  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "rowNum",
      title: "#",
      type: "string",
      aggregate: "count",
      gridWidth: 40,
      filterable: false,
      columnMenu: false,
    },
    {
      field: "title",
      title: "Attestation",
      type: "string",
      format: {
        type: "string",
        fieldId: "attestationId",
      },
    },
    {
      field: "confirmedText",
      title: "Confirmed",
      gridWidth: 110,
      type: "string",
      format: {
        type: "string",
        fieldId: "confirmed",
      },
    },
    {
      field: "text",
      title: "Text",
      gridWidth: 70,
      type: "string",
      format: {
        type: "icon",
        iconClass: "k-icon k-font-icon k-i-file-txt",
      },
    },
    {
      field: "comment",
      title: "Comment",
      type: "string",
    },
  ]);
  const gridRefreshRef = useRef<() => void>(() => {});
  const currentTcId = useMemo(() => {
    if (isActive && tcId && tcId !== tcIdRef.current) {
      tcIdRef.current = tcId;
    }
    return tcIdRef.current;
  }, [tcId, isActive]);

  const loadData = useCallback(async () => {
    const data = await api.sql.tkGetTcAttestations({ tcId: currentTcId });
    return data.map((item) => ({
      ...item,
      confirmedText: item.confirmed ? "Yes" : "No",
    }));
  }, [currentTcId]);

  const initRefresh = useCallback((refresh: any) => {
    gridRefreshRef.current = refresh;
  }, []);

  const getRowKey = useCallback(
    ({ rowNum }: SQL_TK_GetTCAttestations_Response) => rowNum + "",
    []
  );

  if (!currentTcId) return null;
  return (
    <DashboardGrid
      isActive={isActive}
      isParentPage={true}
      key={currentTcId}
      columnsSettings={columnsRef.current}
      getData={loadData}
      getRowKey={getRowKey}
      initRefresh={initRefresh}
      excelFileName="Attestations.xlsx"
      columnMenu={{
        filters: true,
        columns: false,
      }}
      getRowClass={({ confirmed }: SQL_TK_GetTCAttestations_Response) =>
        !confirmed ? styles.NotConfirmedAttestationsRow : ""
      }
      toolbar={{
        renderToolbar: props.renderToolbar,
      }}
      /*selectSettings={{
        pageId: "TKDashboard",
        onSelectRow: openTCCard, // for tab olso??
      }}*/
    />
  );
};

export default TCAttestations;
