import React, {
  JSX,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridRowProps,
} from "@progress/kendo-react-grid";
import LoaderComponent from "../../../Components/Common/Loader";
import styles from "./tkGrid.module.scss";
import { IProcessedTC } from "../interfaces";
import { ROW_HEIGHT } from "../helpers";
import throttle from "lodash.throttle";
import { useRefresher } from "../../../core/tools/Hooks";

const DATA_ITEM_KEY: keyof IProcessedTC = "tcId"; // todo id can be not
// unique. IsRelated true/false for same Id

export default function TKReviewGrid(props: {
  selectedTC: IProcessedTC | null;
  isLoading: boolean;
  timeCards: IProcessedTC[];
  renderTCItem: (tc: IProcessedTC) => JSX.Element;
  toolbarExpandState: boolean;
}) {
  const { isLoading, timeCards, toolbarExpandState, selectedTC } = props;
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [skip, setSkip] = useState(0);
  const resize = useRefresher();
  const gridRef = React.useRef<any>(null);
  const markedTCRef = useRef<{
    id: number;
    prevId?: number;
    nextId?: number;
  } | null>(null);

  useLayoutEffect(() => {
    resize();
  }, [toolbarExpandState, selectedTC]);

  useEffect(() => {
    setSkip(0);
  }, [timeCards]);

  const onWindowResize = useCallback(
    throttle(() => {
      resize();
    }, 200),
    [resize]
  );

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  useEffect(() => {
    markedTCRef.current = null;
  }, [timeCards]);

  useEffect(() => {
    if (selectedTC) {
      markedTCRef.current = {
        id: selectedTC.id,
        prevId: selectedTC.prevTC?.id,
        nextId: selectedTC.nextTC?.id,
      };
    } else if (!selectedTC && gridRef.current) {
      scrollToMarkedRow();
    }
  }, [selectedTC, gridRef, timeCards]);

  const scrollToMarkedRow = () => {
    if (markedTCRef.current) {
      const { id, prevId, nextId } = markedTCRef.current;
      let rowIndex = timeCards.findIndex(
        ({ id: currentId }) => currentId === id
      );
      if (rowIndex === -1) {
        rowIndex = timeCards.findIndex(({ id }) => id === nextId);
      }
      if (rowIndex === -1) {
        rowIndex = timeCards.findIndex(({ id }) => id === prevId);
      }
      if (rowIndex > -1 && gridRef.current) {
        gridRef.current.scrollIntoView({ rowIndex });
      }
    }
  };

  const gridHeight = useMemo(
    () => gridContainerRef.current?.clientHeight || 300,
    [gridContainerRef.current, resize.value]
  );

  // todo  if after filtration (filteredTimeCards changed) selected tc doesn't
  // shown do unselect TC
  const onGridPageChange = useCallback(
    (event: GridPageChangeEvent) => {
      setSkip(event.page.skip);
    },
    [setSkip]
  );

  const renderGridRow = useCallback(
    (row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => {
      const tc = rowProps.dataItem as IProcessedTC;
      const { style, id } = row.props;
      return (
        <tr
          key={row.key}
          id={`tc_${tc.id}`}
          className={`k-table-row k-master-row ${
            markedTCRef.current?.id === tc.id ? "k-selected" : ""
          }`}
          style={style as React.CSSProperties}
        >
          <td>{props.renderTCItem(tc)}</td>
        </tr>
      );
    },
    [props.renderTCItem]
  );

  const pageSize = Math.ceil((gridHeight * 3) / ROW_HEIGHT);

  return (
    <div className={styles.OuterContainer} ref={gridContainerRef}>
      {isLoading && <LoaderComponent />}
      {!isLoading && !!selectedTC && (
        <div style={{ height: ROW_HEIGHT, padding: "0 4px" }}>
          {props.renderTCItem(selectedTC)}
        </div>
      )}

      {!isLoading && !selectedTC && (
        <div className={styles.InnerContainer}>
          <Grid
            ref={gridRef}
            className={`${styles.ListGrid}`}
            style={{ height: gridHeight }}
            rowHeight={ROW_HEIGHT}
            data={timeCards}
            pageSize={pageSize}
            total={timeCards.length}
            skip={skip}
            scrollable={"virtual"}
            onPageChange={onGridPageChange}
            dataItemKey={DATA_ITEM_KEY}
            rowRender={renderGridRow}
            resizable={true}
          >
            <Column field={"EmployeeName"}></Column>
          </Grid>
        </div>
      )}
    </div>
  );
}
