import React from "react";
import { pageId, simpleObject } from "../../helpers/interfaces";
import DashboardGrid from "../Dashboard/Grid";
import {
  gridChangeDataAction,
  IColumnFilterItem,
  IColumnSetting,
  IGridFilter,
} from "../Dashboard/interfaces";
import BaseComponent from "../BaseComponent";
import { tabId } from "../Tabs/interfaces";

import CardManagement from "../Cards/CardManagement";
import ButtonLink from "../Common/Buttons/ButtonLink";

interface IProps {
  periodId?: number;
  periodName?: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  switchTab?: (
    tab: tabId,
    serverFilters?: simpleObject,
    columnFilters?: IColumnFilterItem[],
    complexGridFilters?: IGridFilter[]
  ) => void;
  onChangeGridData: (
    data: simpleObject[],
    action: gridChangeDataAction
  ) => void;
  doNotSaveFilters: boolean;
  pageId: pageId;
}

class PayrollChecks extends BaseComponent<IProps> {
  gridRef: any = React.createRef();
  periodId = this.props.periodId;
  rowContextMenu:
    | {
        rowData: simpleObject;
        event: any;
      }
    | undefined;
  statusCard: null | {
    checkId: number;
    refresh: (rowData: simpleObject) => void;
  } = null;
  COLUMNS: IColumnSetting[];

  constructor(props: IProps) {
    super(props);
    this.COLUMNS = [
      {
        field: "CheckName",
        title: "Name",
        type: "string",
        format: {
          type: "link",
          refName: "TKPayrollChecks",
          fieldId: "CheckId",
        },
        gridWidth: 220,
      },
      {
        field: "Status",
        title: "Status",
        type: "string",
        format: {
          type: "string",
          fieldId: "Status",
        },
        gridWidth: 180,
      },
      {
        field: "IsDefaultStr",
        title: "Is Default",
        type: "string",
        format: {
          type: "string",
          fieldId: "IsDefault",
        },
        gridWidth: 100,
      },
      {
        field: "CheckStartDate",
        title: "Start Date",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "CheckEndDate",
        title: "End Date",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "CheckDate",
        title: "Check Date",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "IsReadyForSyncToPayrollStr",
        title: "Ready To Sync To Payroll",
        type: "string",
        format: {
          type: "string",
          fieldId: "IsReadyForSyncToPayroll",
        },
        gridWidth: 100,
      },
      {
        field: "IsSyncedToPayrollStr",
        title: "Is Synced To Payroll",
        type: "string",
        format: {
          type: "string",
          fieldId: "IsSyncedToPayroll",
        },
        gridWidth: 100,
      },
      {
        field: "IsReadyForSyncFromPayrollStr",
        title: "Ready For Sync From Payroll",
        type: "string",
        format: {
          type: "string",
          fieldId: "IsReadyForSyncFromPayroll",
        },
        gridWidth: 100,
      },
      {
        field: "IsSyncedFromPayrollStr",
        title: "Is Synced From Payroll",
        type: "string",
        format: {
          type: "string",
          fieldId: "IsSyncedFromPayroll",
        },
        gridWidth: 100,
      },
      {
        field: "SyncedToPayroll",
        title: "Synced To Payroll",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "SyncedFromPayroll",
        title: "Synced From Payroll",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        type: "parent",
        title: "Time Cards",
        columns: [
          {
            field: "TCCount",
            title: "Total",
            type: "number",
            format: {
              type: "integer",
              customFormat: true,
              getCustomFormat: this.renderPPReviewerCell,
              customTotal: true,
              getCustomTotalFormat: this.renderPPTotalCell,
            },
            aggregate: "sum",
          },
          {
            field: "IncompleteAllocationTCs",
            title: "Incomplete Allocation",
            type: "number",
            format: {
              type: "integer",
              customFormat: true,
              getCustomFormat: this.renderPPReviewerCell,
              customTotal: true,
              getCustomTotalFormat: this.renderPPTotalCell,
            },
            aggregate: "sum",
          },
          {
            field: "NotCompletedTCCount",
            title: "Not Completed",
            type: "number",
            format: {
              type: "integer",
              customFormat: true,
              getCustomFormat: this.renderPPReviewerCell,
              customTotal: true,
              getCustomTotalFormat: this.renderPPTotalCell,
            },
            gridWidth: 110,
            aggregate: "sum",
          },
          {
            field: "TCWithHoursCount",
            title: "With Hours",
            type: "number",
            format: {
              type: "integer",
              customFormat: true,
              getCustomFormat: this.renderPPReviewerCell,
              customTotal: true,
              getCustomTotalFormat: this.renderPPTotalCell,
            },
            gridWidth: 110,
            aggregate: "sum",
          },
        ],
      },
      {
        field: "NotCompletedRequestsCount",
        title: "Not Completed Requests",
        type: "number",
        format: {
          type: "integer",
          customFormat: true,
          getCustomFormat: this.renderPPReviewerCell,
          customTotal: true,
          getCustomTotalFormat: this.renderPPTotalCell,
        },
        gridWidth: 110,
        aggregate: "sum",
      },
      {
        title: "Hours",
        type: "parent",
        columns: [
          {
            field: "ApprovedHours",
            title: "Total",
            type: "number",
            format: {
              type: "number",
            },
            aggregate: "sum",
          },
          {
            field: "RegularHours",
            title: "Regular",
            type: "number",
            format: {
              type: "number",
            },
            aggregate: "sum",
          },
          {
            field: "OvertimeHours",
            title: "Overtime",
            type: "number",
            format: {
              type: "number",
            },
            aggregate: "sum",
          },
          {
            field: "DoubleHours",
            title: "Double",
            type: "number",
            format: {
              type: "number",
            },
            aggregate: "sum",
          },
          {
            field: "PrevailingHours",
            title: "Prevailing",
            type: "number",
            format: {
              type: "number",
            },
            aggregate: "sum",
          },
        ],
      },
    ];
  }

  renderLinkCell = (
    value: number,
    dataAttr: {
      dataItem?: simpleObject;
      field: string;
    }
  ) => {
    return (
      <div style={{ textAlign: "center" }}>
        {value === 0 ? (
          <span>{value}</span>
        ) : (
          <ButtonLink
            text={value + ""}
            onClick={this.OnCellClick}
            dataAttr={dataAttr}
          />
        )}
      </div>
    );
  };

  renderPPReviewerCell = (
    dataItem: simpleObject,
    field: string,
    data?: simpleObject[]
  ) => this.renderLinkCell(dataItem[field], { field, dataItem });

  renderPPTotalCell = (
    gridData: Array<simpleObject>,
    field: string,
    value: number
  ) => this.renderLinkCell(value, { field });

  render() {
    const { periodId, isActive, onChangeGridData } = this.props;
    if (isActive && this.periodId !== periodId) {
      this.periodId = periodId;
      this.rowContextMenu = undefined;
    }
    if (!this.periodId) return null;
    return (
      <>
        <DashboardGrid
          key={this.periodId /* + startDate + endDate*/}
          ref={this.gridRef}
          isActive={isActive}
          isParentPage={false}
          columnsSettings={this.COLUMNS}
          getData={this.LoadGridData}
          excelFileName="Payroll Period Checks.xlsx"
          getRowKey={(dataItem: simpleObject) => dataItem.CheckId}
          columnMenu={{
            filters: true,
            columns: false,
          }}
          selectSettings={{
            pageId: "TKDashboard",
            onSelectRow: this.OnSelectRow,
          }}
          initRefresh={this.InitRefresh}
          onChangeGridData={onChangeGridData}
        />
      </>
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_PayrollClosureData",
      params: { periodId: this.periodId, ...filters },
    });

    const data = result[0];
    for (let row of data) {
      row.IsDefaultStr = row.IsDefault ? "Yes" : "No";
      row.IsSyncedToPayrollStr = row.IsSyncedToPayroll ? "Yes" : "No";
      row.IsSyncedFromPayrollStr = row.IsSyncedFromPayroll ? "Yes" : "No";
      row.IsDefaultStr = row.IsDefault ? "Yes" : "No";
      row.IsReadyForSyncToPayrollStr = row.IsReadyForSyncToPayroll
        ? "Yes"
        : "No";
      row.IsReadyForSyncFromPayrollStr = row.IsReadyForSyncFromPayroll
        ? "Yes"
        : "No";
    }
    const { checkId, refresh } = this.statusCard || {};
    if (checkId && refresh) {
      const rowData = data.find((row: simpleObject) => row.CheckId === checkId);
      if (rowData) {
        refresh(rowData);
      }
      this.statusCard = null;
    }
    return data;
  };

  OnSelectRow = (dataItem: simpleObject | null): any => {
    if (!dataItem) return;
    CardManagement.OpenPayrollCheckStatusCard(
      dataItem,
      this.Refresh,
      this.OnSetDate
    );
  };

  OnSetDate = (
    checkId: number,
    refreshStatusCardFunc: (rowData: simpleObject) => void
  ) => {
    this.statusCard = { checkId, refresh: refreshStatusCardFunc };
    this.Refresh();
  };

  OnCellClick = (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    dataAttr?: any
  ) => {
    if (!this.periodId) return;
    const { field, dataItem } = dataAttr;
    const serverFilters: simpleObject = {
      periodId: this.periodId,
    };
    const fieldToReviewStateIdParamValue: simpleObject = {
      NotCompletedTCCount: "NotCompleted",
      IncompleteAllocationTCs: "ClosureAuditsState",
      NotCompletedRequestsCount: "ActiveRequestState",
    };
    const reviewStateId = fieldToReviewStateIdParamValue[field];
    if (reviewStateId) serverFilters.reviewStateId = reviewStateId;
    if (dataItem?.CheckId) serverFilters.checkId = dataItem.CheckId;
    const complexGridFilters: IGridFilter[] | undefined =
      field === "TCWithHoursCount"
        ? [
            {
              logic: "and",
              filters: [
                {
                  field: "TotalHours",
                  operator: "gt",
                  value: 0,
                },
              ],
            },
          ]
        : undefined;

    if (this.props.switchTab)
      this.props.switchTab(
        "PPTimeCards",
        serverFilters,
        undefined,
        complexGridFilters
      );
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default PayrollChecks;
