import DashboardGrid from "../Components/Dashboard/Grid";
import { simpleObject } from "../helpers/interfaces";
import {
  IColumnSetting,
  IFilterSetting,
} from "../Components/Dashboard/interfaces";
import BaseComponent from "../Components/BaseComponent";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { RunScriptLocal } from "../helpers/runscripts";
import DesktopActions from "../Components/Common/DesktopActions";

const FILTERS: Array<IFilterSetting> = [
  {
    id: "filterStatus",
    placeholder: "Filter by Status",
    type: "combobox",
    width: 200,
    defValue: "Active",
    values: [
      {
        Id: "Active",
        Name: "Active",
      },
      {
        Id: "Closed",
        Name: "Closed",
      },
    ],
    serverFilter: {
      param: "status",
    },
    toolbarRow: 1,
  },
];

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    columnMenu: false,
    locked: true,
    reorderable: false,
    aggregate: "count",
  },
  {
    field: "Status",
    title: "Status",
    type: "string",
    gridWidth: 100,
  },
  {
    field: "EmployeeName",
    title: "Full Name",
    type: "string",
    format: {
      type: "link",
      refName: "Employees",
      fieldId: "EmployeeId",
    },
    gridWidth: 220,
  },
  {
    field: "SyncedStr",
    title: "Synced",
    type: "string",
    format: {
      type: "string",
      fieldId: "Synced",
    },
    gridWidth: 100,
  },
  {
    field: "EmployeeNumber",
    title: "Employee ID",
    type: "string",
    gridWidth: 120,
  },
  {
    field: "DepartmentName",
    title: "Office / Department",
    type: "string",
    format: {
      type: "link",
      refName: "Departments",
      fieldId: "DepartmentId",
    },
    gridWidth: 130,
  },
  {
    field: "SupervisorName",
    title: "Supervisor",
    type: "string",
    format: {
      type: "link",
      refName: "Employees",
      fieldId: "SupervisorId",
    },
    gridWidth: 220,
  },
  {
    field: "JobTitleName",
    title: "Job Title",
    type: "string",
    format: {
      type: "link",
      refName: "JobTitles",
      fieldId: "JobTitleId",
    },
    gridWidth: 140,
  },
  {
    field: "EmployeeCategoryName",
    title: "Employee Category",
    type: "string",
    format: {
      type: "link",
      refName: "EmployeeCategories",
      fieldId: "EmployeeCategoryId",
    },
    gridWidth: 180,
  },
  {
    field: "DesktopUserStr",
    title: "Desktop User",
    type: "string",
    format: {
      type: "string",
      fieldId: "DesktopUser",
    },
    gridWidth: 110,
  },
  {
    field: "WebUserStr",
    title: "Web User",
    type: "string",
    format: {
      type: "string",
      fieldId: "WebUser",
    },
    gridWidth: 110,
  },
  {
    field: "PayrollStateName",
    title: "Payroll State",
    type: "string",
    format: {
      type: "link",
      refName: "States",
      fieldId: "PayrollStateId",
    },
    gridWidth: 100,
  },
  {
    field: "PayrollPeriodTypeName",
    title: "Payroll Period Type",
    type: "string",
    format: {
      type: "link",
      refName: "TKPayrollPeriodTypes",
      fieldId: "PayrollPeriodTypeId",
    },
    gridWidth: 130,
  },
  {
    field: "HomeAddress",
    title: "Home Address",
    type: "string",
    format: {
      type: "link",
      refName: "Locations",
      fieldId: "HomeAddressLocationId",
    },
    doNotFilterByValue: true,
    gridWidth: 220,
  },
  {
    field: "WorkEmail",
    title: "Work E-mail",
    type: "string",
    gridWidth: 220,
  },
  {
    field: "Cell",
    title: "Cell",
    type: "string",
  },
  {
    field: "PIN",
    title: "PIN",
    type: "string",
    gridWidth: 90,
  },
  {
    field: "StartDate",
    title: "Start Date",
    type: "date",
    gridWidth: 140,
    format: {
      type: "date",
    },
  },
  {
    field: "LastWorkingDate",
    title: "Last Working Date",
    type: "date",
    gridWidth: 140,
    format: {
      type: "date",
    },
  },
  {
    field: "TerminationDate",
    title: "Termination Date",
    type: "date",
    gridWidth: 140,
    format: {
      type: "date",
    },
  },
  {
    field: "ClassName",
    title: "Class",
    type: "string",
    format: {
      type: "link",
      refName: "Classes",
      fieldId: "ClassId",
    },
    gridWidth: 180,
  },
  {
    title: "Sick Hours",
    type: "string",
    columns: [
      {
        title: "Requested (Not Completed)",
        field: "SickHoursRequestedNotCompleted",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        title: "Available to Request",
        field: "SickHoursAvailableToRequest",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
    ],
  },
  {
    title: "Vacation Hours",
    type: "string",
    columns: [
      {
        title: "Requested (Not Completed)",
        type: "number",
        field: "VacationHoursRequestedNotCompleted",
        aggregate: "sum",
        format: {
          type: "number",
        },
      },
      {
        title: "Available to Request",
        type: "number",
        field: "VacationHoursAvailableToRequest",
        aggregate: "sum",
        format: {
          type: "number",
        },
      },
    ],
  },
];

interface props {
  isActive: boolean;
}

interface state {}

class EmployeesDashboard extends BaseComponent<props, state> {
  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={COLUMNS}
        filters={FILTERS}
        getData={this.LoadGridData}
        excelFileName="Employees.xlsx"
        getRowKey={(dataItem: simpleObject) => dataItem.EmployeeId}
        toolbar={{
          actionBtns: (
            <DesktopActions
              actions={
                <>
                  <Button onClick={this.CreateNewEmployee}>New Employee</Button>
                </>
              }
            />
          ),
        }}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        initRefresh={this.InitRefresh}
        defaultSort={[
          {
            dir: "asc",
            field: "EmployeeName",
          },
        ]}
      ></DashboardGrid>
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_Employees",
      params: filters,
    });
    const data = result[0];
    for (let row of data) {
      row.SyncedStr = row.Synced ? "Yes" : "No";
      row.DesktopUserStr = row.DesktopUser ? "Yes" : "No";
      row.WebUserStr = row.WebUser ? "Yes" : "No";
    }
    return data;
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  CreateNewEmployee = () => {
    RunScriptLocal("Employees_CreateNew");
  };
}

export default EmployeesDashboard;
