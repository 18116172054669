import React from "react";
import styles from "./tabscomponents.module.scss";
import { Toolbar } from "@progress/kendo-react-buttons";

interface IProps {
  isActive: boolean;
  children?: any;
  buttonProps?: React.HTMLProps<HTMLButtonElement>;
}

export const TabBtnComponent = (props: IProps) => {
  const { isActive, children, buttonProps } = props;

  return (
    <button
      className={`${styles.TabBtn} ${isActive ? styles.ActiveTabBtn : ""}`}
      {...(buttonProps || {})}
      type={"button"}
    >
      <>
        {children}
        {isActive && <div className={styles.ActiveTabIndicator}></div>}
        {!isActive && <div className={styles.TabIndicator}></div>}
      </>
    </button>
  );
};

export const TabsBtnsGroupComponent = (props: { children: any }) => (
  <div className={styles.TabsBtnsGroup}>{props.children}</div>
);

export const TabsToolbarComponent = (props: { children: any }) => (
  <Toolbar className={styles.TabsToolbar}>{props.children}</Toolbar>
);
