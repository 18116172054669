import { IProcessedTC } from "../interfaces";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Card } from "@progress/kendo-react-layout";
import styles from "./tkToolbar.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

export const CompareControl = (props: {
  compareMode: {
    setFalse: () => void;
    setTrue: () => void;
    toggle: () => void;
    value: boolean;
  };
  tcsToCompare: IProcessedTC[];
  compare(): void;
}) => {
  const { tcsToCompare } = props;
  const length = tcsToCompare.length;
  return (
    <Tooltip anchorElement="target" showCallout={false} parentTitle={true}>
      <Card
        className={styles.Card}
        style={props.compareMode.value ? { width: 180 } : {}}
      >
        <Button
          className={styles.CompareModeBtn}
          themeColor={props.compareMode.value ? "primary" : "base"}
          togglable={true}
          selected={props.compareMode.value}
          onClick={props.compareMode.toggle}
          iconClass={"mdi mdi-swap-horizontal"}
          fillMode={"flat"}
        ></Button>
        {props.compareMode.value && (
          <Button
            disabled={length < 2}
            fillMode={"flat"}
            onClick={props.compare}
            className={styles.CompareModeBtn}
          >
            Compare {length} Time Cards
          </Button>
        )}
      </Card>
    </Tooltip>
  );
};
