import React, { useCallback, useEffect } from "react";
import { Popover } from "@progress/kendo-react-tooltip";
import { useBooleanState } from "../../../core/tools/Hooks";
import OpenCardLink from "../../OpenCardLink";
import styles from "./timecard.module.scss";
import { SQL_DB_TK_GetTCSummary_Response_DefaultWOs } from "../../../core/api/generated/conterra";

export const MultipleWo = (props: {
  workOrders: SQL_DB_TK_GetTCSummary_Response_DefaultWOs[];
}) => {
  const isShownPopover = useBooleanState(false);
  const anchor = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShownPopover.value) {
      document.addEventListener("click", isShownPopover.setFalse);
    } else {
      document.removeEventListener("click", isShownPopover.setFalse);
    }
    return () => {
      document.removeEventListener("click", isShownPopover.setFalse);
    };
  }, [isShownPopover.value]);

  const onClickChip = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      isShownPopover.toggle();
    },
    [isShownPopover.toggle]
  );

  const renderPopover = () => {
    return (
      <Popover
        show={isShownPopover.value}
        anchor={anchor.current}
        callout={false}
        position={"right"}
      >
        <div style={{ width: 330, overflow: "auto", maxHeight: 400 }}>
          {props.workOrders.map(
            ({ defaultWOName, defaultWOId, percentage }) => (
              <div key={defaultWOId} className={styles.WORow}>
                <div>
                  <OpenCardLink
                    text={defaultWOName}
                    refName={"FSMWorkOrders"}
                    dataAttr={defaultWOId}
                  />
                </div>
                <div>{percentage}%</div>
              </div>
            )
          )}
        </div>
      </Popover>
    );
  };

  return (
    <>
      <div ref={anchor} onClick={onClickChip} className={styles.MultipleWOBtn}>
        Multiple
      </div>
      {renderPopover()}
    </>
  );
};
