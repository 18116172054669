import { ITabSetting } from "./interfaces";
import { tabId } from "../Tabs/interfaces";
import { GetTabId, GetTabName } from "../Tabs/helpers";
import React from "react";
import TabBtn from "./TabsBtn";
import { TabsBtnsGroupComponent } from "../Common/Tabs/TabsComponents";

interface IProps {
  tabsList: (tabId | ITabSetting)[];
  activeTabId: tabId | undefined;
  onChange: (tabId: tabId) => void;
}

const TabsBtnsGroup = (props: IProps) => {
  const { tabsList, activeTabId } = props;

  return (
    <TabsBtnsGroupComponent>
      {tabsList.map((tab) => {
        let tabId = GetTabId(tab);
        return (
          <TabBtn
            key={tabId}
            tabId={tabId}
            isActive={activeTabId === tabId}
            counter={typeof tab === "object" ? tab.counter : undefined}
            text={GetTabName(tab)}
            onChange={props.onChange}
          />
        );
      })}
    </TabsBtnsGroupComponent>
  );
};

export default TabsBtnsGroup;
