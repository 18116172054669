import React, { useCallback, useEffect, useState } from "react";
import { INewComboboxItem } from "../../helpers/interfaces";
import { Button } from "@progress/kendo-react-buttons";
import FXCard from "../Common/FXCard/FXCard";
import { IFXCardProps } from "./interfaces";
import api from "../../core/api/api";
import formStyles from "./card.module.scss";
import FilterCombobox from "../Common/Form/FilterCombobox";
import { showSomeError } from "../../helpers/helpers";
import { useBooleanState } from "../../core/tools/Hooks";
import {
  PropsWarehouseAreaDto,
  WarehouseAreaDto,
} from "../../core/api/generated/warehouse";
import LoaderComponent from "../Common/Loader";

interface IProps extends IFXCardProps {
  materialItemsIds: number[];
  refreshGrid(): void;
}

const ChangeWHAreaCard = (props: IProps) => {
  const { materialItemsIds } = props;
  const [warehouse, setWarehouse] = useState<INewComboboxItem>();
  const [warehouseAreaList, setWarehouseAreaList] = useState<
    WarehouseAreaDto[]
  >([]);
  const [warehouseArea, setWarehouseArea] = useState<WarehouseAreaDto>();
  const loadingWHAreas = useBooleanState();
  const processing = useBooleanState();
  const LoadWHAreas = useCallback(async (warehouseId: number) => {
    try {
      loadingWHAreas.setTrue();
      const result = await api.wh.area.find({ warehouseId });
      setWarehouseAreaList(result);
    } catch (e) {
      showSomeError(e);
    } finally {
      loadingWHAreas.setFalse();
    }
  }, []);

  const ChangeWarehouseArea = useCallback(async () => {
    if (!warehouseArea) return;
    try {
      processing.setTrue();
      await api.wh.mi.transfer({
        areaId: warehouseArea.id,
        materialItemIds: materialItemsIds,
      });
      props.refreshGrid();
      props.finally!();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [warehouseArea]);

  useEffect(() => {
    if (!warehouse) {
      setWarehouseAreaList([]);
    } else {
      LoadWHAreas(+warehouse.id);
    }
  }, [warehouse]);

  return (
    <FXCard
      title={"Change Warehouse Area"}
      onClose={props.finally}
      originalPaddings={true}
      initialWidth={500}
      initialHeight={170}
    >
      <div className={formStyles.FormWrapper}>
        {processing.value && <LoaderComponent />}
        <FilterCombobox
          placeholder="Warehouse"
          getData={api.sql.whBomAvailableWarehouses}
          value={warehouse}
          onChange={setWarehouse}
          className={formStyles.FormField}
          required={true}
          dataItemKey={"id"}
          textField={"name"}
        />
        <FilterCombobox
          placeholder="Warehouse Area"
          data={warehouseAreaList}
          value={warehouseArea}
          onChange={setWarehouseArea}
          className={formStyles.FormField}
          required={true}
          dataItemKey={PropsWarehouseAreaDto.id}
          textField={PropsWarehouseAreaDto.number}
          loading={loadingWHAreas.value}
          disabled={!warehouse}
        />
        <div className={`${formStyles.FormFooter} k-action-buttons`}>
          <Button onClick={props.finally}>Cancel</Button>
          <Button
            onClick={ChangeWarehouseArea}
            themeColor="primary"
            disabled={!warehouse || !warehouseArea}
          >
            Change
          </Button>
        </div>
      </div>
    </FXCard>
  );
};
export default ChangeWHAreaCard;
