import { showSomeError } from "../helpers/helpers";
import api from "../core/api/api";
import { SQL_DB_GetCounters_Response } from "../core/api/generated/conterra";

class CountersStore {
  autoRefreshTimeout: NodeJS.Timeout | undefined;
  INBOX_COUNTER_CLASS_NAME = "inbox-badge";
  TKReview_COUNTER_CLASS_NAME = "tkReview-badge";
  VIM_COUNTER_CLASS_NAME = "vim-badge";

  private values: SQL_DB_GetCounters_Response = {
    unReadJobs: 0,
    reviewTimeCards: 0,
    vimInvoiceCount: 0,
  };

  GetValues = () => this.values;

  Load = async () => {
    try {
      if (this.autoRefreshTimeout) clearTimeout(this.autoRefreshTimeout);
      const response = await api.sql.dbGetCounters();
      this.values = response[0];
      const { vimInvoiceCount, unReadJobs, reviewTimeCards } = this.values;
      this.setCounter(unReadJobs, this.INBOX_COUNTER_CLASS_NAME);
      this.setCounter(vimInvoiceCount, this.VIM_COUNTER_CLASS_NAME);
      this.setCounter(reviewTimeCards, this.TKReview_COUNTER_CLASS_NAME);
      this.autoRefreshTimeout = setTimeout(this.Load, 10 * 60 * 1000);
    } catch (error) {
      showSomeError(error);
    }
  };

  private setCounter = (count: number, className: string) => {
    const text = count ? count.toString() : "";
    const badges = window.document.getElementsByClassName(className);
    if (badges && badges.length) {
      // @ts-ignore
      for (let el of badges) {
        el.innerHTML = text;
      }
    }
  };
}

export default new CountersStore();
