import { Component } from "react";

import FXCard from "../Common/FXCard/FXCard";
import { IFXCardProps } from "./interfaces";
import AssignMaterialItemsToBP from "../../Pages/InventoryDashboard/AssignMaterialItemsToBP";

interface props extends IFXCardProps {
  bpId?: number;
  refreshGrid(): void;
}

export default class AssignMaterialItemsToBPCard extends Component<props> {
  render() {
    return (
      <FXCard
        title={"Assign Material Items to Build Plan"}
        onClose={this.props.finally}
      >
        <AssignMaterialItemsToBP
          bpId={this.props.bpId}
          onSuccess={() => {
            this.props.refreshGrid();
            this.props.finally?.();
          }}
        />
      </FXCard>
    );
  }
}
