import { showSomeError } from "../../../helpers/helpers";
import Loader from "../../Common/Loader";
import TCInfo from "./TCInfo";
import TCNonWages from "./TCNonWages";
import styles from "./timecard.module.scss";
import TCHistory from "./TCHistory";
import React, { useEffect, useRef, useState } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import { SQL_DB_TK_GetTCSummary_Response } from "../../../core/api/generated/conterra";
import TCDetailsGrid from "../DetailsGrid";
import { TRenderToolbar } from "../../TabsNew/interfaces";

const TCAllocation = (props: {
  tcId: number;
  isActive?: boolean;
  pageId?: string;
  renderToolbar?: TRenderToolbar;
}) => {
  const { tcId, isActive } = props;
  const tcIdRef = useRef<number>();
  const loading = useBooleanState(false);
  const [state, setState] = useState<SQL_DB_TK_GetTCSummary_Response>();

  useEffect(() => {
    if (isActive && tcId && tcId !== tcIdRef.current) {
      tcIdRef.current = tcId;
      loadData();
    }
  }, [tcId, isActive]);

  const refresh = () => {
    loadData();
  };

  const loadData = async () => {
    if (!tcId) return;
    try {
      loading.setTrue();
      const [data] = await api.sql.dbTkGetTcSummary({ TCId: tcId });
      setState(data);
    } catch (e) {
      showSomeError(e);
    } finally {
      loading.setFalse();
    }
  };

  const renderContent = () => {
    if (loading.value) return <Loader />;
    if (!state) return null;

    const { timeCardInfo, defaultWOs, history, nonWageSummary, details } =
      state;
    return (
      <>
        {!!tcIdRef.current && (
          <TCInfo
            key={tcIdRef.current + "tc-info"}
            info={timeCardInfo[0]}
            defaultWOs={defaultWOs}
            tcId={tcIdRef.current}
            refresh={refresh}
          />
        )}
        <TCDetailsGrid data={details} />
        {!!nonWageSummary.length && <TCNonWages data={nonWageSummary} />}
        {!!History.length && <TCHistory data={history} />}
      </>
    );
  };

  return (
    <>
      {props.renderToolbar?.({
        refresh,
      })}
      <div
        className={styles.TCContainer}
        style={{ overflow: "auto", position: "relative" }}
      >
        {renderContent()}
      </div>
    </>
  );
};

export default TCAllocation;
