import {
  IProcessedTCAjustedTE,
  IProcessedTCOriginalTE,
} from "../../../Pages/TKReview/interfaces";
import React from "react";

export const TETooltipContent = (props: {
  data: IProcessedTCOriginalTE | IProcessedTCAjustedTE;
  originalTe?: IProcessedTCOriginalTE;
  isFlag?: boolean;
}) => {
  const {
    costTypeName,
    startFormatted,
    finishFormatted,
    hoursDuration,
    approvedDuration,
  } = props.data;
  const {
    event: Event,
    location: Location,
    site: Site,
    comments: Comments,
  } = props.originalTe || {};
  return (
    <div>
      {!!props.isFlag ? (
        <div>{startFormatted}</div>
      ) : (
        <div>
          {startFormatted} - {finishFormatted}
        </div>
      )}
      {!!Event && <div>Event: {Event}</div>}
      <div>Cost Type: {costTypeName || ""}</div>
      {!!Location && <div>Location: {Location}</div>}
      {!!Site && <div>Site: {Site}</div>}
      {Site === null && Location === null && <div>Location: Unknown</div>}

      {!props.isFlag && <div>Duration: {hoursDuration}</div>}
      {!props.isFlag && <div>Approved: {approvedDuration}</div>}
      {!!Comments && <div>Comment: {Comments}</div>}
    </div>
  );
};
