import { useRef, useState } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import styles from "./timecard.module.scss";
import moment from "moment";
import { SQL_DB_TK_GetTCSummary_Response_History } from "../../../core/api/generated/conterra";

interface IColumn {
  field: keyof SQL_DB_TK_GetTCSummary_Response_History;
  title: string;
  width?: string;
}

const TCHistory = ({
  data,
}: {
  data: SQL_DB_TK_GetTCSummary_Response_History[];
  /*// todo
  * EmployeeId: number;
  EmployeeName: string;
  * Can be deleted from server data
  * */
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const columnsRef = useRef<IColumn[]>([
    {
      field: "date",
      title: "Date",
      width: "130px",
    },
    {
      field: "comment",
      title: "Comment",
    },
  ]);

  const toggleGrid = () => {
    setIsHidden(!isHidden);
  };

  const renderCell = (
    column: IColumn,
    props: React.PropsWithChildren<GridCellProps>
  ) => {
    const { field } = column;
    const value = props.dataItem[field];
    if (field === "date") return <td>{moment(value).format("L LT")}</td>;
    return <td style={{ color: "red" }}>{value}</td>;
  };

  const rendreHeaderToggleCell = () => {
    return (
      <div className={styles.TCGridToggleCell} onClick={toggleGrid}>
        <span
          className={`k-icon k-font-icon ${
            isHidden ? "k-i-arrow-chevron-right" : "k-i-arrow-chevron-down"
          }`}
        ></span>
        {isHidden ? "COMMENTS" : "Date"}
      </div>
    );
  };

  return (
    <Grid
      data={data}
      filterable={false}
      scrollable="none"
      className={`${styles.TCGrid} ${styles.TCGridWithoutTotalRow} ${
        isHidden ? styles.TCGridHidden : ""
      }`}
    >
      {columnsRef.current.map((column) => {
        const { field, title, width } = column;
        return (
          <Column
            key={field}
            field={field}
            title={title}
            width={width}
            cell={(props) => renderCell(column, props)}
            headerCell={field === "date" ? rendreHeaderToggleCell : undefined}
          />
        );
      })}
    </Grid>
  );
};

export default TCHistory;
