import { simpleObject } from "../../helpers/interfaces";
import { RunScriptLocal } from "../../helpers/runscripts";
import { IRowItem, sectionKey, sectionName } from "./interfaces";
import UserInfo from "../../stores/User";

export const minValueAxisNumber = 10000;
export const GetMinMaxAxixValues = (minValue: number) => {
  let minAxisValue =
    minValue === minValueAxisNumber * -1 ? minValueAxisNumber * -1 : undefined;
  let maxAxisValue =
    minValue === minValueAxisNumber * -1 ? minValueAxisNumber : undefined;
  return { min: minAxisValue, max: maxAxisValue };
};
export type filtersFields =
  | "Id"
  | "OwnerId"
  | "ProjectId"
  | "ProfitCenterId"
  | "ClassId"
  | "ScenarioId"
  | "RegionId"
  | "IsActive"
  | "Year";
export const GetPreparedSectionData = (
  data: Array<simpleObject>,
  sectionName: sectionName,
  format: "financial" | "percentages" | "hr"
): Array<IRowItem> => {
  let numberFields = [
    "Remaining",
    "Actual",
    "POBalance",
    "Revised",
    "Initial",
    "Pending",
  ];
  let sectionData = data.map((item: simpleObject): IRowItem => {
    let groupName = sectionName;
    item.Type = "numbers";
    item.Section = groupName;
    item.Format = format;

    for (let field of numberFields) {
      let value = item[field];
      item[`${field}Formatted`] = formatValueCell(value, format);
      if (field === "Remaining") {
        if (IsRevenueGroup(groupName))
          item.RemainingColor = value > 0 ? "red" : "green";
        else item.RemainingColor = value > 0 ? "green" : "red";
      }
    }
    // @ts-ignore
    return item;
  });

  sectionData.forEach((rowData: simpleObject) => {
    let groupName = rowData.Section;
    if (IsGroupWithTotal(groupName) || IsRevenueGroup(groupName)) {
      let UsedofPlanned: null | number = null;
      let isRedUsedofPlanned = false;
      let UsedofPlannedValues: {
        total: number | null;
        value: number | null;
      } = getValues(rowData, "UsedofPlanned", sectionData) || {
        total: null,
        value: null,
      };

      let UsedofPlannedPercentage =
        UsedofPlannedValues.value !== null && UsedofPlannedValues.total
          ? (UsedofPlannedValues.value / UsedofPlannedValues.total) * 100
          : null;
      if (UsedofPlannedPercentage !== null) {
        UsedofPlannedPercentage = +UsedofPlannedPercentage.toFixed(0);
        isRedUsedofPlanned = IsRevenueGroup(groupName)
          ? UsedofPlannedPercentage < 100
          : UsedofPlannedPercentage > 100;
        UsedofPlanned = UsedofPlannedPercentage;
      }
      rowData.UsedofPlanned = UsedofPlanned;
      rowData.UsedofPlannedFormatted = UsedofPlanned && UsedofPlanned + "%"; // todo ! for excel if isRed ???
      rowData.UsedofPlannedIsRed = isRedUsedofPlanned;

      let UsedofTotalPlanned: null | number = null;
      let isRedUsedofTotalPlanned = false;
      let UsedofTotalPlannedValues: {
        total: number | null;
        value: number | null;
      } = getValues(rowData, "UsedofTotalPlanned", sectionData) || {
        total: null,
        value: null,
      };
      let UsedofTotalPlannedPercentage =
        UsedofTotalPlannedValues.value !== null &&
        UsedofTotalPlannedValues.total
          ? (UsedofTotalPlannedValues.value / UsedofTotalPlannedValues.total) *
            100
          : null;
      if (UsedofTotalPlannedPercentage !== null) {
        UsedofTotalPlannedPercentage = +UsedofTotalPlannedPercentage.toFixed(0);
        isRedUsedofTotalPlanned = IsRevenueGroup(groupName)
          ? UsedofTotalPlannedPercentage < 100
          : UsedofTotalPlannedPercentage > 100;
        UsedofTotalPlanned = UsedofTotalPlannedPercentage;
      }
      rowData.UsedofTotalPlanned = UsedofTotalPlanned;
      rowData.UsedofTotalPlannedFormatted =
        UsedofTotalPlanned && UsedofTotalPlanned + "%"; // todo ! for excel if isRed ???
      rowData.UsedofTotalPlannedIsRed = isRedUsedofTotalPlanned;
    }
  });

  return sectionData;
};

export const GetSectionTitle = async (key: sectionKey) => {
  let settings = await UserInfo.getInfo();
  if (!!settings?.UseEasyBudget && key === "plannedRevenue")
    return "1. Labor Cost ($)";
  else return sectionTitles[key];
};

export const sectionTitles: { [key in sectionKey]: sectionName } = {
  plannedRevenue: "1. Planned Revenue", // if !useEasyBudget -> 1. Planned Revenue
  plannedExpenses: "2. Planned Expenses",
  totals: "3. Totals",
  profitMargins: "4. Profit Margins",
  laborHours: "5. Labor Hours",
};

export const sectionKeys: { [key in sectionName]: sectionKey } = {
  "1. Planned Revenue": "plannedRevenue", // if !useEasyBudget -> 1. Planned Revenue
  "1. Labor Cost ($)": "plannedRevenue",
  "2. Planned Expenses": "plannedExpenses",
  "3. Totals": "totals",
  "4. Profit Margins": "profitMargins",
  "5. Labor Hours": "laborHours",
};

export const IsRevenueGroup = (name: sectionName) => {
  return name === "1. Planned Revenue" || name === "1. Labor Cost ($)";
};

export const IsGroupWithTotal = (name: sectionName) => {
  return (
    name === sectionTitles.laborHours || name === sectionTitles.plannedExpenses
  );
};

export const formatValueCell = (
  value: number | null | undefined,
  format: "financial" | "percentages" | "hr"
) => {
  let valueString = "";
  if (value === null) {
    valueString = "--";
  } else if (value === undefined) {
    valueString = "";
  } else {
    if (format === "financial") {
      valueString = "$ " + formatFinancial(value);
    } else {
      let sign = format === "percentages" ? "%" : "hr";
      valueString = value.toFixed(2) + sign;
    }
  }
  return valueString;
};

export const formatFinancial = (value: number) => {
  var val = +Math.abs(value).toFixed(2);
  var format = val.toLocaleString("en");
  var float = format.split(".")[1];
  float = float ? (float.length === 2 ? float : float + "0") : "00";
  var result = format.split(".")[0] + "." + float;
  if (value < 0) {
    return "(" + result + ")";
  }
  return result;
};

export const GetTitleSettings = (
  title: string,
  numberOfRows: 1 | 2,
  isColoredValue?: boolean
): object => {
  return {
    text: title,
    position: "left",
    font: "700 normal 10px sans-serif",
    color: "#000000",
    background: "#fff",
    margin: {
      top: isColoredValue ? -47 : numberOfRows > 1 ? -38 : -33,
      bottom: numberOfRows === 2 ? -5 : undefined,
      left: -55,
    },
    padding: 8,
  };
};

export const GetLabelSettings = (format?: "percent", getColor?: any) => {
  return {
    font: "300 normal 11px sans-serif",
    margin: { top: -25 },
    format: format,
    content: (e: any) => {
      let value = e.value;
      if (value === null || !value.toString().length) {
        return "N/A";
      } else {
        return e.format === "percent"
          ? value.toFixed(2) + "%"
          : "$ " + formatFinancial(value);
      }
    },
    visual: getColor
      ? (e: any) => {
          let el: any = e.createVisual();
          el.children[0].options.fill.color = getColor(e.value);
          return el;
        }
      : undefined,
  };
};

export const gridColumns = [
  {
    title: " ",
    field: "Title",
    printWidth: 110,
    gridWidth: 220,
  },
  {
    title: "Pending Budget",
    field: "Pending",
    printWidth: 45,
    gridWidth: 100,
  },
  {
    title: "Initial Budget",
    field: "Initial",
    printWidth: 45,
    gridWidth: 100,
  },
  {
    title: "Current Budget",
    field: "Revised",
    printWidth: 45,
    gridWidth: 100,
  },
  {
    title: "Committed",
    field: "POBalance",
    printWidth: 45,
    gridWidth: 100,
  },
  {
    title: "Actual",
    field: "Actual",
    printWidth: 45,
    gridWidth: 100,
  },
  {
    title: "Remaining",
    field: "Remaining",
    printWidth: 45,
    gridWidth: 100,
  },
  {
    title: "% of Planned",
    field: "UsedofPlanned",
    printWidth: 45,
    gridWidth: 85,
  },
  {
    title: "% of Total",
    field: "UsedofTotalPlanned",
    printWidth: 45,
    gridWidth: 85,
  },
];

export const getCommitedActualSum = (rowItem: simpleObject) => {
  if (
    (rowItem.POBalance !== null && rowItem.POBalance !== undefined) ||
    (rowItem.Actual !== null && rowItem.Actual !== undefined)
  ) {
    return (rowItem.POBalance || 0) + (rowItem.Actual || 0);
  }
  return null;
};

export const getValues = (
  rowItem: simpleObject,
  columnField: string,
  sectionData: Array<IRowItem>
) => {
  let values = {
    total: null,
    value: getCommitedActualSum(rowItem),
  };

  if (columnField === "UsedofPlanned") {
    values.total = rowItem.Revised;
    return values;
  } else if (columnField === "UsedofTotalPlanned") {
    let totalRow = IsRevenueGroup(rowItem.Section)
      ? rowItem
      : rowItem.IsTotalRow && rowItem;
    if (!totalRow && sectionData) {
      totalRow = sectionData.find((row) => row.IsTotalRow);
    }
    if (totalRow) {
      values.total = totalRow.Revised;
      return values;
    }
  }
};

export const OpenBudget = (BuildPlanId: number, refresh: () => void) => {
    RunScriptLocal('WAFSM_OpenBPBudget', {BuildPlanId})
}
