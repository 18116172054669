import { useContext } from "react";
import { IntegrationWithDesktopContext } from "../../helpers/settings";

export default function DesktopActions({ actions }: { actions: any }) {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  if (!isIntegrationWithDesktop) return null;
  return actions;
}
