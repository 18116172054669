import {
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_TK_GetAvailableWOs_Response,
} from "../../../../core/api/generated/conterra";
import { costTypeCode } from "../interfaces";

export type adjustmentsAction =
  | "restore"
  | "split"
  | "delete"
  | "add"
  | "undo"
  | "clearAll";
export const EMPTY_COST_TYPE_CODE: costTypeCode = "EMPTY";
export const FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const getDefaultStateCode = (
  allocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[],
  workOrders: SQL_TK_GetAvailableWOs_Response[]
) => {
  // if (data.StateCode !== null) return data.StateCode
  const currentWorkOrders = workOrders.filter(({ id }) =>
    allocation.some(({ workOrderId }) => workOrderId === id)
  );
  if (!currentWorkOrders.length) return null;
  if (currentWorkOrders.length === 1)
    return currentWorkOrders[0].defaultStateCode;

  const uniqueStatesCodes = new Set(
    currentWorkOrders.map(({ defaultStateCode }) => defaultStateCode)
  );
  if (uniqueStatesCodes.size === 1) return Array.from(uniqueStatesCodes)[0];
  return null;
};
