import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBooleanState, useRefresher } from "../../core/tools/Hooks";
import { process, State } from "@progress/kendo-data-query";
import styles from "./tkReview.module.scss";
import TKReviewGrid from "./Grid";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { showSomeError } from "../../helpers/helpers";
import {
  EFiltersNames,
  IFilters,
  IProcessedTC,
  TLocalStorageKeys,
} from "./interfaces";
import TKListToolbar from "./Toolbar";
import { TKListTimeCard } from "./TimeCard";
import { SQL_DB_TK_Review_Response } from "../../core/api/generated/conterra";
import { INewComboboxItem, simpleObject } from "../../helpers/interfaces";
import { loadTimecards } from "./helpers";
import { settingsStorage } from "../../helpers/settings";
import moment from "moment/moment";
import { ISO_DATE_FORMAT } from "../../core/tools/formats";
import { DateRangePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { TimeCardsActions } from "./Toolbar/TimeCardsActions";
import { CompareControl } from "./Toolbar/CompareControl";
import CardManagement from "../../Components/Cards/CardManagement";
import { tabId } from "../../Components/Tabs/interfaces";

const localStorageKeys: TLocalStorageKeys = {
  [EFiltersNames.RESOURCE]: "TKResource",
  [EFiltersNames.REVIEWER]: "TKReviewer",
  [EFiltersNames.PP]: "TKPayroll",
  [EFiltersNames.DISPATCH]: "TKDispatch",
  [EFiltersNames.REVIEW_STATE]: "TKReviewState",
  [EFiltersNames.DATE]: "TKDate",
  [EFiltersNames.DATE_TO]: "TKDateTo",
};

export default function TKMain(props: {
  onSelectTC: (tc: IProcessedTC | null, initialTab?: tabId) => void;
  selectedTC: IProcessedTC | null;
  onSortChange: (isASC: boolean) => void;
  refreshKey: number;
}) {
  const filtersValuesRef = useRef<IFilters>({});
  const filtersRefresher = useRefresher();
  const dispatchFilterRefresher = useRefresher();
  const { selectedTC, refreshKey } = props;
  const [timeCards, setTimeCards] = useState<IProcessedTC[]>([]);
  const [localFilterState, setLocalFilterState] = useState<State | null>(null);
  const loading = useBooleanState(false);
  const toolbarExpand = useBooleanState(true);
  const checkAllRefresh = useRefresher();
  const [checkedTCs, setCheckedTCs] = useState<number[]>([]);
  const [tcsToCompare, setTcsToCompare] = useState<IProcessedTC[]>([]);
  const compareMode = useBooleanState(false);

  useEffect(() => {
    initFiltersFromStorage();
    loadData();
  }, []);

  useEffect(() => {
    refresh();
  }, [refreshKey]);

  useEffect(() => {
    if (!compareMode.value) setTcsToCompare([]);
  }, [compareMode.value]);

  const initFiltersFromStorage = useCallback(() => {
    for (let key in localStorageKeys) {
      const filterName = key as keyof TLocalStorageKeys;
      const valueStr = settingsStorage.getForCurrentUser(
        localStorageKeys[filterName]
      );
      if (
        valueStr &&
        (filterName === EFiltersNames.DATE ||
          filterName === EFiltersNames.DATE_TO)
      ) {
        filtersValuesRef.current[filterName] = moment(valueStr).toDate();
      } else {
        filtersValuesRef.current[filterName] = valueStr
          ? JSON.parse(valueStr)
          : null;
      }
    }
    filtersRefresher();
  }, [filtersValuesRef.current, filtersRefresher]);

  const clearDispatchFilter = useCallback(() => {
    filtersValuesRef.current[EFiltersNames.DISPATCH] = null;
    filtersRefresher();
    dispatchFilterRefresher();
  }, [filtersValuesRef.current, filtersRefresher, dispatchFilterRefresher]);

  const loadData = useCallback(
    async (/*params: SQL_TK_Review_New_Request*/) => {
      try {
        loading.setTrue();
        const filters = filtersValuesRef.current;
        const params: simpleObject /*SQL_TK_Review_New_Request*/ = {};
        for (let filterId in filters) {
          const value = filters[filterId as keyof IFilters];
          if (!value) continue;
          const paramName = !(value instanceof Date)
            ? value.ServerParamName || filterId
            : filterId;
          params[paramName as keyof SQL_DB_TK_Review_Response] =
            value instanceof Date
              ? moment(value).format(ISO_DATE_FORMAT)
              : value.ServerParamValue !== undefined
              ? value.ServerParamValue
              : value.id;
        }
        props.onSelectTC(null);
        console.log(params);
        const data = await loadTimecards(params, selectedTC?.tcId || null);
        data.forEach((tc, index) => {
          if (index > 0) {
            const prevTC = data[index - 1];
            tc.prevTC = prevTC;
            prevTC.nextTC = tc;
          }
        });
        setTimeCards(data);
      } catch (e) {
        showSomeError(e);
      } finally {
        loading.setFalse();
      }
      // todo scroll to selected after refresh
    },
    [loading, setTimeCards, filtersValuesRef.current, props.onSelectTC]
  );

  const setDispatchFilter = useCallback(
    (dispatch: INewComboboxItem) => {
      filtersValuesRef.current[EFiltersNames.DISPATCH] = dispatch;
      filtersRefresher();
      loadData();
    },
    [filtersValuesRef.current, filtersRefresher, loadData]
  );

  const onFilterChange = useCallback(
    (value: INewComboboxItem | null, filterName: keyof IFilters) => {
      if (value) {
        settingsStorage.setForCurrentUser(
          localStorageKeys[filterName],
          JSON.stringify(value)
        );
      } else {
        settingsStorage.removeForCurrentUser(localStorageKeys[filterName]);
      }
      // @ts-ignore
      filtersValuesRef.current[filterName] = value;
      if (filterName === EFiltersNames.RESOURCE) {
        clearDispatchFilter();
      }
      setTcsToCompare([]);
      setCheckedTCs([]);

      loadData();
    },
    [filtersValuesRef.current, clearDispatchFilter, loadData]
  );

  const onDateFilterChange = useCallback(
    (e: DateRangePickerChangeEvent) => {
      const { start, end } = e.value;
      const startLSKey = localStorageKeys[EFiltersNames.DATE];
      if (start) {
        settingsStorage.setForCurrentUser(
          startLSKey,
          moment(start).format(ISO_DATE_FORMAT)
        );
      } else {
        settingsStorage.removeForCurrentUser(startLSKey);
      }
      filtersValuesRef.current[EFiltersNames.DATE] = start;

      const endLSKey = localStorageKeys[EFiltersNames.DATE_TO];
      if (end) {
        settingsStorage.setForCurrentUser(
          endLSKey,
          moment(end).format(ISO_DATE_FORMAT)
        );
      } else {
        settingsStorage.removeForCurrentUser(endLSKey);
      }
      filtersValuesRef.current[EFiltersNames.DATE_TO] = end;
      clearDispatchFilter();
      loadData();
    },
    [filtersValuesRef.current, clearDispatchFilter, loadData]
  );

  const clearFilters = useCallback(() => {
    const filters = filtersValuesRef.current;
    for (let filterName in filters) {
      filters[filterName as keyof IFilters] = null;
      settingsStorage.removeForCurrentUser(
        localStorageKeys[filterName as EFiltersNames]
      );
    }
    dispatchFilterRefresher();
    filtersRefresher();
    loadData();
  }, [
    filtersValuesRef.current,
    dispatchFilterRefresher,
    filtersRefresher,
    loadData,
  ]);

  const refresh = useCallback(() => {
    loadData();
  }, [loadData]);

  const filteredTimeCards: IProcessedTC[] = useMemo(() => {
    if (!localFilterState) return timeCards;
    return process(timeCards, localFilterState).data;
  }, [timeCards, localFilterState]);

  const processedTimeCards: IProcessedTC[] = useMemo(() => {
    return filteredTimeCards.map((i) => ({
      ...i,
    }));
  }, [filteredTimeCards]);

  const availableToActionsTimeCardsCount = useMemo(
    () =>
      filteredTimeCards.filter(({ isPossibleActions }) => isPossibleActions)
        .length,
    [filteredTimeCards]
  );

  const onToggleCheckTC = useCallback(
    (event: CheckboxChangeEvent) => {
      const tcId = /*event.target.id.splice('_')[0]*/ +event.target.name!;
      setCheckedTCs(
        event.value
          ? [...checkedTCs, tcId]
          : checkedTCs.filter((Id) => Id !== tcId)
      );
    },
    [setCheckedTCs, checkedTCs]
  );

  const onToggleCheckAll = useCallback(
    (event: CheckboxChangeEvent) => {
      setCheckedTCs(
        event.value
          ? filteredTimeCards
              .filter((tc) => tc.isPossibleActions)
              .map(({ tcId }) => tcId)
          : []
      );
      checkAllRefresh();
    },
    [setCheckedTCs, checkAllRefresh, filteredTimeCards]
  );

  const clearCheckedIds = useCallback(() => {
    setCheckedTCs([]);
    checkAllRefresh();
  }, [setCheckedTCs, checkAllRefresh]);

  const onToggleCompareCheckTC = useCallback(
    (tc: IProcessedTC) => {
      const state = tcsToCompare.findIndex(({ tcId }) => tcId === tc.tcId) > -1;
      setTcsToCompare(
        !state
          ? [...tcsToCompare, tc]
          : tcsToCompare.filter(({ tcId }) => tcId !== tc.tcId)
      );
    },
    [tcsToCompare, setTcsToCompare]
  );

  const pushUpdateTC = useCallback(
    (tc: IProcessedTC) => {
      const tcForUpdateIndex = timeCards.findIndex(
        ({ tcId }) => tcId === tc.tcId
      );
      if (tcForUpdateIndex > -1) {
        timeCards.splice(tcForUpdateIndex, 1, tc);
      }
      setTimeCards([...timeCards]);
    },
    [timeCards, setTimeCards]
  );

  const renterTCItem = useCallback(
    (tc: IProcessedTC) => {
      return (
        <TKListTimeCard
          key={tc.tcId}
          data={tc}
          onToggleCheck={onToggleCheckTC}
          checkBoxRefresher={checkAllRefresh.value}
          checkedTcs={checkedTCs}
          pushUpdateTC={pushUpdateTC}
          setDispatchFilter={setDispatchFilter}
          onClick={props.onSelectTC}
          onToggleCompareCheck={onToggleCompareCheckTC}
          isSelected={selectedTC?.tcId === tc.tcId}
          isSelectedForComparing={
            tcsToCompare.findIndex(
              ({ tcId: compareId }) => compareId === tc.tcId
            ) > -1
          }
          isCompareMode={compareMode.value}
        />
      );
    },
    [
      onToggleCheckTC,
      checkAllRefresh,
      checkedTCs,
      pushUpdateTC,
      setDispatchFilter,
      props.onSelectTC,
      setTcsToCompare,
      compareMode,
      compareMode.value,
      tcsToCompare,
      onToggleCompareCheckTC,
      checkAllRefresh.value,
      selectedTC,
    ]
  );

  return (
    <div
      className={styles.ListContainer}
      style={{ flex: selectedTC ? "0 0 auto" : 1 }}
    >
      <div style={selectedTC ? { display: "none" } : {}}>
        <TKListToolbar
          onLocalStateChange={setLocalFilterState}
          refreshList={refresh}
          toolbarExpandState={toolbarExpand.value}
          onToggleToolbar={toolbarExpand.toggle}
          onClearFilters={clearFilters}
          filtersRefresher={filtersRefresher.value}
          dispatchFilterRefresher={dispatchFilterRefresher.value}
          onServerFilterChange={onFilterChange}
          onDateFilterChange={onDateFilterChange}
          filters={filtersValuesRef.current}
          onSortChange={props.onSortChange}
          timecardsActions={
            <TimeCardsActions
              onToggleCheckAll={onToggleCheckAll}
              clearCheckedIds={clearCheckedIds}
              checkedTCS={checkedTCs}
              enableTCSCount={availableToActionsTimeCardsCount}
              refreshList={refresh}
            />
          }
          compare={
            <CompareControl
              compareMode={compareMode}
              tcsToCompare={tcsToCompare}
              compare={() => {
                CardManagement.OpenTimeCardsComparisonCard({
                  timeCards: tcsToCompare,
                });
              }}
            />
          }
        ></TKListToolbar>
      </div>
      <TKReviewGrid
        selectedTC={selectedTC}
        toolbarExpandState={toolbarExpand.value}
        isLoading={loading.value}
        timeCards={processedTimeCards}
        renderTCItem={renterTCItem}
      />
    </div>
  );
}
