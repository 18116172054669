import diContainer from "../../inversify.config";
import DI_TYPES from "../diTypes";
import { IAuthProvider, ILogger } from "../interfaces";
import { Api_conterra } from "./generated/conterra";
import { ContentType, HttpClient } from "./httpClient";
import { Api_pnl } from "./generated/pnl";
import { Api_gps } from "./generated/gps";
import { Api_documents } from "./generated/documents";
import { Api_auth } from "./generated/auth";
import { Api_warehouse } from "./generated/warehouse";
import { ApiScriptLocal } from "../../helpers/runscripts";

const authProvider = diContainer.get<IAuthProvider>(DI_TYPES.IAuthProvider);
export { authProvider };
const logger = diContainer.get<ILogger>(DI_TYPES.ILogger);
const httpClient = new HttpClient(authProvider, logger);
const runScript = (
  scriptName: string,
  data: object = {},
  myParams?: object
) => {
  return httpClient.request<string | null>({
    path: `/old-script/${scriptName}`,
    method: "POST",
    body: data,
    type: ContentType.Json,
    ...(myParams || {}),
  });
};

const getSqlData = (spName: string, data?: unknown) => {
  return httpClient.request<any>({
    path: `/sql-old/${spName}`,
    method: "POST",
    format: "json",
    body: data,
  });
};
const conterra = new Api_conterra(httpClient);
// TODO эту часть кода можно генерировать при вызове swagger
const api = {
  sql: conterra.sql,
  script: conterra.scripts,
  pnl: new Api_pnl(httpClient).pnl,
  gps: new Api_gps(httpClient).gps,
  documents: new Api_documents(httpClient).documents,
  auth: new Api_auth(httpClient).auth,
  wh: new Api_warehouse(httpClient),
  runScript,
  getSqlData,
  axios: httpClient.axios,
  localScript: ApiScriptLocal,
};
export default api;
