import { ITabsToolbar } from "./interfaces";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import React, { useCallback } from "react";
import { tabId } from "../Tabs/interfaces";
import OpenCardIconLink from "../Cards/OpenCardIconLink";
import { OpenRecord } from "../../helpers/runscripts";
import LoaderComponent from "../Common/Loader";
import TabsBtnsGroup from "./TabsBtnsGroup";
import { TabsToolbarComponent } from "../Common/Tabs/TabsComponents";

const TabsToolbar = (props: ITabsToolbar) => {
  const {
    isLoading,
    activeTabId,
    onChangeTab,
    tabsList,
    nav,
    desktopLink,
    actionBtns,
    refresh,
    unSelect,
    setDefaultFilters,
    isFiltersDefault,
    exportToExcel,
  } = props;
  const OnClickTab = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { id } = e.currentTarget.dataset;
      if (id && onChangeTab) onChangeTab(id as tabId);
    },
    [onChangeTab]
  );

  const renderTabs = useCallback(
    (activeTabId?: tabId) => {
      return (
        <>
          {tabsList && (
            <TabsBtnsGroup
              tabsList={tabsList}
              activeTabId={activeTabId}
              onChange={onChangeTab}
            />
          )}
        </>
      );
    },
    [tabsList, OnClickTab]
  );

  const renderUnSelectBtn = useCallback(() => {
    if (!unSelect) return null;
    return (
      <Button
        icon={"close"}
        themeColor={"error"}
        fillMode={"flat"}
        onClick={unSelect}
      />
    );
  }, [unSelect]);

  const renderRefreshBtn = () => {
    if (!refresh) return null;
    return <Button icon={"refresh"} fillMode={"flat"} onClick={refresh} />;
  };

  const renderClearFiltersBtn = useCallback(() => {
    if (!setDefaultFilters) return null;
    return (
      <Button
        icon="filter-clear"
        title="Set Default Filters"
        fillMode={"flat"}
        onClick={setDefaultFilters}
        themeColor={!isFiltersDefault ? "primary" : undefined}
      />
    );
  }, [setDefaultFilters, isFiltersDefault]);

  const renderExportToExcelBtn = useCallback(() => {
    if (!exportToExcel) return null;
    return (
      <Button
        icon="file-excel"
        title="Export to Excel"
        fillMode={"flat"}
        onClick={exportToExcel}
      />
    );
  }, [exportToExcel]);

  const renderNavBtns = useCallback(() => {
    if (!nav) return null;
    return (
      <>
        <Button
          icon={"arrow-60-left"}
          disabled={nav.prevDisabled}
          fillMode={"flat"}
          onClick={nav.prevAction}
        />
        <Button
          icon={"arrow-60-right"}
          disabled={nav.nextDisabled}
          fillMode={"flat"}
          onClick={nav.nextAction}
        />
      </>
    );
  }, [nav]);

  const renderDesktopLink = useCallback(() => {
    if (!desktopLink) return null;
    const { refName, objectId } = desktopLink;
    return (
      <OpenCardIconLink
        notCardHeaderBtn={true}
        fillMode={"flat"}
        onClick={() => {
          OpenRecord(refName, objectId);
        }}
        title="Open Desktop Card"
      />
    );
  }, [desktopLink]);

  return (
    <TabsToolbarComponent>
      <>
        {renderTabs(activeTabId)}
        <ToolbarSpacer />
        {isLoading ? (
          <div style={{ position: "relative", width: 27, height: 27 }}>
            <LoaderComponent
              size={"small"}
              style={{ background: "transparent" }}
            />
          </div>
        ) : (
          <>
            {actionBtns}
            {renderNavBtns()}
            {renderDesktopLink()}
            {renderClearFiltersBtn()}
            {renderExportToExcelBtn()}
            {renderRefreshBtn()}
            {renderUnSelectBtn()}
          </>
        )}
      </>
    </TabsToolbarComponent>
  );
};

export default TabsToolbar;
