import { Button } from "@progress/kendo-react-buttons";
import { useContext } from "react";
import { IntegrationWithDesktopContext } from "../../helpers/settings";
import styles from "./card.module.scss";

interface props {
  title: string;
  notCardHeaderBtn?: boolean;
  fillMode?: null | "solid" | "outline" | "flat" | "link" | "clear";
  onClick(): any;
}

export default function OpenCardIconLink(props: props) {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  if (!isIntegrationWithDesktop) return null;
  return (
    <Button
      className={props.notCardHeaderBtn ? undefined : styles.HeaderLinkBtn}
      icon="hyperlink-open"
      fillMode={props.fillMode || "flat"}
      onClick={props.onClick}
      title={props.title}
    ></Button>
  );
}
