import FXCard from "../../Common/FXCard/FXCard";
import formStyles from "../card.module.scss";
import React, { useEffect, useMemo, useState } from "react";
import { IFXCardProps } from "../interfaces";
import { useLazyAsync } from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import LoaderComponent from "../../Common/Loader";
import ShortCutsGroupedList from "./GroupedList";
import { SQL_DB_ShortCuts_Response } from "../../../core/api/generated/conterra";
import { Toolbar } from "@progress/kendo-react-buttons";
import ClearableInput from "../../Common/Form/ClearableInput";
import { InputChangeEvent } from "@progress/kendo-react-inputs";

export interface IShortCutItem extends SQL_DB_ShortCuts_Response {
  index: number;
}

const QuickLaunchCard = (props: IFXCardProps) => {
  const [getShortCuts, { data, loading }] = useLazyAsync(api.sql.dbShortCuts);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getShortCuts();
  }, []);

  const close = () => {
    props.finally!();
  };

  const groupedData = useMemo(() => {
    if (!data) return null;
    const group: { [type: string]: IShortCutItem[] } = {};
    for (let item of data) {
      if (
        !!filter &&
        !item.shortCutName.toLowerCase().includes(filter.toLowerCase())
      ) {
        continue;
      }
      if (!group[item.shortCutType]) {
        group[item.shortCutType] = [];
      }
      group[item.shortCutType].push({
        ...item,
        index: group[item.shortCutType].length,
      });
    }
    let groupedList: {
      type: string;
      items: IShortCutItem[];
    }[] = [];
    for (let type in group) {
      groupedList.push({
        type,
        items: group[type],
      });
    }
    return groupedList;
  }, [data, filter]);
  const onChangeFilter = (e?: InputChangeEvent | undefined) => {
    setFilter(e?.value || "");
  };
  const onClearFilter = () => {
    setFilter("");
  };

  return (
    <FXCard
      title={"Quick Launch"}
      initialWidth={400}
      initialHeight={500}
      originalPaddings={false}
      onClose={close}
    >
      <div className={formStyles.FormWrapper}>
        {loading && <LoaderComponent />}
        <Toolbar style={{ flex: "0 0 auto" }}>
          <ClearableInput
            defaultValue={""}
            style={{ width: "100%" }}
            name="searchVIM"
            placeholder="Search..."
            onChange={onChangeFilter}
            clear={onClearFilter}
          />
        </Toolbar>
        {!loading && !!groupedData && (
          <ShortCutsGroupedList groupedList={groupedData} />
        )}
      </div>
    </FXCard>
  );
};
export default QuickLaunchCard;
