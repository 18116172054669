import FilterCombobox from "../../../Components/Common/Form/FilterCombobox";
import { INewComboboxItem } from "../../../helpers/interfaces";
import {
  EFiltersNames,
  IDispatchDataFilter,
  IPPFilterItem,
  TLocalStorageKeys,
} from "../interfaces";
import React, { useEffect, useRef, useState } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import styles from "./tkToolbar.module.scss";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import api from "../../../core/api/api";
import { ISO_DATE_FORMAT } from "../../../core/tools/formats";
import { showSomeError } from "../../../helpers/helpers";
import { SQL_TK_GetResources_Response } from "../../../core/api/generated/conterra";

type onFilterChange = (
  value: INewComboboxItem | null,
  name: keyof TLocalStorageKeys
) => void;

export function FilterByPP(props: {
  onChange: onFilterChange;
  value: IPPFilterItem | null;
  refresher: number;
}) {
  const loading = useBooleanState(false);
  const [dataState, setDataState] = useState<{
    data: IPPFilterItem[];
    isOverdue: boolean;
  }>({ data: [], isOverdue: false });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    loading.setTrue();
    const result = await api.sql.tkGetActivePayrollPeriods({});
    const data: IPPFilterItem[] = result.map(({ id, name, closureDate }) => ({
      id,
      name,
      isOverdue: closureDate ? new Date() > new Date(closureDate) : false,
    }));
    setDataState({
      data,
      isOverdue: data.findIndex((item) => item.isOverdue) > -1,
    });
    loading.setFalse();
  };

  const itemRender = (
    el: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    props: ListItemProps
  ) => {
    const dataItem = props.dataItem as IPPFilterItem;
    const style: React.CSSProperties = {};
    if (dataItem.isOverdue) {
      style.color = "red";
    }
    return (
      <li
        id={props.id}
        role="option"
        aria-selected={props.selected}
        className={el.props.className}
        // @ts-ignore
        onClick={el.props.onClick}
        style={style}
      >
        {dataItem.name}
      </li>
    );
  };

  return (
    <FilterCombobox
      key={"ppFilter" + props.refresher}
      className={dataState.isOverdue ? styles.OverdueFilter : ""}
      placeholder={"Filter by Payroll Period"}
      data={dataState.data}
      defaultValue={props.value}
      width={180}
      loading={loading.value}
      onChange={(value: INewComboboxItem | null) => {
        props.onChange(value, EFiltersNames.PP);
      }}
      popupSettings={{ width: 250 }}
      itemRender={itemRender}
      dataItemKey={"id"}
      textField={"name"}
    />
  );
}

export function FilterByDispatch(props: {
  params: IDispatchDataFilter;
  onChange: onFilterChange;
  value: INewComboboxItem | null;
  refresher: number;
}) {
  const [data, setData] = useState<INewComboboxItem[]>([]);
  const isLoading = useBooleanState(false);
  const GROUPED_FIELD = "Date";

  useEffect(() => {
    const getData = async () => {
      try {
        const { resource, finishDate, date } = props.params;
        isLoading.setTrue();
        const result = await api.sql.tkGetDispatches({
          employeeId: resource?.Id || null,
          startDate: date && moment(date).format(ISO_DATE_FORMAT),
          finishDate: finishDate && moment(finishDate).format(ISO_DATE_FORMAT),
        });

        setData(
          result.map(({ id, workOrderName, date }) => ({
            id,
            name: workOrderName,
            [GROUPED_FIELD]: moment(date).format("MM/DD/YYYY"),
          }))
        );
      } catch (e) {
        showSomeError(e);
      } finally {
        isLoading.setFalse();
      }
    };
    getData();
  }, [props.params]);

  return (
    <FilterCombobox
      key={"dispatchFilter" + props.refresher}
      placeholder={"Filter by Dispatch"}
      data={data}
      groupMode="modern"
      groupField={GROUPED_FIELD}
      popupSettings={{ width: 330 }}
      width={150}
      defaultValue={props.value}
      dataAttr={EFiltersNames.DISPATCH}
      onChange={props.onChange}
      loading={isLoading.value}
      dataItemKey={"id"}
      textField={"name"}
    />
  );
}

export function FilterByReviewer(props: {
  onChange: onFilterChange;
  value: INewComboboxItem | null;
  refresher: number;
}) {
  const [data, setData] = useState<INewComboboxItem[]>();
  const loading = useBooleanState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    loading.setTrue();
    const data = await api.sql.tkGetReviewers();
    setData(data);
    loading.setFalse();
  };

  return (
    <FilterCombobox
      key={"tkReviewFilter" + props.refresher}
      placeholder={"Filter by Reviewer"}
      data={data}
      width={160}
      onChange={(value: INewComboboxItem | null) => {
        props.onChange(value, EFiltersNames.REVIEWER);
      }}
      defaultValue={props.value}
      dataItemKey={"id"}
      textField={"name"}
      loading={loading.value}
    />
  );
}

export function FilterByResource(props: {
  onChange: onFilterChange;
  value: INewComboboxItem | null;
  refresher: number;
}) {
  const [data, setData] = useState<INewComboboxItem[]>();
  const loading = useBooleanState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    loading.setTrue();
    const data = await api.sql.tkGetResources();
    setData(
      data.sort(
        (a, b) => +b.hasIncompletedTimeCards - +a.hasIncompletedTimeCards
      )
    );
    loading.setFalse();
  };

  const itemRender = (
    el: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    props: ListItemProps
  ) => {
    const dataItem = props.dataItem as SQL_TK_GetResources_Response;
    const style: React.CSSProperties = {
      opacity: dataItem.hasIncompletedTimeCards ? 1 : 0.5,
    };
    return (
      <li
        id={props.id}
        key={dataItem.id}
        role="option"
        aria-selected={props.selected}
        className={el.props.className}
        // @ts-ignore
        onClick={el.props.onClick}
        style={style}
      >
        {dataItem.name}
      </li>
    );
  };

  return (
    <FilterCombobox
      key={"tkResource" + props.refresher}
      placeholder={"Filter by Resource"}
      data={data}
      width={160}
      loading={loading.value}
      defaultValue={props.value}
      onChange={(value: INewComboboxItem | null) => {
        props.onChange(value, EFiltersNames.RESOURCE);
      }}
      popupSettings={{ width: 250 }}
      itemRender={itemRender}
      dataItemKey={"id"}
      textField={"name"}
    />
  );
}

export function FilterByState(props: {
  onChange: onFilterChange;
  value: INewComboboxItem | null;
  refresher: number;
}) {
  const dataRef = useRef<INewComboboxItem[]>([
    { id: "P", name: "Pending" },
    { id: "D", name: "Adjusted" },
    { id: "A", name: "Acknowledged" },
    { id: "T", name: "Change Time" },
    { id: "C", name: "Completed" },
    {
      id: "ActiveRequestState",
      name: "Active Request",
      ServerParamName: "hasActiveRequest",
      ServerParamValue: true,
    },
    {
      id: "ClosureAuditsState",
      name: "Incomplete Allocation",
      ServerParamName: "hasClosureAuditResults",
      ServerParamValue: true,
    },
    {
      id: "HasReason",
      name: "Has Reason",
      ServerParamName: "hasReason",
      ServerParamValue: true,
    },
    {
      id: "NotCompleted",
      name: "All not Completed",
      ServerParamName: "notCompleted",
      ServerParamValue: true,
    },
  ]);

  return (
    <FilterCombobox
      key={"tkState" + props.refresher}
      placeholder={"Filter by State"}
      data={dataRef.current}
      width={150}
      defaultValue={props.value}
      onChange={(value: INewComboboxItem | null) => {
        props.onChange(value, EFiltersNames.REVIEW_STATE);
      }}
      dataItemKey={"id"}
      textField={"name"}
    />
  );
}
