import { Component } from "react";
// import { IFilterSetting } from './interfaces';
import { IComboboxItem } from "../../helpers/interfaces";
import {
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import gridStyles from "./dashboard.module.scss";

interface props {
  className?: string;
  // filter: IFilterSetting
  defaultValue?: boolean;
  trueText: string;
  falseText: string;
  id: string;

  onChange(value: boolean | null /* , filter: any */): void;
}

interface state {
  value: boolean | null;
}

interface IItem extends IComboboxItem {
  value: boolean;
}

class BooleanFilter extends Component<props, state> {
  items: Array<IItem> = [
    { Name: this.props.trueText, Id: this.props.id, value: true },
    { Name: this.props.falseText, Id: "Not" + this.props.id, value: false },
  ];

  constructor(props: props) {
    super(props);
    this.state = {
      value:
        this.props.defaultValue !== undefined ? this.props.defaultValue : null,
    };
  }

  render() {
    let value = this.state.value;
    return (
      <DropDownButton
        className={`${value !== null ? gridStyles.BlueFilter : ""} ${
          this.props.className
        }`}
        icon="filter"
        onItemClick={this.OnChange}
      >
        {this.items.map((item: any) => (
          <DropDownButtonItem
            key={item.Id}
            data-value={item.value}
            text={item.Name}
            selected={value !== null && value === item.value}
          ></DropDownButtonItem>
        ))}
      </DropDownButton>
    );
  }

  OnChange = (e: any) => {
    let value = e.item["data-value"];
    let newValue = this.state.value === value ? null : value;
    this.setState({ value: newValue });
    this.props.onChange(newValue /* , this.props.filter */);
  };
}

export default BooleanFilter
