import { useBooleanState, useRefresher } from "../../../core/tools/Hooks";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IAdjustedTimelinesData } from "./interfaces";
import { showSomeError } from "../../../helpers/helpers";
import LiveMap from "../../../Pages/LiveMap/LiveMap";
import SideBar from "../../Common/SideBar/SideBar";
import formStyles from "../../Cards/card.module.scss";
import api from "../../../core/api/api";
import { GetPreparedAdjustedTimeline } from "./helpers";
import TotalDurations from "../TotalDurations";
import Adjustments from "./Adjustments";
import styles from "../tc.module.scss";
import { refresherAction } from "../../../core/tools/interfaces";
import { SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo } from "../../../core/api/generated/conterra";
import moment from "moment";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import CardManagement from "../../Cards/CardManagement";
import { ModalRef } from "../../Common/Modal/Modal";
import { RunScriptAsync } from "../../../helpers/runscripts";
import { cardId, pageId } from "../../../helpers/interfaces";
import AdjustmentsEdit from "./AdjustmentsEdit";
import { TRenderToolbar } from "../../TabsNew/interfaces";

interface IProps {
  tcId?: number;
  tcRefresh?: () => void;
  isMapHidden?: boolean;
  modalTCMapWindowStage?: string | null;
  isActive: boolean;
  parentId?: pageId | cardId;
  renderToolbar?: TRenderToolbar;

  refreshParent?(): void;
}

const TCMap = (props: IProps) => {
  const { tcId, modalTCMapWindowStage, isActive } = props;
  const isCard = props.parentId === "TCMapCard";
  const isEditMode = useBooleanState(isCard);
  const refreshTCMapKey = useRefresher();
  const mapResizeRefresher = useRefresher();
  const loading = useBooleanState(false);
  const [state, setState] = useState<IAdjustedTimelinesData>();
  const tcIdRef = useRef<number>();
  const currentTcId = useMemo(() => {
    if (isActive && tcId && tcId !== tcIdRef.current) {
      tcIdRef.current = tcId;
    }
    return tcIdRef.current;
  }, [tcId, isActive]);

  useEffect(() => {
    if (currentTcId) {
      loadData(currentTcId);
    }
  }, [currentTcId]);

  useEffect(() => {
    if (!props.isMapHidden) mapResizeRefresher();
  }, [modalTCMapWindowStage]);

  const refresh = useCallback(() => {
    if (!currentTcId) return;
    loadData(currentTcId);
    refreshTCMapKey();
  }, [currentTcId]);

  const doShowAdjustmentsPanel = useMemo(() => {
    if (!state) return false;
    const { adjustmentInfo, auditAdjustmentInfo } = state;
    return (
      !!adjustmentInfo?.id ||
      (!!auditAdjustmentInfo?.canChange && isEditMode.value)
    );
  }, [state, isEditMode.value]);

  const loadData = useCallback(
    async (TCId: number) => {
      try {
        loading.setTrue();
        if (!isCard) isEditMode.setFalse();
        const [data] = await api.sql.dbTkGetTcAdjustmentInfo({ TCId });
        const {
          timeCardInfo,
          waivedLunches,
          adjustmentTimeLines,
          adjustmentAllocation,
          auditAdjustmentInfo,
          teTimeLines,
          teAllocation,
        } = data;
        const adjustmentInfo = data.adjustmentInfo[0];
        const timeLine = adjustmentInfo ? adjustmentTimeLines : teTimeLines;
        const allocation = adjustmentInfo ? adjustmentAllocation : teAllocation;

        const {
          timeline: preparedTimeline,
          totalApprovedHours,
          totalClockedHours,
        } = GetPreparedAdjustedTimeline(timeLine, allocation);

        setState({
          timeCardInfo: timeCardInfo[0],
          waivedLunches: waivedLunches,
          adjustmentInfo: adjustmentInfo,
          auditAdjustmentInfo: auditAdjustmentInfo[0],
          timeline: preparedTimeline,
          totals: {
            clocked: totalClockedHours,
            approved: totalApprovedHours,
            lunch:
              (adjustmentInfo
                ? adjustmentInfo.lunchDuration
                : auditAdjustmentInfo[0].lunchDuration) || undefined,
          },
        });
      } catch (e) {
        showSomeError(e);
      } finally {
        loading.setFalse();
      }
    },
    [loading, setState]
  );

  const onAdjustmentsSave = () => {
    refresh();
    props.tcRefresh?.();
  };

  const manageAdjustment = useCallback(
    async (action: "Apply" | "Delete" | "Reject") => {
      if (!state?.adjustmentInfo) return;
      const { id } = state.adjustmentInfo;
      try {
        ModalRef.startProcessing();
        await RunScriptAsync("TKAdjustments_Manage", {
          AdjID: id,
          Action: action,
        });
        props.refreshParent?.();
        refresh();
      } catch (e) {
        showSomeError(e);
      } finally {
        ModalRef.stopProcessing();
      }
    },
    [state]
  );

  if (!currentTcId) return null;

  return (
    <>
      {props.renderToolbar?.({
        refresh,
        actionBtns: (
          <>
            {!isEditMode.value &&
              !state?.adjustmentInfo &&
              !!state?.auditAdjustmentInfo?.canChange && (
                <Button
                  icon={"plus"}
                  fillMode={"flat"}
                  title={"Create Adjustment"}
                  onClick={isEditMode.setTrue}
                />
              )}
          </>
        ),
      })}
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          height: !props.renderToolbar ? "100%" : undefined,
        }}
      >
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <LiveMap
            isActive={true}
            tcId={currentTcId}
            sideBarBtnText={`GPS Timeline`}
            resizeRefresher={mapResizeRefresher.value}
            refreshKey={refreshTCMapKey.value}
            isMapHidden={props.isMapHidden}
          />
          {!loading.value &&
            doShowAdjustmentsPanel &&
            !!state &&
            !!currentTcId && (
              <AdjustmentsPanel
                isMapHidden={!!props.isMapHidden}
                mapSizeRefresher={mapResizeRefresher}
              >
                <AdjustmentsPanelContent
                  state={state}
                  onAdjustmentsSave={onAdjustmentsSave}
                  tcId={currentTcId}
                  manage={manageAdjustment}
                />
              </AdjustmentsPanel>
            )}
        </div>
      </div>
    </>
  );
};

const AdjustmentsPanelContent = ({
  state,
  onAdjustmentsSave,
  tcId,
  manage,
}: {
  state: IAdjustedTimelinesData;
  onAdjustmentsSave(): void;
  manage(action: "Apply" | "Delete" | "Reject"): void;
  tcId: number;
}) => {
  const { adjustmentInfo, auditAdjustmentInfo, ...restState } = state;
  const CanChange = adjustmentInfo
    ? adjustmentInfo.canChange
    : auditAdjustmentInfo?.canChange;

  const renderAdjustmentAction = () => {
    if (!state) return;
    const { adjustmentInfo } = state;

    if (!adjustmentInfo) return;
    if (!adjustmentInfo.isActive) {
      return (
        <Button
          icon={"check"}
          title={"Apply Adjustments"}
          themeColor={"success"}
          fillMode={"flat"}
          onClick={() => manage("Apply")}
        ></Button>
      );
    }

    if (adjustmentInfo.canDelete) {
      return (
        <Button
          icon={"trash"}
          title={"Delete Adjustments"}
          fillMode={"flat"}
          themeColor={"error"}
          onClick={() => manage("Delete")}
        ></Button>
      );
    }
    return (
      <Button
        icon={"cancel"}
        title={"Reject Adjustments"}
        themeColor={"error"}
        fillMode={"flat"}
        onClick={() => manage("Reject")}
      ></Button>
    );
  };

  return (
    <div
      className={formStyles.FormWrapper}
      style={{
        position: "relative",
        padding: "12px 4px 0",
        boxSizing: "border-box",
      }}
    >
      {CanChange ? (
        <AdjustmentsEdit
          onSave={onAdjustmentsSave}
          tcId={tcId}
          adjustmentInfo={{
            lunchDuration:
              (adjustmentInfo
                ? adjustmentInfo.lunchDuration
                : auditAdjustmentInfo?.lunchDuration) || null,
            comment: adjustmentInfo?.comment || null,
          }}
          {...restState}
          action={
            <>
              {!!adjustmentInfo && <AdjustmentsInfoBtn info={adjustmentInfo} />}
              {renderAdjustmentAction()}
            </>
          }
        />
      ) : (
        <>
          <TotalDurations values={state.totals} />
          <div style={{ flex: 1, overflow: "auto" }}>
            <Adjustments data={state.timeline} />
          </div>
          {!!state.adjustmentInfo?.comment && (
            <div className={styles.TCAdjustmentComment}>
              {state.adjustmentInfo.comment}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 8,
              padding: 12,
              marginTop: "auto",
            }}
          >
            {!!adjustmentInfo && <AdjustmentsInfoBtn info={adjustmentInfo} />}
            {renderAdjustmentAction()}
          </div>
        </>
      )}
    </div>
  );
};

const AdjustmentsPanel = (props: {
  mapSizeRefresher: refresherAction;
  isMapHidden: boolean;
  children: any;
}) => {
  const [isAdjustmentsPanelOpened, setAdjustmentsPanelOpened] = useState(true);
  const onToggleSideBar = (state: boolean) => {
    setAdjustmentsPanelOpened(state);
  };

  useEffect(() => {
    if (!props.isMapHidden) props.mapSizeRefresher();
  }, [props.isMapHidden, isAdjustmentsPanelOpened]);

  return (
    <SideBar
      style={{
        width: isAdjustmentsPanelOpened ? 430 : 30,
        flex: "0 0 auto",
        height: "100%",
      }}
      defaultOpened={true}
      mode="push"
      onToggle={onToggleSideBar}
      content={props.children}
      btnText={"Adjustments"}
      rtl={true}
    ></SideBar>
  );
};

const AdjustmentsInfoBtn = (props: {
  info: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo;
}) => {
  const renderStatus = () => {
    const { canChange, isActive } = props.info;

    if (canChange) {
      return <span>Adjusted</span>;
    }
    if (isActive) {
      return <span style={{ color: "green" }}>Applied</span>;
    }
    return <span style={{ color: "red" }}>Rejected</span>;
  };

  const renderContent = useCallback(() => {
    const { authorName, lastChangeDate, lastChangeByName } = props.info;
    return (
      <>
        <div>Status: {renderStatus()}</div>
        <div>Author: {authorName}</div>
        <div>
          Last Change{" "}
          {!!lastChangeDate && moment(lastChangeDate).format("L LT")}
          by {lastChangeByName}
        </div>
        <div
          style={{
            marginTop: 8,
            textAlign: "center",
            opacity: 0.6,
            fontSize: "90%",
          }}
        >
          Click to Open History
        </div>
      </>
    );
  }, [props.info]);

  return (
    <Tooltip
      anchorElement="target"
      position="top"
      parentTitle={true}
      style={{ width: "190px" }}
      openDelay={400}
      showCallout={true}
      content={renderContent}
    >
      <Button
        iconClass="mdi mdi-information-outline"
        fillMode={"flat"}
        themeColor={"info"}
        onClick={() => {
          const { id } = props.info;
          if (id) CardManagement.OpenRecordHistoryCard(id);
        }}
        title={props.info.authorName || "Author"}
      ></Button>
    </Tooltip>
  );
};

export default TCMap;
