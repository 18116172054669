import { IDocumentItem, IHistoryItem } from "./interfaces";
import BaseComponent from "../../../Components/BaseComponent";
import Loader from "../../../Components/Common/Loader";
import ChecklistResultHistory from "./ChecklistHistory";
import { LoadHistory } from "./helpers";
import { showSomeError } from "../../../helpers/helpers";

interface props {
  dataItem: IDocumentItem;
}

interface state {
  loadingHistory: boolean;
  history: Array<IHistoryItem>;
}

class MobileCliHistory extends BaseComponent<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      loadingHistory: false,
      history: [],
    };
  }

  componentDidMount() {
    this.LoadHistory();
  }

  render() {
    if (this.state.loadingHistory) return <Loader />;
    return (
      <ChecklistResultHistory
        loadingHistory={this.state.loadingHistory}
        history={this.state.history}
      />
    );
  }

  LoadHistory = async () => {
    if (!this.props.dataItem.resultId) return;

    try {
      this.setState({ loadingHistory: true });
      let history = await LoadHistory(this.props.dataItem.resultId);
      this.setState({ history });
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ loadingHistory: false });
    }
  };
}

export default MobileCliHistory;
