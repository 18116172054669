import { useBooleanState } from "../../core/tools/Hooks";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";
import { eyeIcon, eyeSlashIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";

export const PasswordField = (props: {
  value: string;
  onChange: (event: InputChangeEvent) => void;
  onEnter: () => void;
  styles: React.CSSProperties;
}) => {
  const isHiddenMode = useBooleanState(true);
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") props.onEnter();
  };
  return (
    <div style={{ position: "relative", ...props.styles }}>
      <Input
        style={props.styles}
        type={isHiddenMode.value ? "password" : "text"}
        name="sPwd"
        id="sPwd"
        autoComplete="sPwd"
        label="Password"
        onKeyDown={onKeyDown}
        onChange={props.onChange}
      />
      <SvgIcon
        onClick={isHiddenMode.toggle}
        icon={isHiddenMode.value ? eyeSlashIcon : eyeIcon}
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          cursor: "pointer",
          padding: "5px 10px",
          opacity: 0.4,
        }}
      />
    </div>
  );
};
