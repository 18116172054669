import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getSQLData } from "../../helpers/queries";

import "./liveMap.scss";
import styles from "./livemap.module.scss";
import mapStyles from "../../Components/Map/map.module.scss";
import { Button, Chip, Toolbar } from "@progress/kendo-react-buttons";
import { IComboboxItem, pageId, simpleObject } from "../../helpers/interfaces";
import UserInfo from "../../stores/User";
import {
  IBuildPlanFilterItem,
  IClockInItem,
  IConnector,
  IEmployee,
  IEmployeeFilterItem,
  IListItemInfo,
  IMapClockInOut,
  IMapPeriodItem,
  IServerEmployee,
  ISiteToMap,
  ITimeCard,
  ITimeCardServer,
  stopType,
} from "./interfaces";
import Employee from "./Employee";
import FilterCombobox from "../../Components/Common/Form/FilterCombobox";
import { Loader } from "@progress/kendo-react-indicators";
import PinAllToggle from "./PinAllToggle";
import ClockedOnlyToggle from "./ClockedOnlyToggle";
import moment from "moment";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import MapContextMenu from "../../Components/Map/MapContextMenu";
import OtherNodesControl from "../../Components/Map/OtherNodesControl";
import MapSearchSitesControl from "../../Components/Map/SearchSitesControl";
import RelatedSitesControl from "../../Components/Map/RelatedSitesControl";
import {
  animateMarker,
  DEVICES_COLORS,
  getPeriodIdForFilter,
  MARK_POLYLINE_WEIGHT,
  POLYLINE_WEIGHT,
  siteAnimatedClass,
} from "./helpers";
import {
  formatTimeDiff,
  getParamsFromUrl,
  showSomeError,
} from "../../helpers/helpers";
import {
  BindNodeTooltip,
  fitBoundsGroup,
  getFigureObjectLayer,
  getObjectMapData,
  MARKERS_COLORS,
  OpenObject,
  zIndexMarkers,
} from "../../Components/Map/helpers";
import FilterMultiSelect from "../../Components/Common/Form/FilterMultiSelect";
import { TagData } from "@progress/kendo-react-dropdowns";
import TimeCard from "./TimeCard";
import SearchNodesLocation from "../../Components/Map/SearchNodesLocation";
import MapSearchAddressesControl from "../../Components/Map/SearchAddressesControl";

import FullSiteTooltips from "../../Components/Map/FullSiteTooltips";
import { ModalRef } from "../../Components/Common/Modal/Modal";
import config from "../../config";
import { useBooleanState, useRefresher } from "../../core/tools/Hooks";
import SideBar from "../../Components/Common/SideBar/SideBar";

interface IProps {
  tcId?: number;
  refreshKey?: number;
  bpId?: number;
  date?: Date;
  isActive: boolean;
  sideBarBtnText?: string;
  resizeRefresher?: number;
  isMapHidden?: boolean;
  pageId?: pageId;
}

const LIVE_MAP_CONTAINER_ID = "LiveMapContainer";

const LiveMap = (props: IProps) => {
  const { bpId, date: defaultDate, tcId, isActive } = props;
  const isTCMapRef = useRef(!!tcId);
  const tcIdRef = useRef<number>();
  // settings
  const initialBPORef = useRef<number | null>(null);
  const [userInfo, setUserInfo] = useState<{
    currentEmployeeId: number;
    showStateAllocation: boolean;
  } | null>(null);
  const refreshListItems = useRefresher();
  const remountFilters = useRefresher();
  const refresh = useRefresher();
  const isCalendar = props.pageId === "Calendar";
  const isLoading = useBooleanState(false);
  const isLoadingFilters = useBooleanState(false);
  const selectedIdRef = useRef<number | null>(null);
  const pinnedListRef = useRef<number[]>([]);
  let snapshotInitialDate = defaultDate || new Date();
  const dateFromURL = getParamsFromUrl().date;
  if (dateFromURL) {
    snapshotInitialDate = moment(dateFromURL).toDate();
  }
  const snapshotDateRef = useRef<Date>(snapshotInitialDate);
  const isCurrentTimeModeRef = useRef(
    props.tcId
      ? false
      : dateFromURL
      ? true
      : moment(snapshotDateRef.current).isSame(new Date(), "day")
  );
  const needFilters = !bpId || isCalendar;
  const isFiltersInitialized = useBooleanState(!needFilters);
  const listContainerIdRef = useRef("live-map-list-" + +new Date());
  const leftPanelRef = useRef<HTMLDivElement | null>(null);
  // filters
  const filtersValueRef = useRef<{
    OnlyClockedIn: boolean;
    CrewMembers: IComboboxItem[];
    BuildPlan?: IComboboxItem | null;
    BPO?: IComboboxItem | null;
  }>({
    OnlyClockedIn: false,
    CrewMembers: [],
  });
  const filtersDataRef = useRef<{
    buildPlans: IBuildPlanFilterItem[];
    crewMembers: IEmployeeFilterItem[];
  }>({
    buildPlans: [],
    crewMembers: [],
  });
  const [buildPlans, setBuildPlans] = useState<IComboboxItem[]>([]);
  const [bpOwners, setBPOwners] = useState<IComboboxItem[]>([]);
  const [crewMembers, setCrewMembers] = useState<IComboboxItem[]>([]);

  // render lists
  const [filteredEmployeesList, setFilteredEmployeesList] = useState<
    IEmployee[]
  >([]);
  const [filteredTimeCardsList, setFilteredTimeCardsList] = useState<
    ITimeCard[]
  >([]);
  const employeeslistRef = useRef<IEmployee[]>([]);
  const timeCardsListRef = useRef<ITimeCard[]>([]);

  // map
  const mapContainerIdRef = useRef(LIVE_MAP_CONTAINER_ID + +new Date());
  const mapRef = useRef<any>();
  const isShownRelatedSitesRef = useRef(true);
  const lastStopMarkersClusterRef = useRef<any>();
  const mapLayersRef = useRef<{ [key: number]: IListItemInfo }>({});

  // Init Map
  useLayoutEffect(() => {
    const container = window.L.DomUtil.get(mapContainerIdRef.current);
    if (container != null) {
      // container._leaflet_id = null;
      const oldContainer = window.document.getElementById(
        mapContainerIdRef.current
      );
      if (oldContainer && oldContainer.parentNode) {
        const newContainer = document.createElement("div");
        const newId = LIVE_MAP_CONTAINER_ID + (+new Date() + 10);
        newContainer.id = newId;
        newContainer.className = styles.MapContainer;
        oldContainer.parentNode.replaceChild(newContainer, oldContainer);
        mapContainerIdRef.current = newId;
      }
    }
    if (!!mapRef.current) return;
    window.L.mapquest.key = config.MAP_QUEST_KEY;
    mapRef.current = window.L.mapquest.map(mapContainerIdRef.current, {
      center: [37.2566, -104.6759],
      layers: window.L.mapquest.tileLayer("hybrid"),
      zoom: 100,
      zoomOnDoubleClick: true,
      bestFitMargin: 200,
    });
    mapRef.current.addControl(
      window.L.mapquest.satelliteControl({
        position: "topleft",
        mapType: "map",
      })
    );
    mapRef.current.addControl(new window.L.Control.SearchControl());
    mapRef.current.addControl(window.L.Control.measureControl());

    lastStopMarkersClusterRef.current = window.L.markerClusterGroup();
    lastStopMarkersClusterRef.current.on(
      "clustermouseover",
      onClusterMarkerMouseOver
    );
    mapRef.current.addLayer(lastStopMarkersClusterRef.current);

    return () => {
      if (mapRef.current) {
        mapRef.current.off();
        // mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!props.resizeRefresher || !mapRef.current) return;
    onMapContainerResize();
  }, [props.resizeRefresher]);

  useEffect(() => {
    UserInfo.getInfo()
      .then((userInfo) => {
        if (userInfo) {
          const EmployeeId = userInfo.EmployeeId;
          initialBPORef.current = EmployeeId;
          setUserInfo({
            currentEmployeeId: EmployeeId,
            showStateAllocation: userInfo.ShowStateAllocation,
          });
        }
      })
      .catch(showSomeError);
  }, []);
  useEffect(() => {
    if (!props.isActive) return;
    snapshotDateRef.current = defaultDate || new Date();
    isCurrentTimeModeRef.current = props.tcId
      ? false
      : moment(snapshotDateRef.current).isSame(new Date(), "day");
  }, [defaultDate, props.isActive]);

  // Load Filters Data
  useEffect(() => {
    if (
      isTCMapRef.current ||
      !isCurrentTimeModeRef.current ||
      !needFilters ||
      !userInfo?.currentEmployeeId
    )
      return;

    isLoadingFilters.setTrue();
    getSQLData({
      spName: "TK_LiveMap_FiltersData",
      params: getParamsFromUrl().date
        ? { currentDate: getParamsFromUrl().date }
        : undefined,
    })
      .then((data) => {
        const buildPlans = data[0] as IBuildPlanFilterItem[];
        const crewMembers = data[1] as IEmployeeFilterItem[];
        filtersDataRef.current.buildPlans = buildPlans;
        filtersDataRef.current.crewMembers = crewMembers;
        const buildPlansList = buildPlans.map((bp) => ({
          Id: bp.BuildPlanId,
          Name: bp.BuildPlanName,
        }));
        const bpOwnersList: IComboboxItem[] = [];
        const uniqueBPOs: { [key: number]: true } = {};
        for (let bp of buildPlans) {
          if (!uniqueBPOs[bp.BPOwnerId]) {
            uniqueBPOs[bp.BPOwnerId] = true;
            const item = { Id: bp.BPOwnerId, Name: bp.BPOwnerName };
            bpOwnersList.push(item);
            if (
              initialBPORef.current &&
              bp.BPOwnerId === initialBPORef.current
            ) {
              filtersValueRef.current.BPO = item;
            }
          }
        }
        initialBPORef.current = null;
        setBuildPlans(buildPlansList);
        setBPOwners(bpOwnersList);
        isFiltersInitialized.setTrue();
      })
      .catch(showSomeError)
      .finally(() => {
        isLoadingFilters.setFalse();
      });
    return () => {};
  }, [userInfo]);

  // load List Data
  useEffect(() => {
    if (!props.isActive) return;
    if(isTCMapRef.current) {
      if (isActive && tcId && tcId !== tcIdRef.current) {
        tcIdRef.current = tcId;
      } else return;
    }

    if (isTCMapRef.current) {
      clearMap();
      if (tcId) {
        mapLayersRef.current[tcId] = {
          ClockIns: [],
          StopLayers: [],
          StopTrackLayers: [],
          DriveLayers: [],
          ClockInOutLayers: [],
          ConnectorsLayers: [],
          TrackLayersGroup: window.L.featureGroup(),
          SitesGroup: window.L.featureGroup(),
          SitesLayers: [],
        };
      }
      return;
    }
    if (!userInfo?.currentEmployeeId || !isFiltersInitialized.value) return;
    isLoading.setTrue();
    clearMap();
    setFilteredTimeCardsList([]);
    setFilteredEmployeesList([]);
    employeeslistRef.current = [];
    timeCardsListRef.current = [];

    if (isCurrentTimeModeRef.current) {
      loadCurrentEmployees();
    } else {
      loadTimeCardsForDate();
    }
    return () => {};
  }, [
    isFiltersInitialized.value,
    userInfo,
    bpId,
    refresh.value,
    tcId,
    props.refreshKey,
    defaultDate,
    props.isActive,
  ]);

  const loadCurrentEmployees = () => {
    const params: simpleObject = { bpId };
    if (getParamsFromUrl().date) {
      params.currentTime = getParamsFromUrl().date;
    }
    getSQLData({
      spName: "TK_LiveMap_ActiveWorkShifts",
      params,
    })
      .then((data) => {
        const serverList: IServerEmployee[] = data[0];
        const clockIns: IClockInItem[] = data[1];
        const employeesList: IEmployee[] = [];
        const uniqueEmployees: { [key: number]: true } = {};
        const crewMembers: IComboboxItem[] = [];
        const defaultIsPinned = !!bpId; // ??
        for (let i = 0; i < serverList.length; i++) {
          const item = serverList[i];
          const ListId = item.EmployeeId;
          const lastLogsMoment = moment(item.LastPointLocalTime);
          const employeeClockIns = clockIns
            .filter((clockIn) => clockIn.EmployeeId === item.EmployeeId)
            .map(({ ClockInId }) => ClockInId);
          employeesList.push({
            ...item,
            ListId,
            Duration: formatTimeDiff(item.DurationMinutes * 60),
            IsOldGPS:
              item.DurationMinutes > 30 &&
              item.IsWorkingNow &&
              moment().diff(lastLogsMoment, "minutes") > 30,
            LastLogsTime: lastLogsMoment.format("LT"),
            ClockIns: employeeClockIns,
          });

          const isPinned = getPinnedIndex(ListId) > -1;
          if (defaultIsPinned && !isPinned) pinnedListRef.current.push(ListId);
          mapLayersRef.current[ListId] = {
            ClockIns: employeeClockIns,
            StopLayers: [],
            StopTrackLayers: [],
            DriveLayers: [],
            ClockInOutLayers: [],
            ConnectorsLayers: [],
            TrackLayersGroup: window.L.featureGroup(),
            SitesGroup: window.L.featureGroup(),
            SitesLayers: [],
          };
          if (needFilters) {
            if (!uniqueEmployees[item.EmployeeId]) {
              uniqueEmployees[item.EmployeeId] = true;
              crewMembers.push({
                Id: item.EmployeeId,
                Name: item.EmployeeName,
              });
            }
          }
        }

        if (needFilters) setCrewMembers(crewMembers);
        employeeslistRef.current = employeesList;
        const filteredList = getFilteredEmployeesList(crewMembers);
        drawLastStops(filteredList);
        setFilteredEmployeesList(filteredList);
        fitBounds(filteredList);
      })
      .catch(showSomeError)
      .finally(() => {
        isLoading.setFalse();
      });
  };

  const loadTimeCardsForDate = () => {
    getSQLData({
      spName: "TK_GetDevicesForDate",
      params: { bpId, date: moment(snapshotDateRef.current).format("L") },
    })
      .then((data) => {
        if (needFilters) {
          filtersDataRef.current.buildPlans = data[1] as IBuildPlanFilterItem[];
          filtersDataRef.current.crewMembers = data[2] as IEmployeeFilterItem[];
          const buildPlans = filtersDataRef.current.buildPlans.map(
            ({ BuildPlanId: Id, BuildPlanName: Name }) => ({
              Id,
              Name,
            })
          );
          const bpOwners: IComboboxItem[] = [];
          const uniqueBPOs: { [key: number]: true } = {};
          for (let bp of filtersDataRef.current.buildPlans) {
            if (!uniqueBPOs[bp.BPOwnerId]) {
              uniqueBPOs[bp.BPOwnerId] = true;
              const item = {
                Id: bp.BPOwnerId,
                Name: bp.BPOwnerName,
              };
              bpOwners.push(item);
              if (
                initialBPORef.current &&
                bp.BPOwnerId === initialBPORef.current
              ) {
                filtersValueRef.current.BPO = item;
              }
            }
          }
          initialBPORef.current = null;
          setBuildPlans(buildPlans);
          setBPOwners(bpOwners);
        }

        const defaultIsPinned = !!bpId || props.tcId; // ??
        let timeCards = data[0] as ITimeCardServer[];
        if (props.tcId) {
          const tc = timeCards.find((item) => item.TimeCardId === props.tcId);
          timeCards = [tc!];
        }
        const crewMembers: IComboboxItem[] = [];
        const uniqueEmployees: { [key: number]: true } = {};
        const timeCardsList: ITimeCard[] = [];
        for (let timecard of timeCards) {
          const ListId = timecard.TimeCardId;
          timeCardsList.push({
            ...timecard,
            ListId,
            Duration: formatTimeDiff(timecard.ActualDurationHours * 3600),
          });

          const isPinned = getPinnedIndex(ListId) > -1;
          if (defaultIsPinned && !isPinned) pinnedListRef.current.push(ListId);
          mapLayersRef.current[ListId] = {
            ClockIns: [],
            StopLayers: [],
            StopTrackLayers: [],
            DriveLayers: [],
            ClockInOutLayers: [],
            ConnectorsLayers: [],
            TrackLayersGroup: window.L.featureGroup(),
            SitesGroup: window.L.featureGroup(),
            SitesLayers: [],
          };

          if (needFilters && !uniqueEmployees[timecard.EmployeeId]) {
            uniqueEmployees[timecard.EmployeeId] = true;
            const item: IComboboxItem = {
              Id: timecard.EmployeeId,
              Name: timecard.EmployeeName,
            };
            crewMembers.push(item);
            /*if (this.initialCrewMemberId && this.initialCrewMemberId === item.Id) {
                                                                                    this.CrewMember = item
                                                                                }*/
          }
        }
        // this.initialCrewMemberId = null
        if (needFilters) setCrewMembers(crewMembers);
        /* const filteredTimeCards = this.GetFilteredTimecards()
                                                     const idsNeedDetails: number[] = []
                                                     for (let timeCard of timeCards) {
                                                         // todo hide not shown tcs ??
                                                         const id = timeCard.EmployeeId
                                                         const IsPinned = mapLayersRef.current[id]
                                                         const filteredTC = filteredTimeCards.find((tc) => tc.TimeCardId === id)
                                                         if (filteredTC && IsPinned || selectedIdRef.current === id) idsNeedDetails.push(id)
                                                     }
                                        
                                                   /*if (idsNeedDetails.length) {
                                                         this.SetOnTCLoadDrawTrack(idsNeedDetails, filteredTimeCards)
                                                     } else {
                                                         this.fitBoundsVisibleLayers(filteredTimeCards.map(({TimeCardId}) => TimeCardId))
                                                     }*/

        timeCardsListRef.current = timeCardsList;
        const filteredList = getFilteredTimeCardsList(crewMembers);
        setFilteredTimeCardsList(filteredList);
        fitBounds(filteredList);
      })
      .catch(showSomeError)
      .finally(() => {
        isLoading.setFalse();
      });
  };

  const onItemLoadDrawTrack =
    useRef<(listId: number, clockInId: number) => void | undefined>();

  const SetOnDeviceLoadDrawTrack = (
    idsNeedDetails: (string | number)[],
    filteredList: IEmployee[] | ITimeCard[]
  ) => {
    const fitBoundsAll = fitBounds;
    const self = this;
    const func = function () {
      return function (listId: number, clockInId: number) {
        const id = `${listId}_${clockInId}`;
        const index = idsNeedDetails.findIndex((item) => item === id);
        idsNeedDetails.splice(index, 1);
        if (idsNeedDetails.length === 0) {
          fitBoundsAll.call(self, filteredList);
          onItemLoadDrawTrack.current = undefined;
        }
      };
    };
    onItemLoadDrawTrack.current = func();
  };

  const onClusterMarkerMouseOver = (a: any) => {
    const coords = a.layer.getLatLng();
    let employeesHTML: string = "";
    for (let layer of a.layer.getAllChildMarkers()) {
      employeesHTML += "<div>" + layer.options.employee + "</div>";
    }

    window.L.popup({
      closeButton: false,
      className: "leaflet-scroll-popup",
    })
      .setLatLng([coords.lat, coords.lng])
      .setContent(
        '<div class="leaflet-scroll-popup-content">' + employeesHTML + "</div>"
      )
      .openOn(mapRef.current);
  };

  const refreshLiveMap = () => {
    if (isCurrentTimeModeRef.current && getParamsFromUrl().date) {
      snapshotDateRef.current = new Date();
    }
    refresh();
  };

  const getFilteredCrewMembers = (
    crewMembersList: IComboboxItem[],
    selectedCrewMembers: IComboboxItem[],
    bpo?: IComboboxItem | null,
    bp?: IComboboxItem | null
  ) => {
    let filteredCrewMembers = crewMembersList;
    if (selectedCrewMembers.length) {
      filteredCrewMembers = filteredCrewMembers.filter(
        (fcm) => selectedCrewMembers.findIndex((cm) => cm.Id === fcm.Id) > -1
      );
    }

    if (bp?.Id) {
      filteredCrewMembers = filteredCrewMembers.filter(
        (fcm) =>
          filtersDataRef.current.crewMembers.findIndex(
            (cm) => cm.EmployeeId === fcm.Id && cm.BuildPlanId === bp.Id
          ) > -1
      );
    }

    if (bpo?.Id) {
      const filteredBuildPlans = filtersDataRef.current.buildPlans.filter(
        (bp) => bp.BPOwnerId === bpo.Id
      );
      const crewMembers = filtersDataRef.current.crewMembers.filter(
        (cm) =>
          filteredBuildPlans.findIndex(
            (bp) => bp.BuildPlanId === cm.BuildPlanId
          ) > -1
      );

      filteredCrewMembers = filteredCrewMembers.filter((fcm) => {
        return crewMembers.findIndex((cm) => cm.EmployeeId === fcm.Id) > -1;
      });
    }
    return filteredCrewMembers;
  };

  const getFilteredEmployeesList = (crewMembersList: IComboboxItem[]) => {
    const selectedCrewMembers = filtersValueRef.current["CrewMembers"];
    const bpo = filtersValueRef.current["BPO"];
    const bp = filtersValueRef.current["BuildPlan"];
    if (
      !filtersValueRef.current.OnlyClockedIn &&
      !selectedCrewMembers?.length &&
      !bpo &&
      !bp
    )
      return employeeslistRef.current;
    const filteredCrewMembers = getFilteredCrewMembers(
      crewMembersList,
      selectedCrewMembers,
      bpo,
      bp
    );

    return employeeslistRef.current.filter(
      (e) =>
        (!filtersValueRef.current.OnlyClockedIn || e.IsWorkingNow) &&
        filteredCrewMembers.findIndex((fcm) => fcm.Id === e.EmployeeId) > -1
    );
  };

  const getFilteredTimeCardsList = (crewMembersList: IComboboxItem[]) => {
    const selectedCrewMembers = filtersValueRef.current["CrewMembers"];
    const bpo = filtersValueRef.current["BPO"];
    const bp = filtersValueRef.current["BuildPlan"];
    if (!selectedCrewMembers?.length && !bpo && !bp)
      return timeCardsListRef.current;
    const filteredCrewMembers = getFilteredCrewMembers(
      crewMembersList,
      selectedCrewMembers,
      bpo,
      bp
    );

    return timeCardsListRef.current.filter(
      (cm) =>
        filteredCrewMembers.findIndex((fcm) => fcm.Id === cm.EmployeeId) > -1
    );
  };

  const filterList = () => {
    if (isCurrentTimeModeRef.current) {
      const list = getFilteredEmployeesList(crewMembers);
      redrawListAfterFilter(list);
      setFilteredEmployeesList(list);
    } else {
      const filteredTimeCards = getFilteredTimeCardsList(crewMembers);
      redrawListAfterFilter(filteredTimeCards);
      setFilteredTimeCardsList(filteredTimeCards);
    }
  };

  const onChangeFilter = (
    value: IComboboxItem | null,
    filterName: "BuildPlan" | "BPO"
  ) => {
    filtersValueRef.current[filterName] = value;
    filterList();
  };

  const onChangeCrewMembersFilter = (value: IComboboxItem[]) => {
    filtersValueRef.current.CrewMembers = value;
    filterList();
  };

  const onChangeOnlyClockedIn = (value: boolean) => {
    filtersValueRef.current.OnlyClockedIn = value;
    filterList();
  };

  const onPinAllEvent = (value: boolean) => {
    const idsNeedDetails: (string | number)[] = [];
    const filteredList = isCurrentTimeModeRef.current
      ? filteredEmployeesList
      : filteredTimeCardsList;

    for (let listItem of filteredList) {
      const listId = listItem.ListId;
      const isPinned = getPinnedIndex(listId) > -1;
      if (value && isPinned !== value) {
        pinnedListRef.current.push(listId);
        if (isCurrentTimeModeRef.current) {
          // @ts-ignore
          for (let clockInId of listItem.ClockIns) {
            idsNeedDetails.push(`${listId}_${clockInId}`);
          }
        } else {
          idsNeedDetails.push(listId);
        }
      }
    }

    if (idsNeedDetails.length) {
      SetOnDeviceLoadDrawTrack(idsNeedDetails, filteredList);
    } else {
      fitBounds(filteredList);
    }
    if (!value) pinnedListRef.current = [];
    selectedIdRef.current = null;
    refreshListItems();
  };

  const onChangeDate = (e: DatePickerChangeEvent) => {
    const date = e.value;
    if (date === null) return; // ts
    isCurrentTimeModeRef.current = moment(date).isSame(new Date(), "day");
    if (isCurrentTimeModeRef.current && getParamsFromUrl().date) {
      snapshotDateRef.current = moment(getParamsFromUrl().date).toDate();
    } else {
      snapshotDateRef.current = date;
    }

    filtersValueRef.current.CrewMembers = [];
    filtersValueRef.current.BPO = null;
    filtersValueRef.current.BuildPlan = null;
    remountFilters();
    pinnedListRef.current = [];
    setSelectedId(null);
    refresh();
  };

  const redrawListAfterFilter = (filteredList: IEmployee[] | ITimeCard[]) => {
    const layersForAdd: any[] = [];
    const originalList = isCurrentTimeModeRef.current
      ? employeeslistRef.current
      : timeCardsListRef.current;
    for (let listItem of originalList) {
      const { ListId } = listItem;
      const { LastStopMarker } = mapLayersRef.current[ListId];
      const filteredItemIndex = filteredList.findIndex(
        (d: IEmployee | ITimeCard) => d.ListId === ListId
      );
      const pinnedIndex = getPinnedIndex(ListId);
      const isPinned = pinnedIndex > -1;
      const isSelected = selectedIdRef.current === ListId;
      if (filteredItemIndex === -1) {
        const doSwitchOff = isSelected || isPinned;
        if (isSelected) selectedIdRef.current = null;
        if (isPinned) pinnedListRef.current.splice(pinnedIndex, 1);
        if (doSwitchOff) switchListItem(ListId);
      } else {
        if (LastStopMarker) layersForAdd.push(LastStopMarker);
        switchListItem(ListId);
      }
    }
    if (isCurrentTimeModeRef.current) refreshLastStops(layersForAdd);
    fitBounds(filteredList);
  };

  const onToggleRelatedSites = (value: boolean) => {
    isShownRelatedSitesRef.current = value;
    for (let id in mapLayersRef.current) {
      const isPinned = getPinnedIndex(+id) > -1;
      const isSelected = selectedIdRef.current === +id;
      if (!isPinned && !isSelected) continue;

      const { SitesLayers, SitesGroup } = mapLayersRef.current[id];
      SitesGroup.removeFrom(mapRef.current);
      const sitesToAdd: any[] = isShownRelatedSitesRef.current
        ? SitesLayers
        : [];
      if (!isShownRelatedSitesRef.current) {
        for (let layer of SitesLayers) {
          if (layer.options.isMain) sitesToAdd.push(layer);
        }
      }

      mapLayersRef.current[id].SitesGroup = window.L.featureGroup(sitesToAdd);
      mapLayersRef.current[id].SitesGroup.addTo(mapRef.current);
    }
  };

  const clearMap = () => {
    const clear = (listId: number) => {
      const { TrackLayersGroup, SitesGroup } =
        mapLayersRef.current[listId] || {};
      if (TrackLayersGroup) TrackLayersGroup.removeFrom(mapRef.current);
      if (SitesGroup) SitesGroup.removeFrom(mapRef.current);
    };
    for (let id in mapLayersRef.current) clear(+id);
    refreshLastStops([]);
  };

  const onMapContainerResize = () => {
    if (mapRef.current?.invalidateSize) mapRef.current.invalidateSize();
  };

  const fitBounds = (
    filteredList:
      | IEmployee[]
      | ITimeCard[]
      | {
          ListId: number;
        }[]
  ) => {
    const allGroup = window.L.featureGroup();
    for (let { ListId: Id } of filteredList) {
      const { LastStopMarker, TrackLayersGroup, SitesGroup } =
        mapLayersRef.current[Id];
      const isPinned = isTCMapRef.current || getPinnedIndex(Id) > -1;
      if (
        LastStopMarker &&
        lastStopMarkersClusterRef.current.hasLayer(LastStopMarker)
      )
        allGroup.addLayer(LastStopMarker);
      if (isPinned || selectedIdRef.current === Id) {
        if (TrackLayersGroup) allGroup.addLayer(TrackLayersGroup);
        if (SitesGroup) allGroup.addLayer(SitesGroup);
      }
    }
    fitBoundsGroup(allGroup, mapRef.current);
  };

  const onFitBounds = () => {
    fitBounds(
      isTCMapRef.current && tcId
        ? [{ ListId: tcId }]
        : isCurrentTimeModeRef.current
        ? filteredEmployeesList
        : filteredTimeCardsList
    );
  };

  const fitBoundsListItem = (listId: number) => {
    if (!mapRef.current) return;
    const group = window.L.featureGroup();
    const info = mapLayersRef.current[listId];
    const { TrackLayersGroup, SitesGroup, LastStopMarker } = info;
    if (LastStopMarker) {
      group.addLayer(LastStopMarker);
    }
    group.addLayer(TrackLayersGroup);
    group.addLayer(SitesGroup);
    fitBoundsGroup(group, mapRef.current);
  };

  const refreshLastStops = (markers: any[]) => {
    lastStopMarkersClusterRef.current.clearLayers();
    lastStopMarkersClusterRef.current.addLayers(markers);
  };

  const drawLastStops = (filteredEmployees: IEmployee[]) => {
    const lastStopsForDraw: any[] = [];
    for (let item of filteredEmployees) {
      if (item.IsWorkingNow) {
        const { LastPointLat, LastPointLng, ListId } = item;
        if (!LastPointLat || !LastPointLng) continue;
        const marker = getLastStopMarker(item);
        mapLayersRef.current[ListId].LastStopMarker = marker;
        lastStopsForDraw.push(marker);
      }
    }
    refreshLastStops(lastStopsForDraw);
  };

  const getLastStopMarker = (employee: IEmployee) => {
    const html = `<div class="last-point-icon" data-listid="${employee.ListId}">
                             <div class="last-point-icon__content">
                                <div class="last-point-icon__employees">${employee.EmployeeName}</div>
                             </div>
                           </div>`;

    const iconWidth = 100;
    const iconHeight = 30;
    const coords = [employee.LastPointLat, employee.LastPointLng];
    return window.L.marker(coords, {
      icon: window.L.divIcon({
        className: "-icon-box",
        iconSize: [iconWidth, iconHeight],
        iconAnchor: [iconWidth / 2, iconHeight],
        html,
      }),
      riseOnHover: true,
      zIndexOffset: zIndexMarkers.initLastStopMarker,
      riseOffset: zIndexMarkers.markedLastStopMarker,
      listId: employee.ListId,
      employee: employee.EmployeeName,
    });
    // .on("click", this.onClickLastStop)
    /*    .on("mouseover", this.onMouseOverLastStop);*/
  };

  const showHideLastStops = (doFitbounds: boolean) => {
    // after select/unselect and pin/upin
    if (!isCurrentTimeModeRef.current) return {};
    const layersForAdd: any[] = [];
    const pinnedLength = pinnedListRef.current.length;
    let priorytyListId: number | null = null;
    if (selectedIdRef.current) priorytyListId = selectedIdRef.current;
    else if (pinnedLength === 1) priorytyListId = pinnedListRef.current[0];

    for (let { ListId } of filteredEmployeesList) {
      if (!priorytyListId || ListId === priorytyListId) {
        const { LastStopMarker } = mapLayersRef.current[ListId];
        if (LastStopMarker) layersForAdd.push(LastStopMarker);
      }
    }
    refreshLastStops(layersForAdd);

    if (doFitbounds) {
      if (priorytyListId) {
        fitBoundsListItem(priorytyListId);
      } else if (!selectedIdRef.current && !pinnedLength) {
        fitBounds(filteredEmployeesList);
      }
    }
  };

  const switchListItem = (listId: number) => {
    const isPinned = getPinnedIndex(listId) > -1;
    const isSelected = selectedIdRef.current === listId;
    const state = isPinned || isSelected;
    const {
      TrackLayersGroup,
      SitesGroup,
      StopTrackLayers,
      DriveLayers,
      StopLayers,
      ConnectorsLayers,
    } = mapLayersRef.current[listId];
    if (state) {
      const pinnedColor = getPinnedColor(listId);
      const setPolylineStyle = (polyline: any) => {
        if (!polyline.setStyle) return;
        const color =
          pinnedColor ||
          (polyline.options.isConnector
            ? polyline.options.color
            : polyline.options.costTypeColor);
        polyline.setStyle({ color });
      };
      StopTrackLayers.forEach(setPolylineStyle);
      DriveLayers.forEach(setPolylineStyle);
      ConnectorsLayers.forEach(setPolylineStyle);
      for (let stopTextMarker of StopLayers) {
        stopTextMarker.setIcon({
          type: "circle",
          icon: {
            type: "circle",
            primaryColor: pinnedColor || MARKERS_COLORS.BLACK,
            secondaryColor: stopTextMarker.options.costTypeColor,
            size: "sm",
            symbol: stopTextMarker.options.icon.symbol,
            class: siteAnimatedClass,
          },
        });
      }

      TrackLayersGroup.addTo(mapRef.current);
      SitesGroup.addTo(mapRef.current);
    } else {
      TrackLayersGroup.removeFrom(mapRef.current);
      SitesGroup.removeFrom(mapRef.current);
    }
    const doFitbounds = state;
    if (isCurrentTimeModeRef.current) showHideLastStops(!doFitbounds);
    if (doFitbounds) fitBoundsListItem(listId);
  };

  const setSelectedId = (listId: number | null) => {
    if (selectedIdRef.current === listId) return;
    selectedIdRef.current = listId;
    refreshListItems();
  };

  const onTogglePin = (listId: number) => {
    const pinnedIndex = getPinnedIndex(listId);
    const isPinned = pinnedIndex > -1;

    if (!isPinned) pinnedListRef.current.push(listId);
    else {
      pinnedListRef.current.splice(pinnedIndex, 1);
      if (selectedIdRef.current === listId) {
        selectedIdRef.current = null;
      }
    }
    refreshListItems();
  };

  const unSelectItem = (listId: number) => {
    if (selectedIdRef.current === listId) setSelectedId(null);
  };

  const selectItem = (listId: number) => {
    setSelectedId(listId);
    const containerEl = document.getElementById(listContainerIdRef.current);
    if (!containerEl) return;
    const listItemEl = containerEl.querySelector("#item-" + listId);
    if (!listItemEl) return;
    const toolbar = leftPanelRef.current?.querySelector(".k-toolbar");
    // @ts-ignore
    const toolbarHeight = toolbar?.offsetHeight || 0;
    // @ts-ignore
    containerEl.scrollTop = listItemEl.offsetTop - toolbarHeight;
  };

  const periodTrackMouseEvent = (e: any) => {
    const weight =
      e.type === "mouseover" ? MARK_POLYLINE_WEIGHT : POLYLINE_WEIGHT;
    e.target.setStyle({ weight: weight });
  };

  const stopMarkerMouseEvent = (e: any) => {
    const isClick = e.type === "click";
    const { clockInId, listId, periodId, mainObjectId } = e.target.options;
    const { TrackLayersGroup, StopTrackLayers, StopLayers, SitesLayers } =
      mapLayersRef.current[listId];

    if (isClick) {
      const group = window.L.featureGroup();
      for (let layer of StopTrackLayers) {
        const options = layer.options;
        if (
          +options.periodId === +periodId &&
          +options.listId === +listId &&
          options.clockInId === clockInId
        ) {
          group.addLayer(layer);
          break;
        }
      }
      for (let layer of StopLayers) {
        const options = layer.options;
        if (
          +options.periodId === +periodId &&
          +options.listId === +listId &&
          options.clockInId === clockInId
        ) {
          group.addLayer(layer);
          break;
        }
      }

      fitboundsLayer(group, 17);
      return;
    }

    const isMouseOver = e.type === "mouseover";

    for (let layer of StopTrackLayers) {
      const options = layer.options;

      if (
        +options.periodId === +periodId &&
        +options.listId === +listId &&
        options.clockInId === clockInId
      ) {
        if (isMouseOver) layer.addTo(TrackLayersGroup);
        else layer.removeFrom(TrackLayersGroup);
        break; // if polyline. If dots delete this
      }
    }

    if (mainObjectId) {
      const mainObjectLayer = SitesLayers.find(
        (layer) => +layer.options.objectId === +mainObjectId
      );
      if (mainObjectLayer) {
        const { isScheduledSite, isUnscheduledSite, isMain } =
          mainObjectLayer.options;
        let zIndex = 0;
        if (isScheduledSite || isUnscheduledSite) {
          if (isMain)
            zIndex = isMouseOver
              ? zIndexMarkers.markedSiteMarker
              : zIndexMarkers.initSiteMarker;
          else
            zIndex = isMouseOver
              ? zIndexMarkers.markedRelatedSiteMarker
              : zIndexMarkers.initRelatedSiteMarker;
        } else {
          zIndex = isMouseOver
            ? zIndexMarkers.markedNearestSiteMarker
            : zIndexMarkers.initNearestSiteMarker;
        }

        animateMarker(mainObjectLayer, isMouseOver, zIndex);
      }
    }
  };

  const drawClockInOutMarkers = (
    clockinOut: IMapClockInOut,
    clockInId: number,
    listId: number,
    markersArray: any[],
    trackLayersGroup: any
  ) => {
    const { Coords, Type, Time, PeriodId } = clockinOut;
    if (!Coords) return;
    let text = "IN";
    let className = "start";
    let iconWidth = 50;
    const iconHeight = 49;
    let iconAnhorX = iconWidth;
    if (Type) {
      className = "end";
      text = ~Type.indexOf("FORCE")
        ? "FOUT"
        : ~Type.indexOf("REMOTE")
        ? "ROUT"
        : "OUT";
      iconWidth = 68;
      iconAnhorX = 0;
    }
    const marker = window.L.marker(Coords, {
      icon: window.L.divIcon({
        className: "my-flag-icon",
        iconSize: [iconWidth, iconHeight],
        iconAnchor: [iconAnhorX, iconHeight],
        html: '<div class="flag-icon ' + className + '">' + text + "</div>",
      }),
      riseOnHover: true,
      refName: "TKTimeEntries",
      listId,
      clockInId,
      periodId: PeriodId,
      isClockTE: true,
      zIndexOffset: zIndexMarkers.initClockMarker,
      riseOffset: zIndexMarkers.markedClockMarker,
    }).bindTooltip(Time);
    marker.addTo(trackLayersGroup);
    markersArray.push(marker);
  };

  const onSaveObject = async (
    objectId: number,
    listId: number,
    clockInId: number
  ) => {
    try {
      ModalRef.startProcessing("", "rgba(255, 255, 255, 0.2)");
      const result = await getObjectMapData(objectId);
      if (!result) return;
      const {
        Lat,
        Lng,
        ObjectId,
        ObjectName,
        ObjectType,
        AddressString,
        Boundaries,
        Radius,
      } = result.mainAddress;
      if (!Lat || !Lng) return;

      const coords = [Lat, Lng];
      const { SitesGroup, SitesLayers } = mapLayersRef.current[listId];
      let MarkerLayer: any;
      let FigureLayer: any;
      for (let i = 0; i < SitesLayers.length; i++) {
        const layer = SitesLayers[i];
        const options = layer.options;
        if (
          +options.objectId === objectId &&
          +options.listId === +listId &&
          +options.clockInId === +clockInId
        ) {
          if (options.icon !== undefined) {
            MarkerLayer = layer;
            MarkerLayer.setLatLng(coords);
            MarkerLayer.unbindTooltip();
            BindNodeTooltip(MarkerLayer, ObjectName, AddressString);
          } else {
            SitesGroup.removeLayer(layer);
            const { dispatchIds, fillColor, isMain } = options;
            FigureLayer = getFigureObjectLayer(
              ObjectId,
              ObjectType,
              fillColor,
              "black",
              coords,
              Boundaries,
              Radius,
              {
                listId,
                clockInId,
                isMain,
                dispatchIds,
              }
            );
            SitesLayers.splice(i, 1, FigureLayer);
            SitesGroup.addLayer(FigureLayer);
          }
          if (MarkerLayer && FigureLayer) break;
        }
      }

      fitBoundsGroup(
        new window.L.FeatureGroup([MarkerLayer, FigureLayer]),
        mapRef.current
      );
    } catch (e) {
      showSomeError(e);
    } finally {
      ModalRef.stopProcessing();
    }
  };

  const OpenNodeCard = async (e: any) => {
    let { refName, objectId, listId, clockInId } = e.target.options;
    if (!objectId || !refName) return;
    OpenObject(e, (objectId: number) =>
      onSaveObject(objectId, +listId, +clockInId)
    );
  };

  const drawTrackSites = (
    trackSites: ISiteToMap[],
    listId: number,
    clockInId: number
  ) => {
    const { SitesGroup, SitesLayers } = mapLayersRef.current[listId];
    for (let object of trackSites) {
      const {
        ObjectId,
        ObjectType,
        ObjectName,
        LocationColor,
        LocationAbbr,
        Boundaries,
        Radius,
        Address,
        ScheduledInfo,
        PeriodsInfo,
        Lat,
        Lng,
      } = object;
      const {
        IsRelatedScheduledSite,
        IsUnscheduledSite,
        IsScheduledSite,
        DispatchIds,
      } = ScheduledInfo;
      const { IsMainStopObject } = PeriodsInfo;
      let primaryColor = "";
      let secondaryColor = "";
      let zIndexMarker = 0;
      const isMainSite = !IsRelatedScheduledSite;
      const isLocation = ObjectType === "Location";
      if (IsScheduledSite || IsUnscheduledSite) {
        zIndexMarker = isMainSite
          ? zIndexMarkers.initSiteMarker
          : zIndexMarkers.initRelatedSiteMarker;
        primaryColor = isMainSite ? MARKERS_COLORS.PINK : MARKERS_COLORS.WHITE;
        secondaryColor = MARKERS_COLORS.PINK;
      } else {
        zIndexMarker = zIndexMarkers.initNearestSiteMarker;
        primaryColor = isLocation
          ? LocationColor || MARKERS_COLORS.AQUA
          : MARKERS_COLORS.GRAY;
        secondaryColor = IsMainStopObject ? MARKERS_COLORS.WHITE : "#c1c1c1";
      }
      const coords = [Lat, Lng];
      const MarkerLayer = window.L.mapquest
        .textMarker(coords, {
          type: "marker",
          icon: {
            primaryColor,
            secondaryColor,
            size: IsMainStopObject ? "lg" : "sm",
            symbol: isLocation && LocationAbbr && LocationAbbr[0],
          },
          riseOnHover: true,
          refName: isLocation ? "Locations" : "FSMSites",
          objectId: ObjectId,
          objectType: ObjectType,
          zIndexOffset: zIndexMarker,
          listId,
          clockInId,
          isMain: isMainSite,
          dispatchIds: DispatchIds.join(","),
          isScheduledSite: IsScheduledSite,
          isUnscheduledSite: IsUnscheduledSite,
        })
        .on("contextmenu", OpenNodeCard);
      BindNodeTooltip(MarkerLayer, ObjectName, Address);

      const figureFillColor =
        IsScheduledSite && isMainSite
          ? MARKERS_COLORS.PINK
          : MARKERS_COLORS.BLACK;
      const FigureLayer = getFigureObjectLayer(
        ObjectId,
        ObjectType,
        figureFillColor,
        "black",
        coords,
        Boundaries,
        Radius,
        {
          listId,
          clockInId,
          isMain: isMainSite,
          dispatchIds: DispatchIds.join(","),
        }
      );

      SitesLayers.push(MarkerLayer);
      SitesLayers.push(FigureLayer);
      if (isShownRelatedSitesRef.current || isMainSite) {
        SitesGroup.addLayer(MarkerLayer);
        SitesGroup.addLayer(FigureLayer);
      }
    }
  };

  const removeWokshiftItemFromMap = (listId: number, workshiftId: number) => {
    const mapItem = mapLayersRef.current[listId];
    mapItem.ClockIns = mapItem.ClockIns.filter((id) => id !== workshiftId);

    const {
      StopLayers,
      StopTrackLayers,
      DriveLayers,
      ClockInOutLayers,
      ConnectorsLayers,
      TrackLayersGroup,
      SitesGroup,
      SitesLayers,
      LastStopMarker,
    } = mapItem;

    if (LastStopMarker) {
      lastStopMarkersClusterRef.current.removeLayer(LastStopMarker);
      mapItem.LastStopMarker = undefined;
    }

    const filterTrackLayersFunc = (layer: any) => {
      let options = layer.options;
      if (options.polylineDecoratorOptions) {
        options = options.polylineDecoratorOptions; // todo вынести все общие параметры для слоев одинаково
      }
      if (+options.listId === +listId && +options.clockInId === +workshiftId) {
        TrackLayersGroup.removeLayer(layer); // !! SIDE EFFECT
        return false;
      } else {
        return true;
      }
    };

    mapItem.ClockInOutLayers = ClockInOutLayers.filter(filterTrackLayersFunc);
    mapItem.StopTrackLayers = StopTrackLayers.filter(filterTrackLayersFunc);
    mapItem.DriveLayers = DriveLayers.filter(filterTrackLayersFunc);
    mapItem.StopLayers = StopLayers.filter(filterTrackLayersFunc);
    mapItem.ConnectorsLayers = ConnectorsLayers.filter(filterTrackLayersFunc);
    mapItem.SitesLayers = SitesLayers.filter((layer: any) => {
      let options = layer.options;
      if (options.polylineDecoratorOptions) {
        options = options.polylineDecoratorOptions; // todo вынести все общие параметры для слоев одинаково
      }
      if (+options.listId === +listId && +options.clockInId === +workshiftId) {
        SitesGroup.removeLayer(layer); // !! SIDE EFFECT
        return false;
      } else {
        return true;
      }
    });
  };

  const drawItemTrack = (
    listId: number,
    clockIns: number[],
    clockInId: number,
    connectors: IConnector[],
    periods: IMapPeriodItem[],
    trackSites: ISiteToMap[]
  ) => {
    const { SitesGroup, TrackLayersGroup } = mapLayersRef.current[listId];

    drawPeriods(listId, periods, connectors);
    drawTrackSites(trackSites, listId, clockInId);

    TrackLayersGroup.addTo(mapRef.current);
    SitesGroup.addTo(mapRef.current);

    const isManually = true; // todo
    if (isManually) fitBoundsListItem(listId);
    showHideLastStops(!isManually);
  };

  const drawTimeCardItemTrack = (
    listId: number,
    periods: IMapPeriodItem[],
    connectors: IConnector[],
    trackSites: ISiteToMap[]
  ) => {
    const layersInfo = mapLayersRef.current[listId];
    const { SitesGroup, TrackLayersGroup } = layersInfo;
    drawPeriods(listId, periods, connectors);
    drawTrackSites(trackSites, listId, 0);
    TrackLayersGroup.addTo(mapRef.current);
    SitesGroup.addTo(mapRef.current);

    const isManually = isTCMapRef.current || true; // todo
    if (isManually) fitBoundsListItem(listId);
    showHideLastStops(!isManually);
  };

  const fitboundsLayer = (layer: any, maxZoom?: number) => {
    const bounds = layer.getBounds();
    if (bounds && bounds._northEast) {
      mapRef.current.fitBounds(bounds, {
        maxZoom,
        padding: [50, 50],
      });
    } else {
      console.log("wrong polyline");
    }
  };

  const drawPeriods = (
    listId: number,
    periods: IMapPeriodItem[],
    connectors: IConnector[]
  ) => {
    const {
      TrackLayersGroup,
      StopLayers,
      StopTrackLayers,
      DriveLayers,
      ClockInOutLayers,
      ConnectorsLayers,
    } = mapLayersRef.current[listId];

    const pinnedColor = getPinnedColor(listId);
    for (let period of periods) {
      if (period.Type === "IN" || period.Type === "OUT") {
        drawClockInOutMarkers(
          {
            PeriodId: period.Id + "",
            Coords: period.CenterCoords!,
            Time: period.Time,
            Type: period.Type === "OUT" ? period.ClockOutType : undefined,
          },
          period.ClockInId,
          listId,
          ClockInOutLayers,
          TrackLayersGroup
        );
        continue;
      }

      if (period.Track.length) {
        let dashArray: string | null = null;
        if (period.Type === "N") dashArray = "10 10";
        else if (period.Type === "S" || period.Type === "LUNCH")
          dashArray = "1 5";
        if (period.Track.length) {
          const polyline = window.L.polyline(period.Track, {
            color: pinnedColor || period.CostTypeColor,
            dashArray,
            listId,
            periodId: period.Id,
            clockInId: period.ClockInId,
            costTypeColor: period.CostTypeColor,
          })
            .bindTooltip(period.Time, { sticky: true })
            .on("mouseover", periodTrackMouseEvent)
            .on("mouseout", periodTrackMouseEvent);

          if (
            period.Type === "S" ||
            period.Type === "LUNCH" ||
            period.Type === "N"
          ) {
            StopTrackLayers.push(polyline);
          } else {
            DriveLayers.push(polyline);
            polyline.addTo(TrackLayersGroup);
            const driveArrows = window.L.polylineDecorator(period.Track, {
              polylineDecoratorOptions: {
                listId,
                periodId: period.Id,
                clockInId: period.ClockInId,
              },
              patterns: [
                {
                  repeat: 100,
                  symbol: window.L.Symbol.arrowHead({
                    pixelSize: 10,
                    headAngle: 45,
                    pathOptions: {
                      fillOpacity: 1,
                      weight: 0,
                      color: "#000000",
                    },
                  }),
                },
              ],
            });
            driveArrows.addTo(TrackLayersGroup);
            DriveLayers.push(driveArrows);
          }
        }
      }

      /*if (period.Track.length) {
        let dashArray: string | null = null;
        if (period.Type === "N") dashArray = "10 10";
        else if (period.Type === "S" || period.Type === "LUNCH") {
          dashArray = "1 5";
        }
        if (period.Track.length) {
          if (
            period.Type === "S" ||
            period.Type === "LUNCH" ||
            period.Type === "N"
          ) {
            for (let coords of period.Track) {
              StopTrackLayers.push(
                window.L.mapquest.textMarker(coords, {
                  icon: {
                    type: "via",
                    primaryColor: pinnedColor || period.CostTypeColor,
                    secondaryColor: "#333333",
                    size: "sm",
                  },
                  riseOnHover: false,
                  listId,
                  periodId: period.Id,
                  clockInId: period.ClockInId,
                  costTypeColor: period.CostTypeColor,
                })
              );
            }
          } else {
            const polyline = window.L.polyline(period.Track, {
              color: pinnedColor || period.CostTypeColor,
              dashArray,
              listId,
              periodId: period.Id,
              clockInId: period.ClockInId,
              costTypeColor: period.CostTypeColor,
            })
              .bindTooltip(period.Time, { sticky: true })
              .on("mouseover", periodTrackMouseEvent)
              .on("mouseout", periodTrackMouseEvent);

            DriveLayers.push(polyline);
            polyline.addTo(TrackLayersGroup);
            const driveArrows = window.L.polylineDecorator(period.Track, {
              polylineDecoratorOptions: {
                listId,
                periodId: period.Id,
                clockInId: period.ClockInId,
              },
              patterns: [
                {
                  repeat: 100,
                  symbol: window.L.Symbol.arrowHead({
                    pixelSize: 10,
                    headAngle: 45,
                    pathOptions: {
                      fillOpacity: 1,
                      weight: 0,
                      color: "#000000",
                    },
                  }),
                },
              ],
            });
            driveArrows.addTo(TrackLayersGroup);
            DriveLayers.push(driveArrows);
          }
        }
      }*/

      if (period.TrackDetails) {
        for (let detail of period.TrackDetails) {
          window.L.mapquest
            .textMarker(detail.Coords, {
              icon: {
                type: "via",
                primaryColor: pinnedColor || "#555555",
                secondaryColor: period.CostTypeColor,
                size: "sm",
              },
              riseOnHover: true,
            })
            .bindTooltip(detail.Details + "")
            .addTo(TrackLayersGroup);
        }
      }

      if (period.Type === "S" && period.CenterCoords) {
        const marker = window.L.mapquest
          .textMarker(period.CenterCoords, {
            type: "circle",
            icon: {
              type: "circle",
              primaryColor: pinnedColor || MARKERS_COLORS.BLACK,
              secondaryColor: period.CostTypeColor,
              size: "sm",
              symbol: period.Number,
              class: siteAnimatedClass,
            },
            riseOnHover: true,
            zIndexOffset: zIndexMarkers.initStopMarker,
            riseOffset: zIndexMarkers.markedStopMarker,
            listId,
            periodId: period.Id,
            clockInId: period.ClockInId,
            number: period.Number,
            costTypeColor: period.CostTypeColor,
            mainObjectId: period.MainObjectId,
          })
          .bindTooltip(period.Time)
          .on("mouseover", stopMarkerMouseEvent)
          .on("mouseout", stopMarkerMouseEvent)
          .on("click", stopMarkerMouseEvent);

        marker.addTo(TrackLayersGroup);
        StopLayers.push(marker);
      }
    }

    for (let connector of connectors) {
      const polyline = window.L.polyline(connector.coords, {
        color: connector.color,
        weight: 1.5,
        dashArray: connector.dashed ? "10 10" : undefined,
        listId,
        clockInId: connector.clockInId,
        isConnector: true,
        startPeriodId: connector.startId,
        finishPeriodId: connector.finishId,
      });
      ConnectorsLayers.push(polyline);
      polyline.addTo(TrackLayersGroup);
    }
  };

  const switchPeriod = (
    filteredPeriods: Set<string | number> | null,
    listId: number,
    clockInId: number
  ) => {
    const layersInfo = mapLayersRef.current[listId];
    const {
      TrackLayersGroup,
      StopLayers,
      DriveLayers,
      ClockInOutLayers,
      ConnectorsLayers,
    } = layersInfo;

    const checkAddLayer = (layer: any) => {
      let options = layer.options;
      if (options.polylineDecoratorOptions)
        options = options.polylineDecoratorOptions; // todo вынести все общие параметры для слоев одинаково
      if (options.clockInId !== clockInId) return;
      if (filteredPeriods === null) {
        TrackLayersGroup.removeLayer(layer);
        return;
      }
      if (filteredPeriods.size === 0) {
        TrackLayersGroup.addLayer(layer);
        return;
      }

      if (options.isConnector) {
        const strIdStart = getPeriodIdForFilter(
          options.startPeriodId,
          options.clockInId
        );
        const strIdFinish = getPeriodIdForFilter(
          options.finishPeriodId,
          options.clockInId
        );
        if (filteredPeriods.has(strIdStart) && filteredPeriods.has(strIdFinish))
          TrackLayersGroup.addLayer(layer);
        else TrackLayersGroup.removeLayer(layer);
        return;
      }

      const strId = getPeriodIdForFilter(options.periodId, options.clockInId);
      if (filteredPeriods.has(strId)) TrackLayersGroup.addLayer(layer);
      else TrackLayersGroup.removeLayer(layer);
    };
    DriveLayers.forEach(checkAddLayer);
    StopLayers.forEach(checkAddLayer);
    ConnectorsLayers.forEach(checkAddLayer);
    ClockInOutLayers.forEach(checkAddLayer);
  };

  const onTrackItemMouseEvent = (
    e: React.MouseEvent<HTMLDivElement>,
    filteredPeriods: Set<string> | null
  ) => {
    try {
      e.stopPropagation();
      const dataSet = e.currentTarget?.dataset || {};
      const { listid, periodid, clockinid, eventtype, mainobjectid } = dataSet;
      if (!listid || !periodid || !clockinid || !eventtype) return;
      const listId = +listid,
        periodId = periodid,
        periodClockInId = +clockinid,
        eventType = eventtype as stopType | "IN" | "OUT" | "LUNCH";

      if (e.type === "click") {
        onTrackItemClick(
          listId,
          periodId,
          periodClockInId,
          eventtype as stopType | "IN" | "OUT"
        );
        return;
      }

      const isMouseEnter = e.type === "mouseover";
      const isDrive = eventType === "D";
      const {
        TrackLayersGroup,
        StopTrackLayers,
        StopLayers,
        DriveLayers,
        ClockInOutLayers,
        SitesLayers,
      } = mapLayersRef.current[listId];

      if (mainobjectid) {
        const mainObjectLayer = SitesLayers.find(
          (layer) => +layer.options.objectId === +mainobjectid
        );
        if (mainObjectLayer) {
          const { isScheduledSite, isUnscheduledSite, isMain } =
            mainObjectLayer.options;
          let zIndex = 0;
          if (isScheduledSite || isUnscheduledSite) {
            if (isMain)
              zIndex = isMouseEnter
                ? zIndexMarkers.markedSiteMarker
                : zIndexMarkers.initSiteMarker;
            else
              zIndex = isMouseEnter
                ? zIndexMarkers.markedRelatedSiteMarker
                : zIndexMarkers.initRelatedSiteMarker;
          } else {
            zIndex = isMouseEnter
              ? zIndexMarkers.markedSiteMarker
              : zIndexMarkers.initNearestSiteMarker;
          }
          animateMarker(mainObjectLayer, isMouseEnter, zIndex);
        }
      }
      if (isDrive) {
        for (let layer of DriveLayers) {
          const options = layer.options;
          if (
            +options.periodId === +periodId &&
            +options.listId === +listId &&
            options.clockInId === periodClockInId &&
            layer.setStyle
          ) {
            const strId = getPeriodIdForFilter(
              options.periodId,
              options.clockInId
            );
            if (
              filteredPeriods === null ||
              (filteredPeriods.size && !filteredPeriods.has(strId))
            ) {
              if (isMouseEnter) TrackLayersGroup.addLayer(layer);
              else TrackLayersGroup.removeLayer(layer);
            }
            if (layer.setStyle)
              layer.setStyle({
                weight: isMouseEnter ? MARK_POLYLINE_WEIGHT : POLYLINE_WEIGHT,
              });
            break;
          }
        }
      } else if (eventtype === "IN" || eventtype === "OUT") {
        for (let layer of ClockInOutLayers) {
          const options = layer.options;
          if (
            options.periodId == periodId &&
            +options.listId == +listId &&
            options.clockInId == periodClockInId
          ) {
            if (filteredPeriods === null || filteredPeriods.size) {
              if (isMouseEnter) layer.addTo(TrackLayersGroup);
              else layer.removeFrom(TrackLayersGroup);
            }
            const zIndex = isMouseEnter
              ? zIndexMarkers.markedClockMarker
              : zIndexMarkers.initClockMarker;

            animateMarker(layer, isMouseEnter, zIndex);
            break;
          }
        }
      } else {
        for (let layer of StopTrackLayers) {
          const options = layer.options;
          if (
            +options.periodId === +periodId &&
            +options.listId === +listId &&
            options.clockInId === periodClockInId
          ) {
            if (isMouseEnter) layer.addTo(TrackLayersGroup);
            else layer.removeFrom(TrackLayersGroup);
            break; // if polyline. If dots delete this
          }
        }
        if (eventType === "S") {
          for (let layer of StopLayers) {
            const options = layer.options;
            if (
              +options.periodId === +periodId &&
              +options.listId === +listId &&
              options.clockInId === periodClockInId
            ) {
              const strId = getPeriodIdForFilter(
                options.periodId,
                options.clockInId
              );
              if (
                filteredPeriods === null ||
                (filteredPeriods.size && !filteredPeriods.has(strId))
              ) {
                if (isMouseEnter) TrackLayersGroup.addLayer(layer);
                else TrackLayersGroup.removeLayer(layer);
              }
              const zIndex = isMouseEnter
                ? zIndexMarkers.markedStopMarker
                : zIndexMarkers.initStopMarker;

              animateMarker(layer, isMouseEnter, zIndex);
              break;
            }
          }
        }
      }
    } catch (e: any) {
      showSomeError(e);
    }
  };

  const onTrackItemClick = (
    listId: number,
    periodId: string,
    periodClockInId: number,
    event: stopType | "IN" | "OUT"
  ) => {
    const isDrive = event === "D";
    const layersInfo = mapLayersRef.current[listId];
    const { DriveLayers, StopTrackLayers, ClockInOutLayers, StopLayers } =
      layersInfo;

    if (isDrive) {
      for (let layer of DriveLayers) {
        const options = layer.options;
        if (
          options.periodId === +periodId &&
          options.listId === +listId &&
          options.clockInId === periodClockInId &&
          layer.setStyle
        ) {
          fitboundsLayer(layer);
        }
      }
    } else if (event === "IN" || event === "OUT") {
      for (let layer of ClockInOutLayers) {
        const options = layer.options;
        if (
          options.periodId == periodId &&
          +options.listId === +listId &&
          options.clockInId === periodClockInId
        ) {
          const latlng = layer.getLatLng?.();
          if (latlng) {
            mapRef.current.setZoom(17, { animate: false });
            mapRef.current.setView(latlng);
          }
        }
      }
    } else {
      const group = window.L.featureGroup();
      for (let layer of StopTrackLayers) {
        const options = layer.options;
        if (
          +options.periodId === +periodId &&
          +options.listId === +listId &&
          options.clockInId === periodClockInId
        ) {
          group.addLayer(layer);
          break;
        }
      }
      if (event === "S") {
        for (let layer of StopLayers) {
          const options = layer.options;
          if (
            +options.periodId === +periodId &&
            +options.listId === +listId &&
            options.clockInId === periodClockInId
          ) {
            group.addLayer(layer);
            break;
          }
        }
      }

      fitboundsLayer(group, 17);
    }
    return false;
  };

  const onDispatchClick = (listId: number, dispatchid: number) => {
    const group = window.L.featureGroup();
    const { SitesLayers } = mapLayersRef.current[listId];

    for (let layer of SitesLayers) {
      const options = layer.options;
      const dispatchIds: string[] = (options.dispatchIds || "").split(",");
      const hasDispatchId =
        dispatchIds.findIndex((dIdStr) => +dIdStr === +dispatchid) > -1;
      if (!hasDispatchId || +options.listId !== +listId) continue;
      const isMain = options.isMain;
      if (isShownRelatedSitesRef.current || isMain) {
        group.addLayer(layer);
      }
    }
    fitBoundsGroup(group, mapRef.current, 100);
  };

  const onDispatchMouseEvent = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    const { dispatchid, listid } = e.currentTarget.dataset;
    if (!dispatchid || !listid) return;
    const Id: number = +listid;
    if (e.type === "click") {
      onDispatchClick(+Id, +dispatchid);
      return;
    }
    const isMouseEnter = e.type === "mouseenter";
    const { SitesLayers, SitesGroup } = mapLayersRef.current[Id];

    for (let layer of SitesLayers) {
      const options = layer.options;
      const dispatchIds: string[] = (options.dispatchIds || "").split(",");
      const hasDispatchId =
        dispatchIds.findIndex((dIdStr) => +dIdStr === +dispatchid) > -1;
      if (!hasDispatchId || +options.listId !== Id) continue;
      const isMarker = !!layer._icon;
      const isMain = options.isMain;
      if (!isShownRelatedSitesRef.current && !isMain) {
        if (isMouseEnter) layer.addTo(SitesGroup);
        else layer.removeFrom(SitesGroup);
      }
      if (!isMarker) {
        const weight = isMouseEnter ? 2 : 1;
        const fillOpacity = isMouseEnter ? 0.1 : 0;
        if (layer.setStyle)
          layer.setStyle({
            weight: weight,
            fillOpacity: fillOpacity,
          });
      } else {
        let zIndex = 0;
        if (isMouseEnter) {
          zIndex = isMain
            ? zIndexMarkers.markedSiteMarker
            : zIndexMarkers.markedRelatedSiteMarker;
          if (layer?._icon?.classList)
            layer._icon.classList.add(siteAnimatedClass);
        } else {
          zIndex = isMain
            ? zIndexMarkers.markedSiteMarker
            : zIndexMarkers.initRelatedSiteMarker;
          if (layer?._icon?.classList)
            layer._icon.classList.remove(siteAnimatedClass);
        }
        layer.setZIndexOffset(zIndex);
      }
    }

    return false;
  };

  const renderCMFilterTag = (tagData: TagData, tag: any) => {
    let selectedLength = filtersValueRef.current.CrewMembers.length;
    if (!selectedLength) return null;
    return (
      <Chip key={"selectedBPNumbers"} text={`${selectedLength} Selected`} />
    );
    return tag; // for ts
  };
  const getPinnedIndex = (listId: number) => {
    return pinnedListRef.current.findIndex((id) => id === listId);
  };

  const getPinnedColor = (listId: number) => {
    const pinnedIndex = getPinnedIndex(listId);
    if (pinnedIndex === -1) return null;
    const colorsLength = DEVICES_COLORS.length;
    const lastIndex = colorsLength - 1;
    const colorIndex =
      pinnedIndex > lastIndex ? pinnedIndex % lastIndex : pinnedIndex;
    return DEVICES_COLORS[colorIndex];
  };

  const renderSideBarList = () => {
    if (!isTCMapRef.current && isCurrentTimeModeRef.current) {
      return (
        <>
          {filteredEmployeesList.map((employee) => {
            const listId = employee.ListId;
            return (
              <Employee
                key={listId}
                map={mapRef.current}
                data={employee}
                isPinned={getPinnedIndex(listId) > -1}
                pinnedColor={getPinnedColor(listId)}
                onTogglePin={onTogglePin}
                isSelected={listId === selectedIdRef.current}
                selectItem={selectItem}
                unSelectItem={unSelectItem}
                draw={drawItemTrack}
                switchItem={switchListItem}
                switchPeriod={switchPeriod}
                onTrackItemMouseEvent={onTrackItemMouseEvent}
                onDispatchMouseEvent={onDispatchMouseEvent}
                onLoadDrawTrack={onItemLoadDrawTrack.current}
                ShowStateAllocation={userInfo!.showStateAllocation}
                removeWokshiftItemFromMap={removeWokshiftItemFromMap}
              />
            );
          })}
        </>
      );
    }

    if (!isTCMapRef.current && !isCurrentTimeModeRef.current) {
      return (
        <>
          {filteredTimeCardsList.map((tc) => (
            <TimeCard
              key={tc.ListId}
              map={mapRef.current}
              isTCMap={false}
              refreshKey={refreshListItems.value} // for pin/unpin
              data={tc}
              isPinned={getPinnedIndex(tc.ListId) > -1}
              pinnedColor={getPinnedColor(tc.ListId)}
              onTogglePin={onTogglePin}
              isSelected={selectedIdRef.current === tc.ListId}
              onDispatchMouseEvent={onDispatchMouseEvent}
              draw={drawTimeCardItemTrack}
              switchPeriod={switchPeriod}
              selectItem={selectItem}
              unSelectItem={unSelectItem}
              switchItem={switchListItem}
              onTrackItemMouseEvent={onTrackItemMouseEvent}
              onLoadDrawTrack={onItemLoadDrawTrack.current}
              ShowStateAllocation={userInfo!.showStateAllocation}
            />
          ))}
        </>
      );
    }

    if (isTCMapRef.current) {
      return (
        <TimeCard
          key={tcIdRef.current}
          map={mapRef.current}
          isTCMap={true}
          refreshKey={(props.refreshKey || 0) + refresh.value}
          data={{
            TimeCardId: tcIdRef.current!,
            ListId: tcIdRef.current!,
            ActualDurationHours: 0,
            Duration: "",
            EmployeeId: 0, // ??
            EmployeeName: "",
            OrderNumber: 0,
          }}
          isPinned={false}
          pinnedColor={""}
          isSelected={false}
          onDispatchMouseEvent={onDispatchMouseEvent}
          draw={drawTimeCardItemTrack}
          onTogglePin={onTogglePin}
          switchPeriod={switchPeriod}
          selectItem={selectItem}
          unSelectItem={unSelectItem}
          switchItem={switchListItem}
          onTrackItemMouseEvent={onTrackItemMouseEvent}
          onLoadDrawTrack={onItemLoadDrawTrack.current}
          ShowStateAllocation={userInfo!.showStateAllocation}
        />
      );
    }
  };

  const renderSideBar = () => {
    if (userInfo === null) return null;
    return (
      <div ref={leftPanelRef} className={styles.Panel}>
        {!isTCMapRef.current && (
          <Toolbar className={styles.PanelToolbar}>
            {needFilters && (
              <div className={styles.PanelRow}>
                <FilterCombobox
                  key={"filterbpo" + remountFilters.value}
                  placeholder="Filter by BP Owners"
                  data={bpOwners}
                  loading={isLoadingFilters.value}
                  className={styles.Filter}
                  onChange={onChangeFilter}
                  defaultValue={filtersValueRef.current["BPO"]}
                  dataAttr={"BPO"}
                />
                <FilterMultiSelect
                  key={"filtercm" + remountFilters.value}
                  placeholder="Filter by Crew Member"
                  data={crewMembers}
                  className={styles.Filter}
                  loading={isLoading.value}
                  defaultValue={filtersValueRef.current.CrewMembers}
                  selectAll={true}
                  autoClose={false}
                  tags={[{ text: ` `, data: [] }]}
                  tagRender={renderCMFilterTag}
                  onChange={onChangeCrewMembersFilter}
                />
              </div>
            )}
            {needFilters && (
              <div className={styles.PanelRow}>
                <FilterCombobox
                  key={"filterbp" + remountFilters.value}
                  placeholder="Filter by Build Plan"
                  data={buildPlans}
                  className={styles.BPFilter}
                  onChange={onChangeFilter}
                  loading={isLoadingFilters.value}
                  defaultValue={filtersValueRef.current["BuildPlan"]}
                  dataAttr={"BuildPlan"}
                />
              </div>
            )}
            <div className={`${styles.PanelRow}`}>
              <div style={{ width: 109 }}>
                {isCurrentTimeModeRef.current && (
                  <ClockedOnlyToggle
                    initialState={filtersValueRef.current.OnlyClockedIn}
                    onChange={onChangeOnlyClockedIn}
                  />
                )}
              </div>
              <div style={{ margin: "0 auto" }}>
                Snapshot on:{" "}
                {moment(snapshotDateRef.current).format(
                  isCurrentTimeModeRef.current ? "LT" : "L"
                )}
              </div>
              <DatePicker
                width={26}
                placeholder={"pl"}
                dateInput={() => null}
                max={new Date()}
                onChange={onChangeDate}
              />
              <PinAllToggle
                className={styles.PinAllBtns}
                onClick={onPinAllEvent}
              />
              {isLoading.value ? (
                <Loader
                  size="small"
                  type="converging-spinner"
                  style={{ margin: "0 3.57px" }}
                  themeColor="dark"
                />
              ) : (
                <Button
                  size={"small"}
                  icon="refresh"
                  onClick={refresh}
                ></Button>
              )}
            </div>
          </Toolbar>
        )}
        <div className={styles.DevicesList}>{renderSideBarList()}</div>
      </div>
    );
  };

  return (
    <SideBar
      style={bpId || isTCMapRef.current ? { height: "100%" } : undefined}
      defaultOpened={true}
      mode="push"
      onToggle={onMapContainerResize}
      content={renderSideBar()}
      btnText={props.sideBarBtnText}
      rtl={true}
    >
      <div
        className={styles.LiveMapContainer}
        style={props.isMapHidden ? { display: "none" } : {}}
      >
        <div
          id={mapContainerIdRef.current}
          className={styles.MapContainer}
        ></div>
        <div className={styles.CustomMapControls}>
          <div className={mapStyles.Controls}>
            <Button
              iconClass="mdi mdi-arrow-collapse-all"
              title="Fit Bounds"
              onClick={onFitBounds}
            />
          </div>
          <RelatedSitesControl
            isOn={isShownRelatedSitesRef.current}
            onToggle={onToggleRelatedSites}
          />
          {!!mapRef.current && (
            <OtherNodesControl className={""} map={mapRef.current} />
          )}
          {!!mapRef.current && (
            <SearchNodesLocation map={mapRef.current} className="" />
          )}
        </div>
        {!!mapRef.current && (
          <>
            <MapSearchSitesControl
              map={mapRef.current}
              className={styles.SearchControl}
            />
            <MapContextMenu map={mapRef.current} refresh={refreshLiveMap} />
            <MapSearchAddressesControl map={mapRef.current} />
            <FullSiteTooltips map={mapRef.current} />
          </>
        )}
      </div>
    </SideBar>
  );
};

export default LiveMap;
