/*import "intl";
import "intl/locale-data/jsonp/en";*/

export const formatCurrency = (amount: number | bigint) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const formatTimeDiff = (seconds: number) => {
  let hrs = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  return hrs + "h " + min + "m";
};

export const formatTimeDiffCeil = (s: number) => {
  let hrs = Math.floor(s / 3600);
  let min = Math.ceil((s % 3600) / 60);
  return hrs + "h " + min + "m";
};

export const leadingZeros = (value: string, length: number) => {
  length = length || 2;
  return ("000000000000" + value).substr(-1 * length);
};

export const CONTERRA_DATETIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const CONTERRA_DATE_FORMAT = "DD.MM.YYYY";
export const ISO_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const ISO_DATE_FORMAT = "YYYY-MM-DD";

export function FormatSize(bytes: number) {
  return Math.round((bytes || 0) / 1000) / 1000 + " MB";
}
