import { Component } from "react";
import { IAddCustomerCardProps } from "./interfaces";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { RunScriptAsync } from "../../helpers/runscripts";
import Loader from "../Common/Loader";
import FXCard from "../Common/FXCard/FXCard";
import formStyles from "./card.module.scss";
import { showSomeError } from "../../helpers/helpers";

interface IInfo {
  CustomerName: string;
  CustomerAbbv: string;
}

interface state {
  processing: boolean;
  remountKey: number;
  invalidForm: boolean;
}

class AddCustomerCard extends Component<IAddCustomerCardProps, state> {
  Info: IInfo = {
    CustomerName: "",
    CustomerAbbv: "",
  };

  constructor(props: IAddCustomerCardProps) {
    super(props);
    this.state = {
      processing: false,
      remountKey: +new Date(),
      invalidForm: true,
    };
  }

  render() {
    return (
      <FXCard
        title={<span className={formStyles.HeaderTitle}>New Customer</span>}
        onClose={this.Close}
        initialWidth={400}
        initialHeight={180}
        originalPaddings={true}
      >
        <div className={formStyles.FormWrapper}>
          {this.state.processing && <Loader />}
          <Input
            key={this.state.remountKey + "name"}
            className={`${formStyles.FormField} ${formStyles.TextField}`}
            placeholder="Name *"
            defaultValue={this.Info.CustomerName}
            onChange={this.OnChangeTextField}
            name="CustomerName"
            required={true}
          />
          <Input
            key={this.state.remountKey + "abbr"}
            className={`${formStyles.FormField} ${formStyles.TextField}`}
            placeholder="Abbr *"
            defaultValue={this.Info.CustomerAbbv}
            name="CustomerAbbv"
            onChange={this.OnChangeTextField}
            required={true}
          />
          {this.renderFooter()}
        </div>
      </FXCard>
    );
  }

  OnChangeTextField = (e: InputChangeEvent) => {
    // @ts-ignore
    let field: keyof IInfo = e.target.name!;
    this.OnChangeField(e.value, field);
  };

  OnChangeField = (value: any, dataAttr: keyof IInfo) => {
    this.Info[dataAttr] = value;
    this.ValidateForm();
  };

  ValidateForm = () => {
    let invalidForm = !this.Info.CustomerName || !this.Info.CustomerAbbv;
    this.setState({ invalidForm });
  };

  renderFooter = () => {
    return (
      <div className={`${formStyles.FormFooter} k-action-buttons`}>
        <span className={formStyles.InvalidMessage}>
          {this.state.invalidForm && <span>Fill all mandatory fields</span>}
        </span>
        <Button onClick={this.Close}>Cancel</Button>
        <Button
          onClick={this.CreateCustomer}
          themeColor="primary"
          disabled={this.state.invalidForm}
        >
          Create Customer
        </Button>
      </div>
    );
  };

  CreateCustomer = async () => {
    try {
      this.setState({ processing: true });
      let params = {
        CustomerName: this.Info.CustomerName,
        CustomerAbbv: this.Info.CustomerAbbv,
      };
      let newCustomer = await RunScriptAsync("Customers_CreateNew", params);
      if (newCustomer && this.props.onFinish) {
        this.props.onFinish(+newCustomer);
        this.Close();
      }
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };

  Close = () => {
    this.props.finally!();
  };
}

export default AddCustomerCard;
