import { GridCellProps } from "@progress/kendo-react-grid";
import { IDialogButton, INewComboboxItem } from "../../../helpers/interfaces";
import React, { useRef } from "react";
import { formatFinancial } from "../../../helpers/helpers";
import styles from "./bomcard.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import {
  Input,
  InputChangeEvent,
  TextArea,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import debounce from "lodash.debounce";
import {
  BomMaterialDetailsDto,
  BomMaterialItemDetailsDto,
} from "../../../core/api/generated/warehouse";
import { ModalRef } from "../../Common/Modal/Modal";
import { IBOMMaterial } from "./interfaces";
import OpenCardLink from "../../OpenCardLink";
import CardManagement from "../CardManagement";
import ButtonLink from "../../Common/Buttons/ButtonLink";

export const renderLinkCell = (props: GridCellProps) => {
  const { dataItem, field } = props;
  const item: INewComboboxItem = dataItem[field!];
  return (
    <td>
      <div>{item?.name}</div>
    </td>
  );
};

export const renderSNLinkCell = (props: GridCellProps) => {
  const dataItem = props.dataItem as BomMaterialItemDetailsDto;
  return (
    <td>
      <ButtonLink
        text={dataItem.sn}
        dataAttr={dataItem.id}
        onClick={(
          e: React.BaseSyntheticEvent<object, any, any> | undefined,
          dataAttr: number
        ) => {
          CardManagement.OpenMaterialItemCard(dataAttr);
        }}
      />
    </td>
  );
};

export const renderMaterialNameCell = (props: GridCellProps) => {
  const dataItem = props.dataItem as IBOMMaterial;
  return (
    <td>
      <OpenCardLink
        text={dataItem.material.name}
        dataAttr={dataItem.material.id}
        refName={"FSMMaterials"}
      />
    </td>
  );
};

export const renderBooleanCell = (props: GridCellProps) => {
  const { dataItem, field } = props;
  const item: INewComboboxItem = dataItem[field!];

  return (
    <td>
      <div>{item === null ? "" : item ? "Yes" : "No"}</div>
    </td>
  );
};

export const renderCurrencyCell = (props: GridCellProps) => {
  const { dataItem, field } = props;
  const value = dataItem[field!];
  if (value === null || value === undefined) {
    return <td></td>;
  }
  return (
    <td>
      <div className={styles.CurrencyBox}>
        <span>$</span>
        {formatFinancial(value)}
      </div>
    </td>
  );
};

export const IconBtnCell = (props: {
  gridCellProps: GridCellProps;
  iconClass: string;
  action: (dataItem: any) => void;
  title?: string;
  themeColor?:
    | null
    | "base"
    | "primary"
    | "secondary"
    | "tertiary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "light"
    | "inverse";
  disabled?: boolean;
}) => (
  <td>
    <div style={{ textAlign: "center" }}>
      <Button
        tabIndex={-1}
        title={props.title}
        iconClass={props.iconClass}
        fillMode={"flat"}
        onClick={() => props.action(props.gridCellProps.dataItem)}
        themeColor={props.themeColor}
        disabled={props.disabled}
      />
    </div>
  </td>
);

export const QTYCell = (props: {
  gridCellProps: GridCellProps;
  action: (dataItem: BomMaterialDetailsDto, qty: number) => void;
}) => {
  const {
    gridCellProps: { dataItem, field },
    action,
  } = props;
  const valueRef = useRef(dataItem[field!] || "0");
  return (
    <td>
      <div>
        <Input
          tabIndex={2}
          defaultValue={valueRef.current}
          type={"number"}
          min={1}
          required={true}
          onChange={(e) => {
            valueRef.current = e.value;
          }}
          onBlur={() => {
            if (dataItem[field!] === +valueRef.current) return;
            action(dataItem, +valueRef.current);
          }}
        />
      </div>
    </td>
  );
};
export const CommentCell = (props: {
  gridCellProps: GridCellProps;
  editable: boolean;
  action: (dataItem: BomMaterialDetailsDto, comment: string) => void;
}) => {
  const {
    gridCellProps: { dataItem, field },
    editable,
  } = props;
  const valueRef = useRef(dataItem[field!] || "");

  const onChange = debounce((event: InputChangeEvent | TextAreaChangeEvent) => {
    valueRef.current = event.value;
  }, 800);

  const OpenModalComment = (e: any) => {
    e.stopPropagation();

    const buttons: IDialogButton[] = [];
    if (editable) {
      buttons.push({
        text: "Ok",
        color: "primary",
        action: () => {
          TryChange();
          ModalRef.hideDialog();
        },
      });
    }
    buttons.push({
      text: editable ? "Cancel" : "Close",
      action: () => {
        ModalRef.hideDialog();
      },
    });

    ModalRef.showDialog({
      title: "Comment",
      width: 400,
      buttons,
      children: (
        <div>
          <TextArea
            rows={field === "CxStatusComment" ? 20 : 10}
            style={{ width: "100%" }}
            defaultValue={valueRef.current}
            readOnly={!editable}
            onChange={onChange}
          />
        </div>
      ),
    });
  };

  const TryChange = () => {
    if (dataItem[field!] !== valueRef.current) {
      props.action(dataItem, valueRef.current);
    }
  };

  return (
    <td>
      <div className={styles.CommentCell}>
        {editable ? (
          <Input
            tabIndex={3}
            // className={styles.GroupComment}
            type="text"
            defaultValue={valueRef.current}
            onChange={onChange}
            // onKeyDown={this.OnChangeGridTextField}
            onBlur={TryChange}
          />
        ) : (
          <div className={styles.CommentEllipsis} style={{ flex: 1 }}>
            {valueRef.current}
          </div>
        )}

        {(editable || !!valueRef.current) && (
          <Button
            tabIndex={-1}
            className={styles.CommentCellBtn}
            iconClass={
              editable ? "mdi mdi-pencil-outline" : "mdi" + " mdi-eye-outline"
            }
            fillMode="flat"
            onClick={OpenModalComment}
          />
        )}
      </div>
    </td>
  );
};
