import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./form.module.scss";
import { useRef, useState } from "react";
import moment from "moment";

interface IProps {
  min: Date;
  max: Date;
  defaultValues?: Date[];
  onChange: (startDate: Date, endDate: Date) => void;
}

const getFormatValue = (
  values: number[],
  lastDayIndex: number,
  min: Date,
  max: Date
) => {
  const [start, end] = values;
  const startDate = moment(min).add(start, "day").format("L");
  if (start === end) return startDate;
  const endDate = moment(min).add(end, "day").format("L");
  return `${startDate} - ${endDate}`;
};

const getDateValues = (sliderValues: number[], min: Date) => {
  const [startValue, endValue] = sliderValues;
  const startDate = moment(min).add(startValue, "days").toDate();
  const endDate = moment(min).add(endValue, "days").toDate();
  return [startDate, endDate];
};

const getSliderValues = (dateValues: Date[], min: Date) => {
  const [startDate, endDate] = dateValues;
  const a = moment(startDate).diff(moment(min), "days");
  const b = moment(endDate).diff(moment(min), "days");
  return [a, b];
};

const getMaxSliderValue = (min: Date, max: Date) => {
  const momentMin = moment(min);
  const momentMax = moment(max);
  const daysLength = momentMax.diff(momentMin, "days"); // + 1 // 1
  return daysLength;
};

const DateSliderFilter = (props: IProps) => {
  const { min, max, defaultValues } = props;
  // const [maxSliderValue, setMaxSliderValue] = useState(getMaxSliderValue(min, max))
  const defaultDateValues = defaultValues || [min, max];
  // const [sliderValues, setSliderValues] = useState(getSliderValues(defaultDateValues, min))
  const settingsRef = useRef<{
    maxValue: number;
    values: number[];
  }>({
    maxValue: getMaxSliderValue(min, max),
    values: getSliderValues(defaultDateValues, min),
  });

  const [formatValue, setFormatValue] = useState(
    getFormatValue(
      settingsRef.current.values,
      settingsRef.current.maxValue,
      min,
      max
    )
  );

  const onChange = (values: number[]) => {
    setFormatValue(
      getFormatValue(values, settingsRef.current.maxValue, min, max)
    );
  };

  const onChangeEnd = (values: number[]) => {
    const [startDate, endDate] = getDateValues(values, min);
    props.onChange(startDate, endDate);
  };

  return (
    <div className={styles.DateRangeSlider}>
      <div className={styles.DateRangeSliderBox}>
        <Slider
          range={true}
          step={1}
          min={0}
          max={settingsRef.current.maxValue}
          defaultValue={settingsRef.current.values}
          allowCross={false}
          // @ts-ignore
          onAfterChange={onChangeEnd}
          // @ts-ignore
          onChange={onChange}
          handleStyle={{ borderColor: "#2196f3", boxShadow: "none" }}
        />
      </div>
      <div className={styles.DateRangeSliderValue}>{formatValue}</div>
    </div>
  );
};


export default DateSliderFilter;
