import React, { Component } from "react";
import DailyBuildplans from "./DailyBuildPlans";
import SideBar from "../../Components/Common/SideBar/SideBar";
import DailyTabs from "./DailyTabs";

interface state {
  buildPlanId: number | null;
  buildPlanName: string;
}

interface props {}

class DaylyDashboard extends Component<props, state> {
  refList: any = React.createRef();
  refTabs: any = React.createRef();

  constructor(props: props) {
    super(props);
    this.state = {
      buildPlanId: null,
      buildPlanName: "",
    };
  }

  render() {
    return (
      <SideBar
        btnText={`BUILD PLAN: ${this.state.buildPlanName}`}
        defaultOpened={true}
        mode="overlay"
        content={
          <DailyBuildplans
            ref={this.refList}
            onChangeBuildPlan={this.ChangeBuildPlan}
          />
        }
      >
        {this.state.buildPlanId && (
          <DailyTabs
            ref={this.refTabs}
            buildPlanId={this.state.buildPlanId}
            buildPlanName={this.state.buildPlanName}
          ></DailyTabs>
        )}
      </SideBar>
    );
  }

  ChangeBuildPlan = (BP: { name: string; id: number }) => {
    if (this.state.buildPlanId === BP.id) return;
    this.setState({ buildPlanId: BP.id, buildPlanName: BP.name });
  };

  Refresh = () => {
    if (this.refList.current && this.refList.current.RefreshBuildPlans) {
      this.refList.current.RefreshBuildPlans();
    }

    if (this.refTabs.current && this.refTabs.current.RefreshActiveTabs) {
      this.refTabs.current.RefreshActiveTabs();
    }
  };
}

export default DaylyDashboard;
