import React, { useMemo, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./tkToolbar.module.scss";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
} from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Card } from "@progress/kendo-react-layout";
import {
  getAvailableTCActionsToPerform,
  performTCAction,
} from "../PerformTCActions";
import { ETCPerformAction } from "../interfaces";
import { PerformActionToSettings } from "../helpers";
import { buttonThemeColor } from "../../../helpers/interfaces";

export const TimeCardsActions = (props: {
  checkedTCS: number[];
  onToggleCheckAll: (event: CheckboxChangeEvent) => void;
  enableTCSCount: number;
  clearCheckedIds: () => void;
  refreshList: () => void;
}) => {
  const { checkedTCS, enableTCSCount } = props;
  const [comment, setComment] = useState("");
  const availableActions = useMemo(() => getAvailableTCActionsToPerform(), []);

  const tryAction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const actionName = e.currentTarget.dataset.action as ETCPerformAction;
    if (!actionName) return;

    performTCAction({
      actionName,
      tcs: checkedTCS,
      comment,
      OnSuccess: () => {
        props.clearCheckedIds();
        props.refreshList();
      },
    });
  };

  return (
    <>
      <Tooltip anchorElement="target" showCallout={false} parentTitle={true}>
        <Card
          className={styles.Card}
          style={{
            width: !checkedTCS.length ? 180 : 506,
          }}
          type={"primary"}
        >
          <Checkbox
            label={` `}
            onChange={props.onToggleCheckAll}
            size={"large"}
          />
          <div className={styles.CheckedNumbers} style={{}}>
            <div title={"Checked"}>{checkedTCS.length}</div>
            <div>of</div>
            <div title={"Available for Actions"}>{enableTCSCount}</div>
          </div>
          <div
            style={
              !checkedTCS.length
                ? { display: "none" }
                : {
                    display: "flex",
                    gap: 4,
                  }
            }
          >
            <Input
              placeholder={"Comment"}
              value={comment}
              onChange={(e) => {
                setComment(e.value);
              }}
              style={{ width: 250 }}
            />
            {availableActions.map((action) => (
              <Button
                key={action}
                {...PerformActionToSettings[action]}
                themeColor={
                  PerformActionToSettings[action].themeColor as buttonThemeColor
                }
                data-action={action}
                fillMode={"flat"}
                onClick={tryAction}
              />
            ))}
          </div>
        </Card>
      </Tooltip>
    </>
  );
};
