import formStyles from "../card.module.scss";
import api from "../../../core/api/api";
import React from "react";
import LoaderComponent from "../../Common/Loader";
import BaseComponent from "../../BaseComponent";
import { IFXCardProps } from "../interfaces";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ModalRef } from "../../Common/Modal/Modal";
import FXCard from "../../Common/FXCard/FXCard";

interface IValues {
  password: string;
  newPassword: string;
  repeatNew: string;
}

interface IState extends IValues {
  isProcessing: boolean;
  isValid: boolean;
  error: string;
  remountKey: number;
}

class ChangePasswordCard extends BaseComponent<IFXCardProps, IState> {
  values: IValues = {
    password: "",
    newPassword: "",
    repeatNew: "",
  };

  constructor(props: any) {
    super(props);
    this.state = {
      isProcessing: false,
      isValid: false,
      error: "",
      remountKey: +new Date(),
      ...this.values,
    };
  }

  render() {
    const {
      isProcessing,
      password,
      newPassword,
      repeatNew,
      error,
      remountKey,
      isValid,
    } = this.state;

    return (
      <FXCard
        title={"Change Password"}
        onClose={this.Close}
        initialWidth={430}
        initialHeight={220}
        originalPaddings={true}
      >
        <form autoComplete="off" className={formStyles.FormWrapper}>
          {isProcessing && <LoaderComponent />}

          <div>
            <Input
              style={{ width: "100%", marginTop: "5px" }}
              type={"password"}
              placeholder="Password"
              value={password}
              autoComplete={"off"}
              onChange={(e: InputChangeEvent) => this.OnChange("password", e)}
            />
            <Input
              style={{ width: "100%", marginTop: "5px" }}
              type={"password"}
              placeholder="New Password"
              key={remountKey + "npsw"}
              value={newPassword}
              onChange={(e: InputChangeEvent) =>
                this.OnChange("newPassword", e)
              }
            />
            <Input
              style={{ width: "100%", marginTop: "5px" }}
              type="password"
              placeholder="Repeat New Password"
              value={repeatNew}
              onChange={(e: InputChangeEvent) => this.OnChange("repeatNew", e)}
            />
          </div>
          <div className={`${formStyles.FormFooter} k-action-buttons`}>
            <span className={formStyles.InvalidMessage}>
              {!!error && <span>{error}</span>}
            </span>
            <Button onClick={this.Close}>Close</Button>
            <Button
              themeColor={"primary"}
              onClick={this.ChangePassword}
              disabled={!isValid}
            >
              Change Password
            </Button>
          </div>
        </form>
      </FXCard>
    );
  }

  OnChange = (field: keyof IValues, e: InputChangeEvent) => {
    // if (e.nativeEvent.inputType !== "insertText") return
    this.values[field] = e.value;
    const { password, newPassword, repeatNew } = this.values;
    const isAllFilledIn = !!password && !!newPassword && !!repeatNew;
    this.setState({
      isValid: isAllFilledIn && newPassword === repeatNew,
      error:
        isAllFilledIn && newPassword !== repeatNew
          ? "New Password and repeating is not equal"
          : "",
      ...this.values,
    });
  };

  ChangePassword = async () => {
    try {
      this.setState({ isProcessing: true });
      const { password: oldPassword, newPassword } = this.values;
      await api.auth.changePassword({ oldPassword, newPassword });
      this.values = {
        password: "",
        newPassword: "",
        repeatNew: "",
      };
      this.setState({ isValid: false, remountKey: +new Date() });
      ModalRef.showDialog({
        type: "success",
        title: "Success",
        text: "Password was changed",
      });
    } catch (error: any) {
      const { status, data } = error.response;

      if (status === 500 || status === 403) {
        ModalRef.showDialog({
          type: "error",
          title: "Changing Password Error",
          text: data.Message,
        });
      }
    } finally {
      this.setState({ isProcessing: false });
    }
  };

  Close = () => {
    this.props.finally!();
  };
}

export default ChangePasswordCard;
