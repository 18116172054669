import { IProcessedTCOriginalTE } from "../../../Pages/TKReview/interfaces";
import styles from "./tkTimeLine.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React from "react";
import { getTEPositionAnsWidth } from "./helpers";
import { TETooltipContent } from "./TETooltipContent";

export const TimeLineFlagItem = (props: {
  item: IProcessedTCOriginalTE;
  isClockIn: boolean;
  isClockOut: boolean;
  isWaivedLunch: boolean;
  isForceClockOut: boolean;
}) => {
  const { item, isForceClockOut, isClockOut, isClockIn, isWaivedLunch } = props;
  const { left } = getTEPositionAnsWidth(item.startMinutes, item.finishMinutes);
  return (
    <div className={`${styles.TimeLineFlagTE}`} style={{ left }}>
      <Tooltip
        style={{ maxWidth: 300 }}
        content={(props) => (
          <TETooltipContent data={item} originalTe={item} isFlag={true} />
        )}
        anchorElement="target"
        showCallout={false}
        parentTitle={true}
      >
        <div
          style={{
            height: isClockOut ? 30 : isClockIn ? 50 : 25,
            background: "#000",
          }}
        ></div>
        {isClockIn && (
          <div className={styles.ClockInFlag} title={"Clock In"}></div>
        )}
        {isClockOut && (
          <div
            className={`${styles.ClockOutFlag} ${
              isForceClockOut ? styles.ForceClockOutFlag : ""
            }`}
            title={"Clock Out"}
          >
            <span className={styles.ForceClockOutSymbol}>
              {isForceClockOut && "F"}
            </span>
          </div>
        )}
        {isWaivedLunch && (
          <div className={styles.WaivedLunchFlag} title={"Lunch"}></div>
        )}
      </Tooltip>
    </div>
  );
};
