import {
  IProcessedTCAjustedTE,
  IProcessedTCOriginalTE,
} from "../../../Pages/TKReview/interfaces";
import styles from "./tkTimeLine.module.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { getTEPositionAnsWidth } from "./helpers";
import React from "react";
import { TETooltipContent } from "./TETooltipContent";

export const TimeLineItem = (props: {
  data: IProcessedTCOriginalTE | IProcessedTCAjustedTE;
  originalTe?: IProcessedTCOriginalTE;
  index: number;
}) => {
  const { data, originalTe } = props;
  const {
    costTypeCode,
    costTypeColor,
    startMinutes,
    finishMinutes,
    hours,
    approvedHours,
    startFormatted,
    finishFormatted,
  } = data;
  const isApproved = !+hours || !!+Math.abs(approvedHours);
  const isLunch = costTypeCode === "LUNCH";
  const noWONoAllocation =
    !isLunch && originalTe?.wo === null && originalTe?.hasAllocation === false;
  const isNoGPS =
    !!originalTe?.event && originalTe.event.indexOf("NO_GPS") > -1;
  return (
    <div
      className={`${styles.TimeLineSegment} class-name`}
      style={getTEPositionAnsWidth(startMinutes, finishMinutes)}
    >
      <Tooltip
        style={{ maxWidth: 300 }}
        content={(props) => (
          <TETooltipContent data={data} originalTe={originalTe} />
        )}
        anchorElement="target"
        showCallout={false}
        parentTitle={true}
      >
        <div style={{ height: 25 }}>
          <div
            className={`${styles.TimeLineTE} ${
              isApproved
                ? styles.TimeLineTEApproved
                : styles.TimeLineTENotApproved
            }  class-name`}
            style={{ background: costTypeColor || "" }}
            title={`${startFormatted} - ${finishFormatted}`}
          >
            {noWONoAllocation && <div className={styles.TimeLineNoWO}></div>}
          </div>
          {isNoGPS && <div className={styles.TimeLineNoGPS}></div>}
        </div>
      </Tooltip>
    </div>
  );
};
