import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { useContext } from "react";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";

export default function DesktopButton(props: ButtonProps) {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );

  if (!isIntegrationWithDesktop) return null;
  return <Button {...props} />;
}
