import { IntegrationWithDesktopContext } from "../../helpers/settings";
import Switch from "../../Components/Common/Switch";
import { useContext } from "react";
import { Button } from "@progress/kendo-react-buttons";

export default function SwitchDesktopIntegration(props: {
  id: string;
  style?: React.CSSProperties;
}) {
  const { value: isIntegrationWithDesktop, setValue } = useContext(
    IntegrationWithDesktopContext
  );
  return (
    <div style={props.style}>
      <div>
        <Switch
          id={"autorefresh" + props.id}
          checked={isIntegrationWithDesktop}
          onChange={setValue}
          label={"Integration with Desktop"}
        ></Switch>
      </div>
      <Button
        icon={"download"}
        fillMode={"flat"}
        onClick={() => {
          window.location.href = "/tools/UrlProcessorInstaller.exe";
        }}
      />
    </div>
  );
}
