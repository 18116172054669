import { ModalRef } from "../Components/Common/Modal/Modal";
import { INotification, simpleObject } from "./interfaces";
import api, { authProvider } from "../core/api/api";

const $ = window.$;

export const OpenJob = async (JobID: number) => {
  RunScriptLocal("ShowJobCard", { JobID });
};

export const ApiScriptLocal = async (scriptName: string, params: object) => {
  RunScriptLocal(scriptName, { JSONString: JSON.stringify(params) });
};

export const GetRunScriptLocalLink = (
  scriptName: string,
  params?: simpleObject
) => {
  let paramsStr = "";
  if (params) {
    const paramsArr: string[] = [];
    for (let key in params) {
      paramsArr.push(`${key}=${params![key]}`);
    }
    if (paramsArr.length) paramsStr = paramsArr.join("|");
  }
  return `fieldclixurl://run/?instanceId=${authProvider.getInstanceId()}&componentType=Script&component=${scriptName}${
    paramsStr ? "&params=" + paramsStr : ""
  }`;
};

export const RunScriptLocal = (scriptName: string, params?: simpleObject) => {
  const elem = window.document.createElement("a");
  elem.href = GetRunScriptLocalLink(scriptName, params);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
  const notification: INotification = {
    id: +new Date(),
    text: "Opening Desktop Client...",
  };

  const cardTimeOutTimer = setTimeout(() => {
    ModalRef.hideNotification(notification.id);
    clearTimeout(cardTimeOutTimer);
  }, 10000);
  ModalRef.showNotification(notification);
};

export const OpenRecord = (refName: string, id: number) => {
  RunScriptLocal("ShowRecordCard", { ReferenceType: refName, RecordID: id });
};

export const RunScriptAsync = async (
  scriptName: string,
  params: simpleObject,
  modal?: boolean,
  abortSignal?: AbortSignal,
  supressErrorHandler?: boolean
) => {
  let abortHandler: () => void = () => {};
  try {
    if (modal) ModalRef.startProcessing();
    const promise = api.runScript(
      scriptName,
      params || {},
      supressErrorHandler ? { supressErrorHandler } : undefined
    );
    abortHandler = () => {
      if (promise.isCancelled() || promise.isResolved() || promise.isRejected())
        return;
      promise.cancel();
    };
    abortSignal?.addEventListener("abort", abortHandler);
    return await promise;
  } finally {
    abortSignal?.removeEventListener("abort", abortHandler);
    if (modal) ModalRef.stopProcessing();
  }
};

window.helpers.runScriptAsync = function (
  scriptName: string,
  params: simpleObject,
  modal?: boolean,
  abortSignal?: AbortSignal
) {
  var defer = $ && $.Deferred();
  RunScriptAsync(scriptName, params, modal, abortSignal)
    .then((result) => {
      defer.resolve(result);
    })
    .catch((error: any) => {
      defer.reject(error);
    });
  return defer.promise();
};

window.helpers.openRecord = function (refName: string, id: number) {
  OpenRecord(refName, id);
};

window.helpers.runScriptLocal = function (
  scriptName: string,
  params?: simpleObject
) {
  RunScriptLocal(scriptName, params);
};
