import { getSQLData } from "../helpers/queries";
import { ICurrentUserInfo } from "./interfaces";
import { showSomeError } from "../helpers/helpers";
import { IntegrationWithDesktop, settingsStorage } from "../helpers/settings";

class UserInfo {
  private info: ICurrentUserInfo | null = null;
  private isFinancialDataAllow: boolean | null = null;
  private abortController = new AbortController();

  setInfo = (info: ICurrentUserInfo | null) => {
    this.info = info;
  };

  getInfo = async () => {
    if (!this.info) {
      await this.LoadUserInfo();
    }
    return this.info;
  };

  getIsFinancialDataAllow = async () => {
    if (this.isFinancialDataAllow === null) {
      await this.LoadIsFinancialDataAllow();
    }
    return this.isFinancialDataAllow;
  };

  private LoadUserInfo = async () => {
    try {
      if (this.abortController) {
        if (!this.abortController.signal.aborted) this.abortController.abort();
        this.abortController = new AbortController();
      }
      const response = await getSQLData(
        { spName: "GetCurrentUserInfo" },
        this.abortController.signal
      );
      if (!response) return;
      const [settings]: [ICurrentUserInfo] = response;
      if (!settings.IsDesktopUser)
        settingsStorage.removeForCurrentUser(IntegrationWithDesktop);
      this.setInfo(settings);
    } catch (error) {
      showSomeError(error);
    }
  };

  private LoadIsFinancialDataAllow = async () => {
    try {
      const response = await getSQLData({ spName: "IsFinancialDataAllow" });
      this.isFinancialDataAllow = response[0][0].IsFinancialDataAllow;
    } catch (e) {
      showSomeError(e);
    }
  };
}

export default new UserInfo()
