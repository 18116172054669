import FXCard from "../../Common/FXCard/FXCard";
import { IFXCardProps } from "../interfaces";
import React from "react";
import BOMCardForm from "./Content";

interface IProps extends IFXCardProps {
  bomId: number;
  refreshGrid?(): void;
}

const BOMCard = (props: IProps) => {
  const { bomId } = props;
  return (
    <FXCard
      title={"BOM"}
      onClose={() => {
        props.refreshGrid?.();
        props.finally!();
      }}
      initialWidth={1200}
      initialHeight={550}
      originalPaddings={false}
    >
      <BOMCardForm
        bomId={bomId}
        refreshGrid={props.refreshGrid}
        closeCard={props.finally!}
      />
    </FXCard>
  );
};

export default BOMCard;
