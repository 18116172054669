import { Component } from "react";
import {
  Window,
  WindowActionsEvent,
  WindowMoveEvent,
  WindowProps,
} from "@progress/kendo-react-dialogs";
import styles from "./fxcard.module.scss";

interface props extends WindowProps {
  originalPaddings?: boolean;
}

interface state {
  left: number | undefined;
  top: number | undefined;
  width: number | undefined;
  height: number | undefined;
  changingSize: boolean;
}

class FXCard extends Component<props, state> {
  initialWidth: number = window.innerWidth - window.innerWidth / 20;
  initialHeight: number = window.innerHeight - window.innerHeight / 10;

  constructor(props: props) {
    super(props);
    this.state = {
      left: undefined,
      top: undefined,
      width: undefined,
      height: undefined,
      changingSize: false,
    };
  }

  render() {
    return (
      <>
        <Window
          initialHeight={this.initialHeight}
          initialWidth={this.initialWidth}
          onResize={this.OnResize}
          onStageChange={this.OnStageChange}
          onMove={this.OnWindowMove}
          minWidth={this.props.initialWidth || 600}
          minHeight={this.props.initialHeight || 400}
          modal={true}
          {...this.props}
          className={`${this.props.className || ""} ${styles.Window}`}
          width={this.state.width}
          height={this.state.height}
          left={this.state.left}
          top={this.state.top}
        >
          {!this.props.originalPaddings && (
            <div
              className={styles.WindowContent}
              style={this.state.changingSize ? { pointerEvents: "none" } : {}}
            >
              {this.props.children}
            </div>
          )}
          {this.props.originalPaddings && this.props.children}
        </Window>
      </>
    );
  }

  OnResize = (e: WindowMoveEvent) => {
    let maxWidth = window.innerWidth - e.left;
    let width = e.width > maxWidth ? maxWidth : e.width;
    let maxHeight = window.innerHeight - e.top;
    let height = e.height > maxHeight ? maxHeight : e.height;
    this.setState({ changingSize: !e.end, width, height });
    if (e.end && this.props.onResize) this.props.onResize(e);
  };

  OnWindowMove = (e: WindowMoveEvent) => {
    let maxLeft = window.innerWidth - e.width;
    let left = e.left > maxLeft ? maxLeft : e.left;
    let maxTop = window.innerHeight - e.height;
    let top = e.top > maxTop ? maxTop : e.top;
    this.setState({ left, top });
  };

  OnStageChange = (e: WindowActionsEvent) => {
    if (this.props.onStageChange) this.props.onStageChange(e);
  };
}

export default FXCard;
