import BaseComponent from "../../../Components/BaseComponent";
import SideBar from "../../../Components/Common/SideBar/SideBar";
import VIMDetails from "../Details/VIMDetails";
import VIMList from "./VIMList";

interface props {
  isActive: boolean;
}

interface state {
  selectedVIMId: number | null;
  selectedVIMName: string | null;
}

class VIMReview extends BaseComponent<props, state> {
  listRef: any;
  detailsRef: any;

  constructor(props: props) {
    super(props);

    this.state = {
      selectedVIMId: null,
      selectedVIMName: null,
    };
  }

  render() {
    let VIMId = this.state.selectedVIMId;
    return (
      <SideBar
        btnText={this.state.selectedVIMName || ""}
        btnTextStyle={{ paddingLeft: "130px" }}
        defaultOpened={true}
        mode="overlay"
        content={
          <VIMList
            isActive={this.props.isActive}
            selectedId={this.state.selectedVIMId}
            onSelect={this.Select}
            onRefresh={this.RefreshDetails}
            ref={(ref) => {
              this.listRef = ref;
            }}
          />
        }
      >
        {this.props.isActive && (
          <VIMDetails
            key={"details" + VIMId}
            VIMId={VIMId}
            onRefresh={this.ListItemRefresh}
            onDelete={this.OnDeleteInvoice}
            ref={(ref) => {
              this.detailsRef = ref;
            }}
          />
        )}
      </SideBar>
    );
  }

  Select = (id: number | null, name: string | null) => {
    this.setState({ selectedVIMId: id, selectedVIMName: name });
  };

  ListItemRefresh = (VIMId: number) => {
    this.listRef.ExternalRefreshInvoice(VIMId);
  };

  RefreshDetails = () => {
    if (this.detailsRef && this.detailsRef.ExternalRefreshDetails)
      this.detailsRef.ExternalRefreshDetails();
  };

  OnDeleteInvoice = (VIMId: number) => {
    this.listRef.DeleteInvoice(VIMId);
  };

  Refresh = () => {
    this.listRef.RefreshVIMInvoices();
  };
}

export default VIMReview;
