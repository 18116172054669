import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./tkReview.module.scss";
import { tabId } from "../../Components/Tabs/interfaces";
import { IProcessedTC } from "./interfaces";
import TKMain from "./Main";
import TCTabsNew from "../../Components/TabsNew/TCTabsNew";
import { CardsStackRef } from "../../Components/Common/FXCard/Cards";
import { IToolbarNav } from "../../Components/TabsNew/interfaces";
import { useRefresher } from "../../core/tools/Hooks";

interface IProps {
  isActive: boolean;
}

const TKReview = (props: IProps) => {
  const listRefresher = useRefresher();
  const [selectedInfo, setSelectedInfo] = useState<{
    tc: IProcessedTC;
    initialTabId?: tabId;
  } | null>(null);
  const isASCSortRef = useRef(true);
  const onSelectTC = (
    tc: IProcessedTC | null,
    initialTabId?: tabId | undefined
  ) => {
    setSelectedInfo(tc !== null ? { tc, initialTabId } : null);
  };

  const unselect = useCallback(() => {
    setSelectedInfo(null);
    CardsStackRef.closeAllCards();
  }, [setSelectedInfo]);

  const nav = (action: "prev" | "next") => {
    const { tc } = selectedInfo || {};
    if (!tc) return null;
    const prevTC = isASCSortRef.current ? tc.prevTC : tc.nextTC;
    const nextTC = isASCSortRef.current ? tc.nextTC : tc.prevTC;
    if (action === "prev" && prevTC) setSelectedInfo({ tc: prevTC });
    if (action === "next" && nextTC) setSelectedInfo({ tc: nextTC });
  };

  const navSettings: IToolbarNav = useMemo(() => {
    const { tc } = selectedInfo || {};
    return {
      prevAction: () => nav("prev"),
      prevDisabled: !(isASCSortRef.current ? tc?.prevTC : tc?.nextTC),
      nextAction: () => nav("next"),
      nextDisabled: !(isASCSortRef.current ? tc?.nextTC : tc?.prevTC),
    };
  }, [nav, selectedInfo]);

  return (
    <div className={styles.Container}>
      <TKMain
        onSelectTC={onSelectTC}
        selectedTC={selectedInfo?.tc || null}
        onSortChange={(isACS: boolean) => (isASCSortRef.current = isACS)}
        refreshKey={listRefresher.value}
      />

      <div
        className={styles.SelectedContainer}
        style={
          !selectedInfo
            ? { display: "none", position: "absolute", zIndex: -1 }
            : undefined
        }
      >
        <TCTabsNew
          isActive={props.isActive}
          parentId={"TKAuditCover"}
          rowData={selectedInfo?.tc}
          initialTab={selectedInfo?.initialTabId}
          refreshParent={listRefresher}
          unSelect={unselect}
          nav={navSettings}
        />
      </div>
    </div>
  );
};

export default TKReview;
