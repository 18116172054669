import { injectable } from "inversify";

import { IDeviceInfoProvider } from "../core/interfaces";

@injectable()
export default class DeviceInfoProvider implements IDeviceInfoProvider {
  getUniqueId = () => "BROWSER";
  getTime = () => new Date();
  getTimeOffset = () => new Date().getTimezoneOffset() * -1; // conversion to generally accepted format
}
