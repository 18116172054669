import { useCallback, useMemo, useState } from "react";
import {
  DropDownsPopupSettings,
  DropDownTree,
  DropDownTreeChangeEvent,
  DropDownTreeExpandEvent,
  DropDownTreeFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-react-data-tools";
import { IComboboxItem } from "../../../helpers/interfaces";

interface IProps {
  opened?: boolean;
  dataAttr?: any;
  data: IDataItem[];
  placeholder: string;
  className?: string;
  loading?: boolean;
  defaultValue?: IDataItem | null;
  value?: IDataItem | null;
  disabled?: boolean;
  width?: number;
  required?: boolean;
  popupSettings?: DropDownsPopupSettings;
  // textField?: string;
  // dataItemKey?: string;
  // getData?: () => Promise<any>;
  size?: "small" | "medium" | "large" | null | undefined;

  onChange(value: IDataItem | null, dataAttr?: any): void;

  itemRender?(
    li: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    itemProps: ListItemProps
  ): React.ReactNode | undefined;
}

interface IDataItem extends IComboboxItem {
  ParentId?: number | string | null;
  Level: number;
}

interface ITreeItem extends IDataItem {
  items?: ITreeItem[];
  expanded?: boolean;
  parentNames?: string;
}

const FilterDropdownTree = (props: IProps) => {
  const [filter, setFilter] = useState("");

  const treeData = useMemo(() => {
    const rareData = props.data;
    const levels: IDataItem[][] = [];
    for (let i = 1; i < 5; i++) {
      const levelArr = rareData.filter((item) => item.Level === i);
      if (levelArr.length) {
        levels.push(levelArr);
      } else {
        break;
      }
    }

    const getItems = (parentItem: ITreeItem, levels: IDataItem[][]) => {
      const childLevelItems = levels[parentItem.Level];
      if (!childLevelItems?.length) return undefined;

      const items = childLevelItems
        .filter((subItem) => subItem.ParentId === parentItem.Id)
        .map((subItem) => {
          const treesubItem: ITreeItem = {
            ...subItem,
            expanded: true,
            parentNames:
              (parentItem.parentNames ? parentItem.parentNames + " " : "") +
              parentItem.Name,
          };
          treesubItem.items = getItems(treesubItem, levels);
          return treesubItem;
        });
      return items;
    };

    const result: ITreeItem[] = [];
    if (levels.length) {
      for (let item of levels[0]) {
        result.push({
          ...item,
          items: getItems(item, levels),
          expanded: true,
        });
      }
    }
    return result;
  }, [props.data]);

  const filteredTreeData = useMemo(() => {
    if (!filter) return treeData;
    return filterBy(
      treeData,
      [
        {
          logic: "or",
          filters: [
            {
              value: filter,
              field: "Name",
              operator: "contains",
            },
            {
              value: filter,
              field: "parentNames",
              operator: "contains",
            },
          ],
        },
      ],
      "items"
    );
  }, [treeData, filter]);

  const onFilterChange = useCallback(
    (e: DropDownTreeFilterChangeEvent) => {
      const filter = e.filter.value;
      setFilter(filter);
    },
    [setFilter]
  );

  const OnChange = useCallback(
    (e: DropDownTreeChangeEvent) => {
      const value = e.value;
      if (!value) {
        props.onChange(value, props.dataAttr);
        return;
      }

      const { selected, items, expanded, parentNames, ...selectedItemFields } =
        e.value;
      props.onChange({ ...selectedItemFields }, props.dataAttr);
    },
    [props.onChange, props.dataAttr]
  );

  const OnExpandChange = useCallback((event: DropDownTreeExpandEvent) => {
    event.item.expanded = !event.item.expanded;
  }, []);

  return (
    <>
      <DropDownTree
        className={props.className}
        style={{ width: props.width }}
        placeholder={props.placeholder}
        data={filteredTreeData}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={OnChange}
        textField={"Name"}
        dataItemKey={"Id"}
        selectField={"selected"}
        expandField={"expanded"}
        onExpandChange={OnExpandChange}
        filterable={true}
        onFilterChange={onFilterChange}
        filter={filter}
        disabled={!!props.disabled}
        loading={/*this.state.isLoading || */ props.loading}
        opened={props.opened}
        required={props.required}
        popupSettings={{
          popupClass: "dropdowntree-scroll-popup",
          ...(props.popupSettings || {}),
        }}
        size={props.size}
      />
    </>
  );
};

export default FilterDropdownTree;
