import DashboardGrid from "../Components/Dashboard/Grid";
import BPTabs from "../Components/Tabs/BPTabs";
import { simpleObject } from "../helpers/interfaces";
import {
  IColumnSetting,
  IFilterSetting,
} from "../Components/Dashboard/interfaces";
import OpenCardLink from "../Components/OpenCardLink";
import BaseComponent from "../Components/BaseComponent";

const FILTERS: Array<IFilterSetting> = [
  {
    id: "filterCustomer",
    placeholder: "Filter by Customer",
    idColumn: "CustomerID",
    labelColumn: "CustomerName",
    type: "combobox",
    toolbarRow: 1,
  },
  {
    id: "filterBPO",
    placeholder: "Filter by Owner",
    idColumn: "BPOID",
    labelColumn: "BPOName",
    type: "combobox",
    toolbarRow: 1,
  },
  {
    id: "filterMarket",
    placeholder: "Filter by Market",
    idColumn: "MarketID",
    labelColumn: "MarketName",
    type: "combobox",
    toolbarRow: 1,
  },
  {
    id: "filterProfitCenter",
    placeholder: "Filter by Profit Center",
    idColumn: "ProfitCenterID",
    labelColumn: "ProfitCenterName",
    type: "combobox",
    toolbarRow: 2,
  },
  {
    id: "filterClass",
    placeholder: "Filter by Class",
    idColumn: "ClassID",
    labelColumn: "ClassName",
    type: "combobox",
    toolbarRow: 2,
  },
  {
    id: "filterBPDate",
    placeholder: "Show data on",
    type: "date",
    serverFilter: {
      param: "date",
    },
    width: 170,
    toolbarRow: 2,
  },
  {
    id: "hasWIP",
    label: "Has WIP",
    type: "checkbox",
    defValue: true,
    serverFilter: {
      param: "hasWIP",
    },
    toolbarRow: 1,
  },
  {
    id: "confirmedOnly",
    label: "Confirmed Only",
    type: "checkbox",
    defValue: false,
    serverFilter: {
      param: "confirmedOnly",
    },
    toolbarRow: 1,
  },
  {
    id: "hasBudget",
    idColumn: "PlannedRevenueBudget",
    label: "Has Budget",
    type: "checkbox",
    defValue: true,
    filterByCustom: function (item: simpleObject, checkBoxValue: boolean) {
      if (!checkBoxValue) return true;
      return (
        item.BudgetHrsConsumed !== null ||
        item.PlannedRevenueBudget !== null ||
        item.PlannedSpendFromBudget !== null
      );
    },
    toolbarRow: 1,
  },
];

interface state {}

class WIPDashboard extends BaseComponent<{ isActive: boolean }, state> {
  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={this.COLUMNS}
        filters={FILTERS}
        getData={this.LoadGridData}
        saveSettings={{ prefix: "wip", saveFilters: true }}
        excelFileName="WIP.xlsx"
        getRowKey={(dataItem: simpleObject) => dataItem.BPID}
        selectSettings={{
          Component: BPTabs,
          pageId: "WIPDashboard",
          componentProps: { doNotSaveFilters: true },
        }}
        initRefresh={this.InitRefresh}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        modeSettings={{
          modeType: "columns",
          id: "wipDefaultColumns",
          name: "Full data",
          columns: this.FULL_COLUMNS,
        }}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_WIP",
      params: filters,
    });
    return result[0];
  };

  Refresh = () => {
    this.GridRefresh();
  };

  COLUMNS: Array<IColumnSetting> = [
    {
      field: "RowNum",
      title: "#",
      type: "number",
      gridWidth: 40,
      filterable: false,
      columnMenu: false,
      aggregate: "count",
      locked: true,
    },
    {
      field: "ProjectName",
      title: "Project",
      type: "string",
      gridWidth: 200,
      locked: true,
      format: {
        type: "string",
        fieldId: "ProjectID",
      },
    },
    {
      field: "BPNum",
      title: "Build Plan Number",
      type: "string",
      gridWidth: 200,
      format: {
        type: "string",
        fieldId: "BPID",
        customFormat: true,
        getCustomFormat: (dataItem) => (
          <OpenCardLink
            text={dataItem.BPNum}
            dataAttr={dataItem.BPID}
            refName={"FSMBuildPlans"}
            refresh={this.Refresh}
          />
        ),
      },
      locked: true,
    },
    {
      title: "Planned and Current Revenue (Totals)",
      type: "parent",
      columns: [
        {
          field: "PlannedRevenueBudget",
          title: "Budget",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Total Planned Revenue from Budget Dashboard",
          aggregate: "sum",
        },
        {
          field: "CurrentRevenue",
          title: "Revenue",
          type: "number",
          format: {
            type: "currency",
          },
          aggregate: "sum",
          headerHint: "Total of All Build Plan Invoices",
        },
      ],
    },
    {
      title: "Planned and Current Spend (Totals)",
      type: "parent",
      columns: [
        {
          field: "PlannedSpendFromBudget",
          title: "Budget",
          type: "number",
          format: {
            type: "currency",
          },
          aggregate: "sum",
          headerHint: "Current Planned Spend from Budget Dashboard",
        },
        {
          field: "ActualSpend",
          title: "Current",
          type: "number",
          format: {
            type: "currency",
          },
          aggregate: "sum",
          headerHint: "Total Actual Spend from Budget Dashboard",
        },
        {
          field: "ActualSpendDevidedPlannedSpendPerc",
          title: "% Spent",
          type: "number",
          format: {
            type: "percent",
          },
          headerHint: "% of Budget Spent",
        },
      ],
    },
    {
      field: "EstimatedCompletion",
      title: "EC, %",
      type: "number",
      format: {
        type: "percent",
      },
      headerHint: "Estimated Completion, %, as Noted in Build Plan",
      highlight: true,
    },
    {
      title: "Adjusted Baseline, Completion %",
      type: "parent",
      columns: [
        {
          field: "AdjustedSpend",
          title: "Spend",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Planned Spend * EC, %",
          highlight: true,
        },
        {
          field: "AdjustedRevenue",
          title: "Revenue",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Planned Revenue * EC, %",
          highlight: true,
        },
      ],
    },
    {
      title: "Variance, Completion %",
      type: "parent",
      columns: [
        {
          field: "VarianceUnderSpend",
          title: "O/U Spend",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          aggregate: "sum",
          headerHint: "Total Spend - Adjusted Spend",
        },
        {
          field: "VarianceUnderBilled",
          title: "O/U Billed",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          aggregate: "sum",
          headerHint: "Current Revenue - Adjusted Revenue",
        },
      ],
    },
    {
      field: "CurrentWIP",
      title: "Current WIP",
      type: "number",
      format: {
        type: "currency",
      },
      aggregate: "sum",
      headerHint: "Variance Under Spend - Variance Under Billed",
      highlight: true,
    },
  ];
  FULL_COLUMNS: Array<IColumnSetting> = [
    {
      field: "RowNum",
      title: "#",
      type: "number",
      gridWidth: 40,
      filterable: false,
      columnMenu: false,
      aggregate: "count",
      locked: true,
    },
    {
      field: "ProjectName",
      title: "Project",
      type: "string",
      gridWidth: 200,
      locked: true,
      format: {
        type: "string",
        fieldId: "ProjectID",
      },
    },
    {
      field: "BPNum",
      title: "Build Plan Number",
      type: "string",
      gridWidth: 200,
      format: {
        type: "string",
        fieldId: "BPID",
        customFormat: true,
        getCustomFormat: (dataItem) => (
          <OpenCardLink
            text={dataItem.BPNum}
            dataAttr={dataItem.BPID}
            refName={"FSMBuildPlans"}
            refresh={this.Refresh}
          />
        ),
      },
      locked: true,
    },
    {
      field: "BPStartDate",
      title: "Start Date",
      type: "date",
      locked: true,
      format: {
        type: "date",
      },
    },
    {
      field: "BPCompletedDate",
      title: "Completed Date",
      type: "date",
      locked: true,
      format: {
        type: "date",
      },
    },
    {
      title: "Planned and Current Revenue (Totals)",
      type: "parent",
      columns: [
        {
          field: "RevenueSalesQuote",
          title: "Sales Quote",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Total of All Quotes in Build Plan",
        },
        {
          field: "RevenueCustomerPOs",
          title: "Customer POs",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Total of All PO Line Items Associated to Build Plan",
        },
        {
          field: "PlannedRevenueBudget",
          title: "Budget",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Total Planned Revenue from Budget Dashboard",
          aggregate: "sum",
        },
        {
          field: "CurrentRevenue",
          title: "Revenue",
          type: "number",
          format: {
            type: "currency",
          },
          aggregate: "sum",
          headerHint: "Total of All Build Plan Invoices",
        },
      ],
    },
    {
      title: "PO Variance",
      type: "parent",
      columns: [
        {
          field: "CustomerPOsLessSalesQuote",
          title: "Sales Quote",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          headerHint: "Customer POs - Sales Quote",
        },
        {
          field: "CustomerPOsLessPlannedBudget",
          title: "Budget",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          headerHint: "Customer POs - Budget",
        },
      ],
    },
    {
      title: "Planned and Current Spend (Totals)",
      type: "parent",
      columns: [
        {
          field: "PlannedSpendFromBudget",
          title: "Budget",
          type: "number",
          format: {
            type: "currency",
          },
          aggregate: "sum",
          headerHint: "Current Planned Spend from Budget Dashboard",
        },
        {
          field: "ActualSpend",
          title: "Current",
          type: "number",
          format: {
            type: "currency",
          },
          aggregate: "sum",
          headerHint: "Total Actual Spend from Budget Dashboard",
        },
        {
          field: "ActualSpendDevidedPlannedSpendPerc",
          title: "% Spent",
          type: "number",
          format: {
            type: "percent",
          },
          headerHint: "% of Budget Spent",
        },
        {
          field: "PlannedSpendLessActualSpend",
          title: "Remaining",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          aggregate: "sum",
          headerHint: "Budget - Current",
        },
      ],
    },
    {
      field: "Margin",
      title: "PGM, $",
      type: "number",
      format: {
        type: "currency",
      },
      headerHint:
        "Planned Gross Margin, $ = Planned Budget Revenue - Planned Budget Spend",
    },
    {
      field: "MarginPerc",
      title: "PGM, %",
      type: "number",
      format: {
        type: "percent",
      },
      headerHint:
        "Planned Gross Margin, % = (Planned Budget Revenue - Planned Budget Spend) / Planned Budget Revenue",
    },
    {
      field: "BudgetHrsConsumed",
      title: "BHC, %",
      type: "number",
      format: {
        type: "percent",
      },
      headerHint:
        "Budget Hours Consumed, % = (Actual Labor Hours / Budget Labor Hours) * 100 \n	BHC always = 100%, if Actual Labor Hours > Budget Labor Hours",
    },
    {
      field: "EstimatedCompletion",
      title: "EC, %",
      type: "number",
      format: {
        type: "percent",
      },
      headerHint: "Estimated Completion, %, as Noted in Build Plan",
      highlight: true,
    },
    {
      title: "Adjusted Baseline, Completion %",
      type: "parent",
      columns: [
        {
          field: "AdjustedSpend",
          title: "Spend",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Planned Spend * EC, %",
          highlight: true,
        },
        {
          field: "AdjustedRevenue",
          title: "Revenue",
          type: "number",
          format: {
            type: "currency",
          },
          headerHint: "Planned Revenue * EC, %",
          highlight: true,
        },
      ],
    },
    {
      title: "Variance, Completion %",
      type: "parent",
      columns: [
        {
          field: "VarianceUnderSpend",
          title: "O/U Spend",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          aggregate: "sum",
          headerHint: "Total Spend - Adjusted Spend",
        },
        {
          field: "VarianceUnderBilled",
          title: "O/U Billed",
          type: "number",
          format: {
            type: "currency",
            positiveColor: "green",
            negativeColor: "red",
          },
          aggregate: "sum",
          headerHint: "Current Revenue - Adjusted Revenue",
        },
      ],
    },
    {
      field: "CurrentWIP",
      title: "Current WIP",
      type: "number",
      format: {
        type: "currency",
      },
      aggregate: "sum",
      headerHint: "Variance Under Spend - Variance Under Billed",
      highlight: true,
    },
    {
      field: "PreviousMonthClosedWIP",
      title: "Previous Month Closed WIP",
      type: "number",
      format: {
        type: "currency",
      },
      aggregate: "sum",
      headerHint: "Variance Under Spend - Variance Under Billed",
      highlight: true,
    },
    {
      field: "ChangeToDateWIP",
      title: "Change to date WIP",
      type: "number",
      format: {
        type: "currency",
      },
      aggregate: "sum",
      headerHint: "Current WIP - Previous Month Closed WIP",
      highlight: true,
    },
  ];

  GridRefresh = () => {};

  InitRefresh = (refresh: any) => {
    this.GridRefresh = refresh;
  };
}

export default WIPDashboard;
