import { RadioButtonProps } from "@progress/kendo-react-inputs";
import moment from "moment";
import { ModalRef } from "../../../Components/Common/Modal/Modal";
import { IComboboxItem, simpleObject } from "../../../helpers/interfaces";
import { getSQLData } from "../../../helpers/queries";
import { RunScriptAsync } from "../../../helpers/runscripts";
import {
  IDocumentItem,
  IHistoryItem,
  IUpdateIncludedParams,
} from "./interfaces";

import CLPMSettings from "../../../stores/CLPMSettings";
import { CheckListStatuses, ICLMSettings } from "../../../stores/interfaces";
import { showSomeError } from "../../../helpers/helpers";

export const TYPES: Array<IComboboxItem> = [
  { Id: "Documents", Name: "Documents" },
  { Id: "PhotoAndVideo", Name: "Photo" },
];

export const HAS_MEDIA_LIST: Array<IComboboxItem> = [
  {
    Id: "Yes",
    Name: "Yes",
  },
  {
    Id: "No",
    Name: "No",
  },
];

export const FIELD_DETAIL_LIST: Array<IComboboxItem> = [
  {
    Id: "null",
    Name: "No Detail",
  },
  {
    Id: "NA",
    Name: "NA",
  },
  {
    Id: "Completed",
    Name: "Completed",
  },
  {
    Id: "Has comment",
    Name: "Has comment",
  },
];

export const CommentTitle: { [key: string]: string } = {
  Rejected: "Rejection Comments",
  Approved: "Approval Comments",
  DoNotSubmit: "Do Not Submit Comments",
};

export const radioButtonsRoles: { [key: string]: Array<RadioButtonProps> } = {
  Reviewer: [
    {
      label: "Rejected",
      value: "Rejected",
      checked: false,
    },
    {
      label: "Reviewed",
      value: "Reviewed",
      checked: true,
    },
    {
      label: "Do Not Submit",
      value: "DoNotSubmit",
      checked: false,
    },
    {
      label: "Approved",
      value: "Approved",
      checked: false,
    },
  ],
  Customer: [
    {
      label: "Rejected",
      value: "CustomerRejected",
      checked: false,
    },
    {
      label: "Approved",
      value: "Approved",
      checked: false,
    },
  ],
};

export const IsDisableChangeIncluded = (
  dataItem: IDocumentItem,
  settings: ICLMSettings
) => {
  return dataItem.isCompleted || settings.IsCustomer || !!dataItem.resultId;
};

export const IsDisableEditStatus = (
  dataItem: IDocumentItem,
  settings: ICLMSettings
) => {
  let disabledEditStatus = false;
  if (
    (settings.IsReviewer && dataItem.status === "InProgress") ||
    (settings.IsSubmitter &&
      dataItem.status !== "InProgress" &&
      dataItem.status !== "Pending")
  )
    disabledEditStatus = true;

  return disabledEditStatus;
};

export const GetMyCommentsTitle = (
  dataItem: IDocumentItem,
  settings: ICLMSettings
) => {
  if (settings.IsSubmitter || !CommentTitle[dataItem.status])
    return "My Comments";
  return CommentTitle[dataItem.status];
};

export const mobileActionIcons = {
  DeleteResult: "mdi mdi-trash-can-outline",
  UploadVersion: "mdi mdi-upload-outline",
  UploadResult: "mdi mdi-upload-outline",
  EditCLIComments: "mdi mdi-comment-outline",
  MyComments: "mdi mdi-comment-arrow-left-outline",
  CommentsForMe: "mdi mdi-comment-arrow-right-outline",
  ChangeStatus: "mdi mdi-radiobox-marked",
  ShowDocument: "mdi mdi-eye",
  DownloadSample: "mdi mdi-download-outline",
  ShowHistory: "mdi mdi-history",
  Include: "mdi mdi-arrow-right-bold-outline",
  Exclude: "mdi mdi-cancel",
  ResultFromVersion: "mdi mdi-text-box-plus-outline",
};

export const GetContextMenuItems = (
  settings: ICLMSettings,
  dataItem: IDocumentItem,
  isMobile?: boolean
) => {
  let items: Array<IComboboxItem> = [];

  if (settings.IsReviewer || settings.IsSubmitter) {
    if (dataItem.resultId) {
      if (settings.IsReviewer) {
        items.push({ Id: "DeleteResult", Name: "Delete Result" });
      }
      if (
        !settings.IsSubmitter ||
        dataItem.status === "Rejected" ||
        dataItem.status === "InProgress"
      ) {
        items.push({ Id: "UploadVersion", Name: "Upload New Version" });
      }
    }
    items.push({ Id: "UploadResult", Name: "Upload Result" });
    if (dataItem.sampleExt) {
      items.push({ Id: "DownloadSample", Name: "Download Sample" });
    }

    if (isMobile) {
      if (dataItem.comments)
        items.push({
          Id: "EditCLIComments",
          Name: "Checklist Comments",
        });
    } else if (settings.IsReviewer) {
      items.push({ Id: "EditCLIComments", Name: "Edit Comments" });
    }
  }
  return items;
};

export const GetMobileMenuItems = (
  settings: ICLMSettings,
  dataItem: IDocumentItem,
  fromPreview?: boolean,
  fileId?: number
) => {
  let hasResult = dataItem.resultId !== null;
  const items = GetContextMenuItems(settings, dataItem, true);
  if (hasResult) {
    items.push({
      Id: "MyComments",
      Name: GetMyCommentsTitle(dataItem, settings),
    });
    if (GetCommentForMe(dataItem, settings))
      items.push({
        Id: "CommentsForMe",
        Name: "Comments for Me",
      });
    items.push({ Id: "ShowHistory", Name: "Show History" });
  }
  if (!fromPreview) {
    items.push({ Id: "ShowDocument", Name: "Show Result" });

    if (
      (settings.IsReviewer || settings.IsCustomer) &&
      !IsDisableChangeIncluded(dataItem, settings)
    ) {
      if (dataItem.included)
        items.push({
          Id: "Exclude",
          Name: "Exclude Checklist Item",
        });
      else items.push({ Id: "Include", Name: "Include Checklist Item" });
    }
  }
  if (
    fromPreview &&
    fileId !== undefined &&
    settings.IsReviewer &&
    !IsDisabledResultFromVersion(dataItem, fileId || null)
  ) {
    items.push({
      Id: "ResultFromVersion",
      Name: "Create Result from Version",
    });
  }
  return items;
};

export const IsDisabledResultFromVersion = (
  dataItem: IDocumentItem,
  currentFileId: number | null
) => {
  return (
    dataItem.files.length < 2 ||
    (currentFileId !== null && dataItem.approvedFileId === currentFileId)
  );
};

export const GetCommentForMe = (
  dataItem: IDocumentItem,
  settings: ICLMSettings
) => {
  if (!settings.IsReviewer) return dataItem.reviewerComment;
  else if (dataItem.status == "CustomerRejected")
    return dataItem.customerComment;
  else return dataItem.crewComment;
};

export const GetMyComment = (
  dataItem: IDocumentItem,
  settings: ICLMSettings
) => {
  if (settings.IsReviewer) return dataItem.reviewerComment || "";
  else if (settings.IsCustomer) return dataItem.customerComment || "";
  else return dataItem.crewComment || "";
};

export const UpdateCliComments = async (
  comments: string,
  dataItem: IDocumentItem
) => {
  await RunScriptAsync("CLI_UpdateComments", {
    CLID: dataItem.clId,
    CLIID: dataItem.cliId,
    Comments: comments,
  });
};

export const LoadHistory = async (ResultID: number) => {
  try {
    let USER_ROLES = CLPMSettings.getUserRolesLib();
    let result: any = await getSQLData({
      spName: "CLPM_GetChecklistResultHistory",
      params: { ResultID },
    });
    result[0].forEach((entry: IHistoryItem) => {
      entry.d = entry.d ? moment(entry.d).format("L LT") : "";
      let src = "System";
      switch (entry.s) {
        case "R":
          src = USER_ROLES.REVIEWER;
          break;
        case "C":
          src = USER_ROLES.CUSTOMER;
          break;
        case "S":
          src = USER_ROLES.SUBMITTER;
          break;
      }
      entry.src = src;
    });
    return result[0];
  } catch (e: any) {
    showSomeError(e);
  }
};

export const SendCliIncluded = async (queryParams: IUpdateIncludedParams) => {
  await RunScriptAsync("CLPM_SetChecklistItemIncluded", queryParams);
};

export const CreateResultFromVersion = async (FileID: number) => {
  return await RunScriptAsync("CLPM_TransformFileToChecklistResult", {
    FileID,
  });
};

export const SaveMyComment = async (
  dataItem: IDocumentItem,
  comment: string,
  settings: ICLMSettings,
  abortSignal?: AbortSignal | undefined
) => {
  let fieldName: keyof IDocumentItem = "reviewerComment";
  if (settings.IsReviewer) fieldName = "reviewerComment";
  else if (settings.IsCustomer) fieldName = "customerComment";
  else if (settings.IsSubmitter) fieldName = "crewComment";

  let newValue = comment;
  let status = dataItem.status;
  if (dataItem) {
    let prevValue = dataItem[fieldName] || "";
    if (prevValue != newValue) {
      dataItem[fieldName] = newValue;
      if (status === "Rejected" && settings.IsReviewer) {
        ModalRef.showDialog({ text: "Rejection Comments Sent To Field" });
      }
    }
  }

  await UpdateResultStatus(
    dataItem.resultId!,
    dataItem.status,
    comment,
    dataItem.approvedFileId || undefined,
    abortSignal
  );
};

export const OnChangeStatus = async (
  newStatus: CheckListStatuses,
  dataItem: IDocumentItem,
  currentFileId: number | null = null,
  abortSignal?: AbortSignal | undefined
) => {
  let settings = await CLPMSettings.getSettings();
  if (settings.IsReviewer) {
    if (
      newStatus == "Rejected" ||
      newStatus == "Approved" ||
      newStatus == "DoNotSubmit"
    ) {
      dataItem.reviewerComment = "";
    }
    dataItem.approvedFileId = newStatus == "Approved" ? currentFileId : null;
  } else if (settings.IsCustomer) {
    dataItem.customerComment = "";
  }
  dataItem.status = newStatus;

  let comment: string | null = "";
  let approvedFileId;
  if (settings.IsSubmitter) {
    comment = dataItem.crewComment;
  } else {
    comment = settings.IsReviewer
      ? dataItem.reviewerComment
      : dataItem.customerComment;
    approvedFileId = dataItem.approvedFileId
      ? +dataItem.approvedFileId
      : undefined;
  }

  if (newStatus === "Approved" && !approvedFileId) {
    approvedFileId = currentFileId;
    if (!approvedFileId) return;
  }
  await UpdateResultStatus(
    dataItem.resultId!,
    newStatus,
    comment || "",
    approvedFileId,
    abortSignal
  );
};

export const UpdateResultStatus = async (
  resultId: number,
  Stage: CheckListStatuses,
  Comment: string,
  approvedFileId?: number,
  abortSignal?: AbortSignal | undefined
) => {
  let params: simpleObject = {
    ID: resultId,
    Stage,
    Comment,
  };
  if (approvedFileId) params.ApprovedFileId = approvedFileId;
  await RunScriptAsync("CLPM_UpdateResult", params, undefined, abortSignal);
};
