import { Popover } from "@progress/kendo-react-tooltip";
import React, { useCallback, useRef, useState } from "react";
import OpenCardLink from "../../../OpenCardLink";
import { IAdjustedTimeLineItem } from "../interfaces";
import styles from "./adjustments.module.scss";
import debounce from "lodash.debounce";

const Adjustment = ({ data }: { data: IAdjustedTimeLineItem }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isShownPopover, setIsShownPopover] = useState(false);
  const insidePopoverContent = useRef(false);
  const insideTE = useRef(false);

  const onTEMouseEvent = useCallback(
    debounce((e: React.MouseEvent<HTMLDivElement>) => {
      const value = e.type === "mouseover";
      insideTE.current = value;
      if (!value && insidePopoverContent.current) return;
      setIsShownPopover(value);
    }, 200),
    [setIsShownPopover, insidePopoverContent.current]
  );

  const onContentMouseEvent = useCallback(
    debounce((e: React.MouseEvent<HTMLDivElement>) => {
      const value = e.type === "mouseover";
      insidePopoverContent.current = value;
      if (!insideTE.current && !value) {
        setIsShownPopover(false);
      }
    }, 100),
    [setIsShownPopover]
  );

  const isLunch = data.costTypeCode === "LUNCH";
  return (
    <>
      <div className={styles.TE}>
        <div className={styles.TESpace}></div>
        <span
          title="Open Time Entry"
          className={`${styles.TECostType} ${
            !isLunch && !data.approvedDuration ? styles.thin : ""
          }`}
          style={{ color: data.color }}
        ></span>
        <div className={styles.TEContent}>
          <div className={styles.TEText}>
            <span className={styles.TEDuration}>
              {data.approvedDurationString}
            </span>
            <Allocation data={data} />
          </div>
        </div>
        <div
          ref={elementRef}
          onMouseOver={onTEMouseEvent}
          onMouseOut={onTEMouseEvent}
          className={styles.TERowOnHoverLayer}
        ></div>
        <Popover
          animate={false}
          show={isShownPopover}
          position={"left"}
          anchor={elementRef.current}
        >
          <PopoverContent data={data} onMouseEvent={onContentMouseEvent} />
        </Popover>
      </div>
    </>
  );
};

const PopoverContent = ({
  data,
  onMouseEvent,
}: {
  data: IAdjustedTimeLineItem;
  onMouseEvent(e: React.MouseEvent<HTMLDivElement>): void;
}) => {
  return (
    <div
      className={styles.TEDetailsContent}
      onMouseOver={onMouseEvent}
      onMouseOut={onMouseEvent}
    >
      <div>
        {data.startFormatted}&nbsp;-&nbsp;{data.finishFormatted}
      </div>
      <div>Cost Type: {data.costTypeName || ""}</div>
      <div>Duration: {data.actualDurationString}</div>
      <div>Approved: {data.approvedDurationString}</div>
      {!!data.manualAllocation && !!data.manualAllocation.length && (
        <>
          <div style={{ marginTop: 8 }}>Allocation:</div>
          <div className={styles.TEDetails}>
            {data.manualAllocation.map((item) => {
              return (
                <div
                  key={`${item.rowNumber}_${item.workOrderId}`}
                  className={styles.TEDetailsRow}
                >
                  <span>{item.percentage} % -&nbsp;</span>
                  <OpenCardLink
                    text={item.workOrderName}
                    dataAttr={item.workOrderId}
                    refName="FSMWorkOrders"
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const Allocation = ({ data }: { data: IAdjustedTimeLineItem }) => {
  const isLunch = data.costTypeCode === "LUNCH";
  const allocationLength = data.manualAllocation?.length || 0;
  if (isLunch) {
    return (
      <span>
        {data.costTypeCode} ({data.actualDurationString})
      </span>
    );
  } else if (allocationLength === 1) {
    const WO = data.manualAllocation![0];
    return (
      <div className={styles.WOAllocationBox}>
        <span className={styles.WOLinkNumber}>{WO.workOrderNumber}</span>
        <span className={styles.WOLinkName}>{WO.workOrderName}</span>
      </div>
    );
  } else if (allocationLength > 1) {
    return <span>Multiple Work Orders ({allocationLength})</span>;
  }
  return <span></span>;
};

export default Adjustment;
