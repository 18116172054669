import { simpleObject } from "../../helpers/interfaces";
import { getSQLData } from "../../helpers/queries";
import { IServerRelatedDocument } from "./interfaces";

export const LoadDocuments = async (SourceId: number) => {
  const documents: Array<IServerRelatedDocument> = [];
  const settings: { IsBuildPlan: boolean } = { IsBuildPlan: false };
  try {
    let result = await getSQLData({
      spName: "DB_DocumentsBySourceId",
      params: { SourceId },
    });
    const documents: Array<IServerRelatedDocument> = result[0];
    const settings: { IsBuildPlan: boolean } = result[1][0];
    return { documents, settings };
  } catch (e) {
    return { documents, settings };
  }
};

export type documentAction =
  | "Delete"
  | /* 'Send' | */ "Upload"
  | "Download"
  | "ChangeKind";

export const groupFieldName: simpleObject = {
  SrcObjType: "SrcObjTypeName",
  SrcObjName: "SrcObjName",
  ObjectSubGroupId: "ObjectSubGroupName",
};

export const GetDataItemFromGroup = (data: any) => {
  let dataItem;
  let group = data;
  while (!dataItem) {
    if (group.items) group = group.items[0];
    else dataItem = group;
  }
  return dataItem;
};

export const GetDocumentCountFromGroup = (data: any) => {
  let count = 0;

  let groupItems = data.items;
  if (groupItems[0].items) {
    for (let subGroup of groupItems) {
      count += subGroup.items.length;
    }
  } else {
    count = groupItems.length;
  }
  return count;
};

