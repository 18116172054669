import FXCard from "../Common/FXCard/FXCard";
import { IOpenSiteCardProps } from "./interfaces";

import formStyles from "./card.module.scss";
import BaseComponent from "../BaseComponent";

import { OpenRecord } from "../../helpers/runscripts";
import OpenCardIconLink from "./OpenCardIconLink";
import React from "react";
import { GetReferenceRecordName } from "../../helpers/queries";
import SiteView from "../Map/SiteCardView";

import styles from "../Map/ObjectMap/objectmap.module.scss";
import { showSomeError } from "../../helpers/helpers";

interface state {
  lastResizeCard: number;
  siteName: string;
}

class SiteCard extends BaseComponent<IOpenSiteCardProps, state> {
  constructor(props: IOpenSiteCardProps) {
    super(props);
    this.state = {
      lastResizeCard: +new Date(),
      siteName: "",
    };
  }

  componentDidMount(): void {
    if (this.props.siteId) this.GetSiteName();
  }

  render() {
    return (
      <FXCard
        title={this.renderCardTitle()}
        onClose={this.Close}
        initialWidth={730}
        initialHeight={750}
        originalPaddings={false}
        onResize={this.OnCardResize}
        onStageChange={this.OnCardResize}
      >
        <SiteView
          siteId={this.props.siteId}
          isModalCard={true}
          closeModalCard={this.Close}
          lastResizeModalCard={this.state.lastResizeCard}
          {...this.props}
        />
      </FXCard>
    );
  }

  renderCardTitle = () => {
    let content: any;
    if (!this.props.siteId) content = "New Site";
    else {
      content = (
        <>
          <OpenCardIconLink onClick={this.OpenSite} title="Open Site Card" />
          <span className={styles.HeaderTitle}>
            {this.state.siteName || "Loading..."}
          </span>
        </>
      );
    }
    return <span className={formStyles.HeaderTitle}>{content} </span>;
  };

  OpenSite = () => {
    if (this.props.siteId) OpenRecord("FSMSites", this.props.siteId);
  };

  GetSiteName = async () => {
    try {
      const { siteId } = this.props;
      if (!siteId) return;
      const siteName = await GetReferenceRecordName(+siteId);
      this.setState({ siteName });
    } catch (e) {
      showSomeError(e);
    }
  };

  OnCardResize = () => {
    this.setState({ lastResizeCard: +new Date() });
  };

  Close = () => {
    this.props.finally!();
  };
}

export default SiteCard;
