import { Loader } from "@progress/kendo-react-indicators";

interface IProps {
  theme?: "light";
  size?: "large" | "small";
  style?: React.CSSProperties;
  text?: string | JSX.Element;
}

export default function LoaderComponent(props: IProps) {
  let style = props.style || {};
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 99,
        background: "#fff",
        gap: 8,
        ...style,
      }}
    >
      <Loader
        themeColor={props.theme || "dark"}
        type="converging-spinner"
        size={props.size}
      />
      {!!props.text && <div>{props.text}</div>}
    </div>
  );
}
