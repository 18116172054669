import DI_TYPES from "../core/diTypes";
import { ILogger } from "../core/interfaces";
import diContainer from "../inversify.config";
import { showRequestError, showSomeError } from "../helpers/helpers";
import api from "../core/api/api";

const logger = diContainer.get<ILogger>(DI_TYPES.ILogger);
window.addEventListener("error", function (e) {
  logger.fatalException(e.error, "Unhandled Exception");
  showSomeError(e, "Unhandled Exception");
  e.preventDefault();
});

window.onerror = function (msg, url, line, col, error) {
  console.log(error, msg, url, line, col);
  const title = "Unexpected Error";
  const text = `${msg},
            url: ${url},
			line: ${line},
			col: ${col}`;
  showSomeError(error, title, text);
  // If you return true, then error alerts (like in older versions of
  // Internet Explorer) will be suppressed.
  // return true;
};

api.axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.supressErrorHandler) showRequestError(error);
    throw error;
  }
);
