import React, { useCallback, useEffect } from "react";
import { Popover } from "@progress/kendo-react-tooltip";
import styles from "./adjustmentsEdit.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { useBooleanState } from "../../../../core/tools/Hooks";
import { SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation } from "../../../../core/api/generated/conterra";

export const MultipleWo = (props: {
  workOrders: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[];
  btnText?: string;
}) => {
  const { workOrders } = props;
  const isShownPopover = useBooleanState(false);
  const anchor = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShownPopover.value) {
      document.addEventListener("click", isShownPopover.setFalse);
    } else {
      document.removeEventListener("click", isShownPopover.setFalse);
    }
    return () => {
      document.removeEventListener("click", isShownPopover.setFalse);
    };
  }, [isShownPopover.value, isShownPopover]);

  const onClickChip = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      isShownPopover.toggle();
    },
    [isShownPopover.toggle]
  );

  const renderPopover = () => {
    return (
      <Popover
        show={isShownPopover.value}
        anchor={anchor.current}
        callout={false}
        style={{ maxHeight: 400, width: 300 }}
        position={"left"}
      >
        <div>
          {workOrders.map(({ workOrderName, workOrderId, percentage }) => (
            <div key={workOrderId} className={styles.StaticWORow}>
              <div>{workOrderName}</div>
              <div>{percentage}%</div>
            </div>
          ))}
        </div>
      </Popover>
    );
  };

  return (
    <>
      <div ref={anchor} style={{ marginLeft: 2 }}>
        <Button
          iconClass={"mdi mdi-eye-outline"}
          title={"Multiple Static Allocation"}
          fillMode={"flat"}
          onClick={onClickChip}
        />
      </div>
      {renderPopover()}
    </>
  );
};
