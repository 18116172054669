import { injectable } from "inversify";
import { ILastUserService, IUserIdentity } from "../core/interfaces";

@injectable()
export class LastUserService implements ILastUserService {
  setLastUserIdentity(userIdentity: IUserIdentity) {
    const pathName = `/${userIdentity.InstanceId}/${userIdentity.UserId}`;
    if (window.location.pathname === pathName) return;
    window.location.pathname = pathName;
  }

  getLastUserIdentity(): IUserIdentity | null {
    const instanceIdFromUrl = window.location.pathname.split("/")[1];
    const userIdFromUrl = window.location.pathname.split("/")[2];
    if (instanceIdFromUrl && userIdFromUrl) {
      return {
        InstanceId: instanceIdFromUrl,
        UserId: userIdFromUrl,
      };
    }

    return null;
  }
}
