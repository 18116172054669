import { Component } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { simpleObject } from "../../helpers/interfaces";
import { formatFinancial } from "../../helpers/helpers";
import OpenCardLink from "../OpenCardLink";
import { IColumnSetting, IFormatCell } from "./interfaces";
import styles from "./dashboard.module.scss";
import moment from "moment";

interface state {}

interface props {
  gridCellProps: GridCellProps;

  getColumn(field: string): IColumnSetting | undefined;

  refresh(): any;
}

class GridCell extends Component<props, state> {
  render() {
    let props = this.props.gridCellProps;
    let format = props.format
      ? (JSON.parse(props.format) as IFormatCell)
      : undefined;
    let style: simpleObject = {};
    let inner: any = "";
    let value = props.field && props.dataItem[props.field];
    let tdTitle = "";
    if (/* value &&  */ format && (format.customColor || format.customFormat)) {
      let columnField = props.field || "";
      let column = props.field && this.props.getColumn(columnField);

      if (
        column &&
        column.format &&
        format.customColor &&
        column.format.getColor
      ) {
        let color = column.format.getColor(props.dataItem);
        if (color) style.color = color;
      }

      if (
        column &&
        column.format &&
        column.format.customFormat &&
        column.format.getCustomFormat
      ) {
        inner = column.format.getCustomFormat(props.dataItem, props.field!);
      }
    }
    let hasTooltip = false;
    if (!(format && format.customFormat)) {
      switch (format?.type) {
        case "link":
          if (value && typeof value === "object" && value instanceof Date) {
            value = moment(value).format("L");
          }
          let refName =
            format.refName ||
            (format.refNameField ? props.dataItem[format.refNameField] : "");
          let id = format.fieldId && props.dataItem[format.fieldId];
          if (value) {
            inner = (
              <OpenCardLink
                text={value}
                dataAttr={id}
                refName={refName}
                refresh={this.props.refresh}
              />
            );
          } else if (format.notAssignedValueName) {
            inner = format.notAssignedValueName;
          }
          break;

        case "icon":
          style.textAlign = "center";
          tdTitle = value;
          if (value) {
            hasTooltip = true;
            inner = (
              <span
                style={{ pointerEvents: "none" }}
                className={format.iconClass}
              ></span>
            );
          }
          break;

        case "percent":
          style.textAlign = "center";
          if (value !== null && value !== undefined)
            inner = `${value.toFixed(2)} %`;
          else inner = format.formatNull || "";
          break;

        case "number":
          style.textAlign = "center";
          if (value !== null && value !== undefined) inner = value.toFixed(2);
          else inner = format.formatNull || "";
          break;

        case "integer":
          style.textAlign = "center";
          if (value !== null && value !== undefined) inner = value.toFixed(0);
          else inner = format.formatNull || "";
          break;

        case "date":
          style.textAlign = "center";
          inner = value ? moment(value).format("L") : "";
          break;

        case "datetime":
          style.textAlign = "center";
          inner = value ? moment(value).format("MM/DD/YY LT") : "";
          break;

        case "time":
          style.textAlign = "center";
          inner = value ? moment(value).format("LTS") : "";
          break;

        case "currency":
          if (value !== null && value !== undefined) {
            inner = (
              <div className={styles.CurrencyBox}>
                <span className={styles.Currency}>$</span>
                {formatFinancial(value)}
              </div>
            );
          } else {
            inner = "";
          }
          break;

        default:
          inner += (props.field && props.dataItem[props.field]) || "";
      }
    }

    if (
      format &&
      !format.customColor &&
      (format.type === "percent" ||
        format.type === "number" ||
        format.type === "currency" ||
        format.type === "integer") &&
      value !== null &&
      value !== undefined
    ) {
      if (value >= 0 && format.positiveColor) {
        style.color = format.positiveColor;
      } else if (value < 0 && format.negativeColor) {
        style.color = format.negativeColor;
      }
    }

    if (!hasTooltip) style.pointerEvents = "none";
    return (
      <td className={`${props.className}`} style={props.style}>
        {hasTooltip && (
          <Tooltip
            anchorElement="target"
            position="left"
            style={{ maxWidth: "300px" }}
            {...(format?.tooltipProps || {})}
          >
            <div style={style} title={tdTitle}>
              {inner}
            </div>
          </Tooltip>
        )}
        {!hasTooltip && <div style={style}>{inner}</div>}
      </td>
    );
  }
}

export default GridCell
