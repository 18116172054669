import moment from "moment";
import jwtDecode from "jwt-decode";

import api from "../api/api";
import DI_TYPES from "../diTypes";
import {
  IAuthProvider,
  ISasTokenService,
  ISettingsStorage,
} from "../interfaces";
import diContainer from "../../inversify.config";
import { SasTokenAccessType } from "../api/generated/documents";

export type TParsedSasToken = {
  InstanceId: string;
  Login: string;
  SasTokenAccessType: string;
  UserId: string;
  exp: number;
  iat: number;
  nbf: number;
};

export const STORAGE_KEY_SAS_TOKEN = "STORAGE_SAS_TOKEN";

const authProvider = diContainer.get<IAuthProvider>(DI_TYPES.IAuthProvider);
const settingsStorage = diContainer.get<ISettingsStorage>(
  DI_TYPES.ISettingsStorage
);

export class SasTokenService implements ISasTokenService {
  constructor() {}

  async getSasToken(accessType: SasTokenAccessType) {
    const keyStorage = this.getKeySasToken(accessType);
    const sasTokenFromStorage = settingsStorage.get(keyStorage);

    if (sasTokenFromStorage) {
      const decodeToken: TParsedSasToken = jwtDecode(sasTokenFromStorage);
      const nowDate = moment();
      const expireTimeDate = moment(decodeToken.exp * 1000);

      if (nowDate.isAfter(expireTimeDate, "milliseconds")) {
        return this.requestAndRecordSasToken(accessType);
      }

      return sasTokenFromStorage;
    }

    return await this.requestAndRecordSasToken(accessType);
  }

  private getKeySasToken(accessType: SasTokenAccessType) {
    const instanceId = authProvider.getInstanceId() as string;
    const userId = authProvider.getUserId() as string;

    return [STORAGE_KEY_SAS_TOKEN, accessType, instanceId, userId].join("_");
  }

  private async requestAndRecordSasToken(accessType: SasTokenAccessType) {
    const keyStorage = this.getKeySasToken(accessType);
    const newSasToken = await api.documents.getSasToken({ accessType });

    settingsStorage.set(keyStorage, newSasToken);

    return newSasToken;
  }
}

export const sasTokenService = new SasTokenService();
