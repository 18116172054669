import { Button } from "@progress/kendo-react-buttons";
import { IComboboxItem, INewComboboxItem } from "../../helpers/interfaces";
import {
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
} from "@progress/kendo-react-inputs";
import FXCard from "../Common/FXCard/FXCard";
import LoaderComponent from "../Common/Loader";
import FilterCombobox from "../Common/Form/FilterCombobox";
import formStyles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import { showSomeError } from "../../helpers/helpers";
import { useBooleanState } from "../../core/tools/Hooks";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GetReferenceRecordName,
  ReferenceRecordsDataSource,
} from "../../helpers/queries";
import api from "../../core/api/api";
import {
  CreateBillOfMaterialDto,
  CreateBomType,
  EnumCreateBomType,
} from "../../core/api/generated/warehouse";
import { ISO_DATE_FORMAT } from "../../core/tools/formats";
import moment from "moment/moment";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { IsWarehouseManager } from "../../core/tools/roles";
import CardManagement from "./CardManagement";

interface props extends IFXCardProps {
  bpId?: number;

  afterCreate(): void;
}

const AddBOMCard = (props: props) => {
  const loadingBuildPlans = useBooleanState();
  const loadingDefaultWarehouse = useBooleanState();
  const processing = useBooleanState();
  const [buildPlans, setBuildPlans] = useState<IComboboxItem[]>();
  const [bp, setBP] = useState<IComboboxItem | null>();
  const [defaultWarehouse, setDefaultWarehouse] = useState<INewComboboxItem>();
  const [warehouse, setWarehouse] = useState<INewComboboxItem>();
  const [type, setType] = useState<CreateBomType>(
    EnumCreateBomType.Original as CreateBomType
  );
  const [date, setDate] = useState<Date | null>(new Date());
  const [dueDate, setDueDate] = useState<Date | null>();
  const commentsRef = useRef("");

  useEffect(() => {
    if (props.bpId) {
      loadDefaultBPWarehouse(props.bpId);
    } else {
      loadBuildPlans();
    }
  }, []);

  useEffect(() => {
    setDefaultWarehouse(undefined);
    if (bp) {
      loadDefaultBPWarehouse(+bp.Id);
    }
  }, [bp]);

  const loadDefaultBPWarehouse = useCallback(async (BuildPlanId: number) => {
    try {
      loadingDefaultWarehouse.setTrue();
      const warehouseIdStr = await api.script.whBomGetBuildPlanWarehouse({
        BuildPlanId,
      });
      if (warehouseIdStr) {
        const name = await GetReferenceRecordName(+warehouseIdStr);
        setDefaultWarehouse({ id: +warehouseIdStr, name });
      }
    } catch (e: any) {
      showSomeError(e);
    } finally {
      loadingDefaultWarehouse.setFalse();
    }
  }, []);

  const loadBuildPlans = useCallback(async () => {
    try {
      loadingBuildPlans.setTrue();
      const result: IComboboxItem[] = await ReferenceRecordsDataSource(
        "FSMBuildPlans"
      );
      setBuildPlans(result);
    } catch (e: any) {
      showSomeError(e);
    } finally {
      loadingBuildPlans.setFalse();
    }
  }, []);

  const createParams: CreateBillOfMaterialDto | null = useMemo(() => {
    const buildPlanId = props.bpId || bp?.Id;
    const warehouseId = warehouse ? warehouse.id : defaultWarehouse?.id;
    if (!buildPlanId || !warehouseId || !date || !dueDate || !type) return null;
    if (moment(dueDate).isBefore(date)) return null;
    return {
      buildPlanId: +buildPlanId,
      type,
      warehouseId: +warehouseId,
      date: moment(date).format(ISO_DATE_FORMAT),
      dueDate: moment(dueDate).format(ISO_DATE_FORMAT),
    };
  }, [props.bpId, bp, warehouse, defaultWarehouse, date, dueDate, type]);

  const createBOM = useCallback(async () => {
    if (!createParams) return;
    try {
      processing.setTrue();
      const result = await api.wh.bom.create({
        ...createParams,
        comments: commentsRef.current,
      });
      CardManagement.OpenBOMCard(result.id, props.afterCreate);
      props.afterCreate();
      props.finally?.();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [createParams]);

  const renderType = useCallback((type: CreateBomType) => {
    if (!IsWarehouseManager()) return type;
    return (
      <div style={{ gap: 8, display: "flex", flex: 1 }}>
        <div>
          <RadioButton
            name="typegroup"
            value={EnumCreateBomType.Original}
            size={"large"}
            checked={type === EnumCreateBomType.Original}
            label={EnumCreateBomType.Original}
            onChange={(event: RadioButtonChangeEvent) => setType(event.value)}
          />
        </div>
        <div>
          <RadioButton
            name="typegroup"
            value={EnumCreateBomType.Return}
            size={"large"}
            checked={type === EnumCreateBomType.Return}
            label={EnumCreateBomType.Return}
            onChange={(event: RadioButtonChangeEvent) => setType(event.value)}
          />
        </div>
      </div>
    );
  }, []);

  return (
    <FXCard
      title={"New BOM"}
      onClose={props.finally}
      initialWidth={450}
      initialHeight={370}
      originalPaddings={true}
    >
      <div className={formStyles.FormWrapper}>
        {processing.value && (
          <LoaderComponent text={"New BOM is Creating..."} />
        )}
        {!props.bpId && (
          <FilterCombobox
            placeholder="Build Plan *"
            data={buildPlans}
            value={bp}
            loading={loadingBuildPlans.value}
            onChange={setBP}
            className={formStyles.FormField}
            required={true}
          />
        )}
        <FilterCombobox
          placeholder="Warehouse"
          getData={api.sql.whBomAvailableWarehouses}
          value={warehouse || defaultWarehouse}
          onChange={setWarehouse}
          className={formStyles.FormField}
          required={true}
          dataItemKey={"id"}
          textField={"name"}
          loading={loadingDefaultWarehouse.value}
        />
        <div className={formStyles.Row} style={{ marginBottom: 4 }}>
          <div className={formStyles.FormFieldLabel}>Date:</div>
          <DatePicker
            id={"date"}
            onChange={(event: DatePickerChangeEvent) => setDate(event.value)}
            className={formStyles.Datepicker}
            defaultValue={date}
            formatPlaceholder={{
              month: "m",
              day: "d",
              year: "y",
            }}
            required={true}
          ></DatePicker>
        </div>
        <div className={formStyles.Row}>
          <div className={formStyles.FormFieldLabel}>Due Date:</div>
          <DatePicker
            id={"dueDate"}
            min={date || undefined}
            onChange={(event: DatePickerChangeEvent) => setDueDate(event.value)}
            className={formStyles.Datepicker}
            defaultValue={dueDate}
            formatPlaceholder={{
              month: "m",
              day: "d",
              year: "y",
            }}
            required={true}
          ></DatePicker>
        </div>
        <div className={`${formStyles.Row}`}>
          <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
            <div className={formStyles.FormFieldLabel}>Type:</div>
            <div>
              <div className={formStyles.ReadOnlyField}>{renderType(type)}</div>
            </div>
          </div>
        </div>
        <TextArea
          className={formStyles.TextArea}
          style={{ marginTop: 8 }}
          rows={8}
          placeholder="Comments"
          onChange={(event) => (commentsRef.current = event.value)}
        ></TextArea>
        <div className={`${formStyles.FormFooter} k-action-buttons`}>
          <Button onClick={props.finally}>Cancel</Button>
          <Button
            onClick={createBOM}
            themeColor="primary"
            disabled={!createParams}
          >
            Create
          </Button>
        </div>
      </div>
    </FXCard>
  );
};

export default AddBOMCard;
