import LoaderComponent from "../../Common/Loader";
import formStyles from "../card.module.scss";
import { showSomeError } from "../../../helpers/helpers";
import { useBooleanState } from "../../../core/tools/Hooks";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import api from "../../../core/api/api";
import {
  AddMaterialDetailsDto,
  BillOfMaterialDetailsDto,
  BillOfMaterialsStageEnum,
  BomMaterialDetailsDto,
  BomMaterialItemDetailsDto,
  KitBomMaterialDto,
  UpdateBillOfMaterialDto,
} from "../../../core/api/generated/warehouse";
import moment from "moment";
import { CONTERRA_DATE_FORMAT } from "../../../core/tools/formats";
import { ModalRef } from "../../Common/Modal/Modal";
import { MaterialItemsSelect } from "./MISelect";
import { MaterialsSelect } from "./MaterialsSelect";
import RelatedDocuments from "../../RelatedDocuments/RelatedDocuments";
import {
  bomCardTabId,
  EBomActions,
  IAvailableMaterial,
  IBOMMaterial,
} from "./interfaces";
import {
  Script_DESKTOP_WH_BOM_CreatePurchaseRequest_Request,
  SQL_WH_BOM_AvailableTemplates_Response_Materials,
} from "../../../core/api/generated/conterra";
import BomCardToolbar from "./BomCardToolbar";
import { BOMCardGeneral } from "./General";
import { BOMCardMaterials } from "./Materials";
import { SelectFromTemplate } from "./TemplateSelect";
import CardManagement from "../CardManagement";

const BOMCardForm = (props: {
  bomId: number;
  closeCard(): void;
  refreshGrid?: () => void;
}) => {
  const { bomId } = props;
  const loadingDetails = useBooleanState();
  const processing = useBooleanState();
  const [activeTab, setActiveTab] = useState<bomCardTabId>("general");
  const availableMaterialsRef = useRef<IAvailableMaterial[]>([]);
  const [bomData, setBomData] = useState<BillOfMaterialDetailsDto>();
  const [bomMaterials, setBomMaterials] = useState<IBOMMaterial[]>([]);
  const documentsInitedRef = useRef(false);

  useEffect(() => {
    LoadData(true);
  }, []);

  const LoadData = useCallback(async (init?: boolean) => {
    try {
      loadingDetails.setTrue();
      const result = await api.wh.bom.getById(bomId);
      if (result.stage.id === BillOfMaterialsStageEnum.Pending) {
        result.bomMaterials.forEach((bm) => {
          bm.total = bm.cogs * bm.quantity;
        });
      }
      if (init) await LoadMaterials(result.warehouse.id);
      setBomMaterials(
        result.bomMaterials.map((bm) => {
          const material = availableMaterialsRef.current.find(
            (aM) => aM.id === bm.material.id
          );
          return {
            ...bm,
            availableTotalQTY: material?.totalQTY || 0,
            availableWHQTY: material?.warehouseQTY || 0,
            pickQty: bm.isSnRequired ? bm.materialItems.length : 0,
          };
        })
      );
      setBomData(result);
    } catch (e: any) {
      showSomeError(e);
    } finally {
      loadingDetails.setFalse();
    }
  }, []);

  const LoadMaterials = useCallback(async (warehouseId: number) => {
    const response = await api.sql.whBomAvailableMaterials({
      warehouseId,
    });
    availableMaterialsRef.current = response.map((bm) => ({
      ...bm,
      isSNRequiredStr: bm.isSNRequired ? "Yes" : "No",
    }));
  }, []);

  const bomTotal = useMemo(() => {
    if (!bomData) return 0;
    if (bomData.stage.id !== BillOfMaterialsStageEnum.Pending) {
      return bomData.total;
    }
    return bomMaterials.reduce(
      (sum, item) => sum + item.cogs * item.quantity,
      0
    );
  }, [bomMaterials, bomData]);

  const UpdateGeneral = useCallback(
    async (data: UpdateBillOfMaterialDto) => {
      try {
        processing.setTrue();
        await api.wh.bom.update(bomId, data);
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );

  const UpdateMIs = useCallback(
    async (materialItemIds: number[]) => {
      try {
        processing.setTrue();
        await api.wh.bom.addMaterialItems(bomId, {
          materialItemIds,
        });
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );

  const UpdateMaterials = useCallback(
    async (materialDetails: AddMaterialDetailsDto[]) => {
      try {
        processing.setTrue();
        await api.wh.bom.addMaterials(bomId, {
          materialDetails,
        });
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );

  const AssignMaterialItems = useCallback(
    async (materialItemIds: number[]) => {
      try {
        processing.setTrue();
        await api.wh.bom.assignMaterialItems(bomId, {
          materialItemIds,
        });
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );

  const OnAddMaterialsClick = useCallback(() => {
    if (!bomData) return;
    let selectedMaterialsIds: number[] = [];
    ModalRef.showDialog({
      title: "Select Materials",
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            if (!selectedMaterialsIds.length) return;
            ModalRef.hideDialog();
            UpdateMaterials(
              selectedMaterialsIds.map((id) => ({
                materialId: id,
                quantity: 0,
                comment: "",
              }))
            );
          },
        },
      ],
      children: (
        <div>
          <MaterialsSelect
            allMaterials={availableMaterialsRef.current}
            bomMaterials={bomData.bomMaterials}
            onChange={(ids: number[]) => {
              selectedMaterialsIds = ids;
            }}
          />
        </div>
      ),
    });
  }, [bomData, UpdateMaterials]);

  const OnAddMaterialItemClick = useCallback(() => {
    let selectedMaterialItems: number[] = [];
    ModalRef.showDialog({
      title: "Select Material Items",
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            if (!selectedMaterialItems.length) return;
            ModalRef.hideDialog();
            UpdateMIs(selectedMaterialItems);
          },
        },
      ],
      children: (
        <div>
          <MaterialItemsSelect
            bomId={bomId}
            onChange={(ids: number[]) => {
              selectedMaterialItems = ids;
            }}
          />
        </div>
      ),
    });
  }, [UpdateMIs]);

  const OnAddMaterialsFromTemplate = useCallback(() => {
    if (!bomData) return;
    ModalRef.showDialog({
      title: "Add Materials from Template",
      width: 300,
      children: (
        <div>
          <SelectFromTemplate
            buildPlanId={bomData.buildPlan.id}
            addMaterials={(
              materials: SQL_WH_BOM_AvailableTemplates_Response_Materials[]
            ) => {
              ModalRef.hideDialog();
              UpdateMaterials(
                materials.map((m) => ({
                  materialId: m.id,
                  quantity: m.quantity,
                  comment: "",
                }))
              );
            }}
          />
        </div>
      ),
    });
  }, [bomData, UpdateMaterials]);

  const OnAssignMaterialItems = useCallback(
    (dataItem: BomMaterialDetailsDto) => {
      let selectedMaterialItems: number[] = [];
      ModalRef.showDialog({
        title: "Select Material Items to Assign",
        buttons: [
          {
            text: "Cancel",
            action: () => {
              ModalRef.hideDialog();
            },
          },
          {
            text: "Ok",
            color: "primary",
            action: () => {
              if (!selectedMaterialItems.length) return;
              ModalRef.hideDialog();
              AssignMaterialItems(selectedMaterialItems);
            },
          },
        ],
        children: (
          <div>
            <MaterialItemsSelect
              bomId={bomId}
              materialId={dataItem.material.id}
              onChange={(ids: number[]) => {
                selectedMaterialItems = ids;
              }}
            />
          </div>
        ),
      });
    },
    [AssignMaterialItems]
  );

  const RemoveMaterial = useCallback(
    async (material: BomMaterialDetailsDto) => {
      try {
        processing.setTrue();
        await api.wh.bom.removeMaterial(bomId, material.material.id);
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );

  const RemoveMaterialItem = useCallback(
    async (materialItem: BomMaterialItemDetailsDto) => {
      try {
        processing.setTrue();
        await api.wh.bom.removeMaterialItem(bomId, materialItem.id);
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );
  const UpdateMaterialComment = useCallback(
    async (materialId: number, comment: string) => {
      try {
        processing.setTrue();
        await api.wh.bom.updateMaterialComment(bomId, materialId, {
          comment,
        });
        const material = bomMaterials.find((m) => m.material.id === materialId);
        if (material) {
          material.comment = comment;
        }
      } catch (e) {
        showSomeError(e);
      } finally {
        setBomMaterials([...bomMaterials]);
        processing.setFalse();
      }
    },
    [bomMaterials]
  );

  const UpdateMaterialQTY = useCallback(
    async (materialId: number, quantity: number) => {
      try {
        processing.setTrue();
        await api.wh.bom.updateMaterialQuantity(bomId, materialId, {
          quantity,
        });
        const material = bomMaterials.find((m) => m.material.id === materialId);
        if (material) {
          material.quantity = quantity;
          material.total = quantity * material.cogs;
          if (material.pickQty > material.quantity) {
            material.pickQty = material.quantity;
          }
        }
      } catch (e) {
        showSomeError(e);
      } finally {
        setBomMaterials([...bomMaterials]);
        processing.setFalse();
      }
    },
    [bomMaterials]
  );

  const UpdateMaterialPickQTY = useCallback(
    async (dataItem: BomMaterialDetailsDto, qty: number) => {
      const materialId = dataItem.material.id;
      const material = bomMaterials.find((m) => m.material.id === materialId);
      if (material) {
        if (qty > material.quantity) {
          ModalRef.showDialog({
            title: "Warning",
            type: "warning",
            text: "Pick QTY could not be more then QTY",
          });
          material.pickQty = material.quantity;
        } else {
          material.pickQty = qty;
        }
        setBomMaterials([...bomMaterials]);
      }
    },
    [bomMaterials]
  );

  const PickAll = useCallback(async () => {
    bomMaterials.forEach((bm) => {
      if (!bm.isSnRequired) {
        bm.pickQty = bm.quantity;
      }
    });
    setBomMaterials([...bomMaterials]);
  }, [bomMaterials]);

  const RemoveMaterialItems = useCallback(
    async (dataItem: BomMaterialDetailsDto) => {
      try {
        processing.setTrue();
        await api.wh.bom.removeMaterialItems(bomId, {
          materialItemIds: dataItem.materialItems.map((mi) => mi.id),
        });
        LoadData();
      } catch (e) {
        showSomeError(e);
      } finally {
        processing.setFalse();
      }
    },
    [LoadData]
  );

  const SendToWarehouse = useCallback(async () => {
    try {
      processing.setTrue();
      await api.wh.bom.sendToWarehouse(bomId);
      LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [LoadData]);

  const CancelBom = useCallback(async () => {
    try {
      processing.setTrue();
      await api.wh.bom.cancel(bomId);
      LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [LoadData]);

  const ReturnToPending = useCallback(async () => {
    try {
      processing.setTrue();
      await api.wh.bom.returnToPending(bomId);
      LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [LoadData]);

  const CompleteBOM = useCallback(async () => {
    try {
      processing.setTrue();
      await api.wh.bom.complete(bomId);
      LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [LoadData]);

  const ReturnBOM = useCallback(async () => {
    try {
      processing.setTrue();
      await api.wh.bom.return(bomId);
      LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [LoadData]);

  const CreateReturnBom = useCallback(async () => {
    try {
      processing.setTrue();
      const IdDto = await api.wh.bom.createReturn(bomId);
      props.closeCard();
      if (IdDto) {
        CardManagement.OpenBOMCard(IdDto.id, props.refreshGrid);
      }
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [props.closeCard, LoadData, props.refreshGrid]);

  const Kit = useCallback(async () => {
    try {
      processing.setTrue();
      const kitMaterials: KitBomMaterialDto[] = bomMaterials.map((bm) => ({
        materialId: bm.material.id,
        quantity: bm.pickQty,
      }));
      if (!kitMaterials.length) {
        ModalRef.showDialog({
          title: "Error",
          text: "BOM can't be kitted. Pick QTY = 0 for all Materials",
        });
        return;
      }
      const IdDto = await api.wh.bom.kit(bomId, {
        materials: kitMaterials,
      });
      if (IdDto) {
        CardManagement.OpenBOMCard(IdDto.id, props.refreshGrid);
        props.closeCard();
      }
      LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [bomMaterials, props.closeCard, LoadData, props.refreshGrid]);

  const MPR = useCallback(async () => {
    if (!bomData) return;
    const params: Script_DESKTOP_WH_BOM_CreatePurchaseRequest_Request = {
      BomId: bomData.conterraId,
      Materials: bomMaterials.map((m) => ({
        Id: m.material.id,
        Quantity: m.quantity,
      })),
      BuildPlanId: bomData.buildPlan.id,
      DueDate: moment(bomData.dueDate).format(CONTERRA_DATE_FORMAT),
      LocationId: bomData.warehouse.id,
    };
    api.localScript("DESKTOP_WH_BOM_CreatePurchaseRequest", params);
  }, [bomData, bomMaterials]);

  const OpenFinancialDistribution = useCallback(async () => {
    if (!bomData) return;
    api.localScript("DESKTOP_FinancialDistribution_Show", {
      ConterraId: bomData.conterraId,
    });
  }, [bomData]);

  const OnMaterialsAction = (action: EBomActions) => {
    // todo refactoring
    if (action === EBomActions.addMaterials) {
      OnAddMaterialsClick();
    } else if (action === EBomActions.addMaterialItems) {
      OnAddMaterialItemClick();
    } else if (action === EBomActions.send) {
      SendToWarehouse();
    } else if (action === EBomActions.cancel) {
      CancelBom();
    } else if (action === EBomActions.mpr) {
      MPR();
    } else if (action === EBomActions.returnToPending) {
      ReturnToPending();
    } else if (action === EBomActions.pickAllQTY) {
      PickAll();
    } else if (action === EBomActions.kit) {
      Kit();
    } else if (action === EBomActions.addFromTemplate) {
      OnAddMaterialsFromTemplate();
    } else if (action === EBomActions.ofd) {
      OpenFinancialDistribution();
    } else if (action === EBomActions.returnComplete) {
      ReturnBOM();
    } else if (action === EBomActions.complete) {
      CompleteBOM();
    } else if (action === EBomActions.return) {
      CreateReturnBom();
    }
  };

  const renderGeneral = () => {
    if (!bomData || activeTab !== "general") return null;
    return (
      <BOMCardGeneral
        data={bomData}
        total={bomTotal}
        Update={UpdateGeneral}
        renderToolbar={renderToolbar}
      />
    );
  };

  const renderMaterials = () => {
    if (!bomData || activeTab !== "materials") return null;
    return (
      <BOMCardMaterials
        bomTotal={bomTotal}
        general={bomData}
        bomMaterials={bomMaterials}
        renderToolbar={renderToolbar}
        onSelectAction={OnMaterialsAction}
        removeMaterial={RemoveMaterial}
        removeMaterialItem={RemoveMaterialItem}
        updateMaterialComment={UpdateMaterialComment}
        updateMaterialQTY={UpdateMaterialQTY}
        changeMaterialPickQTY={UpdateMaterialPickQTY}
        assignMaterialItems={OnAssignMaterialItems}
        removeMaterialItems={RemoveMaterialItems}
      />
    );
  };

  const renderDocuments = () => {
    if (!bomData) return null;
    return (
      <RelatedDocuments
        key={bomId}
        isActive={true}
        SourceId={bomData.conterraId}
        isMobile={false}
      />
    );
  };

  const renderToolbar = useCallback(
    (actions?: JSX.Element | JSX.Element[]) => {
      return (
        <BomCardToolbar
          activeTabId={activeTab}
          actions={actions}
          onChangeTab={(id) => {
            if (id === "documents") documentsInitedRef.current = true;
            setActiveTab(id);
          }}
        />
      );
    },
    [activeTab]
  );

  return (
    <div className={formStyles.FormWrapper}>
      {(loadingDetails.value || processing.value) && (
        <LoaderComponent style={{ background: "transparent" }} />
      )}
      <>
        {renderGeneral()}
        {renderMaterials()}
        {(documentsInitedRef.current || activeTab === "documents") && (
          <div
            style={{
              display: activeTab === "documents" ? "flex" : "none",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {renderToolbar()}
            <div style={{ flex: 1, position: "relative" }}>
              {renderDocuments()}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default BOMCardForm;
