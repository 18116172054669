import { Container } from "inversify";
import "reflect-metadata";
import AuthProvider from "./core/api/AuthProvider";
import DI_TYPES from "./core/diTypes";
import {
  IAuthProvider,
  IDeviceInfoProvider,
  ILastUserService,
  ILogger,
  ISettingsStorage,
} from "./core/interfaces";
import DeviceInfoProvider from "./tools/deviceInfoProvider";
import Logger from "./tools/logger";
import SettingsStorage from "./tools/settingStorage";
import { LastUserService } from "./tools/lastUserService";

const diContainer = new Container();
diContainer.bind<ILogger>(DI_TYPES.ILogger).to(Logger).inSingletonScope();
diContainer
  .bind<ISettingsStorage>(DI_TYPES.ISettingsStorage)
  .to(SettingsStorage)
  .inSingletonScope();
diContainer
  .bind<IAuthProvider>(DI_TYPES.IAuthProvider)
  .to(AuthProvider)
  .inSingletonScope();
diContainer
  .bind<IDeviceInfoProvider>(DI_TYPES.IDeviceInfoProvider)
  .to(DeviceInfoProvider)
  .inSingletonScope();
diContainer
  .bind<ILastUserService>(DI_TYPES.ILastLoginService)
  .to(LastUserService)
  .inSingletonScope();

export default diContainer;
