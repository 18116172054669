import React from "react";
import { pageId, simpleObject } from "../../helpers/interfaces";
import DashboardGrid from "../Dashboard/Grid";
import {
  gridChangeDataAction,
  IColumnFilterItem,
  IColumnSetting,
  IGridFilter,
} from "../Dashboard/interfaces";
import CardManagement from "../Cards/CardManagement";
import { settingsStorage } from "../../helpers/settings";
import BaseComponent from "../BaseComponent";
import { formatDateColumn } from "../TC/helpers";

interface props {
  isActive: boolean;
  pageId?: pageId;
  periodId?: number;
  serverFilters?: simpleObject;
  columnsFilters?: IColumnFilterItem[];
  complexGridFilters?: IGridFilter[];
  remountKey?: number;

  onChangeGridData?: (
    data: simpleObject[],
    action: gridChangeDataAction
  ) => void;
}

interface state {
  canCreateTimeCards: boolean;
}

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    columnMenu: false,
    aggregate: "count",
    locked: true,
  },
  {
    field: "Date",
    title: "Date",
    type: "date",
    locked: true,
    format: {
      type: "date",
      customFormat: true,
      getCustomFormat: formatDateColumn,
    },
  },
  {
    field: "EmployeeName",
    title: "Employee",
    type: "string",
    gridWidth: 200,
    format: {
      type: "link",
      fieldId: "EmployeeId",
      refName: "Employees",
    },
    locked: true,
  },
  {
    field: "EmployeeFirstName",
    title: "First Name",
    type: "string",
    gridWidth: 100,
  },
  {
    field: "EmployeeLastName",
    title: "Last Name",
    type: "string",
    gridWidth: 100,
  },
  {
    field: "ClassName",
    title: "Employee Class",
    type: "string",
    format: {
      type: "link",
      refName: "Classes",
      fieldId: "ClassId",
    },
    gridWidth: 180,
  },
  {
    field: "PeriodName",
    title: "Payroll Period",
    type: "string",
    // locked: true,
    gridWidth: 220,
    format: {
      type: "link",
      fieldId: "PeriodId",
      refName: "TKPayrollPeriods",
    },
  },
  {
    field: "ReviewStateName",
    title: "Review State",
    type: "string",
    // locked: true,
    format: {
      type: "string",
      fieldId: "ReviewStateId",
    },
  },
  {
    field: "AllStateNames",
    title: "Payroll States",
    type: "string",
    gridWidth: 120,
    format: {
      type: "string",
    },
  },
  {
    field: "ClockedHours",
    title: "Clocked Hours",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "ApprovedHours",
    title: "Approved Hours",
    type: "number",
    gridWidth: 100,
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "RegularHours",
    title: "Regular",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "OvertimeHours",
    title: "Overtime",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "DoubleHours",
    title: "Double",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    title: "PW",
    type: "parent",
    columns: [
      {
        field: "PWRegularHours",
        title: "Regular",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "PWOvertimeHours",
        title: "Overtime",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "PWDoubleHours",
        title: "Double",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "PWTotalHours",
        title: "Total",
        type: "number",
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
    ],
  },
  {
    field: "TotalHours",
    title: "Total Hours",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
  {
    field: "ReviewerName",
    title: "Reviewer",
    type: "string",
    gridWidth: 200,
    format: {
      type: "link",
      fieldId: "ReviewerId",
      refName: "Employees",
    },
  },
  {
    field: "ReasonName",
    title: "Reason",
    type: "string",
    format: {
      type: "string",
      fieldId: "ReasonId",
    },
  },
  {
    field: "FirstClockIn",
    title: "First Clock In",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
  {
    field: "LastClockOut",
    title: "Last Clock Out",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
  {
    field: "DepartmentName",
    title: "Department",
    type: "string",
    format: {
      type: "link",
      fieldId: "DepartmentId",
      refName: "Departments",
    },
  },
  {
    field: "CategoryName",
    title: "Employee Category",
    type: "string",
    gridWidth: 130,
    format: {
      type: "link",
      fieldId: "CategoryId",
      refName: "EmployeeCategories",
    },
  },
  {
    field: "HasAdjustmentText",
    title: "Has Adjustment",
    type: "string",
    format: {
      type: "string",
      fieldId: "HasAdjustment",
    },
  },
  {
    field: "WageAllocationLockedText",
    title: "Wage Allocation Locked",
    type: "string",
    format: {
      type: "string",
      fieldId: "WageAllocationLocked",
    },
  },
  {
    field: "RequestsCount",
    title: "Requests Count",
    type: "number",
    format: {
      type: "integer",
    },
    aggregate: "sum",
  },
  {
    field: "AuditsCount",
    title: "Audits Count",
    type: "number",
    format: {
      type: "integer",
    },
    aggregate: "sum",
  },
  {
    field: "AuditLunchHours",
    title: "Audit Lunch",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "AppliedLunchHours",
    title: "Applied Lunch",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "ManualLunchHours",
    title: "Manual Lunch",
    type: "number",
    format: {
      type: "number",
    },
  },
  {
    field: "MileageHours",
    title: "Mileage",
    type: "number",
    format: {
      type: "number",
    },
    aggregate: "sum",
  },
];

class TimeCards extends BaseComponent<props, state> {
  gridRef: any = React.createRef();
  rowId: number | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      canCreateTimeCards: false,
    };
  }

  render() {
    const { periodId, isActive, remountKey } = this.props;
    if (!periodId || !isActive) return null;
    let key = periodId ? periodId + "" : "0";
    if (remountKey) key += remountKey;
    return (
      <DashboardGrid
        key={key}
        isActive={this.props.isActive}
        isParentPage={false}
        ref={this.gridRef}
        columnsSettings={COLUMNS}
        getData={this.GetGridData}
        excelFileName={"PayrollPeriodTimeCards.xlsx"}
        getRowKey={(dataItem: simpleObject) => dataItem.TCId}
        columnValuesFilter={this.props.columnsFilters}
        complexGridFilters={this.props.complexGridFilters}
        columnMenu={{
          filters: true,
          columns: true,
        }}
        reorderable={true}
        selectSettings={{
          pageId: "TKDashboard",
          onSelectRow: this.OnSelectRowInPPTab,
        }}
        onChangeGridData={this.props.onChangeGridData}
      ></DashboardGrid>
    );
  }

  OnSelectRowInPPTab = (dataItem: simpleObject | null) => {
    const tcId = dataItem?.TCId;
    if (tcId) CardManagement.OpenTCCard(tcId, this.Refresh);
  };

  GetGridData = async (filters: simpleObject) => {
    const { periodId, serverFilters } = this.props;
    const sqlParams: simpleObject = { ...filters, periodId };
    if (serverFilters) {
      for (let filter in serverFilters) {
        sqlParams[filter] = serverFilters[filter];
      }
    }

    const result = await this.GetSQLData({
      spName: "DB_TimeCards",
      params: sqlParams,
    });
    const data = result[1];
    let hasNewSelectedRow = false;
    data.forEach((item: simpleObject) => {
      if (!hasNewSelectedRow && this.rowId && item.TCId === this.rowId) {
        hasNewSelectedRow = true;
      }
      item.WageAllocationLockedText = item.WageAllocationLocked ? "Yes" : "No";
      item.HasAdjustmentText = item.HasAdjustment ? "Yes" : "No";
    });
    return data;
  };

  Refresh = (tcId?: number) => {
    let refresh = this.gridRef?.current?.Refresh;
    if (tcId) {
      settingsStorage.setForCurrentUser(
        "timeCardsDash_SelectedRowId",
        JSON.stringify(tcId)
      );
      this.rowId = tcId;
    }
    if (refresh) refresh();
  };
}

export default TimeCards;
