export class MobileFile {
  uri: string;
  type: string;
  name: string;
  filename: string;

  constructor(body: {
    uri: string;
    type: string;
    name: string;
    filename: string;
  }) {
    this.filename = body.filename;
    this.type = body.type;
    this.uri = body.uri;
    this.name = body.name;
  }

  public getFileBody() {
    return {
      name: this.name,
      filename: this.filename,
      uri: this.uri,
      type: this.type,
    };
  }
}
