import {
  BillOfMaterialDetailsDto,
  UpdateBillOfMaterialDto,
} from "../../../core/api/generated/warehouse";
import React, { useCallback, useEffect, useRef } from "react";
import moment from "moment/moment";
import { ISO_DATE_FORMAT } from "../../../core/tools/formats";
import formStyles from "../card.module.scss";
import styles from "./bomcard.module.scss";
import { formatFinancial } from "../../../helpers/helpers";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";

export const BOMCardGeneral = (props: {
  renderToolbar(actions?: JSX.Element | JSX.Element[]): JSX.Element;
  data: BillOfMaterialDetailsDto;
  total: number;
  Update: (data: UpdateBillOfMaterialDto) => void;
}) => {
  const { data, total, Update } = props;
  // const isPendingStage = data.stage.id === BillOfMaterialsStageEnum.Pending;
  const generalEditableValues = useRef<{
    date?: Date;
    dueDate?: Date;
    comments?: string;
  }>({});

  useEffect(() => {
    generalEditableValues.current = {
      date: moment(data.date).toDate(),
      dueDate: moment(data.dueDate).toDate(),
      comments: data.comments || "",
    };
  }, [data]);

  const TryUpdate = useCallback(() => {
    const { date, dueDate, comments } = generalEditableValues.current;
    if (!date || !dueDate) return;
    Update({
      date: moment(date).format(ISO_DATE_FORMAT),
      dueDate: moment(dueDate).format(ISO_DATE_FORMAT),
      comments,
    });
  }, [Update]);

  const renderReadOnlyValue = useCallback(
    (label: string, value: string, isLargeLabel?: boolean) => (
      <div className={formStyles.Row}>
        <div
          className={formStyles.FormFieldLabel}
          style={isLargeLabel ? { width: 110 } : undefined}
        >
          {label}:
        </div>
        <div>
          <div className={formStyles.ReadOnlyField}>{value}</div>
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      {props.renderToolbar()}
      <div className={styles.GeneralContainer}>
        <div
          className={`${formStyles.Row}`}
          style={{ alignItems: "flex-start", gap: 12 }}
        >
          <div className={formStyles.RowCell}>
            {renderReadOnlyValue("No", data.number, true)}
            {renderReadOnlyValue("BOM Type", data.type.title, true)}
            {renderReadOnlyValue("Lifecycle Stage", data.stage.title, true)}
            {renderReadOnlyValue("Requestor", data.requestor.name, true)}
            {renderReadOnlyValue("BOM Total ($)", formatFinancial(total), true)}
          </div>
          <div className={formStyles.RowCell}>
            {renderReadOnlyValue("Build Plan", data.buildPlan.name)}
            {renderReadOnlyValue("Site", data.site.name)}
            {renderReadOnlyValue("Project", data.project.name)}
            {renderReadOnlyValue("Scenario", data.scenario.name)}
            {renderReadOnlyValue("Region", data.region.name)}
            {renderReadOnlyValue("Market", data.market.name)}
          </div>
          <div className={formStyles.RowCell}>
            {renderReadOnlyValue("Warehouse", data.warehouse.name)}
            <div className={formStyles.Row}>
              <div className={formStyles.FormFieldLabel}>BOM Date:</div>
              <DatePicker
                id={"date"}
                onChange={(event: DatePickerChangeEvent) => {
                  generalEditableValues.current.date = event.value || undefined;
                  TryUpdate();
                }}
                className={`${formStyles.Datepicker} ${styles.DatePicker}`}
                defaultValue={moment(data.date).toDate()}
                formatPlaceholder={{
                  month: "m",
                  day: "d",
                  year: "y",
                }}
                required={true}
                size={"small"}
              ></DatePicker>
            </div>

            <div className={formStyles.Row}>
              <div className={formStyles.FormFieldLabel}>Due Date:</div>
              <DatePicker
                id={"duedate"}
                onChange={(event: DatePickerChangeEvent) => {
                  generalEditableValues.current.dueDate =
                    event.value || undefined;
                  TryUpdate();
                }}
                className={`${formStyles.Datepicker} ${styles.DatePicker}`}
                defaultValue={moment(data.dueDate).toDate()}
                formatPlaceholder={{
                  month: "m",
                  day: "d",
                  year: "y",
                }}
                required={true}
                size={"small"}
              ></DatePicker>
            </div>

            {renderReadOnlyValue(
              "Sent to Warehouse",
              data.sent ? moment(data.sent).format("L") : ""
            )}
            {renderReadOnlyValue(
              "Kitted",
              data.kitted ? moment(data.kitted).format("L") : ""
            )}
            {renderReadOnlyValue(
              "Completed",
              data.completed ? moment(data.completed).format("L") : ""
            )}
          </div>
        </div>

        <TextArea
          className={formStyles.TextArea}
          style={{ flex: 1 }}
          placeholder="Comments"
          onChange={(event: TextAreaChangeEvent) => {
            if (event.value === generalEditableValues.current.comments) return;
            generalEditableValues.current.comments = event.value;
          }}
          onBlur={() => {
            if (data.comments === generalEditableValues.current.comments)
              return;
            TryUpdate();
          }}
          defaultValue={data.comments || undefined}
        ></TextArea>
      </div>
    </>
  );
};
