import { Component } from "react";
import {
  DropDownsPopupSettings,
  ListItemProps,
  MultiSelect,
  TagData,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Checkbox } from "@progress/kendo-react-inputs";

interface props<I> {
  tagRender?:
    | ((
        tagData: TagData,
        tag: React.ReactElement<any, string | React.JSXElementConstructor<any>>
      ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
    | undefined;
  autoClose?: boolean;
  tags?: Array<TagData>;
  filter?: any;
  footer?: any;
  data: Array<I>;
  getData?: any;
  placeholder: string;
  className?: string;
  width?: number | string;
  loading?: boolean;
  defaultValue?: Array<I>;
  value?: Array<I>;
  disabled?: boolean;
  selectAll?: boolean;
  popupSettings?: DropDownsPopupSettings;
  textField?: string;
  dataItemKey?: string;
  required?: boolean;
  opened?: true;

  onChange(value: Array<I> | undefined, dataAttr?: any): void;

  itemRender?(
    li: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    itemProps: ListItemProps
  ): React.ReactNode | undefined;
}

interface state<I> {
  filter: any;
  data: Array<I>;
  value: Array<I>;
  isAllSelected: boolean;
}

class FilterMultiSelect<I> extends Component<props<I>, state<I>> {
  data: Array<I> = this.props.data || [];
  value: Array<I> = this.props.defaultValue || [];

  constructor(props: props<I>) {
    super(props);
    this.state = {
      filter: null,
      data: [],
      value: this.value,
      isAllSelected: this.data.length === this.value.length,
    };
  }

  componentDidMount() {
    if (this.props.getData) this.LoadData();
  }

  render() {
    let data = this.props.data || this.state.data;
    let width = this.props.filter?.width || this.props.width;
    return (
      <MultiSelect
        className={this.props.className}
        style={width ? { width } : undefined}
        placeholder={this.props.placeholder}
        popupSettings={this.props.popupSettings}
        header={this.props.selectAll ? this.renderSelectAllHeader() : undefined}
        data={filterBy(data, this.state.filter)}
        textField={this.props.textField || "Name"}
        dataItemKey={this.props.dataItemKey || "Id"}
        onChange={this.OnChange}
        value={this.props.value || this.state.value}
        filterable={true}
        onFilterChange={this.Filter}
        onOpen={this.OnOpen}
        disabled={!!this.props.disabled}
        loading={this.props.loading}
        itemRender={this.props.itemRender}
        autoClose={this.props.autoClose}
        tagRender={this.props.tagRender}
        tags={this.props.tags}
        footer={this.props.footer}
        required={this.props.required}
        opened={this.props.opened}
      />
    );
  }

  renderSelectAllHeader = () => {
    return (
      <div
        className={"k-list-item"}
        onClick={this.OnClickHeader}
        style={{ color: "#424242", backgroundColor: "#f0f0f0", padding: 8 }}
      >
        <Checkbox
          id={"SelectAll" + this.props.placeholder}
          label="Select All"
          checked={this.state.isAllSelected}
          style={{ zIndex: 0 }}
        />
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
          }}
        ></div>
      </div>
    );
  };

  Filter = (e: any) => {
    this.setState({ filter: e.filter });
  };

  OnOpen = () => {
    this.setState({ filter: null });
  };

  OnChange = (e: any) => {
    let value = e.value.length ? e.value : undefined;
    this.value = value || [];
    this.Change();
  };

  OnClickHeader = (e: any) => {
    let allData = this.props.data || this.state.data;
    this.value = this.value.length === allData.length ? [] : allData;
    this.Change();
  };

  Change = () => {
    let allData = this.props.data || this.state.data;
    this.setState({
      value: this.value,
      isAllSelected: allData.length === this.value.length,
    });
    this.props.onChange(this.value, this.props.filter);
  };

  LoadData = async () => {
    if (this.props.getData) {
      let data = await this.props.getData();
      this.data = data;
      this.setState({ data: this.data });
    }
  };
}

export default FilterMultiSelect
