const DI_TYPES = {
  ILogger: Symbol.for("ILogger"),
  ISettingsStorage: Symbol.for("ISettingsStorage"),
  IDeviceInfoProvider: Symbol.for("IDeviceInfoProvider"),
  IAuthProvider: Symbol.for("IAuthProvider"),
  ILastLoginService: Symbol.for("ILastLoginService"),
  ISasTokenService: Symbol.for("ISasTokenService"),
};

export default DI_TYPES;
