import { Button, Toolbar } from "@progress/kendo-react-buttons";
import styles from "./tkToolbar.module.scss";
import React, { useCallback, useEffect, useMemo } from "react";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import { State } from "@progress/kendo-data-query";
import { SortDescriptor } from "@progress/kendo-data-query/dist/npm/sort-descriptor";
import { useBooleanState } from "../../../core/tools/Hooks";
import {
  EFiltersNames,
  IDispatchDataFilter,
  IFilters,
  IPPFilterItem,
} from "../interfaces";
import CardManagement from "../../../Components/Cards/CardManagement";
import {
  FilterByDispatch,
  FilterByPP,
  FilterByResource,
  FilterByReviewer,
  FilterByState,
} from "./Filters";
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { isTKCanCompleteTCs, isTKManager } from "../../../core/tools/roles";

interface IProps {
  toolbarExpandState: boolean;
  filters: IFilters;
  filtersRefresher: number;
  dispatchFilterRefresher: number;
  compare: JSX.Element;
  timecardsActions: JSX.Element;
  onSortChange: (isASC: boolean) => void;

  refreshList(): void;

  onLocalStateChange(state: State): void;

  onClearFilters(): void;

  onServerFilterChange(
    value: INewComboboxItem | null,
    filterName: EFiltersNames
  ): void;

  onDateFilterChange(e: DateRangePickerChangeEvent): void;

  onToggleToolbar(): void;
}

const TKListToolbar = (props: IProps) => {
  const {
    toolbarExpandState,
    filters,
    filtersRefresher,
    dispatchFilterRefresher,
  } = props;
  const isDateSortAsc = useBooleanState(true);
  const isHideRelatedTCs = useBooleanState(true);
  useEffect(() => {
    props.onSortChange(isDateSortAsc.value);
  }, [isDateSortAsc.value]);

  const solicitTKRequest = () => {
    CardManagement.OpenSolicitTKRequestCard({ afterSave: props.refreshList });
  };

  const localDataSettingsState: State = useMemo(() => {
    const filter: CompositeFilterDescriptor | undefined = isHideRelatedTCs.value
      ? {
          logic: "and",
          filters: [
            {
              field: "isRelated",
              operator: "eq",
              value: false,
            },
          ],
        }
      : undefined;

    const sort: SortDescriptor[] = [
      {
        field: "date",
        dir: !isHideRelatedTCs.value || isDateSortAsc.value ? "asc" : "desc",
      },
    ];

    return {
      sort,
      filter,
    };
  }, [isDateSortAsc.value, isHideRelatedTCs.value]);

  useEffect(() => {
    props.onLocalStateChange(localDataSettingsState);
  }, [localDataSettingsState]);

  const getHasAppliedFilters = useCallback(
    (
      filtersValues: IFilters,
      isDateSortAsc: boolean,
      isHideRelatedTCs: boolean
    ) => {
      if (!isDateSortAsc || !isHideRelatedTCs) return true;

      for (let filterName in filtersValues) {
        if (filtersValues[filterName as keyof IFilters]) return true;
      }

      return false;
    },
    []
  );

  const dispatchDataParams: IDispatchDataFilter = useMemo(
    () => ({
      resource: filters[EFiltersNames.RESOURCE],
      date: filters[EFiltersNames.DATE],
      finishDate: filters[EFiltersNames.DATE_TO],
    }),
    [dispatchFilterRefresher]
  );

  const renderFilters = () => {
    const values = {
      start: filters[EFiltersNames.DATE] || null,
      end: filters[EFiltersNames.DATE_TO] || null,
    };
    const showTCActionsAsIsAdmin = isTKManager() || isTKCanCompleteTCs();
    return (
      <>
        <DateRangePicker
          key={"tkDateRange" + filtersRefresher}
          className={styles.DateRangeFilter}
          onChange={props.onDateFilterChange}
          // clearButton={true}
          defaultValue={values}
        />
        <FilterByPP
          onChange={props.onServerFilterChange}
          value={filters[EFiltersNames.PP] as IPPFilterItem}
          refresher={filtersRefresher}
        />

        <FilterByDispatch
          onChange={props.onServerFilterChange}
          value={filters[EFiltersNames.DISPATCH] as INewComboboxItem}
          params={dispatchDataParams}
          refresher={filtersRefresher}
        />
        <FilterByState
          onChange={props.onServerFilterChange}
          value={filters[EFiltersNames.REVIEW_STATE] as INewComboboxItem | null}
          refresher={filtersRefresher}
        />
        {showTCActionsAsIsAdmin && (
          <FilterByReviewer
            onChange={props.onServerFilterChange}
            value={filters[EFiltersNames.REVIEWER] as INewComboboxItem}
            refresher={filtersRefresher}
          />
        )}
        <FilterByResource
          onChange={props.onServerFilterChange}
          value={filters[EFiltersNames.RESOURCE] as INewComboboxItem}
          refresher={filtersRefresher}
        />
      </>
    );
  };

  const hasAppliedFilters = getHasAppliedFilters(
    filters,
    isDateSortAsc.value,
    isHideRelatedTCs.value
  );
  return (
    <Toolbar style={{ flex: "0 0 auto" }}>
      <div className={styles.ToolbarRow}>
        {props.timecardsActions}
        {props.compare}
        <div style={{ flex: 1 }}></div>
        <Button
          iconClass={
            toolbarExpandState ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"
          }
          title={"Toggle filters"}
          onClick={props.onToggleToolbar}
        ></Button>
        <Button
          iconClass={"mdi mdi-filter-off"}
          title={"Clear Filters"}
          disabled={!hasAppliedFilters}
          onClick={() => {
            isDateSortAsc.setTrue();
            isHideRelatedTCs.setTrue();
            props.onClearFilters();
          }}
        ></Button>
        <Button
          iconClass={"mdi mdi-email"}
          title={"Solicit TK Request"}
          onClick={solicitTKRequest}
        ></Button>
        <Button
          icon={"refresh"}
          title={"Refresh"}
          onClick={props.refreshList}
        ></Button>
      </div>
      <div
        className={styles.ToolbarRow}
        style={!toolbarExpandState ? { display: "none" } : {}}
      >
        {renderFilters()}
        <div style={{ marginLeft: "auto" }}>
          <Button
            icon={
              !isHideRelatedTCs.value || isDateSortAsc.value
                ? "sort-asc"
                : "sort-desc"
            }
            title={"Sort by Date"}
            disabled={!isHideRelatedTCs.value}
            onClick={isDateSortAsc.toggle}
          ></Button>
        </div>
        <Button
          iconClass={"mdi mdi-weather-night"}
          title={"Show Related Time Cards"}
          selected={!isHideRelatedTCs.value}
          // defaultChecked={true}
          togglable={true}
          onClick={(e) => {
            const isHide = !isHideRelatedTCs;
            isHideRelatedTCs.toggle();
          }}
        ></Button>
      </div>
    </Toolbar>
  );
};

export default TKListToolbar;
