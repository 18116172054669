import { Component } from "react";
import { GetReferenceRecordName } from "../../helpers/queries";

import FXCard from "../Common/FXCard/FXCard";
import { Button } from "@progress/kendo-react-buttons";
import { IFXCardProps } from "./interfaces";
import styles from "./card.module.scss";
import TCHistory from "../TC/History";
import { authProvider } from "../../core/api/api";

interface props extends IFXCardProps {
  tcId: number;
}

interface state {
  Name: string;
}

class ModalTCHistory extends Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      Name: "Loading ...",
    };
  }

  componentDidMount() {
    this.LoadName();
  }

  render() {
    return (
      <FXCard
        title={
          <>
            <Button
              className={styles.HeaderLinkBtn}
              icon="hyperlink-open"
              fillMode="flat"
              onClick={this.OpenHistoryInNewTab}
              title="Open History in a New Tab"
            ></Button>
            {this.state.Name}
          </>
        }
        onClose={this.props.finally}
      >
        <TCHistory tcId={this.props.tcId} isActive={true} />
      </FXCard>
    );
  }

  LoadName = async () => {
    let result = await GetReferenceRecordName(+this.props.tcId);
    this.setState({ Name: result });
  };

  OpenHistoryInNewTab = async () => {
    const href = `/${authProvider.getInstanceId()}/${authProvider.getUserId()}/?folder=TKTimeCardHistory&tcId=${
      this.props.tcId
    }`;
    //@ts-ignore
    window.open(href, "_blank").focus();
  };
}

export default ModalTCHistory;
