import styles from "./tc.module.scss";
import { Chip } from "@progress/kendo-react-buttons";
import { formatHoursDuration } from "../../helpers/helpers";
import { ITotalDurationProps } from "./Map/interfaces";
import { useCallback } from "react";

const TotalDurations = (props: { values: ITotalDurationProps }) => {
  const { clocked, approved, lunch, className, isOriginal } = props.values;
  const renderCell = useCallback(
    (title: string, text: string, type?: "success" | "error") => {
      return (
        <div className={styles.TotalDurationsCell}>
          <span className={styles.TotalDurationsName}>{title}</span>
          <Chip themeColor={type} text={text} />
        </div>
      );
    },
    []
  );

  return (
    <div className={`${styles.TotalDurations} ${className}`}>
      {renderCell("Clocked", formatHoursDuration(clocked))}
      {renderCell("Approved", formatHoursDuration(approved), "success")}
      {renderCell(
        isOriginal ? "Audit Lunch" : "Lunch Deduction",
        lunch ? formatHoursDuration(lunch) : "",
        lunch ? "error" : undefined
      )}
      {renderCell(
        "Total",
        formatHoursDuration(approved - (lunch || 0)),
        "success"
      )}
    </div>
  );
};

export default TotalDurations;
