import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { simpleObject } from "../../../helpers/interfaces";
import { Loader, LoaderThemeColor } from "@progress/kendo-react-indicators";
import styles from "./form.module.scss";

const ProcessingButton = (props: {
  ButtonProps: ButtonProps;
  processing: boolean;
  style?: simpleObject;
  className?: string;
  loaderThemeColor?: LoaderThemeColor;
}) => {
  const { loaderThemeColor, ButtonProps, className, style, processing } = props;
  return (
    <div className={`${styles.ProcessingButtonBox} ${className}`} style={style}>
      {processing && (
        <Loader
          className={styles.ProcessingButtonLoader}
          themeColor={loaderThemeColor || "dark"}
          type="converging-spinner"
          size="small"
        />
      )}
      <Button
        {...ButtonProps}
        style={
          processing
            ? {
                opacity: 0,
                zIndex: -1,
              }
            : undefined
        }
      ></Button>
    </div>
  );
};

export default ProcessingButton;
