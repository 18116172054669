import BaseComponent from "../Components/BaseComponent";
import BPTabs from "../Components/Tabs/BPTabs";
import { ITabSetting, tabId } from "../Components/Tabs/interfaces";
import Loader from "../Components/Common/Loader";
import { IDashboardChildProps } from "./../Components/Dashboard/interfaces";
import stylesDashboard from "./../Components/Dashboard/dashboard.module.scss";
import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import tgIcon from "../assets/img/telegram-icon.svg";
import { showSomeError } from "../helpers/helpers";

interface state {
  loading: boolean;
  tabs: Array<ITabSetting | tabId>;
}

const getBPId = (props: IDashboardChildProps) => {
  return (
    props.rowData?.BPID ||
    props.rowData?.BPId ||
    props.rowData?.bpId ||
    props.rowData?.buildPlanId
  );
};

class BPTabsPage extends BaseComponent<IDashboardChildProps, state> {
  tabsRef: any = React.createRef();
  chatWindow: any;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      tabs: [],
    };
  }

  componentDidMount() {
    let bpId = getBPId(this.props);
    if (bpId) {
      this.LoadAvailableTabs(bpId);
    }
  }

  componentDidUpdate(prevProps: IDashboardChildProps) {
    let bpId = getBPId(this.props);
    let prevBpId = getBPId(prevProps);
    if (bpId && prevBpId !== bpId) {
      this.LoadAvailableTabs(bpId);
      this.OnChangeTelegramLink();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.chatWindow && !this.chatWindow.closed) this.chatWindow.close();
  }

  LoadAvailableTabs = async (bpId: number) => {
    try {
      this.setState({ loading: true });
      let result = await this.GetSQLData({
        spName: "BP_AvailableTabs",
        params: { bpId },
      });
      let keys = result[0] || {};
      let tabs: Array<ITabSetting | tabId> = [];
      // @ts-ignore
      let keysArray: Array<tabId> = Object.keys(keys);
      keysArray.forEach((tabId: tabId) => {
        let isAvailable = keys[tabId];
        if (isAvailable) {
          if (tabId === "Map") {
            tabs.push({
              id: tabId,
              props: {
                mode: "bp",
                prefix: "tabshome",
                settings: {
                  BP: { value: bpId, visible: false },
                  BPO: { visible: false },
                  CrewMember: { visible: false },
                  EnableAll: true,
                },
              },
            });
          } else if (tabId === "Calendar") {
            tabs.push({
              id: "Calendar",
              props: {
                initOnReady: true,
              },
            });
          } else {
            tabs.push(tabId);
          }
        }
      });
      this.setState({ tabs });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    let BPID = getBPId(this.props);
    return (
      <div className={stylesDashboard.Container}>
        {this.state.loading && <Loader />}
        {!!this.state.tabs.length && (
          <BPTabs
            {...this.props}
            ref={this.tabsRef}
            rowData={{ BPID }}
            isActive={
              this.props.isActive !== undefined ? this.props.isActive : true
            }
            props={{
              tabs: this.state.tabs,
              toolbarRight: this.renderTelegramLink(),
              doNotSaveFilters: true,
            }}
          />
        )}
      </div>
    );
  }

  renderTelegramLink = () => {
    let bpId = getBPId(this.props);
    let telegramLink = this.props.rowData?.TelegramInviteLink;
    if (bpId && telegramLink) {
      return (
        <Button
          title="Open Telegram Chat"
          onClick={this.OpenChat}
          imageUrl={tgIcon}
        />
      );
    }
  };

  OpenChat = async (e: string | React.MouseEvent<HTMLButtonElement>) => {
    let telegramLink = this.props.rowData?.TelegramInviteLink;
    let windowParams = "width=700,height=800,left=40,top=150";
    if (telegramLink) {
      if (this.chatWindow && !this.chatWindow.closed) {
        this.chatWindow.location = telegramLink;
        if (e) this.chatWindow.focus();
      } else {
        this.chatWindow = window.open(
          telegramLink,
          "Chat telegram",
          windowParams
        );
        this.chatWindow.focus();
      }
    }
  };

  OnChangeTelegramLink = () => {
    let bpId = getBPId(this.props);
    let telegramLink = this.props.rowData?.TelegramInviteLink;

    if (bpId && this.chatWindow && !this.chatWindow.closed) {
      if (telegramLink) {
        this.OpenChat(telegramLink);
      } else {
        this.chatWindow.close();
      }
    }
  };

  RefreshActiveTab = () => {
    // externall call
    if (this.tabsRef.current && this.tabsRef.current.RefreshActiveTab) {
      this.tabsRef.current.RefreshActiveTab();
    }
  };

  Refresh = () => {
    this.RefreshActiveTab();
  };
}

export default BPTabsPage;
