import React from "react";
import { IComboboxItem, simpleObject } from "../../helpers/interfaces";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import BaseComponent from "../../Components/BaseComponent";
import {
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import { formatDateColumn } from "../../Components/TC/helpers";
import DashboardGrid from "../../Components/Dashboard/Grid";
import CardManagement from "../../Components/Cards/CardManagement";

interface props {
  isActive: boolean;
  switcher?: any;
  onChangeGridData?: (data: Array<simpleObject>) => void;
}

interface state {}

class TCAttestations extends BaseComponent<props, state> {
  gridRef: any = React.createRef();
  COLUMNS: Array<IColumnSetting>;
  FILTERS: Array<IFilterSetting> = [];

  constructor(props: props) {
    super(props);
    this.state = {};
    this.COLUMNS = [
      {
        field: "RowNum",
        title: "#",
        type: "string",
        gridWidth: 40,
        filterable: false,
        columnMenu: false,
        aggregate: "count",
      },
      {
        field: "Date",
        title: "Date",
        type: "date",
        format: {
          type: "date",
          customFormat: true,
          getCustomFormat: formatDateColumn,
        },
      },
      {
        field: "EmployeeName",
        title: "Employee",
        type: "string",
        gridWidth: 200,
        format: {
          type: "link",
          fieldId: "EmployeeId",
          refName: "Employees",
        },
      },
      {
        field: "PeriodName",
        title: "Payroll Period",
        type: "string",
        gridWidth: 200,
        format: {
          type: "string",
          fieldId: "PeriodId",
        },
      },
      {
        field: "Shift",
        title: "# Shift",
        type: "number",
        format: {
          type: "integer",
        },
      },
      {
        field: "ClockIn",
        title: "Clock In",
        type: "date",
        gridWidth: 130,
        format: {
          type: "datetime",
        },
      },
      {
        field: "ClockOut",
        title: "Clock Out",
        type: "date",
        gridWidth: 130,
        format: {
          type: "datetime",
        },
      },
      {
        field: "ClockedHours",
        title: "Clocked Hours",
        type: "number",
        gridWidth: 90,
        aggregate: "sum",
        format: {
          type: "number",
        },
      },
      {
        field: "",
        title: "Lunch Hours",
        type: "parent",
        columns: [
          {
            field: "AuditLunchHours",
            title: "Audit",
            type: "number",
            gridWidth: 90,
            format: {
              type: "number",
            },
          },
          {
            field: "ManualLunchHours",
            title: "Manual",
            type: "number",
            gridWidth: 90,
            format: {
              type: "number",
            },
          },
        ],
      },
      {
        title: "Attestations Detail",
        type: "parent",
        columns: [
          {
            field: "Type",
            title: "Type",
            type: "string",
            gridWidth: 70,
            format: {
              type: "string",
              fieldId: "Type",
            },
          },
          {
            field: "Title",
            title: "Title",
            type: "string",
            gridWidth: 200,
            format: {
              type: "string",
              fieldId: "AttestationId",
            },
          },
          {
            field: "Text",
            title: "Text",
            type: "string",
            gridWidth: 70,
            format: {
              type: "icon",
              iconClass: "k-icon k-font-icon k-i-file-txt",
            },
          },
          {
            field: "ConfirmedText",
            title: "Confirmed",
            type: "string",
            gridWidth: 110,
            format: {
              type: "string",
              fieldId: "Confirmed",
            },
          },
          {
            field: "Comment",
            title: "Comment",
            type: "string",
            minWidth: 180,
          },
        ],
      },
    ];

    this.FILTERS = [
      {
        id: "filterPeriod",
        type: "dateRange",
        serverFilter: {
          param: "",
        },
        dateRangeSettings: {
          dateFromParam: "dateFrom",
          dateToParam: "dateTo",
          defaultPeriod: "notApplied",
          allowEmptyValues: true,
        },
        localStoragePrefix: "TKDashboard",
        toolbarRow: 1,
      },
      {
        id: "filterPP",
        placeholder: "Filter by Payroll Period",
        type: "combobox",
        serverFilter: {
          param: "periodId",
          getData: async () => {
            return (await ReferenceRecordsDataSource("TKPayrollPeriods", {
              activeOnly: false,
            })) as IComboboxItem[];
          },
        },
        localStoragePrefix: "TKDashboard",
        width: 298,
        toolbarRow: 2,
      },
    ];
  }

  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        ref={this.gridRef}
        isParentPage={true}
        columnsSettings={this.COLUMNS}
        getData={this.LoadGridData}
        getRowKey={this.GetRowKey}
        saveSettings={{
          prefix: "attestationsDash",
          saveFilters: true,
        }}
        initRefresh={this.InitRefresh}
        excelFileName="Attestations.xlsx"
        columnMenu={{
          filters: true,
          columns: false,
        }}
        filters={this.FILTERS}
        toolbar={{
          switcher: this.props.switcher,
        }}
        defaultColumnValuesFilter={[
          {
            field: "Confirmed",
            values: [false],
          },
        ]}
        selectSettings={{
          pageId: "TKDashboard",
          onSelectRow: this.OpenTCCard,
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_Attestations",
      params: { ...filters },
    });
    const data = result[0];
    data.forEach((item: simpleObject) => {
      item.ConfirmedText = item.Confirmed ? "Yes" : "No";
    });
    return data;
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  GetRowKey = (dataItem: simpleObject) => dataItem.RowNum;

  OpenTCCard = (rowData: simpleObject | null) => {
    if (rowData) {
      const tcId = rowData.TCId;
      CardManagement.OpenTCCard(tcId);
    }
  };
}

export default TCAttestations;
