import stylesDashboard from "../Components/Dashboard/dashboard.module.scss";
import { IInitialTabSettings, tabId } from "../Components/Tabs/interfaces";
import TCTabsNew from "../Components/TabsNew/TCTabsNew";
import React from "react";

interface props {
  tcId: number;
  initialTab?: tabId | IInitialTabSettings;
}

const TCTabsPage = (props: props) => {
  return (
    <div
      className={stylesDashboard.Container}
      style={{
        boxSizing: "border-box",
        paddingBottom: 0,
      }}
    >
      <TCTabsNew
        isActive={true}
        parentId={"TCCard"}
        rowData={{ tcId: props.tcId }}
        initialTab={props.initialTab}
      />
    </div>
  );
};

export default TCTabsPage;
