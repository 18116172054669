import moment from "moment";
import { simpleObject } from "../../helpers/interfaces";
import { IGridFilter, IGridFilterItem, IPeriod, periodId } from "./interfaces";

export const GetGridHeight = (
  selectedRow: simpleObject | null,
  gridRef: any,
  selectSettingsComponent: boolean
) => {
  const container = gridRef?.element;
  if (selectedRow && container) {
    const toolbar = container.getElementsByClassName("k-grid-toolbar")[0];
    const toolbarHeight = (toolbar && toolbar.offsetHeight) || 0;
    const heightHeader =
      container.getElementsByClassName("k-grid-header")[0].offsetHeight;
    const filterRowHeight =
      container.querySelector(".k-filter-row").offsetHeight;
    const contentDOMEl = container.querySelector(".k-grid-content");
    const scrollHeight = contentDOMEl.offsetHeight - contentDOMEl.clientHeight;
    const htmlRows = contentDOMEl.querySelectorAll("tr");
    const height =
      toolbarHeight +
      heightHeader -
      filterRowHeight +
      GridRowHeight +
      (scrollHeight || 16) +
      3;
    if (!htmlRows.length) return height;
    let selectedHTMLRow = contentDOMEl.querySelector(
      '[data-rowkey="' + selectedRow?.Key + '"]'
    );
    if (selectedHTMLRow) {
      let heightSelectedRow = selectedHTMLRow.offsetHeight;
      return (
        toolbarHeight +
        heightHeader -
        filterRowHeight +
        heightSelectedRow +
        scrollHeight +
        3
      );
    }
  }
  return selectSettingsComponent ? "100%" : undefined;
};

export const GridRowHeight = 38;

export const FILTER_OPERATORS = {
  numeric: [
    { text: "grid.filterGtOperator", operator: "gt" },
    { text: "grid.filterGteOperator", operator: "gte" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterLteOperator", operator: "lte" },
    { text: "grid.filterLtOperator", operator: "lt" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
  ],
  text: [
    { text: "grid.filterContainsOperator", operator: "contains" },
    { text: "grid.filterNotContainsOperator", operator: "doesnotcontain" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterStartsWithOperator", operator: "startswith" },
    { text: "grid.filterEndsWithOperator", operator: "endswith" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    { text: "grid.filterIsEmptyOperator", operator: "isempty" },
    { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
  ],
  date: [
    { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
    { text: "grid.filterAfterOperator", operator: "gt" },
    { text: "grid.filterBeforeOperator", operator: "lt" },
    { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
  ],
};

export const GetDefaultGridFilter = (): IGridFilter => ({
  filters: [],
  logic: "and",
});

export const DEFAULT_OPERATOR: { [key: string]: string } = {
  numeric: "gte",
  text: "contains",
  date: "eq",
  datetime: "gte",
};

export const GetNewRemountKey = () => +new Date();

export const IsComplexGridFilter = (
  filter: IGridFilterItem | IGridFilter
): filter is IGridFilter => {
  return (filter as IGridFilter).filters !== undefined;
};

export const CUSTOM_PERIOD_ID = "custom";

export const getPeriods = (allowNotApplied?: boolean) => {
  let periods: Array<IPeriod> = [];
  if (allowNotApplied) {
    periods.push({
      Id: "notApplied",
      Name: "Not Applied",
    });
  }
  periods.push(
    { Id: CUSTOM_PERIOD_ID, Name: "Custom period" },
    { Id: "curr-month-to-date", Name: "Current Month to Date" },
    { Id: "last-month", Name: "Last Month" },
    { Id: "curr-quarter-to-date", Name: "Current Quarter to Date" },
    { Id: "last-quarter", Name: "Last Quarter" },
    { Id: "curr-year-to-date", Name: "Current Year to Date" },
    { Id: "last-year", Name: "Last Year" },
    { Id: "last12months", Name: "Last 12 Months" }
  );

  return periods;
};

export const GetDefaultDateFrom = () => {
  return moment().month(0).date(1);
};

export const GetDefaultDateTo = () => {
  return moment();
};

export const GetPeriodDates = (periodId: periodId) => {
  if (periodId === "notApplied") return [null, null];

  let dateFrom = GetDefaultDateFrom();
  let dateTo = GetDefaultDateTo();

  switch (periodId) {
    case "curr-month-to-date":
      dateFrom = moment().date(1);
      break;
    case "last-month":
      dateFrom = moment().subtract(1, "month").date(1);
      dateTo = moment().date(1).subtract(1, "days");
      break;
    case "curr-quarter-to-date":
      let month = moment().quarter() * 3 - 3;
      dateFrom = moment().month(month).date(1);
      break;
    case "last-quarter":
      var lastQuarter = moment().quarter() - 1;
      if (lastQuarter < 1) {
        dateFrom = moment().subtract(1, "year").month(9).date(1);
      } else {
        dateFrom = moment()
          .month(lastQuarter * 3 - 3)
          .date(1);
      }
      dateTo = moment(dateFrom).add(3, "months").date(1).subtract(1, "days");
      break;
    case "curr-year-to-date":
      dateFrom = moment().month(0).date(1);
      break;
    case "last-year":
      dateFrom = moment().subtract(1, "year").month(0).date(1);
      dateTo = moment().month(0).date(1).subtract(1, "days");
      break;
    case "last12months":
      dateFrom = moment().subtract(12, "month");
      break;
  }
  return [dateFrom.toDate(), dateTo.toDate()];
};

export const DateColumnWidthDefault = 105;
export const DateTimeColumnWidthDefault = 130;
export const NumberColumnWidthDefault = 125;
export const StringColumnMinWidthDefault = 100;
export const IconColumnWidthDefault = 60;
export const BooleanColumnWidthDefault = 60;
export const minDate: Date = new Date(2010, 1, 1);
export const maxDate: Date = new Date(2050, 1, 1);
