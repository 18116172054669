import {
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import BaseComponent from "../../Components/BaseComponent";
import { IBPInfo } from "./interfaces";
import CardManagement from "../../Components/Cards/CardManagement";
import { Button } from "@progress/kendo-react-buttons";
import BPHoursGauge from "./BPHoursGauge";
import BPHoursDetails from "./BPHoursDetails";
import styles from "./bpdashboard.module.scss";

interface props {
  data: IBPInfo;
}

interface state {
  shownDetails: boolean;
}

class BPItem extends BaseComponent<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      shownDetails: false,
    };
  }

  render() {
    let data = this.props.data;
    return (
      <Card className={styles.Card}>
        <CardHeader className={styles.CardHeader}>
          <CardTitle className={styles.CardTitle}>{data.Name}</CardTitle>
        </CardHeader>
        <CardBody className={styles.CardBody}>
          {!this.state.shownDetails && (
            <BPHoursGauge key={data.Id} data={data} />
          )}
          {this.state.shownDetails && (
            <BPHoursDetails key={data.Id} data={data} />
          )}
        </CardBody>
        <CardActions className={styles.CardActions}>
          <Button
            fillMode="flat"
            onClick={this.OpenBP}
            title="Open Build Plan"
            icon="hyperlink-open"
          />
          <Button
            fillMode="flat"
            onClick={this.ShowCard}
            title="Show History"
            iconClass="mdi mdi-chart-line"
          />
          <Button
            fillMode="flat"
            togglable={true}
            onClick={this.ToggleDetails}
            title={this.state.shownDetails ? "Hide Details" : "Details"}
            iconClass="mdi mdi-page-next-outline"
          />
        </CardActions>
      </Card>
    );
  }

  ToggleDetails = () => {
    this.setState((state) => ({ shownDetails: !state.shownDetails }));
  };

  ShowCard = () => {
    CardManagement.OpenBPHistoryChartCard(
      this.props.data.Id,
      this.props.data.Name
    );
  };

  OpenBP = () => {
    CardManagement.OpenBPCard(this.props.data.Id);
  };
}

export default BPItem;
