import { ModalRef } from "../Components/Common/Modal/Modal";
import { IGetSQLDataParams, simpleObject } from "./interfaces";
import api from "../core/api/api";
import { showSomeError } from "./helpers";

const $ = window.$;

export async function getSQLData(
  params: IGetSQLDataParams,
  abortSignal?: AbortSignal
) {
  let abortHandler: () => void = () => {};
  try {
    if (params.modal) ModalRef.startProcessing();
    const promise = api.getSqlData(params.spName, params.params);
    abortHandler = () => {
      if (!promise.isPending()) return;
      promise.cancel();
    };
    abortSignal?.addEventListener("abort", abortHandler);
    return await promise;
  } finally {
    abortSignal?.removeEventListener("abort", abortHandler);
    if (params.modal) ModalRef.stopProcessing();
  }
}

export async function ReferenceRecordsDataSource(
  refName: string,
  params: simpleObject = {}
) {
  // by default server return activeOnly === true
  try {
    const result = await getSQLData({
      spName: "DB_SelectRefRecords",
      params: { refName, ...params },
    });
    return result[0];
  } catch (e) {
    showSomeError(e);
    return [];
  }
}

export async function GetReferenceRecordName(recordId: number) {
  try {
    let result = await getSQLData({
      spName: "DB_SelectRefRecords",
      params: { recordId },
    });
    return (result[0] && result[0][0] && result[0][0].Name) || "No Name";
  } catch (e) {
    return "No Name";
  }
}

window.helpers.getSQLData = function (
  spName: string,
  params?: simpleObject,
  modal?: boolean
) {
  var defer = $.Deferred();
  getSQLData({
    spName: spName,
    params: params,
    modal: modal,
  })
    .then((result) => {
      defer.resolve(result);
    })
    .catch((e) => {
      defer.reject(e);
    });
  return defer.promise();
};
