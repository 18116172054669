import { Component } from "react";
import InventoryDashboardTabs from "../../Components/Tabs/IDTabs";
import style from "../../Components/Dashboard/dashboard.module.scss";
import BOMDashboard from "../../Components/BP/BOMDashboard";
import InventoryBalance from "./InventoryBalance";
import Materials from "./Materials";
import Utilization from "./Utilization";
import { tabId } from "../../Components/Tabs/interfaces";
import { IComboboxItem } from "../../helpers/interfaces";
import MaterialItems from "./MaterialItems";

const TABS: Array<{
  Name: string;
  Id: tabId;
  Component: React.ComponentClass<
    {
      isActive: boolean;
      switchTab(tabId: tabId): void;
      warehouses?: IComboboxItem[];
    },
    any
  >;
}> = [
  {
    Id: "BOM",
    Name: "BOM",
    Component: BOMDashboard,
  },
  {
    Id: "InventoryBalance",
    Name: "Inventory Balance",
    Component: InventoryBalance,
  },
  {
    Id: "Materials",
    Name: "Materials",
    Component: Materials,
  },
  {
    Id: "MaterialItems",
    Name: "Material Items",
    Component: MaterialItems,
  },
  {
    Id: "Utilization",
    Name: "Utilization",
    Component: Utilization,
  },
];

class InventoryDashboard extends Component<{ isActive: boolean }> {
  render() {
    return (
      <div className={style.Container}>
        <InventoryDashboardTabs isActive={this.props.isActive} tabs={TABS} />
      </div>
    );
  }
}

export default InventoryDashboard
