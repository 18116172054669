import { Button } from "@progress/kendo-react-buttons";
import FXCard from "../Common/FXCard/FXCard";
import styles from "./card.module.scss";
import { IFXCardProps } from "./interfaces";
import { useEffect, useRef, useState } from "react";
import { IComboboxItem } from "../../helpers/interfaces";
import FilterCombobox from "../Common/Form/FilterCombobox";
import { useRefresher } from "../../core/tools/Hooks";
import {
  SQL_Map_GetIncompleteWOSites_Response,
  SQL_Map_GetIncompleteWOSites_Response_BuildPlans,
  SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders,
} from "../../core/api/generated/conterra";

interface IProps extends IFXCardProps {
  sites: SQL_Map_GetIncompleteWOSites_Response[];
  filters?: { [key: string]: IComboboxItem | null };
  filter(filters: { [key: string]: IComboboxItem | null }): void;
}

interface IFiltersData {
  scenario: IComboboxItem[];
  class: IComboboxItem[];
  bpo: IComboboxItem[];
  customer: IComboboxItem[];
  carrier: IComboboxItem[];
  lastCrewLead: IComboboxItem[];
  woType: IComboboxItem[];
  woCategory: IComboboxItem[];
}

const BPFilters: {
  name: keyof IFiltersData;
  placeholder: string;
  nameField: keyof SQL_Map_GetIncompleteWOSites_Response_BuildPlans;
  idFiled: keyof SQL_Map_GetIncompleteWOSites_Response_BuildPlans;
}[] = [
  {
    name: "scenario",
    placeholder: "Scenario",
    nameField: "scenarioName",
    idFiled: "scenarioId",
  },
  {
    name: "class",
    placeholder: "Class",
    nameField: "className",
    idFiled: "classId",
  },
  {
    name: "bpo",
    placeholder: "BP Owner",
    nameField: "bpoName",
    idFiled: "bpoId",
  },
  {
    name: "customer",
    placeholder: "Customer",
    nameField: "customerName",
    idFiled: "customerId",
  },
  {
    name: "carrier",
    placeholder: "Carrier",
    nameField: "carrierName",
    idFiled: "carrierId",
  },
];

const WOFilters: {
  name: keyof IFiltersData;
  placeholder: string;
  nameField: keyof SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders;
  idFiled: keyof SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders;
}[] = [
  {
    name: "woType",
    placeholder: "WO Type",
    nameField: "woTypeName",
    idFiled: "woTypeId",
  },
  {
    name: "woCategory",
    placeholder: "WO Category",
    nameField: "woCategoryName",
    idFiled: "woCategoryId",
  },
  {
    name: "lastCrewLead",
    placeholder: "Crew Lead (per Last Dispatch)",
    nameField: "lastCrewLeadName",
    idFiled: "lastCrewLeadId",
  },
];

const IncompleteWOMapFiltersCard = (props: IProps) => {
  const [filtersData, setFiltersData] = useState<IFiltersData>();
  const valuesRef = useRef<{ [key: string]: IComboboxItem | null }>(
    props.filters || {}
  );
  const remountFilters = useRefresher();
  useEffect(() => {
    const newState: IFiltersData = {
      scenario: [],
      class: [],
      bpo: [],
      customer: [],
      carrier: [],
      woType: [],
      woCategory: [],
      lastCrewLead: [],
    };
    for (let site of props.sites) {
      for (let bp of site.buildPlans) {
        for (let filter of BPFilters) {
          const filterValues = newState[filter.name];
          const Id = bp[filter.idFiled];
          const Name = bp[filter.nameField];
          if (
            Id &&
            Name &&
            filterValues.findIndex((value) => value.Id === Id) === -1
          ) {
            newState[filter.name].push({
              Id: +Id,
              Name: Name.toString(),
            });
          }
        }

        for (let wo of bp.workOrders) {
          for (let filter of WOFilters) {
            const filterValues = newState[filter.name];
            const Id = wo[filter.idFiled];
            const Name = wo[filter.nameField];
            if (
              Id &&
              Name &&
              filterValues.findIndex((value) => value.Id === Id) === -1
            ) {
              newState[filter.name].push({
                Id: +Id,
                Name: Name.toString(),
              });
            }
          }
        }
      }
    }
    setFiltersData(newState);
  }, []);

  const OnChangeFilter = (
    value: IComboboxItem | null,
    filterName: keyof IFiltersData
  ) => {
    valuesRef.current[filterName] = value;
  };

  const ClearFilters = () => {
    valuesRef.current = {};
    remountFilters();
  };

  const ApplyFilters = () => {
    props.filter(valuesRef.current);
    props.finally!();
  };

  return (
    <FXCard
      title={"Filter Visible Sites"}
      onClose={props.finally}
      initialWidth={350}
      initialHeight={400}
      originalPaddings={true}
    >
      {!!filtersData && (
        <div className={styles.FormWrapper}>
          {BPFilters.map(({ name, placeholder }) => {
            return (
              <FilterCombobox
                key={remountFilters.value + name}
                placeholder={placeholder}
                data={filtersData[name]}
                dataAttr={name}
                onChange={OnChangeFilter}
                className={styles.FormField}
                defaultValue={valuesRef.current[name]}
              />
            );
          })}
          {WOFilters.map(({ name, placeholder }) => {
            return (
              <FilterCombobox
                key={remountFilters.value + name}
                placeholder={placeholder}
                data={filtersData[name]}
                dataAttr={name}
                onChange={OnChangeFilter}
                className={styles.FormField}
                defaultValue={valuesRef.current[name]}
              />
            );
          })}
          <div className={`${styles.FormFooter} k-action-buttons`}>
            <Button onClick={props.finally}>Cancel</Button>
            <Button onClick={ClearFilters} themeColor="error">
              Clear Filters
            </Button>
            <Button onClick={ApplyFilters} themeColor="primary">
              Apply
            </Button>
          </div>
        </div>
      )}
    </FXCard>
  );
};

export default IncompleteWOMapFiltersCard;
