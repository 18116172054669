import styles from "./tabs.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import dashboardStyles from "../Dashboard/dashboard.module.scss";
import React, { useRef } from "react";
import { tabId } from "./interfaces";
import { TABS } from "./helpers";
import { useRefresher } from "../../core/tools/Hooks";
import OpenCardIconLink from "../Cards/OpenCardIconLink";
import { OpenRecord } from "../../helpers/runscripts";

interface IProps {
  activeTabId: tabId | undefined;
  tabsRefs: { [key in tabId]?: any };
  refresh: () => void;
  unSelect?: () => void;
  children: any;
  desktopLink?: {
    refName: string;
    objectId: number;
  };
}

export type Ref = (ref: any) => void;

const TabsToolbarRight = React.forwardRef<Ref, IProps>(
  (props: IProps, ref: any) => {
    const { refresh, unSelect, activeTabId, tabsRefs, children, desktopLink } =
      props;
    const forceRefresh = useRefresher();
    const thisExternalRef = useRef({
      forceRefresh,
    });
    if (ref) ref(thisExternalRef.current);

    const getGridRef = (activeTabId: tabId) => {
      const activeTabRef = tabsRefs[activeTabId];
      if (activeTabRef?.Refresh) return activeTabRef;
      return (
        activeTabRef?.current?.gridRef?.current ||
        activeTabRef?.current ||
        activeTabRef?.gridRef?.current
      );
    };

    const setDefaultSettings = () => {
      if (!activeTabId) return;
      const gridref = getGridRef(activeTabId);
      const SetDefaultSettingsFunc = gridref?.SetDefaultSettings;
      if (SetDefaultSettingsFunc) SetDefaultSettingsFunc();
    };

    const IFilterInDefaultState = () => {
      if (!activeTabId) return;
      const gridref = getGridRef(activeTabId);
      const func =
        gridref?.IsFiltersInDefault ||
        gridref?.gridRef?.current?.IsFiltersInDefault;
      return func ? func() : true;
    };

    const exportToExcel = () => {
      if (!activeTabId) return;
      const gridref = getGridRef(activeTabId);
      const exportFunc =
        gridref &&
        (gridref.ExportToExcel || gridref.gridRef?.current?.ExportToExcel);
      if (exportFunc) exportFunc();
    };

    // render
    const { hasExportToExcel, hasSetDefaultFilters } =
      (activeTabId && TABS[activeTabId]) || {};
    const IsFiltersInDefaultState = hasSetDefaultFilters
      ? IFilterInDefaultState()
      : true;
    return (
      <div className={styles.ToolbarRight}>
        {children}
        {!!desktopLink && (
          <OpenCardIconLink
            notCardHeaderBtn={true}
            fillMode={"solid"}
            onClick={() => {
              OpenRecord(desktopLink.refName, desktopLink?.objectId);
            }}
            title="Open Desktop Card"
          />
        )}
        {hasSetDefaultFilters && (
          <Button
            onClick={setDefaultSettings}
            title="Set Default Filters"
            icon="filter-clear"
            className={
              IsFiltersInDefaultState ? "" : dashboardStyles.BlueResetBtn
            }
          />
        )}
        {hasExportToExcel && (
          <Button
            icon="file-excel"
            title="Export to Excel"
            onClick={exportToExcel}
          ></Button>
        )}
        <Button icon="refresh" onClick={refresh} />
        {!!unSelect && (
          <Button icon="close" style={{ color: "red" }} onClick={unSelect} />
        )}
      </div>
    );
  }
);

export default TabsToolbarRight;
