import {
  SQL_DB_TK_Review_Response_AdjustedTimeLine,
  SQL_DB_TK_Review_Response_DispatchInfo,
  SQL_DB_TK_Review_Response_TETimeLine,
  SQL_DB_TK_Review_Response_TimeCards,
} from "../../core/api/generated/conterra";
import { INewComboboxItem } from "../../helpers/interfaces";

export enum ETCPerformAction {
  ChangeTime = "ChangeTime",
  Reassign = "Reassign",
  Reject = "Reject",
  Complete = "Complete",
  Confirm = "Confirm",
  NoChanges = "NoChanges",
}

export interface IProcessedTE {
  startFormatted: string;
  finishFormatted: string;
  hoursDuration: string;
  approvedDuration: string;
}

export interface IProcessedTCOriginalTE
  extends SQL_DB_TK_Review_Response_TETimeLine,
    IProcessedTE {}

export interface IProcessedTCAjustedTE
  extends SQL_DB_TK_Review_Response_AdjustedTimeLine,
    IProcessedTE {}

export interface IProcessedTC extends SQL_DB_TK_Review_Response_TimeCards {
  tcId: number;
  isPossibleActions: boolean;
  timeLine: IProcessedTCOriginalTE[];
  adjustedTimeLine: IProcessedTCAjustedTE[];
  dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
  prevTC?: IProcessedTC;
  nextTC?: IProcessedTC;
}

export enum EFiltersNames { // params to server
  RESOURCE = "resourceId", // todo PropsSQLTKReviewNewRequest.resourceId
  REVIEWER = "reviewerId",
  PP = "periodId",
  DISPATCH = "dispatchId",
  REVIEW_STATE = "reviewStateId",
  DATE = "date",
  DATE_TO = "finishDate",
}

export type TLocalStorageKeys = {
  [EFiltersNames.RESOURCE]: string;
  [EFiltersNames.REVIEWER]: string;
  [EFiltersNames.PP]: string;
  [EFiltersNames.DISPATCH]: string;
  [EFiltersNames.REVIEW_STATE]: string;
  [EFiltersNames.DATE]: string;
  [EFiltersNames.DATE_TO]: string;
};

export interface IFilters {
  [EFiltersNames.RESOURCE]?: INewComboboxItem | null;
  [EFiltersNames.REVIEWER]?: INewComboboxItem | null;
  [EFiltersNames.PP]?: INewComboboxItem | null;
  [EFiltersNames.DISPATCH]?: INewComboboxItem | null;
  [EFiltersNames.REVIEW_STATE]?: INewComboboxItem | null;
  [EFiltersNames.DATE]?: Date | null;
  [EFiltersNames.DATE_TO]?: Date | null;
}

export interface IDispatchDataFilter {
  dispatch?: INewComboboxItem | null;
  resource?: INewComboboxItem | null;
  date?: Date | null;
  finishDate?: Date | null;
}

export interface IPPFilterItem extends INewComboboxItem {
  isOverdue: boolean;
}
